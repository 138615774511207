import {
  useCallback,
  useRef,
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useLayoutEffect,
  useState
} from "react";
import { Category } from "api/generated";
import { UseFormReset } from "react-hook-form";
import { ActionInfo, FormInfo, SetFormInfo, SubCategoryFormValues } from "./types";
import { getActionInfo, getInitialValues } from "./utils";
import { useConfigContext } from "context/ConfigContext";

export const CategoriesFormContext = createContext<{
  categories: Category[] | undefined;
  actionInfo: ActionInfo;
  formInfo: FormInfo;
  setFormInfo: SetFormInfo;
  closeForm: VoidFunction;
  initialValues?: Category | SubCategoryFormValues;
  initResetFormFn: (resetFn: UseFormReset<any>) => void;
  resetForm: UseFormReset<any>;
}>({
  categories: undefined,
  actionInfo: {
    target: "",
    type: "",
    category: false,
    subCategory: false,
    edit: false,
    view: false,
    create: false
  },
  formInfo: {
    type: undefined,
    uuid: undefined,
    parentUuid: undefined
  },
  initialValues: undefined,
  setFormInfo: () => console.warn("setFormInfo is not implemented"),
  closeForm: () => console.warn("closeForm is not implemented"),
  initResetFormFn: () => console.warn("initResetFormFn is not implemented"),
  resetForm: () => console.warn("resetForm is not implemented")
});

export const CategoriesFormProvider = ({
  categories,
  children
}: {
  categories?: Category[];
  children: ReactNode;
}) => {
  const { config } = useConfigContext();
  const [formInfo, setInfo] = useState<FormInfo>({ type: null, uuid: null, parentUuid: null });
  const resetFormFnInitialized = useRef<boolean>(false);
  const resetFormFnRef = useRef<UseFormReset<Category>>(() =>
    console.warn(
      "resetFormFn is not initialized! Initialize it by passing reset function to initResetFormFn."
    )
  );

  const setFormInfo = useCallback<SetFormInfo>(
    (type, uuid, parentUuid) => setInfo({ type, uuid, parentUuid }),
    [setInfo]
  );

  const closeForm = useCallback(() => setFormInfo(null), [setFormInfo]);

  const initResetFormFn = useCallback((reset: UseFormReset<any>) => {
    if (!resetFormFnInitialized.current) resetFormFnInitialized.current = true;
    resetFormFnRef.current = reset;
  }, []);
  const value = useMemo(() => {
    return {
      categories,
      formInfo,
      actionInfo: getActionInfo(formInfo),
      initialValues: getInitialValues(
        formInfo,
        categories,
        config?.availableLanguages?.map(({ shortCode }) => shortCode || "") || []
      ),
      closeForm,
      initResetFormFn,
      resetForm: resetFormFnRef.current,
      setFormInfo
    };
  }, [formInfo, setFormInfo, categories]);

  useLayoutEffect(() => {
    if (value.initialValues && resetFormFnInitialized.current) {
      resetFormFnRef.current(value.initialValues);
    }
  }, [value.initialValues]);

  return <CategoriesFormContext.Provider value={value}>{children}</CategoriesFormContext.Provider>;
};

export const useCategoriesFormContext = () => {
  const context = useContext(CategoriesFormContext);

  if (context === undefined) {
    throw new Error("useCategoriesFormContext must be used within a CategoriesFormContext");
  }

  return context;
};
