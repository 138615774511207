import { FormLabel } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "components/Card";
import { useLocalizationContext } from "context/LocalizationContext";
import { useGetBenefitGrantQuery } from "hooks/queries/benefits";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BENEFIT_GRANT_BASE_ROUTE } from "routes/route-declarations";
import LinkButton from "ui-kit/buttons/LinkButton";
import { formatUtcDate } from "utils/date";

const View = () => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { id } = useParams();
  const { data, isLoading } = useGetBenefitGrantQuery(id || "");

  const { grant } = data || { grant: null };

  return isLoading ? (
    <Typography>Загрузка...</Typography>
  ) : (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <Typography lineHeight='1.5' variant='h5'>
            {t("pages.Benefits.Grants.item.benefit_grant")} &quot;
            {getValueByLang(grant?.benefit?.name)}&quot;
          </Typography>
          <LinkButton variant='contained' to={BENEFIT_GRANT_BASE_ROUTE}>
            {t("pages.Benefits.Grants.item.grant_list")}
          </LinkButton>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Box>
          <Typography variant='h6'>{t("pages.Benefits.Grants.item.basic_information")}</Typography>

          <Box marginTop={2} display='flex' justifyContent='space-between' width='100%'>
            <Box flexBasis='50%'>
              <FormLabel>{t("pages.Benefits.Grants.item.benefit")}:</FormLabel>
              <Typography width='100%' component='p'>
                {getValueByLang(grant?.benefit?.name)}
              </Typography>
            </Box>

            <Box flexBasis='50%'>
              <FormLabel>{t("pages.Benefits.Grants.item.validity")}:</FormLabel>
              <Typography width='100%' component='p'>
                {t("pages.Benefits.Grants.item.from")}{" "}
                {formatUtcDate(new Date(+(grant?.start || 0)), "dd.MM.yyyy")}{" "}
                {t("pages.Benefits.Grants.item.to")}{" "}
                {formatUtcDate(new Date(+(grant?.end || 0)), "dd.MM.yyyy")}
              </Typography>
            </Box>
          </Box>

          <Box marginTop={2} display='flex' justifyContent='space-between' width='100%'>
            <Box flexBasis='50%'>
              <FormLabel>{t("pages.Benefits.Grants.item.vehicle_number")}:</FormLabel>
              <Typography width='100%' component='p'>
                {grant?.vrp}
              </Typography>
            </Box>

            <Box flexBasis='50%'>
              <FormLabel>{t("common.zones")}:</FormLabel>
              <Typography width='100%' component='p'>
                {grant?.zones?.length
                  ? grant?.zones?.join(", ")
                  : t("pages.Benefits.Grants.item.all")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default View;
