import {
  City,
  Country,
  CreateDefaultResponse,
  GisType,
  ParkingLocation,
  Point,
  SubCategory,
  Zone,
  Parking
} from "api/generated";
import {
  defaultSetter,
  getCoordinatesWithCenter,
  setOptimisticMutateResultInList
} from "components/ObjectsSidebarContent/components/utils";
import {
  GeometryType,
  ObjectType,
  ParkingWithCoordinates
} from "pages/ObjectManagment/Objects/context/types";
import { getPolylineCenter } from "pages/ObjectManagment/Objects/context/utils";
import { Coordinates } from "pages/ObjectManagment/Objects/context/utils/MapObjectManagers";
import { queryClient } from "config/react-query";
import { PARKINGS_KEY } from "hooks/queries/parkings";
import { AxiosResponse } from "axios";
import { UseFormReset } from "react-hook-form";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import { DebouncedCallback } from "hooks/useDebouncedCallback";

export const defaultCoordinates = {
  longitude: 0,
  lattitude: 0
};

export type ParkingFormValues = Omit<Partial<ParkingWithCoordinates>, "location"> & {
  coordinatesStringified: string;
  location: ParkingLocation;
  onMap: GeometryType;
};

const { GIS_TYPE_POLYGON, GIS_TYPE_POINT, GIS_TYPE_LINE } = GisType;

export const defaultValues: ParkingFormValues = {
  uuid: undefined,
  name: {
    ru: "",
    en: ""
  },
  onMap: GeometryType.Point,
  description: {
    en: "",
    ru: ""
  },
  spacesTotal: undefined,
  spacesHandicapped: undefined,
  location: {
    type: GIS_TYPE_POINT,
    point: defaultCoordinates
  },
  address: {
    country: Country.COUNTRY_RUSSIA,
    city: City.CITY_SOCHI,
    street: {
      ru: "",
      en: ""
    },
    house: {
      ru: "",
      en: ""
    }
  },
  coordinatesStringified: "",
  center: {},
  contacts: {
    ru: "",
    en: ""
  },
  zoneUuid: { value: "loading" },
  categoryUuid: { value: "loading" },
  coordinates: []
};

export enum ParkingGisType {
  GIS_TYPE_UNSPECIFIED = "unspecified",
  GIS_TYPE_POLYGON = "polygon",
  GIS_TYPE_POINT = "point",
  GIS_TYPE_LINE = "line"
}

export const getSubmitValue = (
  {
    coordinatesStringified: _str,
    onMap: _onMap,
    uuid: _uuid,
    coordinates: _coordinates,
    spacesTotal,
    spacesHandicapped,
    ...values
  }: ParkingFormValues,
  {
    coordinates,
    rawCoordinates,
    geometryToDraw
  }: { coordinates: Point[]; rawCoordinates?: Coordinates; geometryToDraw: GeometryType | null }
): Parking => {
  let center = defaultCoordinates;

  if (rawCoordinates) {
    ({ coordinates, center } = getCoordinatesWithCenter(
      geometryToDraw === GeometryType.Polygon ? rawCoordinates[0] : rawCoordinates
    ));
  }

  const location: ParkingLocation =
    geometryToDraw === GeometryType.Point
      ? {
          type: GIS_TYPE_POINT,
          [geometryToDraw]: coordinates[0]
        }
      : {
          type: geometryToDraw === GeometryType.Line ? GIS_TYPE_LINE : GIS_TYPE_POLYGON,
          [geometryToDraw || GeometryType.Polygon]: {
            coordinates
          }
        };

  if (geometryToDraw === GeometryType.Line) {
    const polylineCenter = getPolylineCenter(rawCoordinates || []);
    center = {
      lattitude: polylineCenter[0],
      longitude: polylineCenter[1]
    };
  }

  return {
    ...values,
    spacesTotal: spacesTotal || spacesTotal === 0 ? parseInt(`${spacesTotal}`, 10) : spacesTotal,
    spacesHandicapped:
      spacesHandicapped || spacesHandicapped === 0
        ? parseInt(`${spacesHandicapped}`, 10)
        : spacesHandicapped,
    center,
    location
  };
};

export const handleAddResponse = (
  res: AxiosResponse<CreateDefaultResponse>,
  {
    coordinates,
    preparedValues,
    reset,
    resetNewObject,
    defaultValues,
    startDrawingNewObject,
    onGeometryChange,
    categories,
    zones
  }: {
    coordinates: Point[];
    preparedValues: ReturnType<typeof getSubmitValue> & { coordinates?: Point[] | undefined };
    reset: UseFormReset<ParkingFormValues>;
    resetNewObject: MapContextValue["resetNewObject"];
    defaultValues: ParkingFormValues;
    startDrawingNewObject: MapContextValue["startDrawingNewObject"];
    onGeometryChange: DebouncedCallback;
    categories?: SubCategory[];
    zones?: Zone[];
  }
) => {
  const uuid = res.data?.uuid?.value || "";

  setOptimisticMutateResultInList(
    queryClient,
    res.data?.uuid?.value || "",
    PARKINGS_KEY,
    "parkings",
    coordinates,
    (list) => {
      const updatedList = defaultSetter(uuid, "parkings", coordinates)(list);
      const newParking = {
        ...preparedValues,
        uuid: { value: uuid }
      };

      newParking.coordinates = coordinates;

      updatedList?.data?.parkings?.push(newParking);
      return updatedList;
    }
  );

  reset({
    ...defaultValues,
    categoryUuid: { value: categories?.[0]?.uuid?.value || undefined },
    zoneUuid: { value: zones?.[0]?.uuid?.value || undefined }
  });
  resetNewObject(ObjectType.Parking);

  startDrawingNewObject({
    type: ObjectType.Parking,
    geometry: GeometryType.Point,
    onChange: onGeometryChange
  });
  return res;
};

export const handleEditResponse = (
  res: AxiosResponse<CreateDefaultResponse>,
  {
    parking,
    coordinates,
    preparedValues
  }: {
    coordinates: Point[];
    preparedValues: ReturnType<typeof getSubmitValue>;
    formValues: Parking;
    parking?: Parking;
  }
) => {
  setOptimisticMutateResultInList(
    queryClient,
    parking?.uuid?.value || "",
    PARKINGS_KEY,
    "parkings",
    coordinates,
    (list) => {
      const updatedList = defaultSetter(res.data?.uuid?.value || "", "parkings", coordinates)(list);
      const itemIndex = updatedList?.data?.parkings.findIndex(
        (parking) => parking.uuid?.value === res.data?.uuid?.value
      );

      if (itemIndex !== undefined) {
        (updatedList?.data?.parkings as ParkingWithCoordinates[]).splice(itemIndex, 1, {
          uuid: { value: res.data?.uuid?.value },
          ...preparedValues,
          coordinates
        });
      }

      return updatedList;
    }
  );
};
