import { BaseSyntheticEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import {
  useEditSystemSettingsConfigMutation,
  useGetSystemSettingsConfigQuery
} from "hooks/queries/system-settings";
import Input from "ui-kit/form/Input";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import StrictSwitch from "./components/StrictSwitch";
import { FormControl, InputBox } from "./components/styled";
import { getInputRules } from "./utils";
import { useTranslation } from "react-i18next";
import Select from "ui-kit/form/Select";
import { InputAdornment, MenuItem } from "@mui/material";
import Switch from "ui-kit/form/Switch";

const Mobile = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetSystemSettingsConfigQuery();
  const { mutateAsync, isLoading: isSaving } = useEditSystemSettingsConfigMutation();

  const {
    handleSubmit: onSubmit,
    control,
    reset
  } = useForm({
    defaultValues: data
  });

  const { openErrorAlert } = useAlertStatus();

  const handleSubmit = (e: BaseSyntheticEvent) => {
    return onSubmit(
      async (values) => {
        mutateAsync(values);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Card>
      <Box marginX='-8px'>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.SystemSettings.MobileApp.mobile_app_settings")}
        </Typography>
        <Divider />
      </Box>
      <CardContent>
        {isLoading ? (
          <Typography variant='h5'>{t("common.loading")}</Typography>
        ) : (
          <Box component='form' onSubmit={handleSubmit}>
            <InputBox>
              <FormControl required>
                <FormLabel>{t("pages.SystemSettings.MobileApp.name")}:</FormLabel>
                <Select control={control} disabled={isSaving || isFetching} name='env.name'>
                  <MenuItem value='develop'>
                    {t("pages.SystemSettings.MobileApp.develop_option")}
                  </MenuItem>
                  <MenuItem value='production'>
                    {t("pages.SystemSettings.MobileApp.production_option")}
                  </MenuItem>
                </Select>
              </FormControl>
              <Box
                marginLeft={3}
                height={56}
                textAlign='center'
                display='flex'
                flexWrap='wrap'
                justifyContent='center'
                alignItems='baseline'
                flexBasis={0}
                minHeight={79}
              >
                <FormLabel sx={{ width: "100%" }}>{`${t(
                  "pages.SystemSettings.MobileApp.custom_keyboard_for_vrp"
                )}:`}</FormLabel>
                <FormControl>
                  <Box
                    minHeight='100%'
                    display='flex'
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Switch
                      control={control}
                      disabled={isSaving || isFetching}
                      name='mobileSettings.customKeyboardForVrp'
                    />
                  </Box>
                </FormControl>
              </Box>
            </InputBox>

            <InputBox marginTop={3}>
              <FormControl required>
                <FormLabel>{`${t(
                  "pages.SystemSettings.MobileApp.max_parking_duration"
                )}:`}</FormLabel>
                <Input
                  rules={getInputRules(t, { isOnlyDigits: true })}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='mobileSettings.sessionMaxDurationInHours'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {t("pages.SystemSettings.MobileApp.hours")}
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </InputBox>

            <Typography variant='h5' marginTop={3} marginBottom={1}>
              {t("pages.SystemSettings.MobileApp.android")}:
            </Typography>
            <InputBox>
              <FormControl required>
                <FormLabel>{`${t("pages.SystemSettings.MobileApp.build")}:`}</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='env.versions.android.build'
                />
              </FormControl>

              <FormControl required $marginLeft>
                <FormLabel>{`${t("pages.SystemSettings.MobileApp.release")}:`}</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='env.versions.android.release'
                />
              </FormControl>

              <StrictSwitch os='android' control={control} disabled={isSaving || isFetching} />
            </InputBox>

            <Typography variant='h5' marginTop={3} marginBottom={1}>
              {t("pages.SystemSettings.MobileApp.ios")}:
            </Typography>
            <InputBox>
              <FormControl required>
                <FormLabel>{`${t("pages.SystemSettings.MobileApp.build")}:`}</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='env.versions.ios.build'
                />
              </FormControl>

              <FormControl required $marginLeft>
                <FormLabel>{`${t("pages.SystemSettings.MobileApp.release")}:`}</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='env.versions.ios.release'
                />
              </FormControl>

              <StrictSwitch os='ios' control={control} disabled={isSaving || isFetching} />
            </InputBox>

            <ConfirmCancelPanel
              stackProps={{ sx: { maxWidth: 300, marginTop: 3 } }}
              onConfirmProps={{ type: "submit", disabled: isLoading || isSaving || isFetching }}
              onCancelProps={{ disabled: isLoading || isSaving || isFetching }}
              confirmLabel={
                isSaving ? t("common.saving") : isFetching ? t("common.loading") : t("common.save")
              }
              onConfirm={handleSubmit}
              onCancel={handleCancel}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Mobile;
