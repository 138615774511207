import { useWatch } from "react-hook-form";
import { ReportTablePaginationForm } from "../types";

const useReportPagination = () => {
  const offset = useWatch<ReportTablePaginationForm>({ name: "offset" }) || 0;
  const limit = useWatch<ReportTablePaginationForm>({ name: "limit" }) || 5;

  const startIndex = offset * limit;
  const endIndex = offset * limit + limit;

  return {
    offset,
    limit,
    startIndex,
    endIndex
  };
};

export default useReportPagination;
