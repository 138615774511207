import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { CATEGORY_MUTATE_KEY, GET_CATEGORIES_QUERY_KEY } from ".";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { Params } from "./";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

export const useDeleteCategoryMutation = ({
  name,
  formInfo,
  uuid,
  subCategories,
  resetForm
}: Params) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();
  const queryClient = useQueryClient();

  return useMutation(() => requests.adminGatewayDeleteCategory(`${uuid?.value}`), {
    mutationKey: [CATEGORY_MUTATE_KEY, DELETE_MUTATE_KEY, uuid?.value],
    onSettled() {
      queryClient.invalidateQueries([GET_CATEGORIES_QUERY_KEY]);
    },
    onSuccess() {
      if (
        formInfo.uuid?.value === uuid?.value ||
        subCategories?.find((subCat) => subCat.parentUuid?.value === formInfo.parentUuid?.value)
      ) {
        resetForm();
      }
      openSuccessAlert(
        t("pages.ObjectManagement.Categories.item.category_deleted").replace(
          "{category}",
          getValueByLang(name)
        )
      );
    },
    onError() {
      openErrorAlert(
        t("pages.ObjectManagement.Categories.item.could_not_delete_category").replace(
          "{category}",
          getValueByLang(name)
        )
      );
    }
  });
};
