import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import { useGetZoneQuery } from "hooks/queries/zones";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ObjectOutletContext } from "components/ObjectsSidebarContent/types";
import { useEffect } from "react";
import { ZONES_BASE_ROUTE } from "routes/route-declarations";
import { useDeleteZoneMutation } from "hooks/queries/zones/useDeleteZoneMutation";
import { AxiosError } from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { ZoneType } from "api/generated";
import { useGetTariffQuery } from "hooks/queries/tariffs";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { Paths } from "routes/const";
import { useAppAbility } from "context/AbilityContext";
import useDialog from "hooks/useDialog";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const { ZONE_TYPE_UNSPECIFIED, ZONE_TYPE_SIMPLE } = ZoneType;

const zoneTypeDictionary = {
  ZONE_TYPE_UNSPECIFIED: "pages.ObjectManagement.Objects.item.zones.zone_type_unspecified",
  ZONE_TYPE_SIMPLE: "pages.ObjectManagement.Objects.item.zones.zone_type_simple",
  ZONE_TYPE_WITH_PERMISSION: "pages.ObjectManagement.Objects.item.zones.zone_type_with_permission",
  ZONE_TYPE_PRIVATE: "pages.ObjectManagement.Objects.item.zones.zone_type_private",
  ZONE_TYPE_GATED: "pages.ObjectManagement.Objects.item.zones.zone_type_gated",
  ZONE_TYPE_CLOSED: "pages.ObjectManagement.Objects.item.zones.zone_type_closed"
};

export type Props = {
  id: string;
};

const View = ({ id }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const navigate = useNavigate();
  const { stopViewingMapObject } = useMapContext();
  const { setCurrentObject } = useOutletContext<ObjectOutletContext>();
  const { startViewingObject, showObjectOnMap, isAllObjectsOnMapInitialized } = useMapContext();
  const { mutateAsync, isLoading: isDeleting } = useDeleteZoneMutation({ value: id } || {}, true);
  const { isOpen, openDialog, confirm, closeDialog, cancel } = useDialog({
    onConfirm: () => {
      closeDialog();
      mutateAsync().then((res) => {
        if (res.status === 200) {
          navigate(ZONES_BASE_ROUTE);
        }
        return res;
      });
    }
  });

  const { data, error, isInitialLoading } = useGetZoneQuery(id);
  const zone = data?.data?.zone;

  const {
    data: tariffData,
    error: tariffError,
    isInitialLoading: isTariffInitialLoading
  } = useGetTariffQuery(zone?.tariffUuid?.value || "", {}, { enabled: !!zone?.tariffUuid?.value });
  const tariff = tariffData?.tariff;

  const handleEdit = () => navigate(`${ZONES_BASE_ROUTE}/${id}/edit`);

  const { openErrorAlert } = useAlertStatus();

  const handleShowObjectOnMap = () => showObjectOnMap(zone?.uuid?.value || "");

  useEffect(() => {
    if (data?.data?.zone) {
      setCurrentObject({ type: "zone", data: data.data.zone });
      startViewingObject(data.data.zone);
    }

    if (!data && error && (error as AxiosError)?.response?.status === 404) {
      navigate(ZONES_BASE_ROUTE);
    }
  }, [data, error]);

  useEffect(() => {
    if (data?.data?.zone && isAllObjectsOnMapInitialized) {
      handleShowObjectOnMap();
    }
  }, [data, isAllObjectsOnMapInitialized]);

  useEffect(() => {
    if (!isTariffInitialLoading && tariffError) {
      openErrorAlert(t("pages.Tariffs.item.could_not_load_tariff"));
    }
  }, [tariffError]);

  useEffect(() => stopViewingMapObject, []);

  return isInitialLoading ? (
    <CircularProgress sx={{ margin: "auto" }} data-testid='ZoneView__progress' />
  ) : (
    <Box display='flex' flexWrap='wrap' data-testid='ZoneView'>
      <FormLabel>{t("pages.ObjectManagement.Objects.item.zones.description")}:</FormLabel>
      <Typography width='100%' component='p' data-testid='ZoneView__description'>
        {getValueByLang(zone?.description)}
      </Typography>

      <Box marginTop={2} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.zones.type")}:</FormLabel>
        <Typography width='100%' component='p'>
          {t(zoneTypeDictionary[zone?.type || ZONE_TYPE_UNSPECIFIED])}
        </Typography>
      </Box>

      {zone?.type === ZONE_TYPE_SIMPLE && (
        <Box marginTop={2} width='100%'>
          <FormLabel>{t("pages.Tariffs.tariff")}:</FormLabel>
          <Typography width='100%' component='p'>
            {isTariffInitialLoading ? (
              t("pages.Tariffs.item.loading_tariff")
            ) : tariff?.uuid?.value ? (
              <Link to={`${Paths.Tariffs}/${tariff?.uuid?.value}`}>
                {getValueByLang(tariff?.name)}
              </Link>
            ) : (
              t("pages.Tariffs.item.could_not_load_tariff")
            )}
          </Typography>
        </Box>
      )}

      <Box display='flex' flexWrap='wrap' justifyContent={"stretch"} width='100%'>
        <Button
          disabled={isDeleting}
          sx={{ marginTop: 2, flexGrow: 1 }}
          variant='contained'
          onClick={handleShowObjectOnMap}
        >
          {t("pages.ObjectManagement.Objects.item.show_on_map")}
        </Button>
        {can("update", "Zone") && (
          <Button
            disabled={isDeleting}
            sx={{ marginTop: 2, marginLeft: 2, flexGrow: 1 }}
            variant='contained'
            onClick={handleEdit}
          >
            {t("common.edit")}
          </Button>
        )}
      </Box>
      {can("delete", "Zone") && (
        <LoadingButton
          sx={{ marginTop: 1, marginLeft: "auto" }}
          variant='contained'
          color='error'
          loading={isDeleting}
          onClick={openDialog}
        >
          {t("common.delete")}
        </LoadingButton>
      )}

      <Dialog
        testId='ObjectsDialog'
        title={t("pages.ObjectManagement.Objects.item.zones.delete_zone_title")}
        message={
          <Typography>
            {t("pages.ObjectManagement.Objects.item.zones.delete_zone_message").replace(
              "{number}",
              zone?.number || ""
            )}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onConfirm={confirm}
        onCancel={cancel}
      />
    </Box>
  );
};

export default View;
