import { Uuid } from "api/generated";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableCell from "@mui/material/TableCell";
import ProgressIconButton from "components/ProgressIconButton";
import { useDeleteGrantMutation } from "hooks/queries/benefits";
import useDialog from "hooks/useDialog";
import Dialog from "components/Dialog";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { Paths } from "routes/const";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

type Props = {
  uuid?: Uuid;
  vrp?: string;
  name?: Record<string, string>;
};

const ActionGrantCell = ({ uuid, vrp, name }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const { mutateAsync, isLoading } = useDeleteGrantMutation(uuid || { value: "" });
  const { isOpen, openDialog, confirm, closeDialog, cancel } = useDialog({
    onConfirm: () => {
      mutateAsync().then((res) => {
        if (res.status === 200) {
          closeDialog();
        }
        return res;
      });
    }
  });

  return (
    <>
      <TableCell sx={{ padding: 1 }}>
        <Link to={`${Paths.Benefits}${Paths.Grants}/${uuid?.value}`}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Link>
        {can("delete", "BenefitGrant") && (
          <ProgressIconButton isLoading={isLoading} onDelete={openDialog} Icon={<DeleteIcon />} />
        )}
      </TableCell>

      <Dialog
        title={t("pages.Benefits.Grants.list.delete_benefit_title")}
        message={
          <Typography>
            {t("pages.Benefits.Grants.list.delete_benefit_warning")
              .replace("{name}", getValueByLang(name, true))
              .replace("{vrp}", vrp || "")}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onConfirm={confirm}
        onCancel={cancel}
      />
    </>
  );
};

export default ActionGrantCell;
