import { ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";

export enum AlertStatus {
  Success = "success",
  Error = "error",
  Info = "info"
}

export type Props = {
  open: boolean;
  message: string | ReactNode;
  status: AlertStatus;
  onCloseAlert: VoidFunction;
} & AlertProps;

const StatusAlert = ({ open, message, status, onCloseAlert, ...rest }: Props) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={onCloseAlert}
    >
      <Alert severity={status} sx={{ width: "100%" }} onClose={onCloseAlert} {...rest}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default StatusAlert;
