import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Paths } from "routes/const";

export type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5' data-testid='tariff-name-item'>
          {title}
        </Typography>
        <Link to={`${Paths.Tariffs}`}>
          <Button sx={{ flex: "0 0 180px" }} variant='contained'>
            {t("pages.Tariffs.item.tariff_list")}
          </Button>
        </Link>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    </>
  );
};
export default Header;
