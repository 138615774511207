import { forwardRef } from "react";
import { NavLink as NavLinkBase, NavLinkProps } from "react-router-dom";

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) => (isActive ? "active" : undefined)}
    style={{ textDecoration: "none", color: "inherit" }}
  />
));

export default NavLink;
