import { RefObject, useCallback } from "react";

// Проверяет находится ли элемент (el) внутри указанного элемента (parent | parentArg)
const useClosest = <T extends Node>(parent?: RefObject<T> | Node | null) =>
  useCallback(
    function closest(
      el: EventTarget | RefObject<T> | Node | null,
      parentArg?: RefObject<T> | Node | null
    ): Node | null {
      parentArg = parentArg || parent;

      const parentNode = parentArg && "current" in parentArg ? parentArg.current : parentArg;
      const node = el && "current" in el ? el.current : (el as T);

      return node && (node === parentNode ? node : closest(node.parentNode, parentArg));
    },
    [parent]
  );

export default useClosest;
