import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams, Uuid } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { ABONEMENT_GRANTS_QUERY_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useDeleteGrantMutation = (uuid: Uuid, params?: RequestParams) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation(() => requests.adminGatewayDeleteAbonementGrant(uuid?.value || "", params), {
    mutationKey: [ABONEMENT_GRANTS_QUERY_KEY, DELETE_MUTATE_KEY, uuid?.value],
    onSuccess() {
      openSuccessAlert(t("pages.Abonements.Grant.item.abonement_grant_deleted"));
    },
    onError() {
      openErrorAlert(t("pages.Abonements.Grant.item.could_not_delete_abonement_grant"));
    }
  });
};
