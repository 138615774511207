import { forwardRef } from "react";
import { FieldValues, UseFormWatch, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";
import Input, { InputProps } from "ui-kit/form/Input";
import { parseAmount } from "./utils";

const MIN_AMOUNT = 10;

interface AmountMaskInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const AmountMaskInput = forwardRef<HTMLInputElement, AmountMaskInputProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={Number}
      inputRef={ref}
      min={0}
      max={99999999.99}
      // type any is from MUI docs
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
    />
  );
});

type AmountInputProps<T extends FieldValues> = InputProps & {
  balance: number;
  watch: UseFormWatch<T>;
};

const AmountInput = <T extends FieldValues>({
  balance,
  watch,
  name,
  InputProps,
  ...props
}: AmountInputProps<T>) => {
  const { t } = useTranslation();

  const getHelperText = (): string => {
    const value = watch(name as Path<T>);
    const floatValue = parseAmount(value);

    if (isNaN(floatValue) || floatValue < MIN_AMOUNT) {
      return t("pages.LegalEntityBalance.replenish_dialog.helpers.min_amount", {
        amount: `${MIN_AMOUNT} ₸`
      });
    }

    const formattedBalance = new Intl.NumberFormat("ru-KZ", {
      style: "currency",
      currency: "KZT",
      currencyDisplay: "symbol"
    }).format(balance / 100 + floatValue);

    return t("pages.LegalEntityBalance.replenish_dialog.helpers.balance_after_replenishment", {
      balance: formattedBalance
    });
  };

  return (
    <Input
      label={t("pages.LegalEntityBalance.replenish_dialog.labels.amount")}
      helperText={getHelperText()}
      InputProps={{ inputComponent: AmountMaskInput as any, ...InputProps }}
      name={name}
      rules={{
        validate: (value: string) => {
          const floatValue = parseAmount(value);
          return (
            floatValue >= MIN_AMOUNT ||
            t("pages.LegalEntityBalance.replenish_dialog.helpers.min_amount", {
              amount: `${MIN_AMOUNT} ₸`
            })
          );
        }
      }}
      {...props}
    />
  );
};

export default AmountInput;
