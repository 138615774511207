import Pagination from "components/Pagination";
import { ReportTablePaginationForm } from "pages/Reports/types";
import { ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface ReportTablePaginationContainerProps {
  children: ReactNode;
  total: number;
}

const ReportTablePaginationContainer = ({
  children,
  total
}: ReportTablePaginationContainerProps) => {
  const form = useForm<ReportTablePaginationForm>({
    defaultValues: {
      limit: 5,
      offset: 0
    }
  });

  return (
    <FormProvider {...form}>
      {children}
      <Pagination control={form.control} setValue={form.setValue} total={total} />
    </FormProvider>
  );
};

export default ReportTablePaginationContainer;
