import { useParkingsAllSumPSReport } from "hooks/queries/reports";
import { ReportTableComponentProps, SumPSReportTable } from "../common";

const SumPSTable = ({ filters }: ReportTableComponentProps) => {
  const { data, isFetching } = useParkingsAllSumPSReport(filters);

  return <SumPSReportTable isFetching={isFetching} rows={data?.data.sums} />;
};

export default SumPSTable;
