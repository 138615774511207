import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { ReportFiltersForm, ReportType } from "pages/Reports/types";
import { getReportTabs, mapReportFiltersFormToApi } from "pages/Reports/utils";
import { useReportCsvMutation } from "hooks/queries/reports";
import { ReportTabs, ReportTabsProps } from "../common";
import Filters from "./Filters";

interface Report extends ReportTabsProps {
  reportType: ReportType;
}

const ReportsContainer = () => {
  const { t } = useTranslation();

  const [report, setReport] = useState<Report | null>(null);

  const handleSubmitFilters: SubmitHandler<ReportFiltersForm> = ({ reportType, ...filters }) => {
    setReport({
      reportType,
      tabs: getReportTabs(reportType),
      filters: mapReportFiltersFormToApi(filters)
    });
  };

  const { mutate } = useReportCsvMutation();

  const handleDownload = ({ reportType, ...filters }: ReportFiltersForm) => {
    mutate({
      reportType,
      filters: mapReportFiltersFormToApi(filters)
    });
  };

  return (
    <Box>
      <Typography component='h2' variant='h5'>
        {t("pages.Reports.title")}
      </Typography>

      <Filters sx={{ marginY: 2 }} onSubmit={handleSubmitFilters} onDownload={handleDownload} />

      {report && <ReportTabs key={report.reportType} filters={report.filters} tabs={report.tabs} />}
    </Box>
  );
};

export default ReportsContainer;
