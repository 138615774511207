import { Box, IconButton } from "@mui/material";
import { FC } from "react";
import { Control, UseFormGetFieldState } from "react-hook-form";
import EmailInput from "ui-kit/form/EmailInput";
import { FormInputs } from "./types";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

interface FeedbackEmailInputProps {
  topicIndex: number;
  emailIndex: number;
  control: Control<FormInputs>;
  onDelete: (index: number) => void;
  getFieldState: UseFormGetFieldState<FormInputs>;
}

const FeedbackEmailInput: FC<FeedbackEmailInputProps> = ({
  control,
  topicIndex,
  emailIndex,
  onDelete
}) => {
  const { t } = useTranslation();

  const isEmailFirst = emailIndex === 0;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}>
      <EmailInput
        control={control}
        name={`topics.${topicIndex}.emails.${emailIndex}.value`}
        rules={{ required: { value: true, message: t("common.input_rules.required") } }}
        sx={{ mt: isEmailFirst ? 0 : 1, flexGrow: 1, mr: isEmailFirst ? "48px" : 0 }}
      />

      {!isEmailFirst && (
        <IconButton onClick={() => onDelete(emailIndex)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default FeedbackEmailInput;
