import TablePagination, { TablePaginationProps } from "@mui/material/TablePagination";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import TablePaginationActions from "components/TablePaginationActions";

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<{
    offset?: number;
    limit?: number;
  }>;
  total: number;
  onLimitChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPageChange?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
} & Omit<TablePaginationProps, "count" | "rowsPerPage" | "page" | "onPageChange" | "onLimitChange">;

const Pagination = ({ control, setValue, total, onLimitChange, onPageChange, ...rest }: Props) => {
  const offset = useWatch({ control, name: "offset" }) ?? 0;
  const limit = useWatch({ control, name: "limit" }) ?? 20;

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setValue("offset", page);

    if (onPageChange) onPageChange(e, page * limit);
  };

  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue("limit", +e?.target.value);
    setValue("offset", 0);

    if (onLimitChange) onLimitChange(e);
  };

  const handlePageInputChange = (page: number) => setValue("offset", (page || 1) - 1);

  return (
    <TablePagination
      data-testid='ParkingList__pagination'
      sx={{
        "& .MuiToolbar-root": {
          flexWrap: "wrap",
          justifyContent: "end",
          paddingLeft: 0,
          "& .MuiTablePagination-spacer": { display: "none" },
          "& > .MuiInputBase-root": { marginRight: "5px" },
          "& .MuiTablePagination-actions": { marginLeft: 0 }
        }
      }}
      rowsPerPageOptions={[5, 10, 20]}
      component='div'
      count={total}
      rowsPerPage={+limit}
      page={+offset}
      showFirstButton
      showLastButton
      labelRowsPerPage={null}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleLimitChange}
      {...rest}
      ActionsComponent={(props) => (
        <TablePaginationActions
          onInputChange={handlePageInputChange}
          offset={offset}
          limit={limit}
          {...props}
        />
      )}
    />
  );
};

export default Pagination;
