import { BaseSyntheticEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import {
  useEditSystemSettingsConfigMutation,
  useGetSystemSettingsConfigQuery
} from "hooks/queries/system-settings";
import Input from "ui-kit/form/Input";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import { getInputRules } from "./utils";
import { useTranslation } from "react-i18next";
import { FormControl } from "@mui/material";
import MultiLangInput from "ui-kit/form/MultiLangInput";

const Currency = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetSystemSettingsConfigQuery();
  const { mutateAsync, isLoading: isSaving } = useEditSystemSettingsConfigMutation();

  const {
    handleSubmit: onSubmit,
    control,
    reset
  } = useForm({
    defaultValues: data
  });

  const { openErrorAlert } = useAlertStatus();

  const handleSubmit = (e: BaseSyntheticEvent) => {
    return onSubmit(
      async (values) => {
        mutateAsync(values);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Card>
      <Box marginX='-8px'>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.SystemSettings.Currency.currency")}
        </Typography>
        <Divider />
      </Box>
      <CardContent>
        {isLoading ? (
          <Typography variant='h5'>{t("common.loading")}</Typography>
        ) : (
          <Box component='form' onSubmit={handleSubmit}>
            <Box width='100%'>
              <FormControl required fullWidth>
                <MultiLangInput
                  required
                  langsAlwaysOpen
                  variant='outlined'
                  label={`${t("pages.SystemSettings.Currency.title")}:`}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='currency.title'
                />
              </FormControl>
            </Box>
            <Box width='100%' marginTop={2}>
              <FormControl required fullWidth>
                <FormLabel>{t("pages.SystemSettings.Currency.alpha_code")}:</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='currency.alphaCode'
                />
              </FormControl>
            </Box>
            <Box width='100%' marginTop={2}>
              <FormControl required fullWidth>
                <FormLabel>{t("pages.SystemSettings.Currency.number_code")}:</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='currency.numberCode'
                />
              </FormControl>
            </Box>
            <Box width='100%' marginTop={2}>
              <FormControl required fullWidth>
                <FormLabel>{t("pages.SystemSettings.Currency.symbol")}:</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  control={control}
                  disabled={isSaving || isFetching}
                  name='currency.symbol'
                />
              </FormControl>
            </Box>

            <ConfirmCancelPanel
              stackProps={{ sx: { maxWidth: 300, marginTop: 3 } }}
              onConfirmProps={{ type: "submit", disabled: isLoading || isSaving || isFetching }}
              onCancelProps={{ disabled: isLoading || isSaving || isFetching }}
              confirmLabel={
                isSaving ? t("common.saving") : isFetching ? t("common.loading") : t("common.save")
              }
              onConfirm={handleSubmit}
              onCancel={handleCancel}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Currency;
