import { SxProps, Theme } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";

export type InputProps = Omit<TextFieldProps, "variant"> & {
  // Required Props
  control: Control<any>;
  name: string;

  // Optional Prop
  autoComplete?: string;
  autoFocus?: boolean;
  classes?: object;
  defaultValue?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  FormHelperTextProps?: object;
  helperText?: string;
  hiddenLabel?: boolean;
  id?: string;
  InputLabelProps?: object;
  inputProps?: object;
  label?: string;
  margin?: "dense" | "none" | "normal";
  maxRows?: number | string;
  minRows?: number | string;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  rules?: object;
  size?: "small" | "medium" | undefined;
  sx?: SxProps<Theme>;
  type?:
    | "color"
    | "date"
    | "datetime-local"
    | "datetime"
    | "email"
    | "file"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

  variant?: TextFieldProps["variant"];
  error?: FieldError;
  iconButtonProps?: IconButtonProps;
};

const SearchInput: FC<InputProps> = ({
  control,
  name,
  defaultValue,
  rules,
  helperText,
  inputRef,
  variant = "standard",
  error,
  iconButtonProps,
  ...rest
}) => {
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ""}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...restField }, fieldState }) => {
        const _error = error || fieldState?.error;
        return (
          <Box display='flex' alignItems='center' paddingBottom={2}>
            <TextField
              {...restField}
              {...rest}
              error={!!_error}
              variant={variant}
              inputRef={($ref) => {
                if (inputRef) {
                  if (inputRef instanceof Function) inputRef($ref);
                  else
                    console.warn(` inputRef prop in Input with name "${name}" is not a function`);
                }
                return ref($ref);
              }}
              helperText={_error ? _error?.message || "Invalid Input" : helperText}
            />
            <IconButton type='submit' aria-label='search' {...iconButtonProps}>
              <SearchIcon />
            </IconButton>
          </Box>
        );
      }}
    />
  );
};

export default SearchInput;
