import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import { RoadsideSessionStatus, RoadsideSessionsResponse } from "api/generated";
import { TableBodyCell, TableHeaderCell } from "./components/styled";
import { useGetRoadsideSessionListQuery } from "hooks/queries/roadside-sessions/useGetRoadsideSessionListQuery";
import Pagination from "components/Pagination";
import ParkingCell from "./components/ParkingCell";
import VehicleCell from "./components/VehicleCell";
import { formatUtcDate } from "utils/date";
import { useTranslation } from "react-i18next";

const {
  ROADSIDE_SESSION_STATUS_START,
  ROADSIDE_SESSION_STATUS_ACTIVE,
  ROADSIDE_SESSION_STATUS_STOP,
  ROADSIDE_SESSION_STATUS_END,
  ROADSIDE_SESSION_STATUS_CANCEL,
  ROADSIDE_SESSION_STATUS_UNSPECIFIED
} = RoadsideSessionStatus;

export const roadsideSessionStatus = {
  [ROADSIDE_SESSION_STATUS_START]: "pages.ParkingSessions.roadside_session_status_start",
  [ROADSIDE_SESSION_STATUS_ACTIVE]: "pages.ParkingSessions.roadside_session_status_active",
  [ROADSIDE_SESSION_STATUS_STOP]: "pages.ParkingSessions.roadside_session_status_stop",
  [ROADSIDE_SESSION_STATUS_END]: "pages.ParkingSessions.roadside_session_status_end",
  [ROADSIDE_SESSION_STATUS_CANCEL]: "pages.ParkingSessions.roadside_session_status_cancel",
  [ROADSIDE_SESSION_STATUS_UNSPECIFIED]: "pages.ParkingSessions.roadside_session_status_unspecified"
};

const RoadsideSessionList = () => {
  const { t } = useTranslation();
  const { setValue, control, watch } = useForm<{ offset?: number; limit?: number }>({
    defaultValues: {
      offset: 0,
      limit: 20
    }
  });

  const offset = watch("offset") ?? 0;
  const limit = watch("limit") ?? 20;

  const { data, isLoading, isInitialLoading, isFetching } = useGetRoadsideSessionListQuery({
    offset: `${offset * limit}`,
    limit: `${limit}`
  });

  const { total, sessions } = (data || {}) as RoadsideSessionsResponse;

  const shouldShowTable = !isInitialLoading && Array.isArray(sessions);

  return (
    <Card>
      {shouldShowTable ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>{t("pages.ParkingSessions.uuid")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.user_uuid")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.parking")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.vehicle")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.start_time")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.finish_time")}</TableHeaderCell>
                  <TableHeaderCell>{t("pages.ParkingSessions.status")}</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions?.map((session) => (
                  <TableRow key={session.uuid}>
                    <TableBodyCell>{session.uuid}</TableBodyCell>
                    <TableBodyCell>{session.userUuid}</TableBodyCell>
                    <ParkingCell {...session.parking} />
                    <VehicleCell {...session.vehicle} t={t} />
                    <TableBodyCell>
                      {session.startTime &&
                        formatUtcDate(new Date(+(session.startTime || 0)), "dd.MM.yyyy, HH:mm:ss")}
                    </TableBodyCell>
                    <TableBodyCell>
                      {session.stopTime &&
                        formatUtcDate(new Date(+(session.stopTime || 0)), "dd.MM.yyyy, HH:mm:ss")}
                    </TableBodyCell>
                    <TableBodyCell>
                      {t(
                        roadsideSessionStatus[session.status || ROADSIDE_SESSION_STATUS_UNSPECIFIED]
                      )}
                    </TableBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination control={control} total={+(total || 0)} setValue={setValue} />
        </>
      ) : isLoading || isFetching ? (
        <Typography padding={1} variant='h6'>
          {t("pages.ParkingSessions.loading_parking_sessions")}
        </Typography>
      ) : (
        <Typography padding={1} variant='h6'>
          {t("pages.ParkingSessions.parking_sessions_not_found")}
        </Typography>
      )}
    </Card>
  );
};

export default RoadsideSessionList;
