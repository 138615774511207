import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { useAuthContext } from "context/AuthContext";
import { Permission } from "context/AbilityContext";

export const useLoginMutation = () => {
  const { setIsLoggedIn, setToken, setPermissions, setRefreshToken } = useAuthContext();

  return useMutation(requests.adminGatewayLogin, {
    onSuccess: ({ data }) => {
      const { accessToken, permissions, refreshToken } = data || {};
      if (accessToken) {
        setIsLoggedIn(true);
        setToken(accessToken);
        setRefreshToken(refreshToken || "");
        setPermissions((permissions as Permission[]) || null);
      }
    }
  });
};
