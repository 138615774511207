import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { CreateHolidayRequest, HolidayType } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { CREATE_MUTATE_KEY } from "../action-keys";
import { HOLIDAYS_KEY } from ".";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useCreateHolidayMutation = (date: number) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: CreateHolidayRequest) => requests.adminGatewayCreateHoliday(body),
    mutationKey: [HOLIDAYS_KEY, CREATE_MUTATE_KEY, date],
    onSuccess(_, payload) {
      openSuccessAlert(
        `${
          payload.type === HolidayType.HOLIDAY_TYPE_DAYOFF
            ? t("pages.HolidaysCalendar.item.weekend")
            : t("pages.HolidaysCalendar.item.festive")
        } ${t("pages.HolidaysCalendar.item.day_created")}`
      );
      queryClient.invalidateQueries([HOLIDAYS_KEY]);
    },
    onError({ response }, payload) {
      showErrors({
        ...response?.data,
        defaultMessage:
          !response?.data?.details?.length && !response?.data?.message
            ? t("pages.HolidaysCalendar.item.could_not_create_day").replace(
                "{dayType}",
                (payload.type === HolidayType.HOLIDAY_TYPE_DAYOFF
                  ? t("pages.HolidaysCalendar.item.weekend")
                  : t("pages.HolidaysCalendar.item.festive")
                ).toLowerCase()
              )
            : null
      });
      queryClient.invalidateQueries([HOLIDAYS_KEY]);
    }
  });
};
