import { useMemo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { theme } from "config/theme";
import { useGetBenefitsQuery } from "hooks/queries/benefits";
import { Benefit, BenefitPeriod } from "api/generated";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const BenefitsList = () => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { isLoading, data } = useGetBenefitsQuery({});
  const benefits = useMemo(() => {
    const benefits = (data as { benefits?: Benefit[] })?.benefits;
    return benefits?.filter((benefit) => benefit.active);
  }, [data]);

  const printDayOfWeek = (days: number[]) => {
    const message = [] as string[];
    if (days.length === 7) return t("pages.Benefits.Active.list.everyday");
    if (days.includes(1)) message.push(t("pages.Benefits.Active.list.mon"));
    if (days.includes(2)) message.push(t("pages.Benefits.Active.list.tue"));
    if (days.includes(3)) message.push(t("pages.Benefits.Active.list.wed"));
    if (days.includes(4)) message.push(t("pages.Benefits.Active.list.thu"));
    if (days.includes(5)) message.push(t("pages.Benefits.Active.list.fri"));
    if (days.includes(6)) message.push(t("pages.Benefits.Active.list.sat"));
    if (days.includes(7)) message.push(t("pages.Benefits.Active.list.sun"));
    return message.join(", ");
  };

  const printOddOrEven = (days: number[]) => {
    if (!days.length) return null;
    let oddOrEven = "";
    if (days[0] % 2 === 0) oddOrEven = t("pages.Benefits.Active.list.even");
    else oddOrEven = t("pages.Benefits.Active.list.odd");
    return (
      <Typography flexBasis='100%'>
        {t("pages.Benefits.Active.list.on_evenOrOdd_days").replace("{oddOrEven}", oddOrEven)}
      </Typography>
    );
  };

  const printHours = (periods: BenefitPeriod[] | undefined) => {
    if (periods?.length === 1 && periods[0].start === 0 && periods[0].end === 86400) {
      return (
        <Box>
          <Typography>{t("pages.Benefits.Active.list.round_the_clock")}</Typography>
        </Box>
      );
    }
    return (
      <Box>
        {periods?.map(({ start = 0, end = 0 }, index) => {
          const startHour = Math.floor(start / 3600);
          const startMinutes = Math.floor((start - startHour * 3600) / 60);

          let endHour = Math.floor(end / 3600);
          const endMinutes = Math.floor((end - endHour * 3600) / 60);
          if (endHour === 24) endHour = 0;
          return (
            <Typography key={index}>
              {startHour.toString().padStart(2, "0")}:{startMinutes.toString().padStart(2, "0")} -{" "}
              {endHour.toString().padStart(2, "0")}:{endMinutes.toString().padStart(2, "0")};
            </Typography>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Typography component='h2' variant='h5' padding='1rem 0'>
        {t("pages.Benefits.Active.list.active_benefits")}
      </Typography>
      {isLoading ? (
        <Typography padding='1rem' variant='h6'>
          {t("pages.Benefits.Active.list.loading_benefits")}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {benefits?.map((benefit) => (
            <Grid item sm={12} md={6} lg={4} key={benefit.uuid?.value} data-testid='benefit-item'>
              <Paper sx={{ padding: "1rem" }}>
                <Typography component='h3' variant='h6'>
                  {getValueByLang(benefit?.name)}
                </Typography>
                <Typography fontSize='2rem' color={theme.palette.text.secondary}>
                  <Typography fontSize='2rem' component='span' color='#058d36'>
                    -{benefit.discountPercent}
                  </Typography>
                  %
                </Typography>
                <Typography component='span' color={theme.palette.text.secondary}>
                  {t("pages.Benefits.Active.list.discount_as_percentage")}
                </Typography>
                <Divider />
                <Box display='flex' gap='0 1rem'>
                  <Typography color={theme.palette.text.secondary}>
                    {t("pages.Benefits.Active.list.work_hours")}:
                  </Typography>
                  {printHours(benefit?.periods)}
                </Box>
                <Box display='flex' gap='0 1rem' flexWrap='wrap'>
                  <Typography color={theme.palette.text.secondary}>
                    {t("pages.Benefits.Active.list.days_of_week")}:
                  </Typography>
                  <Box>
                    <Typography>
                      {printDayOfWeek((benefit.periods && benefit.periods[0].dayOfWeeks) || [])}
                    </Typography>
                  </Box>
                  {printOddOrEven((benefit.periods && benefit.periods[0].days) || [])}
                </Box>
                <Typography color={theme.palette.text.secondary}>
                  {benefit?.currentUsesCount === 0
                    ? t("pages.Benefits.Active.list.no_active_benefit_grants")
                    : `${t("pages.Benefits.Active.list.active_grants")}: ${
                        benefit?.currentUsesCount
                      }`}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default BenefitsList;
