import { FormControl, FormLabel, InputAdornment, Typography } from "@mui/material";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputs } from "./types";
import { FC } from "react";
import Input from "ui-kit/form/Input";
import { topicNameLangs } from "./utils";

interface TopicNameInputProps {
  topicIndex: number;
  control: Control<FormInputs>;
}

const TopicNameInput: FC<TopicNameInputProps> = ({ control, topicIndex }) => {
  const { t } = useTranslation();

  return (
    <FormControl component='fieldset' variant='standard' margin='none' fullWidth>
      <FormLabel>{t("pages.Feedback.labels.topic")}</FormLabel>

      {topicNameLangs.map((lang, index) => (
        <Input
          key={lang}
          control={control}
          name={`topics.${topicIndex}.name.${lang}`}
          sx={{ mt: index === 0 ? 0 : 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Typography variant='caption'>{lang.toUpperCase()}</Typography>
              </InputAdornment>
            )
          }}
          rules={{
            required: { value: true, message: t("common.input_rules.required") }
          }}
          inputProps={{ maxlength: 1000 }}
        />
      ))}
    </FormControl>
  );
};

export default TopicNameInput;
