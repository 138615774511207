import Drawer, { DrawerProps } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { CardSidebarProps } from "./types";
import { DEFAULT_WIDTH, getSx } from "./utils";

const CardSidebar = ({
  title,
  header,
  divider = true,
  drawerProps,
  children,
  width = DEFAULT_WIDTH,
  fitContent = false,
  ...rest
}: CardSidebarProps) => {
  const sx = getSx({ width, sx: drawerProps?.sx });

  const boxSx = fitContent
    ? {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }
    : {
        overflow: "auto"
      };

  return (
    <Box width={width} marginRight={2} flexShrink={0} height='100%' {...rest}>
      <Card>
        <Drawer variant='permanent' {...(drawerProps as DrawerProps)} sx={sx}>
          <Box sx={boxSx}>
            {header ? (
              header
            ) : title ? (
              <Typography component='h2' variant='h5' fontWeight='500' padding={2}>
                {title}
              </Typography>
            ) : null}
            {divider && <Divider />}
            <Box padding={2} sx={boxSx}>
              {children}
            </Box>
          </Box>
        </Drawer>
      </Card>
    </Box>
  );
};

export default CardSidebar;
