import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Permission, PermissionFull, StartType, SystemSettingsConfigCurrency } from "api/generated";
import { useDeletePermissionMutation, useGetPermissionsQuery } from "hooks/queries/permissions";
import LinkButton from "ui-kit/buttons/LinkButton";
import { PERMISSIONS_BASE_ROUTE } from "routes/route-declarations";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "components/Dialog";
import useDialog from "hooks/useDialog";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";
import { theme } from "config/theme";
import { DateTime } from "luxon";

const {
  START_TYPE_FROMTOMORROW,
  START_TYPE_FROMTODAY,
  START_TYPE_FROMYESTERDAY,
  START_TYPE_UNSPECIFIED
} = StartType;

export const startTypes = {
  [START_TYPE_FROMTOMORROW]: "pages.Permissions.common.start_from_next_day",
  [START_TYPE_FROMTODAY]: "pages.Permissions.common.start_from_day_of_purchase",
  [START_TYPE_FROMYESTERDAY]: "pages.Permissions.common.start_from_previous_day",
  [START_TYPE_UNSPECIFIED]: "pages.Permissions.common.start_not_specified"
};

export type Props = Permission &
  PermissionFull & {
    refetchList: ReturnType<typeof useGetPermissionsQuery>["refetch"];
    currency: SystemSettingsConfigCurrency;
  };

const PermissionListItem = ({
  refetchList,
  uuid,
  name,
  price,
  dayOfWeeks,
  start,
  end,
  startType,
  zones,
  currentUsesCount,
  currency
}: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const { mutateAsync, isLoading: isDeleting } = useDeletePermissionMutation(undefined, undefined, {
    onSuccess() {
      refetchList();
    }
  });

  const { isOpen, openDialog, closeDialog, confirm, cancel } = useDialog({
    onConfirm: () => {
      mutateAsync({ value: uuid }).finally(() => refetchList());
      closeDialog();
    }
  });

  const weeksData: { day: number; title: string }[] = [
    { day: 1, title: t("pages.Permissions.Active.item.mon") },
    { day: 2, title: t("pages.Permissions.Active.item.tue") },
    { day: 3, title: t("pages.Permissions.Active.item.wed") },
    { day: 4, title: t("pages.Permissions.Active.item.thu") },
    { day: 5, title: t("pages.Permissions.Active.item.fri") },
    { day: 6, title: t("pages.Permissions.Active.item.sat") },
    { day: 7, title: t("pages.Permissions.Active.item.sun") }
  ];
  const weekTitles: string[] = [];
  weeksData.map((w) => dayOfWeeks?.includes(w.day) && weekTitles.push(w.title));

  const startTime = (start && DateTime.fromSeconds(start).setLocale("ru")) || undefined;
  const endTime = (end && DateTime.fromSeconds(end).setLocale("ru")) || undefined;
  const canUpdate = can("update", "Permission");
  const canDelete = can("delete", "Permission");

  return (
    <Paper sx={{ padding: "1rem" }}>
      <Typography component='h3' variant='h6'>
        {getValueByLang(name)}
      </Typography>

      <Typography fontSize='2rem' color={theme.palette.text.secondary}>
        <Typography fontSize='2rem' component='span' color='#058d36'>
          {(+(price || "0")).toLocaleString("ru")}.00 {currency.symbol}
        </Typography>
      </Typography>

      <Divider />

      <Box display='flex' marginTop={1}>
        <Typography color={theme.palette.text.secondary}>
          {t("pages.Permissions.Active.item.days_of_week")}:
        </Typography>
        &nbsp;{weekTitles?.join(", ")}
      </Box>

      <Box display='flex'>
        <Typography color={theme.palette.text.secondary}>
          {t("pages.Permissions.Active.item.work_hours")}:
        </Typography>
        &nbsp;{t("pages.Permissions.Active.item.from")} {startTime?.toFormat("T")}&nbsp;
        {t("pages.Permissions.Active.item.to")} {endTime?.toFormat("T")}
      </Box>

      <Box display='flex'>
        <Typography minWidth='150px' color={theme.palette.text.secondary}>
          {t("pages.Permissions.Active.list.grant_duration_start")}:
        </Typography>
        &nbsp;{t(startTypes[startType || START_TYPE_UNSPECIFIED])}
      </Box>

      <Box display='flex'>
        <Typography minWidth='150px' color={theme.palette.text.secondary}>
          {t("pages.Permissions.common.tariffication_type")}:
        </Typography>
        &nbsp;{t("pages.Permissions.common.discount_100")}
      </Box>

      <Box display='flex'>
        <Typography color={theme.palette.text.secondary}>{t("common.zones")}:</Typography>
        &nbsp;{zones?.length ? zones?.join(", ") : t("common.all_zones")}
      </Box>

      <Typography color={theme.palette.text.secondary}>
        {currentUsesCount
          ? `${t("pages.Permissions.common.active_grants")}: ${currentUsesCount}`
          : t("pages.Permissions.common.no_active_grants")}
      </Typography>

      {(canUpdate || canDelete) && (
        <>
          <Divider sx={{ marginTop: 1 }} />

          <Box marginTop={2}>
            {canUpdate && (
              <LinkButton
                fullWidth
                variant='contained'
                disabled={isDeleting}
                to={`${PERMISSIONS_BASE_ROUTE}/${uuid}/edit`}
              >
                {t("common.edit")}
              </LinkButton>
            )}
            {canDelete && (
              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                variant='contained'
                color='error'
                onClick={openDialog}
                loading={isDeleting}
              >
                {t("common.delete")}
              </LoadingButton>
            )}
          </Box>

          <Dialog
            title={t("pages.Permissions.common.delete_permission_title")}
            message={
              <Typography>
                {t("pages.Abonements.common.delete_abonement_body")} &ldquo;{getValueByLang(name)}
              </Typography>
            }
            showDeleteWarning
            confirmButtonText={t("common.delete")}
            typeError
            open={isOpen}
            onConfirm={confirm}
            onCancel={cancel}
          />
        </>
      )}
    </Paper>
  );
};

export default PermissionListItem;
