import { Control } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import SearchInput from "ui-kit/form/SearchInput";
import { TextFieldProps } from "@mui/material";

type SearchType = {
  placeholder: string;
  onSearch: (value: string) => void;
  control: Control<any>;
};

const SearchBar = ({ placeholder, onSearch, control }: SearchType) => {
  const handleSearch: TextFieldProps["onChange"] = (e) => {
    onSearch(e.target.value);
  };

  return (
    <FormControl>
      <SearchInput
        sx={{ minWidth: "400px" }}
        onChange={handleSearch}
        label={placeholder}
        control={control}
        name='query'
      />
    </FormControl>
  );
};

export default SearchBar;
