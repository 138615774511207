import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, IconButton, TextField } from "@mui/material";
import TablePaginationActionsMui, {
  TablePaginationActionsProps as TablePaginationActionsPropsMui
} from "@mui/material/TablePagination/TablePaginationActions";
import useClosest from "hooks/useClosest";
import { ChangeEvent, KeyboardEvent, useEffect, useLayoutEffect, useRef, useState } from "react";

export type ActionsComponentProps = TablePaginationActionsPropsMui & {
  offset?: number;
  limit?: number;
  onInputChange: (value: number) => void;
};

const DEFAULT_INPUT_WIDTH = 65;
const CHAR_WIDTH = 9;

const TablePaginationActions = ({
  offset = 0,
  limit = 0,
  count,
  onInputChange,
  ...props
}: ActionsComponentProps) => {
  const page = offset + 1;
  const hasRemainder = count % limit !== 0;
  const maxPages = Math.floor(count / limit) + +hasRemainder;

  const [inputValue, setInputValue] = useState<string | number>(page);
  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  const $button = useRef(null);
  const $input = useRef<HTMLInputElement>();

  const closest = useClosest($button);

  const getNewPage = (newPage: number) => (newPage > maxPages ? maxPages : newPage) || 1;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value === "") {
      setInputWidth(DEFAULT_INPUT_WIDTH);
      return setInputValue(value);
    }
    if (value !== "" && !/^\d+$/.test(value)) return;

    setInputValue(getNewPage(+value));
    setInputWidth(DEFAULT_INPUT_WIDTH);
  };

  const goToPage = () => {
    onInputChange(getNewPage(+inputValue));
  };

  const handleInputKeyDown = (e: KeyboardEvent) => {
    const { key } = e;
    if (key && key === "Enter" && inputValue !== "") goToPage();
  };

  const handleFocus = () => {
    // Selection in Safari is cancelled on mouseup,
    // so we need to disable mouseup event handling
    const mouseUp = (e: MouseEvent) => {
      e.preventDefault();
      $input.current?.removeEventListener("mouseup", mouseUp);
    };
    $input.current?.addEventListener("mouseup", mouseUp);

    $input.current?.select();
  };

  // resize input to match input value width
  useLayoutEffect(() => {
    const maxPagesLength = `${maxPages}`.length;
    const inputValueLength = `${inputValue}`.length;

    let newInputWidth = DEFAULT_INPUT_WIDTH;

    if (inputValueLength > 1) {
      // Устанавливаем длину инпута в зависимости от количества цифр в нем
      newInputWidth =
        DEFAULT_INPUT_WIDTH +
        ((inputValueLength > maxPagesLength ? maxPagesLength : inputValueLength) - 1) * CHAR_WIDTH;
    }
    setInputWidth(newInputWidth);
  }, [offset, limit, count, inputValue, inputWidth]);

  useEffect(() => {
    if (page !== inputValue) setInputValue(inputValue);
  }, [offset]);

  useEffect(() => {
    const handleOutsideInputClick = (e: MouseEvent) => {
      setInputWidth(DEFAULT_INPUT_WIDTH);

      const { target } = e;

      if (closest(target)) {
        if (!inputValue || +inputValue !== page) {
          setInputValue(page);
          onInputChange(page);
          return;
        }

        if (+inputValue > maxPages) setInputValue(maxPages || 1);
        return;
      }

      setInputValue(page);
    };

    document.addEventListener("click", handleOutsideInputClick);
    return () => document.removeEventListener("click", handleOutsideInputClick);
  }, []);

  return (
    <Box className='TablePaginationActions' display='flex' alignItems='center' marginLeft={1}>
      <Box className='TablePaginationActions__TextField-container'>
        <TextField
          inputProps={{
            ref: $input,
            sx: { paddingRight: 1 },
            autofill: "false"
          }}
          disabled={count <= 0}
          size='small'
          value={inputValue}
          InputProps={{
            sx: { paddingRight: 0, lineHeight: 1 },
            endAdornment: (
              <IconButton
                ref={$button}
                disabled={!inputValue || count <= 0}
                size='small'
                onClick={goToPage}
                color='primary'
              >
                <ArrowCircleRightIcon />
              </IconButton>
            )
          }}
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
          onFocus={handleFocus}
          sx={{ width: `${inputWidth}px` }}
        />
      </Box>
      <TablePaginationActionsMui count={count} {...props} page={count <= 0 ? 0 : props.page} />
    </Box>
  );
};

export default TablePaginationActions;
