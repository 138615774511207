import { useTranslation } from "react-i18next";
import { Sum } from "api/generated";
import SumReportTable from "./SumReportTable";

interface SumPSReportTableProps {
  isFetching?: boolean;
  rows?: Sum[];
}

const SumPSReportTable = ({ isFetching, rows }: SumPSReportTableProps) => {
  const { t } = useTranslation();

  return (
    <SumReportTable
      isFetching={isFetching}
      rows={rows}
      descriptionTitle={t("pages.Reports.table_columns.ps")}
    />
  );
};

export default SumPSReportTable;
