import { useEditParkingMutation, useGetParkingQuery } from "hooks/queries/parkings";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Form from "../Form";
import { ObjectOutletContext } from "components/ObjectsSidebarContent/types";

type Props = {
  id: string;
};

const EditForm = ({ id }: Props) => {
  const { setCurrentObject } = useOutletContext<ObjectOutletContext>();
  const { data, isLoading, isInitialLoading, isFetching } = useGetParkingQuery(id);
  const { mutateAsync, isLoading: mutationIsLoading, ...rest } = useEditParkingMutation(id);
  const loading = isLoading || isInitialLoading || isFetching || mutationIsLoading;

  useEffect(() => {
    if (data?.data?.parking) setCurrentObject({ type: "parkings", data: data.data.parking });
  }, [data]);

  return (
    <Form
      isLoading={loading}
      isInitialLoading={isInitialLoading}
      submit={mutateAsync}
      parking={data?.data?.parking}
      isEditType
      {...rest}
    />
  );
};

export default EditForm;
