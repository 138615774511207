import { useMemo } from "react";
import { Link } from "react-router-dom";
import { startOfYear, endOfYear, getTime, add, getYear } from "date-fns";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useGetHolidaysQuery } from "hooks/queries/calendar";
import { GetHolidaysResponseYearStat } from "api/generated";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { getNowUtc } from "utils/date";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "initial",
  flexBasis: "30px"
}));
interface holidaysObject {
  [key: number]: GetHolidaysResponseYearStat;
}

const printImported = (
  holidays: number,
  dayOff: number,
  t: TFunction<"translation", undefined>
) => {
  let message = "";
  switch (true) {
    case holidays > 0 && dayOff > 0:
      message = t("pages.HolidaysCalendar.list.weekends_and_holidays");
      break;
    case holidays > 0:
      message = t("pages.HolidaysCalendar.list.holidays");
      break;
    case dayOff > 0:
      message = t("pages.HolidaysCalendar.list.weekends");
      break;
    default:
      message = "";
  }
  return message.length > 0 ? (
    <Typography variant='body1'>{`${t(
      "pages.HolidaysCalendar.list.imported"
    )}: ${message}`}</Typography>
  ) : null;
};

const Years = () => {
  const { t } = useTranslation();
  const start = getTime(startOfYear(add(new Date(), { years: -1 })));
  const end = getTime(endOfYear(add(new Date(), { years: 1 })));
  const { isLoading, data } = useGetHolidaysQuery(
    { start: start.toString(), end: end.toString() },
    {}
  );

  const holidays = useMemo(
    () =>
      data.holidays?.reduce((acc, i) => ({ ...acc, [i.year as number]: i }), {} as holidaysObject),
    [data.holidays]
  );

  const years = [getYear(start), getYear(getNowUtc()), getYear(end)];

  return (
    <Box>
      <Paper>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.HolidaysCalendar.list.holidays_calendar")}
        </Typography>
        <Divider />
        {isLoading ? (
          <Typography padding='1rem' variant='h6'>
            {t("pages.HolidaysCalendar.list.loading_holidays")}
          </Typography>
        ) : (
          <List>
            {years.map((year) => {
              const importedHoliday = (holidays && holidays[year]?.counts?.holiday) || 0;
              const importedDayOff = (holidays && holidays[year]?.counts?.dayOff) || 0;
              return (
                <ListItem key={year} sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box flexBasis='50px'>{year}</Box>
                  <Box flex='1 1 400px'>
                    <Typography variant='body1'>
                      {t("pages.HolidaysCalendar.list.holiday_days")}:{" "}
                      {importedHoliday ? importedHoliday : 0}
                    </Typography>
                    <Typography variant='body1'>
                      {t("pages.HolidaysCalendar.list.non_working_days")}:{" "}
                      {importedDayOff ? importedDayOff : 0}
                    </Typography>
                    {printImported(importedHoliday, importedDayOff, t)}
                  </Box>
                  <StyledLink to={`edit/${year}`}>
                    <VisibilityIcon />
                  </StyledLink>
                </ListItem>
              );
            })}
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default Years;
