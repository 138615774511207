import { useState, useMemo, useRef, ReactNode, useCallback } from "react";
import { MapProvider } from "../../context/MapContext";
import { CurrentObject } from "components/ObjectsSidebarContent/types";
import { InternalMapApi, OnGeometryChange, ReactRefObject } from "../../context/types";

export type UserRefState = <V>(initialValue: V) => [V, ReactRefObject<V>, (value: V) => V, () => V];

const useRefState: UserRefState = (initialValue) => {
  const [state, setState] = useState(initialValue);
  const ref = useRef(state);
  ref.current = state;

  const setFn = useCallback((value: typeof initialValue) => {
    setState(value);
    ref.current = value;
    return value;
  }, []);

  const getFn = useCallback(() => ref.current, []);

  return [state, ref, setFn, getFn];
};

const ObjectsMapApiProvider = ({
  children,
  currentObject
}: {
  children: ReactNode;
  currentObject: CurrentObject;
}) => {
  const [mapActionMode, mapActionModeRef, setMapActionMode, getMapActionMode] =
    useRefState<InternalMapApi["mapActionMode"]>(null);

  const [geometryToDraw, geometryToDrawRef, setGeometryToDraw, getGeometryToDraw] =
    useRefState<InternalMapApi["geometryToDraw"]>(null);

  const [selectedObject, selectedObjectRef, setSelectedObject, getSelectedObject] = useRefState<
    InternalMapApi["selectedObject"]
  >(currentObject.data);

  const [hoveredObject, hoveredObjectRef, setHoveredObject, getHoveredObject] =
    useRefState<InternalMapApi["hoveredObject"]>(null);

  const [newObject, newObjectRef, setNewObject, getNewObject] =
    useRefState<InternalMapApi["newObject"]>(null);

  const [objectTypeToDraw, setObjectTypeToDraw] =
    useState<InternalMapApi["objectTypeToDraw"]>(null);

  const handleOnChangeRef = useRef<OnGeometryChange>(() =>
    console.warn("onChange handler is not defined")
  );

  const mapObjectsRef: InternalMapApi["mapObjectsRef"] = useRef({});

  const internalApi = useMemo(
    () => ({
      selectedObject,
      selectedObjectRef,
      setSelectedObject,
      getSelectedObject,

      hoveredObject,
      hoveredObjectRef,
      setHoveredObject,
      getHoveredObject,

      mapActionMode,
      mapActionModeRef,
      setMapActionMode,
      getMapActionMode,

      geometryToDraw,
      geometryToDrawRef,
      setGeometryToDraw,
      getGeometryToDraw,

      newObject,
      newObjectRef,
      setNewObject,
      getNewObject,

      mapObjectsRef,
      objectTypeToDraw,
      setObjectTypeToDraw,
      handleOnChangeRef
    }),
    [
      hoveredObject,
      selectedObject,
      mapActionMode,
      mapObjectsRef,
      geometryToDraw,
      objectTypeToDraw,
      handleOnChangeRef,
      newObject
    ]
  );

  return <MapProvider internalApi={internalApi}>{children}</MapProvider>;
};

export default ObjectsMapApiProvider;
