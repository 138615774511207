import { v4 as uuidv4 } from "uuid";
import { Topic, TranslateTopicName } from "api/generated";
import { FormInputs } from "./types";

export const topicNameLangs = ["ru", "kz", "en"];

export const getDefaultTopicEmails = (
  emails?: string[]
): FormInputs["topics"][number]["emails"] => {
  const emailsToMap = !emails || emails.length === 0 ? [""] : emails;
  return emailsToMap.map((email) => ({ value: email }));
};

export const getDefaultTopicName = (
  name?: TranslateTopicName
): FormInputs["topics"][number]["name"] => {
  return {
    en: name?.en || "",
    ru: name?.ru || "",
    kz: name?.kz || ""
  };
};

export const getDefaultTopics = (topics: Topic[]): FormInputs["topics"] =>
  topics
    .sort((a, b) => (a.order ?? 1) - (b.order ?? 1))
    .map(({ emails, name, uuid }) => ({
      uuid: uuid ?? uuidv4(),
      emails: getDefaultTopicEmails(emails),
      name: getDefaultTopicName(name),
      isNew: !uuid
    }));

export const getDefaultAuthTopic = (topics: Topic[]): FormInputs["authTopic"] =>
  topics.find((topic) => topic.isAuth)?.uuid ?? "";

export const getDefaultQuickReactionsTopic = (topics: Topic[]): FormInputs["quickReactionsTopic"] =>
  topics.find((topic) => topic.isQuickReaction)?.uuid ?? "";
