import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@mui/material/TableCell";
import ProgressIconButton from "components/ProgressIconButton";
import { useDeleteGrantMutation, useGetGrantsQuery } from "hooks/queries/permissions";
import Dialog from "components/Dialog";
import { Box, Typography } from "@mui/material";
import useDialog from "hooks/useDialog";
import { useLocalizationContext } from "context/LocalizationContext";
import { useTranslation } from "react-i18next";

type Props = {
  uuid?: string;
  vrp?: string;
  name?: Record<string, string>;
  refetchList: ReturnType<typeof useGetGrantsQuery>["refetch"];
};

const DeleteGrantCell = ({ refetchList, uuid, name }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { mutateAsync, isLoading: isDeleting } = useDeleteGrantMutation(uuid || "");

  const { isOpen, openDialog, closeDialog, confirm, cancel } = useDialog({
    onConfirm: () => {
      mutateAsync().finally(() => refetchList());
      closeDialog();
    }
  });

  return (
    <>
      <TableCell sx={{ padding: 1 }}>
        <ProgressIconButton isLoading={isDeleting} onDelete={openDialog} Icon={<DeleteIcon />} />
      </TableCell>
      <Dialog
        title={t("pages.Permissions.Grant.list.delete_grant_title")}
        message={
          <Box textAlign='center'>
            <Typography>
              {t("pages.Permissions.Grant.list.delete_grant_warning")} &ldquo;
              {getValueByLang(name, true)}&ldquo;?
            </Typography>
          </Box>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onConfirm={confirm}
        onCancel={cancel}
      />
    </>
  );
};

export default DeleteGrantCell;
