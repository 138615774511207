import { Uuid } from "api/generated";
import { FormInfo } from "pages/ObjectManagment/Categories/context/types";

export * from "./useCreateSubCategoryMutation";
export * from "./useEditSubCategoryMutation";
export * from "./useDeleteSubCategoryMutation";

export const SUB_CATEGORY_KEY = "sub_category";
export type Params = {
  name?: Record<string, string>;
  uuid?: Uuid;
  formInfo: FormInfo;
  resetForm: VoidFunction;
};
