import { Box, Button, SxProps } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReportFiltersForm, ReportType } from "../../types";
import ReportTypeSelect from "./ReportTypeSelect";
import { useTranslation } from "react-i18next";
import PeriodDatePicker from "./PeriodDatePicker";
import { DateTime } from "luxon";

interface FiltersProps {
  sx?: SxProps;
  onSubmit: SubmitHandler<ReportFiltersForm>;
  onDownload: (filters: ReportFiltersForm) => void;
}

const Filters = ({ sx, onSubmit, onDownload }: FiltersProps) => {
  const { t } = useTranslation();

  const today = DateTime.now();

  const { control, handleSubmit, getValues } = useForm<ReportFiltersForm>({
    defaultValues: {
      endDate: today,
      startDate: today,
      reportType: ReportType.ParkinSessionsApp
    }
  });

  return (
    <Box
      component='form'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      gap='16px'
      flexWrap='wrap'
      sx={sx}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box display='flex' rowGap='16px' columnGap='8px' flexWrap='wrap'>
        <ReportTypeSelect control={control} />

        <PeriodDatePicker
          control={control}
          name='startDate'
          label={t("pages.Reports.labels.start_date")}
        />

        <PeriodDatePicker
          control={control}
          name='endDate'
          label={t("pages.Reports.labels.end_date")}
        />
      </Box>

      <Box display='flex' gap='8px' flexWrap='wrap'>
        <Button variant='contained' type='submit'>
          {t("pages.Reports.submit_filters")}
        </Button>

        <Button variant='outlined' onClick={() => onDownload(getValues())}>
          {t("pages.Reports.download")}
        </Button>
      </Box>
    </Box>
  );
};

export default Filters;
