import { IEvent } from "yandex-maps";
import { Parking, Point, Zone } from "api/generated";
import { WithInstanceRef } from "@pbe/react-yandex-maps/typings/util/typing";
import { LineManager } from "../utils/LineManager";
import { PlacemarkManager } from "../utils/PlacemarkManager";
import { PolygonManager } from "../utils/PolygonManager";

export type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;
export type ReactRefObject<T> = React.MutableRefObject<T>;

export enum MapActionMode {
  Add = "add",
  Edit = "edit",
  ViewObject = "viewObject",
  ViewList = "viewList"
}

export enum GeometryType {
  Point = "point",
  Line = "line",
  Polygon = "polygon"
}

export enum ObjectType {
  Zone = "zone",
  Parking = "parking",
  Parkomat = "parkomat",
  Terminal = "terminal",
  Scoreboard = "scoreboard"
}

export type MapGeometryObjectRef = WithInstanceRef["instanceRef"];

export type ObjectWithCoordinates = { coordinates?: Point[]; onMap?: GeometryType };
export type ZoneWithCoordinates = Zone & ObjectWithCoordinates;
export type ParkingWithCoordinates = Parking & ObjectWithCoordinates;

export type FormObject = ZoneWithCoordinates | ParkingWithCoordinates;
export type FormObjects = {
  zones: ZoneWithCoordinates[];
  parking: ParkingWithCoordinates[];
};

export type MapObject = {
  ref: any;
  object: FormObject;
};

export type GeometryManager = PolygonManager | PlacemarkManager | LineManager;

export type OnGeometryChange = (event: IEvent) => void;

export type InternalMapApi = {
  selectedObject: FormObject | null;
  selectedObjectRef: ReactRefObject<InternalMapApi["selectedObject"]>;
  setSelectedObject: (
    selectedObject: InternalMapApi["selectedObject"]
  ) => InternalMapApi["selectedObject"];
  getSelectedObject: () => InternalMapApi["selectedObject"];

  hoveredObject: GeometryManager | null;
  hoveredObjectRef: ReactRefObject<InternalMapApi["hoveredObject"]>;
  setHoveredObject: (
    selectedObject: InternalMapApi["hoveredObject"]
  ) => InternalMapApi["hoveredObject"];
  getHoveredObject: () => InternalMapApi["hoveredObject"];

  mapActionMode: MapActionMode | null;
  mapActionModeRef: ReactRefObject<InternalMapApi["mapActionMode"]>;
  setMapActionMode: (
    mapActionMode: InternalMapApi["mapActionMode"]
  ) => InternalMapApi["mapActionMode"];
  getMapActionMode: () => InternalMapApi["mapActionMode"];

  geometryToDraw: GeometryType | null;
  geometryToDrawRef: ReactRefObject<InternalMapApi["geometryToDraw"]>;
  setGeometryToDraw: (
    geometryToDraw: InternalMapApi["geometryToDraw"]
  ) => InternalMapApi["geometryToDraw"];
  getGeometryToDraw: () => InternalMapApi["geometryToDraw"];

  newObject: GeometryManager | null;
  newObjectRef: ReactRefObject<GeometryManager | null>;
  setNewObject: (geometryToDraw: InternalMapApi["newObject"]) => InternalMapApi["newObject"];
  getNewObject: () => InternalMapApi["newObject"];

  mapObjectsRef: ReactRefObject<{ [key: string]: GeometryManager | null }>;
  objectTypeToDraw: ObjectType | null;
  setObjectTypeToDraw: ReactSetState<ObjectType | null>;
  handleOnChangeRef: ReactRefObject<OnGeometryChange>;
};
