import { Box } from "@mui/material";
import FormControlMui from "@mui/material/FormControl";
import { styled } from "@mui/system";

export const FormControl = styled(FormControlMui, {
  shouldForwardProp: (prop) => prop !== "$marginLeft"
})<{ $marginLeft?: boolean | number | string }>(({ theme, $marginLeft }) => ({
  width: 210,
  marginLeft: typeof $marginLeft === "boolean" ? theme.spacing(3) : $marginLeft
}));

export const InputBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
});
