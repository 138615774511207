import { AxiosRequestConfig } from "axios";
import { Api } from "./generated";

const config: AxiosRequestConfig = {
  baseURL: window.BACKEND_URL || "http://admingw.imparking.k8s.im.loc"
};

const apiInstance = new Api(config);

export const requests = apiInstance.v1;
export const interceptors = apiInstance.interceptors;
