interface DownloadWithLinkFileOptions {
  content: Blob;
  fileName: string;
}

export const downloadFileWithLink = ({ content, fileName }: DownloadWithLinkFileOptions) => {
  const objectURL = window.URL.createObjectURL(content);
  const link = document.createElement("a");
  link.setAttribute("href", objectURL);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
