import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { AuthContextValue } from "context/AuthContext";
import { useRef } from "react";

export const useRefreshTokenMutation = ({
  setToken,
  setRefreshToken,
  logout,
  ...rest
}: {
  setToken: AuthContextValue["setToken"];
  setRefreshToken: AuthContextValue["setRefreshToken"];
  logout: AuthContextValue["logout"];
}) => {
  const mutation = useMutation(requests.adminGatewayRefreshToken, {
    ...rest,
    onSuccess(response) {
      if (!response) {
        return logout();
      }

      const { data } = response;

      if (!data.accessToken || !data.refreshToken) {
        return logout();
      }

      setToken(data?.accessToken);
      setRefreshToken(data?.refreshToken);
    },
    onError() {
      logout();
    }
  });

  // Храним здесь промис реквеста, чтобы избежать дублирования запроса рефреша,
  // когда два запроса подряд фейлятся из-за протухшего токена.
  const requestPromiseRef = useRef<ReturnType<typeof mutation.mutateAsync> | null>(null);

  const refreshTokenFn = (refreshToken: string) => {
    if (!requestPromiseRef.current) {
      requestPromiseRef.current = mutation.mutateAsync({ refreshToken }).then((res) => {
        requestPromiseRef.current = null;
        return res;
      });
    }

    return requestPromiseRef.current;
  };

  return { refreshTokenFn, mutation };
};
