import { BaseSyntheticEvent, ReactNode, useCallback, useEffect, useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import { useForm } from "react-hook-form";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import Input from "ui-kit/form/Input";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import {
  CreateDefaultResponse,
  Point,
  Parking,
  GisType,
  ParkingLocation,
  SubCategory
} from "api/generated";
import Select from "ui-kit/form/Select";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import OnMapTypeInput, { OnMapTypeInputProps } from "../../OnMapTypeInput";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { PARKINGS_BASE_ROUTE } from "routes/route-declarations";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { GeometryType, ObjectType } from "pages/ObjectManagment/Objects/context/types";
import { useGetCategoriesQuery } from "hooks/queries/category";
import AddressInput from "../../AddressInput";
import { useGetZonesQuery } from "hooks/queries/zones";
import { PlacemarkManager } from "pages/ObjectManagment/Objects/context/utils/PlacemarkManager";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { LineManager } from "pages/ObjectManagment/Objects/context/utils/LineManager";
import {
  convertCoordinatesToNumberFormat,
  convertCoordinatesToObjectFormat,
  getCoordinatesWithCenter,
  handleObjectOnMapValidation,
  parseCoordinatesToNumberFormat,
  stringifyCoordinates
} from "../../utils";
import {
  ParkingFormValues,
  ParkingGisType,
  defaultCoordinates,
  defaultValues,
  getSubmitValue,
  handleAddResponse,
  handleEditResponse
} from "./utils";
import {
  addPutPlacemarkOnMapHandler,
  setGetCurrentActiveObjectFn
} from "pages/ObjectManagment/Objects/components/ObjectsMap/utils";
import { Coordinates } from "pages/ObjectManagment/Objects/context/utils/MapObjectManagers";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const FormItemWrapper = ({ children }: { children: ReactNode }) => (
  <Box marginTop={2} width='100%'>
    {children}
  </Box>
);

const { GIS_TYPE_POLYGON, GIS_TYPE_POINT, GIS_TYPE_LINE } = GisType;

export type Props = {
  submit: UseMutateAsyncFunction<
    AxiosResponse<CreateDefaultResponse>,
    unknown,
    Partial<Parking>,
    unknown
  >;
  isLoading?: boolean;
  parking?: Parking;
  isAddType?: boolean;
  isEditType?: boolean;
  isInitialLoading?: boolean;
};

const Form = ({ parking, submit, isAddType, isEditType, isInitialLoading, isLoading }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const {
    startDrawingNewObject,
    startEditingObject,
    getCurrentActiveObject,
    stopWorkingWithMapObject,
    resetNewObject,
    showObjectOnMap,
    geometryToDraw,
    selectedObject,
    mapRef,
    updateChangeHandler,
    isAllObjectsOnMapInitialized
  } = useMapContext();

  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetCategoriesQuery();
  const { data: zonesData, isLoading: isZonesLoading } = useGetZonesQuery();

  const navigate = useNavigate();

  const categories = useMemo(
    () =>
      categoriesData?.categories?.reduce((acc, category) => {
        if (category.children) {
          return acc.concat(
            category.children.map((subCategory) => ({
              uuid: subCategory.uuid,
              name: subCategory.name
            }))
          );
        }

        return acc;
      }, [] as SubCategory[]),
    [categoriesData]
  );

  const zones = zonesData?.data?.zones;

  const formValues = useMemo(() => {
    const location = parking?.location;
    const type = ParkingGisType[parking?.location?.type || GIS_TYPE_POINT];
    const coordinates =
      type === ParkingGisType.GIS_TYPE_UNSPECIFIED
        ? []
        : type === ParkingGisType.GIS_TYPE_POINT
        ? [location?.[type] || defaultCoordinates]
        : location?.[type]?.coordinates || [defaultCoordinates];

    return parking
      ? {
          ...parking,
          onMap: ParkingGisType[
            parking.location?.type || GIS_TYPE_POLYGON
          ] as unknown as GeometryType,
          coordinatesStringified: stringifyCoordinates(
            convertCoordinatesToNumberFormat(coordinates || [defaultCoordinates])
          ),
          coordinates
        }
      : categories && zones
      ? {
          ...defaultValues,
          categoryUuid: { value: categories?.[0]?.uuid?.value || undefined },
          zoneUuid: { value: zones?.[0]?.uuid?.value || undefined },
          coordinates
        }
      : defaultValues;
  }, [parking, categories, zones]);

  const {
    handleSubmit: onSubmit,
    control,
    reset,
    setValue,
    setError,
    getFieldState,
    clearErrors
  } = useForm({ defaultValues: formValues });
  const { openErrorAlert } = useAlertStatus();

  const onGeometryChange = useDebouncedCallback(
    useCallback(() => {
      const currentActiveObject = getCurrentActiveObject<
        PlacemarkManager | PolygonManager | LineManager
      >();

      let coordinatesStringified = "";
      let coordinates: Coordinates = [];

      if (currentActiveObject) {
        coordinates = currentActiveObject.getCoordinates();
        if (
          coordinates?.length === 0 ||
          (coordinates?.[0]?.length === 0 && !currentActiveObject.isDrawing())
        ) {
          currentActiveObject.startDrawing();
        }

        coordinatesStringified = stringifyCoordinates(
          currentActiveObject instanceof PolygonManager ? coordinates : [coordinates]
        );
      }

      setValue("coordinatesStringified", coordinatesStringified);

      handleObjectOnMapValidation<ParkingFormValues>(
        setError,
        clearErrors,
        t,
        coordinatesStringified,
        [
          () =>
            currentActiveObject instanceof PolygonManager && coordinates?.[0]?.length < 3
              ? t("pages.ObjectManagement.Objects.item.input_rules.minimum_polygon_points_count")
              : null
        ]
      );
    }, [selectedObject]),
    100
  );

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit(
      (values) => {
        const coordinates: Point[] = [defaultCoordinates];
        const rawCoordinates = getCurrentActiveObject()?.getCoordinates() || [];
        const parsedCoordinates = getCoordinatesWithCenter(
          geometryToDraw === GeometryType.Polygon ? rawCoordinates[0] : rawCoordinates
        );
        const preparedValues = getSubmitValue(values, {
          rawCoordinates: rawCoordinates,
          geometryToDraw,
          coordinates: parsedCoordinates.coordinates
        });

        return submit(preparedValues).then((res) => {
          if (isAddType) {
            handleAddResponse(res, {
              coordinates,
              preparedValues,
              reset,
              resetNewObject,
              defaultValues,
              startDrawingNewObject,
              onGeometryChange,
              categories,
              zones
            });
          }

          navigate(PARKINGS_BASE_ROUTE);

          if (isEditType) {
            handleEditResponse(res, {
              parking,
              coordinates: parsedCoordinates.coordinates,
              preparedValues,
              formValues
            });
          }

          stopWorkingWithMapObject();

          return res;
        });
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);

  const handleChangeCoordinates = (value: string) => {
    const parsed = parseCoordinatesToNumberFormat(value);
    const coordinates = convertCoordinatesToObjectFormat(parsed);

    let valuePath = "";
    let preparedValue;

    if (geometryToDraw === GeometryType.Point) {
      valuePath = "location.point";
      preparedValue = coordinates[0];
    } else {
      valuePath = `location.${geometryToDraw}.coordinates`;
      preparedValue = coordinates;
    }

    setValue(valuePath as "location", preparedValue as ParkingLocation);
    const currentActiveObject = getCurrentActiveObject();
    currentActiveObject?.setCoordinates(
      geometryToDraw === GeometryType.Polygon ? [parsed] : parsed
    );
  };

  const handleTypeChange: OnMapTypeInputProps["onTypeChange"] = (e) => {
    if (isEditType) {
      const currentObject = getCurrentActiveObject<PlacemarkManager>();
      if (
        currentObject &&
        (currentObject?.getObject()?.location as ParkingLocation)?.type === GIS_TYPE_POINT
      ) {
        currentObject.stopChanging();
      }
      const geometry = e.target.value as GeometryType;

      const type =
        geometry === GeometryType.Line
          ? GIS_TYPE_LINE
          : geometry === GeometryType.Polygon
          ? GIS_TYPE_POLYGON
          : GIS_TYPE_POINT;

      const [lattitude, longitude] = mapRef.current?.getCenter() || [];
      const center = { longitude, lattitude };

      const coordinates = geometry === GeometryType.Point ? [center] : [];

      if (geometry === GeometryType.Point) {
        setGetCurrentActiveObjectFn(getCurrentActiveObject);
        addPutPlacemarkOnMapHandler(mapRef.current);
      }

      const newFormValues = {
        ...formValues,
        coordinates,
        coordinatesStringified: stringifyCoordinates(
          convertCoordinatesToNumberFormat(
            (geometry === GeometryType.Point ? [coordinates] : coordinates) as Point[]
          )
        ),
        location: {
          type,
          [geometry]: {
            coordinates
          }
        },
        onMap: geometry,
        center
      };

      setValue("onMap", geometry);
      setValue("center", center);
      setValue("location.type", type);
      setValue(`location.${geometry}`, {});
      setValue("coordinatesStringified", newFormValues.coordinatesStringified);

      startEditingObject(newFormValues, {
        geometry: e.target.value as GeometryType,
        onChange: onGeometryChange
      });
    }

    if (isAddType) {
      startDrawingNewObject({
        type: ObjectType.Parking,
        geometry: e.target.value as GeometryType,
        onChange: onGeometryChange
      });
    }
  };

  const handleBackToList = () => {
    navigate(PARKINGS_BASE_ROUTE);
    stopWorkingWithMapObject(true);
  };

  useEffect(() => {
    reset(formValues);
    if (isEditType && parking && isAllObjectsOnMapInitialized) {
      startEditingObject(formValues, {
        geometry: formValues.onMap,
        onChange: onGeometryChange
      });
    }
  }, [parking, formValues, isAllObjectsOnMapInitialized]);

  useEffect(() => {
    updateChangeHandler(onGeometryChange);
  }, [onGeometryChange]);

  useEffect(() => {
    if (parking && isAllObjectsOnMapInitialized && parking.uuid?.value) {
      showObjectOnMap(parking.uuid.value);
    }
  }, [parking, isAllObjectsOnMapInitialized, isCategoriesLoading, isZonesLoading]);

  useEffect(() => {
    if (isAddType) {
      startDrawingNewObject({
        type: ObjectType.Parking,
        geometry: GeometryType.Point,
        onChange: onGeometryChange
      });
      return;
    }
  }, []);

  useEffect(
    () => () => {
      stopWorkingWithMapObject(true);
    },
    []
  );

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }} data-testid='ParkingForm'>
      {isInitialLoading && (
        <Box width='100%' textAlign='center'>
          <CircularProgress data-testid='ParkingForm__progress' />
        </Box>
      )}

      <OnMapTypeInput
        getFieldState={getFieldState}
        clearErrors={clearErrors}
        setError={setError}
        setValue={setValue}
        onOpenInput={() => {
          const currentActiveObject = getCurrentActiveObject();
          setValue(
            "coordinatesStringified",
            stringifyCoordinates(currentActiveObject?.getCoordinates() || [])
          );
        }}
        onTypeChange={handleTypeChange}
        control={control}
        onSave={handleChangeCoordinates}
        validateObjectOnMap={handleObjectOnMapValidation}
      />

      <FormItemWrapper>
        <MultiLangInput
          label={`${t("pages.ObjectManagement.Objects.item.title")}:`}
          name='name'
          langsAlwaysOpen={isAddType}
          control={control}
          multiline
          required
          disabled={isLoading}
        />
      </FormItemWrapper>

      <FormControl required margin='none' sx={{ marginTop: 3 }} fullWidth>
        <Select
          data-testid='ParkingForm__category-input'
          required
          control={control}
          name='categoryUuid.value'
          id='categoryUuid.value'
          size='small'
          label={`${t("pages.ObjectManagement.Objects.item.category")}:`}
          disabled={isCategoriesLoading}
        >
          {isCategoriesLoading && <MenuItem value='loading'>{t("common.loading")}</MenuItem>}
          {categories?.map((category) => (
            <MenuItem key={category.uuid?.value} value={category.uuid?.value}>
              {getValueByLang(category.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormItemWrapper>
        <MultiLangInput
          label={`${t("pages.ObjectManagement.Objects.item.description")}:`}
          name='description'
          langsAlwaysOpen={isAddType}
          control={control}
          multiline
          required
          disabled={isLoading}
        />
      </FormItemWrapper>

      <FormControl fullWidth required sx={{ marginTop: 3 }}>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.spaces_total")}:</FormLabel>
        <Input
          data-testid='ParkingForm__number-input'
          rules={{
            required: { value: true, message: t("common.input_rules.required") },
            pattern: { value: /^\d+$/, message: t("common.input_rules.only_digits") }
          }}
          name='spacesTotal'
          control={control}
          variant='standard'
          fullWidth
          disabled={isLoading}
        />
      </FormControl>

      <FormControl fullWidth required sx={{ marginTop: 3 }}>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.spaces_handicapped")}:</FormLabel>
        <Input
          data-testid='ParkingForm__number-input'
          rules={{
            required: { value: true, message: t("common.input_rules.required") },
            pattern: { value: /^\d+$/, message: t("common.input_rules.only_digits") }
          }}
          name='spacesHandicapped'
          control={control}
          variant='standard'
          fullWidth
          disabled={isLoading}
        />
      </FormControl>

      <FormItemWrapper>
        <AddressInput required control={control} isAddType={isAddType} isLoading={isLoading} />
      </FormItemWrapper>

      <FormControl required margin='none' sx={{ marginTop: 3 }} fullWidth>
        <Select
          data-testid='ParkingForm__zone-input'
          required
          control={control}
          name='zoneUuid.value'
          id='zoneUuid.value'
          size='small'
          label='Зона:'
          disabled={isZonesLoading}
        >
          {isZonesLoading && <MenuItem value='loading'>{t("common.loading")}</MenuItem>}
          {zones?.map((zone) => (
            <MenuItem key={zone.uuid?.value} value={zone.uuid?.value}>
              {t("pages.ObjectManagement.Objects.item.zones.zone")}: {zone.number}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormItemWrapper>
        <MultiLangInput
          label={`${t("pages.ObjectManagement.Objects.item.contacts")}:`}
          name='contacts'
          langsAlwaysOpen={isAddType}
          control={control}
          multiline
          required
          disabled={isLoading}
        />
      </FormItemWrapper>

      <ConfirmCancelPanel
        stackProps={{ sx: { justifyContent: "stretch", marginTop: 3 } }}
        onConfirmProps={{ type: "submit", disabled: isLoading }}
        onCancelProps={{ disabled: isLoading }}
        confirmLabel={isLoading ? t("common.saving") : t("common.save")}
        onConfirm={handleSubmit}
        onCancel={handleBackToList}
      />
    </form>
  );
};

export default Form;
