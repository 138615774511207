import { ObjectType } from "../types";
import { Coordinates, MapObjectManager } from "./MapObjectManagers";

export class PlacemarkManager extends MapObjectManager {
  static readonly defaultOptions = {
    preset: "islands#redDotIcon"
  };

  static readonly defaultColors = {
    [ObjectType.Zone]: {
      active: {
        preset: "islands#blueStretchyIcon"
      },
      inactive: {
        preset: "islands#grayStretchyIcon"
      }
    },
    [ObjectType.Parking]: {
      active: {
        preset: "islands#blueIcon"
      },
      inactive: {
        preset: "islands#grayIcon"
      }
    },
    [ObjectType.Parkomat]: {
      active: {
        preset: "islands#blueIcon"
      },
      inactive: {
        preset: "islands#grayIcon"
      }
    },
    [ObjectType.Terminal]: {
      active: {
        preset: "islands#blueIcon"
      },
      inactive: {
        preset: "islands#grayIcon"
      }
    },
    [ObjectType.Scoreboard]: {
      active: {
        preset: "islands#blueIcon"
      },
      inactive: {
        preset: "islands#grayIcon"
      }
    }
  };

  getCoordinates(): Coordinates {
    return [this.ref.geometry.getCoordinates()];
  }

  setEnabledStyle(shouldShowOverOtherObjects = true) {
    this.setOptionsInGeoObject({
      zIndex: shouldShowOverOtherObjects ? 1000000 : ""
    });

    return this;
  }

  setDisabledStyle() {
    this.setOptionsInGeoObject({
      draggable: false
    });
    return this;
  }

  startCreating(isEnabledStyle = true) {
    this.startDrawing(isEnabledStyle);
    return this;
  }

  startDrawing(isEnabledStyle = true) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.startDrawing();
      this.setDraggable();
      if (isEnabledStyle) this.setEnabledStyle(true);
    }
    return this;
  }

  setCoordinates<Coords = Coordinates>(coordinates: Coords) {
    this.ref?.editor?.geometry?.setCoordinates((coordinates as number[])[0]);
    return this;
  }

  getCoordinatesToPan() {
    return this.getCenter();
  }

  startEditing() {
    this.setDraggable();
    return this;
  }

  stopChanging() {
    this.stopEditing();
    return this;
  }

  stopEditing() {
    this.setDraggable(false);
    return this;
  }

  getCenter(): number[] {
    return [this.object?.center?.lattitude || 0, this.object?.center?.longitude || 0];
  }

  getBounds() {
    const VERTICAL_SHIFT = 0.0010549302245124181;
    const HORIZONTAL_SHIFT = 0.0010487437262938215;

    const center = this.getCenter();
    const bounds = [
      [center[0] - VERTICAL_SHIFT, center[1] - HORIZONTAL_SHIFT],
      [center[0] + VERTICAL_SHIFT, center[1] + HORIZONTAL_SHIFT]
    ];
    return bounds;
  }
}
