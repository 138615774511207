import { useTranslation } from "react-i18next";
import { Sum } from "api/generated";
import SumReportTable from "./SumReportTable";

interface SumZonesReportTableProps {
  rows?: Sum[];
  isFetching?: boolean;
}

const SumZonesReportTable = ({ rows, isFetching }: SumZonesReportTableProps) => {
  const { t } = useTranslation();

  return (
    <SumReportTable
      usePagination
      isFetching={isFetching}
      rows={rows}
      descriptionTitle={t("pages.Reports.table_columns.parking_zone")}
    />
  );
};

export default SumZonesReportTable;
