import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Category, Uuid } from "api/generated";
import ButtonsPanel from "../ButtonsPanel";
import SubCategoryCard from "../SubCategoryCard";
import { useDeleteCategoryMutation } from "hooks/queries/category";
import { useCategoriesFormContext } from "../../context/CategoriesFormContext";
import { CategoryFormType } from "../../context/types";
import { ButtonsPanelCardComponent } from "hoc/ButtonsPanelCardComponent";
import { useAppAbility } from "context/AbilityContext";
import Dialog from "components/Dialog";
import useDialog from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const ParentBox = ButtonsPanelCardComponent<BoxProps>(Box, "parking-card");

const CategoryCard = ({ uuid, name, children: subCategories }: Category) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can, cannot } = useAppAbility();
  const { formInfo, setFormInfo } = useCategoriesFormContext();
  const { mutate, isLoading } = useDeleteCategoryMutation({
    name,
    uuid,
    formInfo,
    resetForm: setFormInfo,
    subCategories
  });
  const { isOpen, openDialog, confirm, cancel } = useDialog({ onConfirm: mutate });

  const handleChangeCategory = (action: CategoryFormType, uuid?: Uuid, parentUuid?: Uuid) => () => {
    setFormInfo(action, uuid, parentUuid);
  };

  return (
    <ParentBox data-testid='CategoryCard'>
      <Box display='flex' justifyContent='space-between' alignContent='center'>
        <Box display='flex' alignItems='center'>
          <Typography padding={1} variant='h6'>
            {getValueByLang(name, true)}
          </Typography>
        </Box>
        <ButtonsPanel
          id='parking-card'
          isLoading={isLoading}
          onAddClick={
            can("create", "Category")
              ? handleChangeCategory(CategoryFormType.CreateSubCategory, undefined, uuid)
              : undefined
          }
          onViewClick={
            can("read", "Category") && cannot("update", "Category")
              ? handleChangeCategory(CategoryFormType.ViewCategory, uuid)
              : undefined
          }
          onEditClick={
            can("update", "Category")
              ? handleChangeCategory(CategoryFormType.EditCategory, uuid)
              : undefined
          }
          onDeleteClick={can("delete", "Category") ? openDialog : undefined}
        />
      </Box>
      <Grid container>
        {subCategories?.map((item) => (
          <Grid key={item.uuid?.value} item lg={4} md={6} sm={12}>
            <SubCategoryCard
              {...item}
              formInfo={formInfo}
              setFormInfo={setFormInfo}
              parentUuid={uuid}
              onChangeCategory={handleChangeCategory}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog
        testId='CategoriesDialog'
        title={t("pages.ObjectManagement.Categories.list.delete_category_title")}
        message={
          <Typography>
            {t("pages.ObjectManagement.Categories.list.delete_category_message").replace(
              "{name}",
              getValueByLang(name, true)
            )}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onCancel={cancel}
        onConfirm={confirm}
      />
    </ParentBox>
  );
};

export default CategoryCard;
