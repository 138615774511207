import { getFormTypes } from "utils/form-utils";
import AddForm from "./AddForm";
import { ABONEMENTS_ADD_ROUTE, ABONEMENTS_EDIT_ROUTE } from "routes/route-declarations";
import { useLocation, useParams } from "react-router-dom";
import EditForm from "./EditForm";

const AbonementItem = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { isAddType, isEditType } = getFormTypes(
    {
      addRoutes: [ABONEMENTS_ADD_ROUTE],
      editRoutes: [ABONEMENTS_EDIT_ROUTE]
    },
    pathname
  );

  return isAddType ? <AddForm /> : isEditType ? <EditForm uuid={id || ""} /> : null;
};

export default AbonementItem;
