import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetSumTransactionsByPaymentParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const TRANSACTIONS_SUM_PS_REPORT_QUERY_KEY = "transactions_sum_ps_report";

const useTransactionsSumPSReport = (params: AdminGatewayGetSumTransactionsByPaymentParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [TRANSACTIONS_SUM_PS_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetSumTransactionsByPayment(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useTransactionsSumPSReport;
