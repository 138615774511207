import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef
} from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { interceptors } from "api";
import { useGetProfileQuery, useRefreshTokenMutation } from "hooks/queries/auth";
import { ProfileResponse } from "api/generated";
import { Permission } from "./AbilityContext";
import axios from "axios";

export type AuthContextValue = {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  token: string;
  refreshToken: string;
  permissions: Array<Permission> | null;
  setPermissions: Dispatch<SetStateAction<Permission[] | null>>;
  setToken: Dispatch<SetStateAction<string>>;
  setRefreshToken: Dispatch<SetStateAction<string>>;
  profile: ProfileResponse | null;
  isProfileFetching: boolean;
  logout: VoidFunction;
};

const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
  setIsLoggedIn: () => undefined,
  token: "",
  setToken: () => undefined,
  refreshToken: "",
  setRefreshToken: () => undefined,
  permissions: null,
  setPermissions: () => undefined,
  profile: null,
  isProfileFetching: false,
  logout: () => undefined
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage<boolean>("isLoggedIn", false);
  const [token, setToken] = useLocalStorage<string>("token", "");
  const [refreshToken, setRefreshToken] = useLocalStorage<string>("refreshToken", "");
  const [permissions, setPermissions] = useLocalStorage<Permission[] | null>("permissions", null);
  const { data: profile = null, isFetching: isProfileFetching } = useGetProfileQuery(isLoggedIn);

  const logout = () => {
    setIsLoggedIn(false);
    setToken("");
    setRefreshToken("");
    setPermissions(null);
  };

  const { refreshTokenFn } = useRefreshTokenMutation({
    setToken,
    setRefreshToken,
    logout
  });

  const requestInterceptorIdRef = useRef(0);
  const responseInterceptorIdRef = useRef(0);

  useLayoutEffect(() => {
    interceptors.request.eject(requestInterceptorIdRef.current);
    interceptors.request.eject(responseInterceptorIdRef.current);

    const refreshTokenIfNeeded = () => {
      if (!token) logout();

      const tokenPayload = JSON.parse(window.atob(token.split(".")[1]));
      const tokenExpirationInSec = tokenPayload.exp;
      const nowDateInSec = Date.now() / 1000;

      if (tokenExpirationInSec - nowDateInSec < 60) {
        refreshTokenFn(refreshToken);
      }
    };

    /**
     * Перехватчик запросов
     * Добавляет значение токена в заголовок
     */
    const requestInterceptor = () => {
      requestInterceptorIdRef.current = interceptors.request.use(
        (req) => {
          if (token && req.url !== "/v1/auth/login") {
            req.headers.authorization = `Bearer ${token}`;
          }
          return req;
        },
        (error) => Promise.reject(error)
      );
    };

    /**
     * Перехватчик ответов на запросы.
     */
    const responseInterceptor = () => {
      responseInterceptorIdRef.current = interceptors.response.use(
        (res) => res,
        async (error) => {
          try {
            if (error.config.url !== "/v1/auth/refresh" && error.response?.status === 401) {
              const { data } = await refreshTokenFn(refreshToken);

              return axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  authorization: `Bearer ${data.accessToken}`
                }
              });
            }
            throw error;
          } catch {
            throw error;
          }
        }
      );
    };

    if (token) {
      requestInterceptor();
      responseInterceptor();

      window.addEventListener("focus", refreshTokenIfNeeded);
    }

    return () => window.removeEventListener("focus", refreshTokenIfNeeded);
  }, [token, refreshToken]);

  const value = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      token,
      setToken,
      refreshToken,
      setRefreshToken,
      permissions,
      setPermissions,
      profile,
      isProfileFetching,
      logout
    }),
    [isLoggedIn, profile, token, refreshToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContext");
  }

  return context;
};
