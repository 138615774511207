import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface ReplenishDialogProps {
  open: boolean;
  onClose: VoidFunction;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
}

const DeleteTopicConfirmDialog: FC<ReplenishDialogProps> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "100%", maxWidth: "600px" }
      }}
    >
      <DialogContent>{t("pages.Feedback.confirm_delete_topic")}</DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} variant='contained'>
          {t("common.continue")}
        </Button>
        <Button onClick={onClose} variant='outlined'>
          {t("common.cancelation")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTopicConfirmDialog;
