import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { Parking, Zone } from "api/generated";
import ProgressIconButton from "components/ProgressIconButton";
import { ObjectType } from "../../context/types";
import { useDeleteMutation } from "./utils";

export type OnDeleteClickHandler = (
  deleteFn: VoidFunction,
  entity: Zone | Parking,
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => void | Promise<any>;

type Props = {
  pathEdit: string;
  row: Zone | Parking;
  objectType: ObjectType;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteClick?: OnDeleteClickHandler;
  isEditButtonHidden?: boolean;
  isDeleteButtonHidden?: boolean;
};

const ActionTableCell = ({
  pathEdit,
  row,
  onEditClick,
  onDeleteClick,
  objectType,
  isEditButtonHidden,
  isDeleteButtonHidden
}: Props) => {
  const { mutate, isLoading } = useDeleteMutation(objectType, {
    uuid: row.uuid || {},
    shouldInvalidateList: true
  });

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onEditClick instanceof Function) onEditClick(e);
  };

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onDeleteClick instanceof Function) onDeleteClick(() => mutate(), row, e);
  };

  return (
    <TableCell padding='none' align='center' data-testid='ActionTableCell'>
      {!isEditButtonHidden && (
        <Box
          component={Link}
          display='inline-block'
          to={pathEdit}
          onClick={
            isLoading
              ? (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }
              : undefined
          }
        >
          <IconButton
            disabled={isLoading}
            data-zone-id={row.uuid?.value}
            onClick={handleEditClick}
            data-testid='ActionTableCell__edit-button'
          >
            <EditIcon />
          </IconButton>
        </Box>
      )}
      {!isDeleteButtonHidden && (
        <ProgressIconButton isLoading={isLoading} onDelete={handleDelete} Icon={<DeleteIcon />} />
      )}
    </TableCell>
  );
};

export default ActionTableCell;
