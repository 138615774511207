import SelectMui, { SelectProps as SelectMuiProps } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Control, Controller, FieldError } from "react-hook-form";

export type SelectProps = {
  // Required Props
  control: Control<any>;
  name: string;

  // Optional Props
  rules?: object;
  helperText?: string;
  error?: FieldError;
} & Omit<SelectMuiProps, "error">;

const Select = ({
  control,
  name,
  defaultValue,
  rules,
  helperText,
  variant,
  inputRef,
  label,
  id,
  error,
  ...rest
}: SelectProps) => {
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ""}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...restField }, fieldState }) => {
        const _error = error || fieldState?.error;
        return (
          <>
            {label && (
              <InputLabel size='small' id={id}>
                {label}
              </InputLabel>
            )}
            <SelectMui
              {...restField}
              {...rest}
              id={id}
              label={label}
              variant={variant}
              error={!!_error}
              inputRef={($ref) => {
                if (inputRef) {
                  if (inputRef instanceof Function) inputRef($ref);
                  else
                    console.warn(` inputRef prop in Input with name "${name}" is not a function`);
                }
                return ref($ref);
              }}
            />
            <FormHelperText error={!!_error} sx={{ margin: 0, marginTop: "3px" }}>
              {_error ? _error?.message || "Invalid Input" : helperText}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default Select;
