import { useState } from "react";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import { ListItemButton, ListItemIcon, ListItemText, Collapse, Box } from "../../styled";
import { NavItem, Paths } from "routes/const";
import ListItem, { Props } from "../ListItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export type NestedListItemProps = {
  nestedLevel?: number;
  pathStrict?: boolean;
  children?: NavItem[];
} & Props;

const defaultPathComparator = (itemPath: string | undefined, currentPath: string | undefined) =>
  itemPath === currentPath;

function getActiveItem(children: NavItem[], getPath: any, cp: string): boolean {
  return !!children?.some(({ children: _children, ...child }) =>
    defaultPathComparator(getPath(child.path, child.pathStrict), cp)
  );
}

const NestedListItem = ({
  title,
  path,
  isNavSidebarOpen,
  Icon,
  nestedLevel = 1,
  children
}: NestedListItemProps) => {
  const { pathname } = useLocation();
  const cp = pathname.slice(-4) === "/add" ? pathname.replace("/add", "") : pathname;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const _nestedLevel = nestedLevel + 1;

  const handleClick = () => {
    setOpen((_open) => !_open);
  };

  const getPath = (pathArg?: string, pathStrictArg?: boolean): Paths =>
    (pathStrictArg || !pathArg ? pathArg : `${path}${pathArg}`) as Paths;

  return children ? (
    <>
      <Tooltip title={t(title)} disableHoverListener={isNavSidebarOpen} arrow placement='right'>
        <ListItemButton open={isNavSidebarOpen} onClick={handleClick}>
          <ListItemIcon open={isNavSidebarOpen}>{Icon && <Icon />}</ListItemIcon>
          <ListItemText
            style={{ whiteSpace: "break-spaces" }}
            primary={t(title)}
            open={isNavSidebarOpen}
          />
          {children && isNavSidebarOpen && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </Tooltip>
      <Collapse
        in={getActiveItem(children, getPath, cp) || open}
        style={{ whiteSpace: getActiveItem(children, getPath, cp) ? "break-spaces" : "normal" }}
        timeout='auto'
      >
        <Box nestedLevel={_nestedLevel}>
          <List>
            {children.map(({ children: _children, ...child }, index) =>
              _children ? (
                <NestedListItem
                  {...child}
                  path={getPath(child.path, child.pathStrict)}
                  nestedLevel={_nestedLevel}
                  isNavSidebarOpen={isNavSidebarOpen}
                  key={child.title + index}
                >
                  {_children}
                </NestedListItem>
              ) : (
                <ListItem
                  {...child}
                  path={getPath(child.path, child.pathStrict)}
                  nestedLevel={_nestedLevel}
                  cp={cp}
                  isNavSidebarOpen={isNavSidebarOpen}
                  key={child.title + index}
                />
              )
            )}
          </List>
        </Box>
      </Collapse>
    </>
  ) : (
    <ListItem
      nestedLevel={_nestedLevel}
      title={title}
      path={path}
      isNavSidebarOpen={isNavSidebarOpen}
      Icon={Icon}
    />
  );
};

export default NestedListItem;
