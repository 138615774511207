import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3399ff",
      contrastText: "white",
      dark: "#277bce",
      light: "#86c3ff"
    }
  }
});
