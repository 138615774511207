import { Tab, Tabs } from "@mui/material";
import { ReportApiFilters, ReportTables } from "pages/Reports/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ReportTableComponentProps {
  filters: ReportApiFilters;
}

export interface ReportTab {
  table: ReportTables;
  component: React.ComponentType<ReportTableComponentProps>;
}

export interface ReportTabsProps {
  tabs: ReportTab[];
  filters: ReportApiFilters;
}

const ReportTabs = ({ tabs, filters }: ReportTabsProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ReportTab>(tabs[0]);

  const ActiveTabTable = activeTab.component;

  return (
    <>
      <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} sx={{ marginY: 2 }}>
        {tabs.map((activeTab) => (
          <Tab
            key={activeTab.table}
            value={activeTab}
            label={t(`pages.Reports.report_tables.${activeTab.table}`)}
          />
        ))}
      </Tabs>

      <ActiveTabTable filters={filters} />
    </>
  );
};

export default ReportTabs;
