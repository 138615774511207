import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import React from "react";

export type Props = {
  isLoading?: boolean;
  progressProps?: CircularProgressProps;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  Icon: React.ReactNode;
} & IconButtonProps;

const ProgressIconButton = ({ isLoading, onDelete, progressProps, Icon }: Props) => {
  return (
    <IconButton disabled={isLoading} onClick={onDelete}>
      {isLoading ? (
        <CircularProgress
          sx={{ height: "20px", width: "20px", margin: "2px" }}
          size='small'
          {...progressProps}
        />
      ) : (
        Icon
      )}
    </IconButton>
  );
};

export default ProgressIconButton;
