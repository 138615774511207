import { BaseSyntheticEvent, /* useCallback, */ useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import {
  useEditSystemSettingsConfigMutation,
  useGetSystemSettingsConfigQuery
} from "hooks/queries/system-settings";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Vehicle from "./components/Vehicle";
// import { Button } from "@mui/material";

const Vehicles = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetSystemSettingsConfigQuery();
  const { mutateAsync, isLoading: isSaving } = useEditSystemSettingsConfigMutation();

  const {
    handleSubmit: onSubmit,
    control,
    // setValue,
    watch,
    // getValues,
    reset
  } = useForm({
    defaultValues: data
  });

  const { openErrorAlert } = useAlertStatus();

  const vehicles = watch("vehicles");

  // const handleDeleteVehicle = useCallback((index: number) => {
  //   setValue(
  //     "vehicles",
  //     getValues("vehicles")?.filter((_, i) => i !== index)
  //   );
  // }, []);

  // const handleAddVehicle = () => {
  //   setValue("vehicles", [...(vehicles || []), {}]);
  // };

  const handleSubmit = (e: BaseSyntheticEvent) => {
    return onSubmit(
      async (values) => {
        mutateAsync(values);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Card>
      <Box marginX='-8px'>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.SystemSettings.Vehicles.vehicles")}
        </Typography>
        <Divider />
      </Box>
      <CardContent>
        {isLoading ? (
          <Typography variant='h5'>{t("common.loading")}</Typography>
        ) : (
          <Box component='form' onSubmit={handleSubmit}>
            {vehicles?.map((vehicle, index) => (
              <Box key={index} marginTop={index !== 0 ? 2 : 0}>
                <Vehicle
                  control={control}
                  name={`vehicles.${index}`}
                  // index={index}
                  // onDeleteClick={handleDeleteVehicle}
                  {...vehicle}
                />
              </Box>
            ))}
            {/* <Box marginTop={2}>
              <Button onClick={handleAddVehicle}>Добавить</Button>
            </Box> */}
            <ConfirmCancelPanel
              stackProps={{ sx: { maxWidth: 300, marginTop: 3 } }}
              onConfirmProps={{ type: "submit", disabled: isLoading || isSaving || isFetching }}
              onCancelProps={{ disabled: isLoading || isSaving || isFetching }}
              confirmLabel={
                isSaving ? t("common.saving") : isFetching ? t("common.loading") : t("common.save")
              }
              onConfirm={handleSubmit}
              onCancel={handleCancel}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Vehicles;
