import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Refresh from "@mui/icons-material/Refresh";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

export type Props = IconButtonProps & {
  isLoading: boolean;
  isFetching: boolean;
  refetch: React.MouseEventHandler<HTMLButtonElement>;
  progressProps?: CircularProgressProps;
  refreshProps?: DefaultComponentProps<SvgIconTypeMap<Record<string, unknown>, "svg">>;
};

const RefetchTableDataButton = ({
  isLoading,
  isFetching,
  refetch,
  progressProps,
  refreshProps,
  ...rest
}: Props) => {
  return (
    <IconButton disabled={isLoading || isFetching} onClick={refetch} {...rest}>
      {isLoading || isFetching ? (
        <CircularProgress
          size='16px'
          sx={{ padding: "4px", boxSizing: "initial" }}
          color='primary'
          {...progressProps}
        />
      ) : (
        <Refresh color='primary' {...refreshProps} />
      )}
    </IconButton>
  );
};

export default RefetchTableDataButton;
