import { MutationOptions, useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams, Uuid } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { ABONEMENTS_QUERY_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useDeleteAbonementMutation = (
  uuid?: Uuid,
  params?: RequestParams,
  options?: MutationOptions<any, any, any, any>
) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation(
    (uuidArg?: Uuid) =>
      requests.adminGatewayDeleteAbonement(uuidArg?.value || uuid?.value || "", params),
    {
      mutationKey: [ABONEMENTS_QUERY_KEY, DELETE_MUTATE_KEY, uuid?.value],
      ...options,
      onSuccess(...args) {
        openSuccessAlert(t("pages.Abonements.Active.item.abonement_deleted"));
        if (options?.onSuccess) options.onSuccess(...args);
      },
      onError(...args) {
        openErrorAlert(t("pages.Abonements.Active.item.could_not_delete_abonement"));
        if (options?.onError) options.onError(...args);
      }
    }
  );
};
