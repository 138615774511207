import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import { useForm } from "react-hook-form";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ZoneType, Zone, CreateDefaultResponse, City, Point } from "api/generated";
import Select from "ui-kit/form/Select";
import Input from "ui-kit/form/Input";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import OnMapTypeInput from "../../OnMapTypeInput";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import Checkbox from "ui-kit/form/Checkbox";
import { AxiosResponse } from "axios";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { ZONES_BASE_ROUTE } from "routes/route-declarations";
import { queryClient } from "config/react-query";
import {
  convertCoordinatesToNumberFormat,
  convertCoordinatesToObjectFormat,
  getCoordinatesWithCenter,
  handleObjectOnMapValidation,
  parseCoordinatesToNumberFormat,
  setOptimisticMutateResultInList,
  stringifyCoordinates
} from "../../utils";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { ObjectType, ZoneWithCoordinates } from "pages/ObjectManagment/Objects/context/types";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { ZONES_KEY } from "hooks/queries/zones";
import { useGetTariffsQuery } from "hooks/queries/tariffs";
import { useConfigContext } from "context/ConfigContext";
import { Coordinates } from "pages/ObjectManagment/Objects/context/utils/MapObjectManagers";
import { Paths } from "routes/const";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const { ZONE_TYPE_SIMPLE } = ZoneType;

export type ZoneFormValues = Partial<ZoneWithCoordinates> & {
  coordinatesStringified: string;
  objectOnMap: string | null;
};

const defaultValues: ZoneFormValues = {
  uuid: undefined,
  number: undefined,
  type: undefined,
  description: {
    en: "",
    ru: ""
  },
  city: City.CITY_SOCHI,
  location: {
    coordinates: []
  },
  coordinatesStringified: "",
  center: {},
  isActive: true,
  prices: [],
  tariffUuid: undefined,
  coordinates: [],
  objectOnMap: ""
};

export type Props = {
  submit: UseMutateAsyncFunction<
    AxiosResponse<CreateDefaultResponse>,
    unknown,
    Partial<Zone>,
    unknown
  >;
  isLoading?: boolean;
  zone?: Zone;
  isAddType?: boolean;
  isEditType?: boolean;
  isInitialLoading?: boolean;
};

const Form = ({ zone, submit, isAddType, isEditType, isInitialLoading, isLoading }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const {
    startDrawingNewObject,
    startEditingObject,
    getCurrentActiveObject,
    stopWorkingWithMapObject,
    resetNewObject,
    showObjectOnMap,
    isAllObjectsOnMapInitialized
  } = useMapContext();

  const { config } = useConfigContext();

  const { data, isLoading: isTariffsLoading, isError: isTariffsError } = useGetTariffsQuery();

  const [changedCoordinates, setChangedCoordinates] = useState<any[] | null>(null);

  const navigate = useNavigate();

  const tariffs = data?.tariffs;

  const formValues = useMemo(() => {
    return zone
      ? {
          ...zone,
          coordinatesStringified: stringifyCoordinates(
            convertCoordinatesToNumberFormat(zone?.location?.coordinates || [])
          ),
          coordinates: zone.location?.coordinates
        }
      : tariffs
      ? {
          ...defaultValues,
          tariffUuid: tariffs[0]?.uuid
        }
      : defaultValues;
  }, [zone, tariffs]);

  const {
    handleSubmit: onSubmit,
    control,
    reset,
    setValue,
    watch,
    setError,
    getFieldState,
    register,
    clearErrors
  } = useForm({ defaultValues: formValues });

  const zoneType = watch("type");

  const { openErrorAlert } = useAlertStatus();

  const onGeometryChange = useDebouncedCallback(
    useCallback(() => {
      const currentActiveObject = getCurrentActiveObject<PolygonManager>();
      let coordinatesStringified = "";
      let coordinates: Coordinates = [];

      if (currentActiveObject) {
        const newCoordinates = currentActiveObject.getCoordinates();

        if (newCoordinates[0]?.length === 0 && !currentActiveObject.isDrawing()) {
          currentActiveObject.startDrawing();
        }

        coordinates = currentActiveObject.getCoordinates();
        coordinatesStringified = stringifyCoordinates(coordinates);
      }

      setValue("coordinatesStringified", coordinatesStringified);

      handleObjectOnMapValidation<ZoneFormValues>(
        setError,
        clearErrors,
        t,
        coordinatesStringified,
        [
          () =>
            coordinates?.[0]?.length < 3
              ? t("pages.ObjectManagement.Objects.item.input_rules.minimum_polygon_points_count")
              : null
        ]
      );
    }, [getCurrentActiveObject]),
    100
  );

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit(
      async ({ number, type, description, city, isActive, prices, tariffUuid }) => {
        const rawCoordinates = getCurrentActiveObject()?.getCoordinates();

        let coordinates: Point[] = [{ longitude: 0, lattitude: 0 }];
        let center = { longitude: 0, lattitude: 0 };

        if (rawCoordinates) {
          ({ coordinates, center } = getCoordinatesWithCenter(rawCoordinates[0]));
        }

        return submit({
          center,
          number,
          type,
          description,
          city,
          location: { coordinates },
          isActive,
          prices,
          tariffUuid
        }).then((res) => {
          if (isAddType) {
            reset({
              ...defaultValues,
              tariffUuid: tariffs?.[0]?.uuid
            });
            resetNewObject(ObjectType.Zone);

            navigate(
              `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Zones}/${res.data.uuid?.value}`
            );

            stopWorkingWithMapObject();

            return res;
          }

          navigate(ZONES_BASE_ROUTE);

          setOptimisticMutateResultInList(
            queryClient,
            res.data?.uuid?.value || "",
            ZONES_KEY,
            "zones",
            coordinates
          );

          if (isEditType) {
            setChangedCoordinates(coordinates);
          }

          stopWorkingWithMapObject();

          return res;
        });
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);

  const handleChangeCoordinates = (value: string) => {
    const parsed = parseCoordinatesToNumberFormat(value);
    const coordinates = convertCoordinatesToObjectFormat(parsed);

    setValue("location.coordinates", coordinates);
    setValue("coordinates", coordinates);
    const currentActiveObject = getCurrentActiveObject();
    currentActiveObject?.setCoordinates([parsed]);
  };

  const handleChangeType = (event: SelectChangeEvent<unknown>) => {
    setValue("type", event.target.value as ZoneType);

    if (event.target.value === ZONE_TYPE_SIMPLE && data.tariffs && data.tariffs.length > 0) {
      setValue("tariffUuid", data.tariffs[0]?.uuid);
      clearErrors("type");
      return;
    }

    setValue("tariffUuid", undefined);
  };

  const handleBackToList = () => {
    navigate(ZONES_BASE_ROUTE);
    stopWorkingWithMapObject(true);
  };

  useEffect(() => {
    reset({
      ...formValues,
      location: { coordinates: changedCoordinates || formValues.location?.coordinates },
      coordinates: changedCoordinates || formValues.location?.coordinates
    });
    if (isEditType && zone && isAllObjectsOnMapInitialized) {
      startEditingObject(formValues, { onChange: onGeometryChange });
    }
  }, [zone, formValues, isAllObjectsOnMapInitialized]);

  useEffect(() => {
    if (zone && isAllObjectsOnMapInitialized && zone.uuid?.value) {
      showObjectOnMap(zone.uuid.value);
    }
  }, [zone, isAllObjectsOnMapInitialized]);

  useEffect(() => {
    if (isTariffsError) {
      openErrorAlert(t("pages.Tariffs.list.could_not_load_tariffs"));
    }
  }, [isTariffsError]);

  useEffect(() => {
    // Без этого поля изменение хоть одной точки зоны приводит
    // к багу, когда инпут для объект сообщает, что объект на карте
    // не размещен, хотя это не так.
    register("objectOnMap", {
      required: true
    });

    if (isAddType) {
      startDrawingNewObject({
        type: ObjectType.Zone,
        onChange: onGeometryChange
      });
    }
  }, []);

  return (
    <Box component='form' onSubmit={handleSubmit} width='100%' data-testid='ZoneForm'>
      {isInitialLoading && (
        <Box width='100%' textAlign='center'>
          <CircularProgress />
        </Box>
      )}

      <FormControl margin='none' fullWidth required>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.zones.number")}:</FormLabel>
        <Input
          data-testid='ZoneForm__number-input'
          rules={{
            required: { value: true, message: t("common.input_rules.required") },
            pattern: {
              value: /^\d+$/,
              message: t(
                "pages.ObjectManagement.Objects.item.zones.input_rules.number_should_persist_only_digits"
              )
            },
            maxLength: {
              value: 10,
              message: t("pages.ObjectManagement.Objects.item.zones.input_rules.10_digits_max")
            }
          }}
          name='number'
          control={control}
          variant='standard'
          fullWidth
          disabled={isLoading}
        />
      </FormControl>

      <FormControl margin='none' required sx={{ marginTop: 3 }} fullWidth>
        <Select
          control={control}
          name='type'
          size='small'
          rules={{ required: { value: true, message: t("common.input_rules.required") } }}
          label={`${t("pages.ObjectManagement.Objects.item.zones.type")}:`}
          disabled={isLoading}
          data-testid='ZoneForm__zone-type-input'
          onChange={handleChangeType}
        >
          {config?.zoneTypes?.map(({ name, code }) => (
            <MenuItem key={code} value={code}>
              {getValueByLang(name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box marginTop={2} width='100%'>
        {zoneType === ZONE_TYPE_SIMPLE && (
          <FormControl required margin='none' sx={{ marginTop: 3 }} fullWidth>
            <Select
              rules={{ required: { value: true, message: t("common.input_rules.required") } }}
              control={control}
              name='tariffUuid.value'
              id='tariffUuid.value'
              size='small'
              label={`${t("pages.Tariffs.tariff")}:`}
              disabled={isTariffsLoading}
            >
              {isTariffsLoading && <MenuItem value='loading'>{t("common.loading")}</MenuItem>}
              {data.tariffs?.map((tariff) => (
                <MenuItem key={tariff.uuid?.value} value={tariff.uuid?.value}>
                  {getValueByLang(tariff.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box marginTop={2} width='100%' data-testid='ZoneForm__description-input-container'>
        <MultiLangInput
          label={`${t("pages.ObjectManagement.Objects.item.zones.description")}:`}
          name='description'
          langsAlwaysOpen={isAddType}
          control={control}
          multiline
          required
          disabled={isLoading}
        />
      </Box>

      <OnMapTypeInput
        validateObjectOnMap={handleObjectOnMapValidation}
        getFieldState={getFieldState}
        clearErrors={clearErrors}
        setError={setError}
        setValue={setValue}
        onOpenInput={() => {
          const currentActiveObject = getCurrentActiveObject();
          setValue(
            "coordinatesStringified",
            stringifyCoordinates(currentActiveObject?.getRef()?.geometry.getCoordinates())
          );
        }}
        disabled
        control={control}
        onSave={handleChangeCoordinates}
        defaultValue='polygon'
      />

      <FormControlLabel
        label={t("pages.ObjectManagement.Objects.item.zones.activity")}
        control={
          <Checkbox
            data-testid='ZoneForm__activity-input'
            name='isActive'
            control={control}
            disabled={isLoading}
          />
        }
      />

      <ConfirmCancelPanel
        onConfirmProps={{ type: "submit", disabled: isLoading }}
        onCancelProps={{ disabled: isLoading }}
        confirmLabel={isLoading ? t("common.saving") : t("common.save")}
        onConfirm={handleSubmit}
        onCancel={handleBackToList}
      />
    </Box>
  );
};

export default Form;
