import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import { ReactComponent } from "tss-react/tools/ReactComponent";

export const ButtonsPanelCardComponent = <Props = PropsWithChildren>(
  Component: ReactComponent<Props>,
  id: string | number
) =>
  styled(Component)(({ theme }) => ({
    [`&:hover .ButtonsPanel-${id}`]: {
      visibility: "visible"
    },
    margin: theme.spacing(1)
  }));
