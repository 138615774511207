import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  PermissionsGrantsRequest,
  PermissionsGrantsRequestStatus,
  PermissionsResponse
} from "api/generated";
import { useGetPermissionsQuery } from "hooks/queries/permissions";
import Select from "ui-kit/form/Select";
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Paths } from "routes/const";
import LinkButton from "ui-kit/buttons/LinkButton";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const statusOptions = [
  {
    label: "pages.Permissions.common.grant_status_current",
    value: PermissionsGrantsRequestStatus.STATUS_CURRENT
  },
  {
    label: "pages.Permissions.common.grant_status_expired",
    value: PermissionsGrantsRequestStatus.STATUS_PAST
  },
  {
    label: "pages.Permissions.common.grant_status_future",
    value: PermissionsGrantsRequestStatus.STATUS_FUTURE
  },
  {
    label: "pages.Permissions.common.grant_status_all",
    value: PermissionsGrantsRequestStatus.STATUS_ALL
  }
];

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<PermissionsGrantsRequest>;
  getValues: UseFormGetValues<PermissionsGrantsRequest>;
  watch: UseFormWatch<PermissionsGrantsRequest>;
  status: PermissionsGrantsRequestStatus;
  onStatusChange: (value: PermissionsGrantsRequestStatus) => void;
};

const SidebarGrants = ({ control, setValue, watch, status, getValues, onStatusChange }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const lastSelectedRef = useRef("");
  const permissionsUuids = watch("permissionsUuids");
  const { isLoading, data } = useGetPermissionsQuery({});

  const permissions = (data as PermissionsResponse)?.permissions;

  const getButtonVariant = (
    value: PermissionsGrantsRequestStatus,
    name: PermissionsGrantsRequestStatus
  ) => (value === name ? "contained" : "outlined");

  const handleStatusChange = (statusArg: PermissionsGrantsRequestStatus) => () =>
    onStatusChange(statusArg);

  const handleAllSelect = () => {
    lastSelectedRef.current = "";
  };

  const handleSelectBenefit = (e: React.SyntheticEvent<HTMLLIElement>) => {
    lastSelectedRef.current = e.currentTarget.dataset.value || "";
  };

  const handleSelectPermissions = () => {
    const value = lastSelectedRef.current;
    if (value === "") {
      setValue("permissionsUuids", [""]);
      return;
    }

    const currentValue = getValues("permissionsUuids");

    let isValueInList = false;

    const newValue = (currentValue || []).filter((uuid: string) => {
      if (uuid === value) isValueInList = true;
      return uuid !== "" && uuid !== value;
    });

    if (!isValueInList) {
      newValue.push(value);
    }

    if (!newValue.length) {
      newValue.push("");
    }

    setValue("permissionsUuids", newValue);
  };

  const getHelperText = () => {
    if (!permissionsUuids?.length || permissionsUuids?.[0] === "") {
      return "";
    }

    return `${t("pages.Permissions.Grant.list.total_selected")} ${permissionsUuids?.length}`;
  };

  const canCreate = can("create", "PermissionGrant");

  return (
    <Box display='flex' flexWrap='wrap'>
      {canCreate && (
        <LinkButton fullWidth variant='contained' to={`${Paths.Permissions}${Paths.Grants}/add`}>
          {t("pages.Permissions.Grant.list.add_grant")}
        </LinkButton>
      )}
      <Box width='100%' marginTop={canCreate ? 2 : 0}>
        <FormControl>
          <FormLabel>{t("pages.Permissions.Grant.list.status")}:</FormLabel>
          <Box>
            {statusOptions.map((option) => (
              <Button
                key={option.value}
                sx={{ marginRight: 1, marginTop: 1 }}
                variant={getButtonVariant(status, option.value)}
                onClick={handleStatusChange(option.value)}
              >
                {t(option.label)}
              </Button>
            ))}
          </Box>
        </FormControl>
      </Box>
      <Box width='100%' marginTop={2}>
        <FormLabel>{t("pages.Permissions.Grant.list.permissions")}:</FormLabel>
        <FormControl sx={{ width: "100%" }}>
          <Select
            sx={{ marginTop: "8px" }}
            multiple
            multiline
            fullWidth
            disabled={isLoading}
            control={control}
            placeholder={t("pages.Permissions.Grant.list.unimportant")}
            name='permissionsUuids'
            onChange={handleSelectPermissions}
            helperText={getHelperText()}
          >
            <MenuItem onClick={handleAllSelect} value=''>
              {isLoading ? t("common.loading") : t("pages.Permissions.Grant.list.unimportant")}
            </MenuItem>
            {permissions?.map((permission) => (
              <MenuItem
                onClick={handleSelectBenefit}
                sx={{ whiteSpace: "normal" }}
                key={permission?.uuid}
                value={permission?.uuid}
              >
                {getValueByLang(permission.name, true)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SidebarGrants;
