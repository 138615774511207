import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportTableContainer } from "../common";
import SumReportTableBody from "./SubReportTableBody";
import SumReportTablePaginationBody from "./SumReportTablePaginationBody";
import { Sum } from "api/generated";

interface SumReportTableProps {
  isFetching?: boolean;
  descriptionTitle: string;
  usePagination?: boolean;
  rows?: Sum[];
}

const SumReportTable = ({
  isFetching,
  rows,
  descriptionTitle,
  usePagination
}: SumReportTableProps) => {
  const { t } = useTranslation();

  return (
    <ReportTableContainer
      isFetching={isFetching}
      rowsLength={rows?.length}
      usePagination={usePagination}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>{descriptionTitle}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.sum")}</TableCell>
          </TableRow>
        </TableHead>
        {usePagination ? (
          <SumReportTablePaginationBody rows={rows} />
        ) : (
          <SumReportTableBody rows={rows} />
        )}
      </Table>
    </ReportTableContainer>
  );
};

export default SumReportTable;
