import styled from "@mui/system/styled";

export const StyledRoot = styled("div")({
  display: "flex",
  width: "100%",
  minHeight: "100vh",
  backgroundColor: "#e7ebef"
});

export const StyledContent = styled("div")({
  position: "relative",
  flexGrow: 1,
  paddingTop: "60px"
});
