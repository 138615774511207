import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { CreateBenefitsGrantRequest, RequestParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { GRANTS_QUERY_KEY } from ".";
import { CREATE_MUTATE_KEY } from "../action-keys";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useCreateGrantMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();

  return useMutation({
    mutationFn: (body: CreateBenefitsGrantRequest, params?: RequestParams) =>
      requests.adminGatewayCreateBenefitGrant(body, params),
    mutationKey: [GRANTS_QUERY_KEY, CREATE_MUTATE_KEY],
    onSuccess() {
      openSuccessAlert(t("pages.Benefits.Grants.item.benefit_grant_created"));
    },
    onError({ response }) {
      showErrors({
        ...response?.data,
        defaultMessage:
          !response?.data?.details?.length && !response?.data?.message
            ? t("pages.Benefits.Grants.item.could_not_create_benefit_grant")
            : null
      });
    }
  });
};
