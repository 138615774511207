import { ProtobufAny } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import ValidationError from "components/ValidationError";
import { ReactNode } from "react";

export type UseShowBackendErrors = () => (error?: {
  defaultMessage?: ReactNode;
  details?: (ProtobufAny & { fieldViolations: { field: string; description: string }[] })[];
  message?: string;
}) => void;

const useShowBackendErrors: UseShowBackendErrors = () => {
  const { openErrorAlert } = useAlertStatus();

  return ({ details, message, defaultMessage } = {}) => {
    if (!details?.length && message) openErrorAlert(message);

    details?.forEach((detail) => {
      const { fieldViolations } = detail;

      if (fieldViolations?.length) {
        openErrorAlert(<ValidationError fieldViolations={fieldViolations} />);
      }
    });

    if (defaultMessage) openErrorAlert(defaultMessage);
  };
};

export default useShowBackendErrors;
