import { Link } from "react-router-dom";
import { formatUtcDate } from "utils/date";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PhoneIcon from "@mui/icons-material/Phone";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { theme } from "config/theme";
import { Paths } from "routes/const";
import AccountAvatar from "components/Avatar";
import { User } from "api/generated";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  width: "100%"
}));

const AccountShortCard = ({
  firstName,
  lastName,
  lastActivityDate,
  middleName,
  accountId,
  vehicles,
  phone,
  uuid
}: User) => {
  const date = lastActivityDate && new Date(lastActivityDate);
  const styledPhone =
    phone && phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");
  return (
    <ListItem data-testid='AccountCard'>
      <StyledLink to={`${Paths.Accounts}/${uuid?.value}`}>
        <Box
          display='flex'
          width='100%'
          flexDirection='row'
          flexWrap='wrap'
          color={theme.palette.text.primary}
        >
          <Box flex='1 70%' display='flex' alignItems='center' mt={2}>
            <ListItemAvatar>
              <AccountAvatar name={`${firstName} ${lastName}`} />
            </ListItemAvatar>
            <Typography variant='h6'>{`${firstName} ${middleName} ${lastName}`}</Typography>
          </Box>
          <Box textAlign='right' flex='1 30%'>
            <Typography variant='body1'>
              {date && formatUtcDate(date, "dd.MM.yyyy в HH:mm")}
            </Typography>
            <Typography variant='body1' color={theme.palette.text.secondary}>
              #{accountId}
            </Typography>
          </Box>
          {!!vehicles?.length && (
            <Box flex='0 200px' color={theme.palette.text.secondary}>
              <List>
                {vehicles.map(({ vrp }) => (
                  <ListItem key={vrp}>
                    <ListItemIcon>
                      <DirectionsCarIcon />
                    </ListItemIcon>
                    <ListItemText primary={vrp} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Box flex='0 250px' color={theme.palette.text.secondary}>
            {phone && (
              <Typography variant='body1' sx={{ padding: "16px 8px 0", lineHeight: 2 }}>
                <PhoneIcon sx={{ marginRight: "8px", verticalAlign: "middle" }} /> {styledPhone}
              </Typography>
            )}
          </Box>
        </Box>
      </StyledLink>
    </ListItem>
  );
};

export default AccountShortCard;
