import { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  BenefitsGrant,
  BenefitsResponse,
  CreateBenefitsGrantRequest,
  GetZonesResponse
} from "api/generated";
import { useLocalizationContext } from "context/LocalizationContext";
import { useCreateGrantMutation, useGetBenefitsQuery } from "hooks/queries/benefits";
import { useGetZonesQuery } from "hooks/queries/zones";
import { BaseSyntheticEvent, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes/const";
import { BENEFIT_GRANT_BASE_ROUTE } from "routes/route-declarations";
import LinkButton from "ui-kit/buttons/LinkButton";
import Autocomplete from "ui-kit/form/Autocomplete/index";
import DataPicker from "ui-kit/form/DataPicker";
import Select from "ui-kit/form/Select";
import { DateTime } from "luxon";
import VehicleInputs from "components/VehicleInputs";

export type Props = {
  submit: ReturnType<typeof useCreateGrantMutation>["mutateAsync"];
  grant?: BenefitsGrant;
  isAddType?: boolean;
  isEditType?: boolean;
  isLoading?: boolean;
};

const allZonesValue = "pages.Benefits.Grants.item.all_zones";

const defaultValues: Omit<CreateBenefitsGrantRequest, "start" | "end"> & {
  start: undefined | DateTime | null;
  end: undefined | DateTime | null;
  vehicle?: string;
} = {
  benefitUuid: undefined,
  vrp: "",
  start: null,
  end: null,
  zones: [allZonesValue],
  vehicle: undefined
};

const Form = ({ submit, isLoading }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const navigate = useNavigate();
  const { isLoading: isBenefitsLoading, data: benefitsData } = useGetBenefitsQuery({});
  const benefits = (benefitsData as BenefitsResponse)?.benefits;

  const allZones = t(allZonesValue);

  const { isLoading: isZonesLoading, data: zonesData } = useGetZonesQuery({
    limit: 0,
    offset: 0
  });
  const zones: string[] = useMemo(
    () =>
      [allZones].concat(
        (zonesData?.data as GetZonesResponse)?.zones?.map((zone) => zone.number as string) ?? []
      ),
    [zonesData?.data, isZonesLoading]
  );

  const {
    handleSubmit: onSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState
  } = useForm({
    defaultValues: {
      ...defaultValues,
      zones: [allZones]
    }
  });

  const handleChangeZone = (
    _e: BaseSyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
    newValue?: { option: string }
  ) => {
    if (
      reason === "clear" ||
      (reason === "removeOption" && (newValue?.option === allZones || !value.length))
    ) {
      return [allZones];
    }

    if (newValue?.option === allZones) {
      if (value.includes(allZones)) {
        return [allZones];
      } else {
        return value.filter((_value: string) => _value !== allZones);
      }
    }
    return value.filter((_value: string) => _value !== allZones);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) =>
    await onSubmit((formValues) => {
      const { vehicle: _, zones, ...values } = formValues;
      return submit({
        ...values,
        benefitUuid: { value: values.benefitUuid as string },
        zones: zones?.includes(allZones) ? [] : zones,
        start: values.start ? `${values.start.valueOf()}` : undefined,
        end: values.end ? `${values.end.valueOf()}` : undefined
      }).then((res) => {
        if (res.data.uuid?.value) {
          navigate(`${BENEFIT_GRANT_BASE_ROUTE}/${res.data.uuid?.value}`);
        } else {
          navigate(BENEFIT_GRANT_BASE_ROUTE);
        }
        return res;
      });
    })(e);

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <Box width='100%' marginTop={2}>
        <FormControl fullWidth>
          <FormLabel required>{t("pages.Benefits.Grants.item.benefit")}:</FormLabel>
          <Select
            fullWidth
            disabled={isBenefitsLoading || isLoading}
            control={control}
            name='benefitUuid'
            rules={{
              required: { value: true, message: t("common.input_rules.required") }
            }}
          >
            {benefits?.map((benefit) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={benefit?.uuid?.value}
                value={benefit?.uuid?.value}
              >
                {getValueByLang(benefit.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <VehicleInputs
        control={control}
        watch={watch}
        setValue={setValue}
        trigger={trigger}
        formState={formState}
        isLoading={isLoading}
      />
      <Box display='flex' flexWrap='nowrap' marginTop={2}>
        <Box width='100%' marginRight={2}>
          <FormControl fullWidth>
            <FormLabel required>{t("common.zones")}:</FormLabel>
            <Autocomplete<string>
              control={control}
              name='zones'
              options={zones}
              variant='outlined'
              multiple
              required
              disableCloseOnSelect
              disabled={isZonesLoading || isLoading}
              onChange={handleChangeZone}
              rules={{
                required: { value: true, message: t("common.input_rules.required") }
              }}
            />
          </FormControl>
        </Box>
        <Box width='100%' marginRight={2}>
          <FormControl fullWidth>
            <FormLabel required>{t("pages.Benefits.Grants.item.start_date")}:</FormLabel>
            <DataPicker
              control={control}
              name='start'
              inputProps={{
                placeholder: ""
              }}
              inputFormat='dd.MM.yyyy'
              maxDate={watch("end")}
              disabled={isLoading}
              rules={{
                required: { value: true, message: t("common.input_rules.required") }
              }}
            />
          </FormControl>
        </Box>
        <Box width='100%'>
          <FormControl fullWidth>
            <FormLabel required>
              {t("pages.Benefits.Grants.item.expiration_date_including")}:
            </FormLabel>
            <DataPicker
              control={control}
              name='end'
              inputProps={{
                placeholder: ""
              }}
              inputFormat='dd.MM.yyyy'
              minDate={watch("start")}
              disabled={isLoading}
              rules={{
                required: { value: true, message: t("common.input_rules.required") }
              }}
            />
          </FormControl>
        </Box>
      </Box>

      <Box display='flex' justifyContent='start' marginTop={2}>
        <Button disabled={isLoading} type='submit' variant='contained' sx={{ marginRight: 2 }}>
          {t("common.save")}
        </Button>
        <LinkButton variant='outlined' to={`${Paths.Benefits}${Paths.Grants}`}>
          {t("common.cancel")}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default Form;
