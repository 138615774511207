import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { Role } from "api/generated";
import { useGetRolesQuery } from "hooks/queries/accounts";
import Item from "./components/Item";
import { useTranslation } from "react-i18next";

const RoleList = () => {
  const { t } = useTranslation();
  const { isFetching, isLoading, data } = useGetRolesQuery();

  const roles = (data as { roles?: Role[] })?.roles;

  return (
    <Box display='flex' flexWrap='nowrap' height='100%'>
      <Card>
        <CardContent>
          {isFetching || isLoading ? (
            <Typography>{t("common.loading")}</Typography>
          ) : (
            roles?.map((role, index) => (
              <Item showDivider={roles.length - 1 !== index} key={role.uuid?.value} {...role} />
            ))
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default RoleList;
