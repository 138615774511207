import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { Link, LinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { theme } from "config/theme";
import { ReactNode } from "react";

const Container = styled(Box)({
  "& a": {
    textDecoration: "none"
  }
});

type Props = {
  activeRoute: string;
  to: string;
  children: ReactNode;
  containerProps?: BoxProps;
  buttonProps?: ButtonProps;
  linkProps?: LinkProps;
  activeComparator?: (to: string, activeRoute: string) => boolean;
};

const defaultActiveComparator = (to: string, activeRoute: string) => to.includes(activeRoute);

const SidebarNavButton = ({
  activeRoute,
  to,
  containerProps,
  buttonProps,
  linkProps,
  children,
  activeComparator = defaultActiveComparator
}: Props) => (
  <Container margin={0.5} {...containerProps}>
    <Link to={to} color={activeRoute ? theme.palette.primary.main : "white"} {...linkProps}>
      <Button
        size='small'
        variant={activeComparator(to, activeRoute) ? "contained" : "outlined"}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link>
  </Container>
);

export default SidebarNavButton;
