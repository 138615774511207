import { Box, FormControl, FormLabel } from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Switch from "ui-kit/form/Switch";

export type StrictSwitchProps<T extends FieldValues = any> = {
  os: string;
  control: Control<T>;
  disabled: boolean;
};

const StrictSwitch = <T extends FieldValues = any>({
  os,
  control,
  disabled
}: StrictSwitchProps<T>) => {
  const { t } = useTranslation();

  return (
    <Box
      marginLeft={3}
      height={56}
      textAlign='center'
      display='flex'
      flexWrap='wrap'
      justifyContent='center'
      alignItems='baseline'
      minHeight={79}
    >
      <FormLabel sx={{ width: "100%" }}>{`${t(
        "pages.SystemSettings.MobileApp.strict"
      )}:`}</FormLabel>
      <FormControl>
        <Box minHeight='100%' display='flex' alignItems={"center"}>
          <Switch control={control} disabled={disabled} name={`env.versions.${[os]}.strict`} />
        </Box>
      </FormControl>
    </Box>
  );
};
export default StrictSwitch;
