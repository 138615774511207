import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/system";

export const TableHeaderCell = styled(TableCell)({
  textAlign: "center",
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12,
  fontSize: "1rem",
  fontWeight: "bold"
});

export const TableBodyCell = styled(TableCell)({
  textAlign: "center",
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12
});
