import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { COMPANIES_QUERY_KEY } from "./";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { requests } from "api";
import { AdminGatewayRefillCompanyParams } from "api/generated";

const useCompanyBalanceMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: AdminGatewayRefillCompanyParams) => requests.adminGatewayRefillCompany(body),
    onSuccess() {
      openSuccessAlert(t("pages.LegalEntityBalance.replenish_dialog.balance_replenished"));
      queryClient.invalidateQueries([COMPANIES_QUERY_KEY]);
    },
    onError() {
      showErrors({
        defaultMessage: t("pages.LegalEntityBalance.replenish_dialog.could_not_replenish_balance")
      });
    }
  });
};

export default useCompanyBalanceMutation;
