import { Category, SubCategoryType, SubCategoryZoneType, Uuid } from "api/generated";
import { CategoryFormType, FormInfo, SubCategoryFormValues } from "../types";

export enum EntityType {
  Category = "category",
  SubCategory = "subcategory"
}

export const getActionInfo = (formInfo: FormInfo) => {
  const action = {
    target: "",
    type: "",
    category: false,
    subCategory: false,
    edit: false,
    view: false,
    create: false
  };

  if (formInfo.type) {
    action.target =
      formInfo.type === CategoryFormType.CreateSubCategory ||
      formInfo.type === CategoryFormType.EditSubCategory ||
      formInfo.type === CategoryFormType.ViewSubCategory
        ? EntityType.SubCategory
        : EntityType.Category;
    action.type =
      formInfo.type === CategoryFormType.CreateSubCategory ||
      formInfo.type === CategoryFormType.CreateCategory
        ? "create"
        : formInfo.type === CategoryFormType.EditSubCategory ||
          formInfo.type === CategoryFormType.EditCategory
        ? "edit"
        : "view";
    action.category = action.target === EntityType.Category;
    action.subCategory = action.target === EntityType.SubCategory;
    action.edit = action.type === "edit";
    action.create = action.type === "create";
    action.view = action.type === "view";
  }

  return action;
};

export const getInitialValues = (
  formInfo: FormInfo,
  categories: Category[] = [],
  langs: string[]
) => {
  const actionInfo = getActionInfo(formInfo);
  const defaultValues = getDefaultValues(formInfo, langs);

  if (actionInfo.category) {
    if (actionInfo.create) return defaultValues;
    return categories?.find((cat) => cat.uuid?.value === formInfo.uuid?.value);
  }

  const category = categories?.find((cat) => cat.uuid?.value === formInfo.parentUuid?.value);

  const subCategory = category?.children?.find(
    (subCat) => subCat.uuid?.value === formInfo.uuid?.value
  );

  const subCategoryFormValues = defaultValues as SubCategoryFormValues;

  if (subCategory) {
    subCategoryFormValues.uuid = subCategory.uuid;
    subCategoryFormValues.name = subCategory.name;
    subCategoryFormValues.parent = subCategory.parentUuid as Uuid;
    subCategoryFormValues.type = subCategory.type as SubCategoryType;
    subCategoryFormValues.zoneType = subCategory.zoneType as SubCategoryZoneType;
  }

  return subCategoryFormValues;
};

export const getDefaultValues = (
  formInfo: FormInfo,
  langs: string[]
): Category | SubCategoryFormValues => ({
  name: langs.reduce(
    (acc, key) => ({
      ...acc,
      [key]: ""
    }),
    {}
  ),
  ...(formInfo.type === CategoryFormType.CreateSubCategory ||
  formInfo.type === CategoryFormType.EditSubCategory
    ? {
        parent: formInfo.parentUuid,
        type: SubCategoryType.SUB_CATEGORY_TYPE_UNSPECIFIED,
        zoneType: SubCategoryZoneType.SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED
      }
    : {})
});
