import { MouseEventHandler, ReactNode } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Stack, { StackProps } from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

type Props = {
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirmProps?: ButtonProps;
  onCancelProps?: ButtonProps;
  stackProps?: StackProps;
  confirmLabel?: string | ReactNode;
  cancelLabel?: string | ReactNode;
};

const ConfirmCancelPanel = ({
  stackProps,
  onConfirmProps,
  onConfirm,
  onCancelProps,
  onCancel,
  confirmLabel = "common.save",
  cancelLabel = "common.cancel"
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
      sx={{ justifyContent: "stretch", marginTop: 1 }}
      direction='row'
      data-testid='ConfirmCancelPanel'
      {...stackProps}
    >
      <Button
        data-testid='ConfirmCancelPanel__confirm-button'
        variant='contained'
        {...onConfirmProps}
        onClick={onConfirm}
        fullWidth
      >
        {typeof confirmLabel === "string" ? t(confirmLabel) : confirmLabel}
      </Button>
      <Button
        data-testid='ConfirmCancelPanel__cancel-button'
        variant='outlined'
        {...onCancelProps}
        onClick={onCancel}
        fullWidth
      >
        {typeof cancelLabel === "string" ? t(cancelLabel) : cancelLabel}
      </Button>
    </Stack>
  );
};

export default ConfirmCancelPanel;
