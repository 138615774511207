import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { PermissionsResponse, RequestParams } from "api/generated";
import { PERMISSIONS_QUERY_KEY } from ".";

const initialPermissions: PermissionsResponse = { permissions: [] };

export const GET_ABONEMETS_QUERY_KEY = "get_permissions";

export const useGetPermissionsQuery = (
  params?: RequestParams,
  options?: Parameters<typeof useQuery>[2]
) => {
  const { data = initialPermissions, ...rest } = useQuery(
    [PERMISSIONS_QUERY_KEY, GET_ABONEMETS_QUERY_KEY, params],
    () => requests.adminGatewayPermissions(params).then(({ data }) => data),
    options
  );

  return {
    data,
    ...rest
  };
};
