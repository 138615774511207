import { Vehicle, VehicleType } from "api/generated";
import { TableBodyCell } from "../styled";
import { vehicleTypeNames } from "pages/Tariffs/const";
import { TFunction } from "i18next";

const VehicleCell = ({
  vrp,
  label,
  type,
  t
}: Vehicle & { t: TFunction<"translate", undefined> }) => (
  <TableBodyCell>
    {[t(vehicleTypeNames[type || VehicleType.VEHICLE_TYPE_UNSPECIFIED]), label, vrp]
      .filter(Boolean)
      .join(", ")}
  </TableBodyCell>
);

export default VehicleCell;
