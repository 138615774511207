import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { requests } from "api";
import {
  AdminGatewayGetRoadsideSessionsParams,
  RequestParams,
  RoadsideSessionsResponse
} from "api/generated";

const initialAccounts: RoadsideSessionsResponse = { sessions: [], total: "0" };

export const GET_ROADSIDE_SESSIONS_QUERY_KEY = "get_roadside_sessions";

export const useGetRoadsideSessionListQuery = (
  query: AdminGatewayGetRoadsideSessionsParams,
  params?: RequestParams,
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn" | "initialData">
) => {
  const {
    data = initialAccounts,
    isLoading,
    ...rest
  } = useQuery(
    [GET_ROADSIDE_SESSIONS_QUERY_KEY, query, params],
    () => requests.adminGatewayGetRoadsideSessions(query, params).then(({ data }) => data),
    options
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
