import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";

export const FEEDBACK_TOPICS_QUERY_KEY = "feedback_topics";

const useFeedbackTopicsQuery = () => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([FEEDBACK_TOPICS_QUERY_KEY], () => requests.adminGatewayGetTopics(), {
    onError() {
      openErrorAlert(t("pages.Feedback.could_not_load_topics"));
    },
    refetchOnWindowFocus: false
  });
};

export default useFeedbackTopicsQuery;
