import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "config/i18n/i18n";

!window.LOGO_NAME && (window.LOGO_NAME = "imb");
const link = document.querySelector("link[rel='icon']");
link?.setAttribute("href", `/${window.LOGO_NAME}_favicon.ico`);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
