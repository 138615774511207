import { matchPath } from "react-router-dom";

export type Routes = {
  addRoutes?: string[];
  viewRoutes?: string[];
  editRoutes?: string[];
};

export const getFormTypes = (
  { addRoutes = [], viewRoutes = [], editRoutes = [] }: Routes,
  pathname: string
) => {
  const isAddType = addRoutes.some((route) => !!matchPath(route, pathname));
  const isViewType = !isAddType && viewRoutes.some((route) => !!matchPath(route, pathname));
  const isEditType = editRoutes.some((route) => !!matchPath(route, pathname));

  return {
    isAddType,
    isViewType,
    isEditType
  };
};

export const getFormTypeInfo = (
  { addRoutes = [], viewRoutes = [], editRoutes = [] }: Routes,
  pathname: string
) => {
  const { isAddType, isViewType, isEditType } = getFormTypes(
    { addRoutes, viewRoutes, editRoutes },
    pathname
  );
  const isFormOpen = isViewType || isAddType || isEditType;
  const formType = isEditType ? "edit" : isAddType ? "add" : isViewType ? "view" : null;

  return {
    isAddType,
    isViewType,
    isEditType,
    isFormOpen,
    formType
  };
};
