import { TFunction } from "i18next";

export const NUMBER_REGEX = /^((\d+)|(-\d+))((\.)|(\.\d+))?$/;
export const POSITIVE_NUMBER_REGEX = /^\d+((\.)|(\.\d+))?$/;

export const getRangeValidator = (min: number, max: number, message: string) => (value: string) => {
  const valueNum = +value;
  if (!Number.isNaN(valueNum) && (valueNum < min || valueNum > max)) {
    return message.replace("{min}", `${min}`).replace("{max}", `${max}`);
  }
};

export const getInputRules = (t: TFunction<"translation", undefined>) => ({
  required: { value: true, message: t("common.input_rules.required") },
  min: {
    value: 0,
    message: t("common.input_rules.not_less_than_zero")
  },
  pattern: {
    value: POSITIVE_NUMBER_REGEX,
    message: t("common.input_rules.only_numbers")
  }
});

export const getMapCenterInputRules = (t: TFunction<"translation", undefined>) => ({
  required: { value: true, message: t("common.input_rules.required") },
  pattern: {
    value: NUMBER_REGEX,
    message: t("common.input_rules.only_numbers")
  }
});
