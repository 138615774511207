import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCategoriesFormContext } from "../../context/CategoriesFormContext";
import { CategoryFormType } from "../../context/types";
import SubCategoryForm from "./components/SubCategoryForm";
import CategoryForm from "./components/CategoryForm";
import { useMemo } from "react";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { EntityType } from "../../context/utils";

const SidebarContent = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const { categories, formInfo, setFormInfo, actionInfo } = useCategoriesFormContext();
  const { target: entityType } = actionInfo;

  const parent = useMemo(
    () =>
      categories?.find((category) => category.uuid?.value === formInfo.parentUuid?.value) || null,
    [formInfo, categories]
  );

  const handleAddParentCategory = () => setFormInfo(CategoryFormType.CreateCategory);

  return (
    <Box>
      <Typography component='h2' variant='h6' marginBottom={1}>
        {actionInfo.create
          ? entityType === EntityType.Category
            ? t("pages.ObjectManagement.Categories.item.new_category")
            : t("pages.ObjectManagement.Categories.item.new_subcategory")
          : actionInfo.edit
          ? entityType === EntityType.Category
            ? t("pages.ObjectManagement.Categories.item.editing_category")
            : t("pages.ObjectManagement.Categories.item.editing_subcategory")
          : null}
      </Typography>
      {formInfo.type ? (
        <Box>{parent ? <SubCategoryForm parent={parent} /> : <CategoryForm />}</Box>
      ) : can("create", "Category") ? (
        <Button onClick={handleAddParentCategory} variant='contained' fullWidth>
          {t("pages.ObjectManagement.Categories.item.add_category")}
        </Button>
      ) : null}
    </Box>
  );
};

export default SidebarContent;
