import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { BenefitsGrantResponse, RequestParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { BENEFIT_GRANT_QUERY_KEY } from ".";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

export const useGetBenefitGrantQuery = (
  uuid: string,
  params?: RequestParams,
  options?: Omit<
    UseQueryOptions<BenefitsGrantResponse, AxiosError<object>, BenefitsGrantResponse, any>,
    "queryKey" | "queryFn"
  >
) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery<
    BenefitsGrantResponse,
    AxiosError<object>,
    BenefitsGrantResponse,
    (string | RequestParams | undefined)[]
  >(
    [BENEFIT_GRANT_QUERY_KEY, uuid, params],
    () => requests.adminGatewayBenefitGrant(uuid, params).then(({ data }) => data),
    {
      ...options,
      onError(err) {
        openErrorAlert(t("pages.Benefits.Grants.item.could_not_load_benefit_grant"));
        if (options?.onError) options.onError(err);
      }
    }
  );
};
