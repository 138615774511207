import { Dialog, DialogContent, DialogTitle, List } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Company } from "api/generated";
import { TransitionProps } from "@mui/material/transitions";
import { parseApiDate } from "pages/LegalEntityBalance/utils";
import DetailItem, { DetailItemProps } from "./DetailItem";

interface CompanyDetailsDialogProps {
  company: Company;
  open: boolean;
  onClose: VoidFunction;
  TransitionProps?: TransitionProps;
}

interface DetailsText {
  key: keyof Company;
  translationKey: string;
}

const convertDateToString = (dateString?: string) => {
  const date = parseApiDate(dateString);

  if (!date) {
    return "";
  }

  return date.toFormat("dd.MM.yyyy");
};

const CompanyDetailsDialog = ({
  company,
  open,
  onClose,
  TransitionProps
}: CompanyDetailsDialogProps) => {
  const { t } = useTranslation();

  const getDetailsValue = (key: keyof Company) => {
    if (key === "contractDate" || key === "licenseExpiry") {
      return convertDateToString(company[key]);
    }

    return company[key] || "";
  };

  const getDetailslist = (): DetailItemProps[] => {
    const baseLabelsTranslationKey = "pages.LegalEntityBalance.details_dialog.labels";

    const details: DetailsText[] = [
      { key: "name", translationKey: `${baseLabelsTranslationKey}.name` },
      { key: "innOrBik", translationKey: `${baseLabelsTranslationKey}.bin` },
      { key: "legalAddress", translationKey: `${baseLabelsTranslationKey}.legal_address` },
      { key: "postalAddress", translationKey: `${baseLabelsTranslationKey}.postal_address` },
      { key: "email", translationKey: `${baseLabelsTranslationKey}.email` },
      { key: "contactPhone", translationKey: `${baseLabelsTranslationKey}.phone` },
      { key: "supervisorFio", translationKey: `${baseLabelsTranslationKey}.supervisor_name` },
      {
        key: "supervisorPosition",
        translationKey: `${baseLabelsTranslationKey}.supervisor_position`
      },
      { key: "note", translationKey: `${baseLabelsTranslationKey}.note` },
      { key: "contractNumber", translationKey: `${baseLabelsTranslationKey}.contract_number` },
      { key: "contractDate", translationKey: `${baseLabelsTranslationKey}.contract_date` },
      { key: "licenseExpiry", translationKey: `${baseLabelsTranslationKey}.license_expiry` }
    ];

    return details
      .map(({ key, translationKey }) => ({
        key,
        label: t(translationKey),
        value: getDetailsValue(key)
      }))
      .filter(({ value }) => value);
  };

  const detailsList = getDetailslist();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "100%", maxWidth: "600px" }
      }}
      TransitionProps={TransitionProps}
    >
      <DialogTitle>{t("pages.LegalEntityBalance.details_dialog.title")}</DialogTitle>
      <DialogContent>
        <List
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
            overflow: "auto"
          }}
        >
          {detailsList.map(({ label, value }) => (
            <DetailItem key={label} label={label} value={value} />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyDetailsDialog;
