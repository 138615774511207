import { useCreateHolidayMutation, useEditHolidayMutation } from "hooks/queries/holidays";
import { DialogProps } from "@mui/material/Dialog";
import { Modal } from "./components";
import { Holiday } from "api/generated";
import { useDeleteHolidayMutation } from "hooks/queries/holidays/useDeleteHolidayMutation";

export type Props = Omit<DialogProps, "children"> & {
  date: Date | null;
  holiday: Holiday | null;
};

const HolidayModal = ({ date, holiday, ...rest }: Props) => {
  const { mutateAsync } = holiday?.uuid
    ? useEditHolidayMutation(holiday.uuid)
    : useCreateHolidayMutation(date?.valueOf() || 0);

  const deleteHolidayMutation = useDeleteHolidayMutation(holiday?.uuid || { value: "" });

  return (
    <Modal
      {...rest}
      submit={mutateAsync}
      deleteHolidayMutation={deleteHolidayMutation}
      date={date}
      holiday={holiday}
    />
  );
};

export default HolidayModal;
