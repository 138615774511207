import Form from "../Form";
import Body from "../Body";
import { useCreatePermissionMutation } from "hooks/queries/permissions";
import { useTranslation } from "react-i18next";

const AddForm = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useCreatePermissionMutation();
  return (
    <Body title={t("pages.Permissions.Active.item.new_permission")}>
      <Form isAddType submit={mutateAsync} isLoading={isLoading} />
    </Body>
  );
};

export default AddForm;
