/**
 * @param {string[]} words - array of words [1 item, 2 - 4 items, other items]
 * @param {number} num - number of items
 * @param {string} lang - words language (default 'ru')
 * @returns {string} one of the words item
 * @example
 * getWordByNumber(['минута', 'минуты', 'минут'], 0)
 * // returns 'минут'
 * getWordByNumber(['минута', 'минуты', 'минут'], 107)
 * // returns 'минут'
 * getWordByNumber(['минута', 'минуты', 'минут'], 1)
 * // returns 'минута'
 * getWordByNumber(['минута', 'минуты', 'минут'], 23)
 * // returns 'минуты'
 *
 */
export default function getWordByNumber(words: string[], num: number, lang = "ru") {
  const floatNum = parseFloat(`${num}`);
  num = parseInt(`${num}`, 10);

  if (num !== floatNum) return words[1] ?? words[0];
  if (Number.isNaN(num)) throw new TypeError("Number argument (num) is NaN");

  num = Math.abs(num);
  if (lang !== "ru") return num === 1 ? words[0] : words[1];

  // Define index in a words array
  const _0 = num % 10 === 1 && num % 100 !== 11 ? 0 : false;
  const _1 =
    _0 === false && num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
      ? 1
      : false;
  const _2 = _1 === false ? 2 : false;
  const wordIndex = _0 !== false ? _0 : _1 || _2;

  return words[Number(wordIndex)] ?? words[0];
}
