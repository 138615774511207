import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

export interface CompanyCardProps {
  name: string;
  phone: string;
  balance: number;
  actionType?: "replenish" | "confirmRegistration" | "extendContract";
  onAction?: VoidFunction;
  onDetails?: VoidFunction;
}

const CompanyCard = ({
  name,
  phone,
  balance,
  actionType,
  onAction,
  onDetails
}: CompanyCardProps) => {
  const { t } = useTranslation();

  const formattedPhone =
    phone.length === 11
      ? phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")
      : phone;

  const formattedBalance = new Intl.NumberFormat("ru-KZ", {
    style: "currency",
    currency: "KZT",
    currencyDisplay: "symbol"
  }).format(balance / 100);

  const getActionButtonColor = (): ButtonProps["color"] => {
    if (actionType === "confirmRegistration" || actionType === "extendContract") {
      return "error";
    } else {
      return "primary";
    }
  };

  const getActionButtonText = (): string | undefined => {
    switch (actionType) {
      case "replenish":
        return t("pages.LegalEntityBalance.replenish");

      case "confirmRegistration":
        return t("pages.LegalEntityBalance.confirm_registration");

      case "extendContract":
        return t("pages.LegalEntityBalance.extend_contract");
    }
  };

  return (
    <Card data-testid='company-card'>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px"
          }}
        >
          <Typography
            component='h3'
            variant='h6'
            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            title={name}
          >
            {name}
          </Typography>

          <IconButton onClick={onDetails}>
            <InfoIcon />
          </IconButton>
        </Box>

        <Divider sx={{ mt: 1 }} />

        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 1,
            gridTemplateColumns: {
              sx: "1fr",
              sm: "auto 1fr"
            },
            mt: 1
          }}
        >
          <Typography sx={{ color: "text.secondary" }}>
            {t("pages.LegalEntityBalance.list.labels.phone")}:
          </Typography>

          {formattedPhone || "-"}

          <Typography sx={{ color: "text.secondary" }}>
            {t("pages.LegalEntityBalance.list.labels.balance")}:
          </Typography>

          {formattedBalance}
        </Box>

        {actionType && (
          <>
            <Divider sx={{ mt: 1 }} />

            <Button
              variant='contained'
              onClick={onAction}
              fullWidth
              sx={{ mt: 1 }}
              color={getActionButtonColor()}
            >
              {getActionButtonText()}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
