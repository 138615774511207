import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AbonementsGrantsRequest, RequestParams } from "api/generated";
import { ABONEMENT_GRANTS_QUERY_KEY } from ".";

export const GET_GRANTS_QUERY_KEY = "get_grants";

const initialGrants = { grants: [] };

export const useGetGrantsQuery = (
  body: AbonementsGrantsRequest,
  params: RequestParams = {},
  options?: Parameters<typeof useQuery>[2]
) => {
  const {
    data = initialGrants,
    status,
    ...rest
  } = useQuery(
    [ABONEMENT_GRANTS_QUERY_KEY, GET_GRANTS_QUERY_KEY, body, params],
    () => requests.adminGatewayAbonementGrants(body, params).then(({ data }) => data),
    options
  );

  return {
    data,
    status,
    ...rest,
    isLoading: status === "loading"
  };
};
