import { Uuid } from "api/generated";
import { useDeleteParkingMutation } from "hooks/queries/parkings";
import { useDeleteZoneMutation } from "hooks/queries/zones";
import { ObjectType } from "pages/ObjectManagment/Objects/context/types";

export const useDeleteMutation = (
  objectType: ObjectType,
  { uuid, shouldInvalidateList }: { uuid: Uuid; shouldInvalidateList?: boolean }
) => {
  switch (objectType) {
    case ObjectType.Zone:
      return useDeleteZoneMutation(uuid, shouldInvalidateList);
    case ObjectType.Parking:
      return useDeleteParkingMutation(uuid, shouldInvalidateList);
    // case ObjectType.Parkomat:
    //   return useDeleteZoneMutation(uuid, shouldInvalidateList);
    // case ObjectType.Scoreboard:
    //   return useDeleteZoneMutation(uuid, shouldInvalidateList);

    default:
      throw new Error();
      break;
  }
};
