import { PropsWithChildren } from "react";
import { useLayoutContext } from "context/LayoutContext";
import { useAuthContext } from "context/AuthContext";
import Skeleton from "@mui/material/Skeleton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslateIcon from "@mui/icons-material/Translate";
import Box from "@mui/material/Box";
import { AppBar, Select } from "./styled";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { useConfigContext } from "context/ConfigContext";
import { useLocalizationContext } from "context/LocalizationContext";

type Props = PropsWithChildren;

const Header = ({ children }: Props) => {
  const { config } = useConfigContext();
  const { availableLanguages = [] } = config || {};
  const { currentLang, setLang } = useLocalizationContext();
  const { isNavSidebarOpen, drawerWidth } = useLayoutContext();
  const { profile, isProfileFetching, logout } = useAuthContext();
  const logo = `/${window.LOGO_NAME}_logo.png`;
  const handleChangeLang = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setLang(value);
  };

  const lang =
    availableLanguages.find(({ shortCode }) => shortCode === currentLang) || availableLanguages[0];

  return (
    <AppBar position='fixed' open={isNavSidebarOpen} drawerWidth={drawerWidth}>
      <Toolbar>
        <Box width={130} m={1} ml={0} position='relative'>
          <img src={logo} alt='intermobility logo' height='30px' style={{ marginTop: 8 }} />
        </Box>
        {children}
        <Box display='flex' justifyContent='end' width='100%' gap={2} alignItems='center'>
          <Typography component='div' variant='body2' color='white'>
            {isProfileFetching ? (
              <Skeleton variant='rounded' width={150} height={10} />
            ) : (
              profile?.user?.email
            )}
          </Typography>
          <Select
            size='small'
            value={lang?.shortCode || ""}
            IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
            onChange={handleChangeLang}
            renderValue={(langCode) => {
              const { localizedName } =
                availableLanguages?.find(({ shortCode }) => shortCode === langCode) ||
                availableLanguages[0];

              return (
                <Box display='flex' alignItems='center' fontSize='0.875rem' mr={1}>
                  <TranslateIcon htmlColor='white' sx={{ ml: 0, mr: 1 }} fontSize='small' />{" "}
                  {localizedName}
                </Box>
              );
            }}
          >
            {availableLanguages?.map((l) => (
              <MenuItem key={l.shortCode} value={l.shortCode}>
                {l.localizedName}
              </MenuItem>
            ))}
          </Select>

          <Tooltip title='Выйти'>
            <IconButton sx={{ color: "white" }} onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
