import { BaseSyntheticEvent, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import { useForm } from "react-hook-form";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import {
  useCreateSubCategoryMutation,
  useEditSubCategoryMutation
} from "hooks/queries/sub-category";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { Category, SubCategoryType, SubCategoryZoneType } from "api/generated";
import { useCategoriesFormContext } from "pages/ObjectManagment/Categories/context/CategoriesFormContext";
import { SubCategoryFormValues } from "pages/ObjectManagment/Categories/context/types";
import { getDefaultValues } from "pages/ObjectManagment/Categories/context/utils";
import Select from "ui-kit/form/Select";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";
import { useConfigContext } from "context/ConfigContext";

const {
  SUB_CATEGORY_TYPE_PARKINGS,
  SUB_CATEGORY_TYPE_PARKOMATS,
  SUB_CATEGORY_TYPE_TERMINALS,
  SUB_CATEGORY_TYPE_SCOREBOARDS,
  SUB_CATEGORY_TYPE_UNSPECIFIED
} = SubCategoryType;

const {
  SUB_CATEGORY_ZONE_TYPE_PAID,
  SUB_CATEGORY_ZONE_TYPE_FREE,
  SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED
} = SubCategoryZoneType;

type Props = {
  parent: Category;
};

const SubCategoryBody = ({ parent }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const { config } = useConfigContext();
  const { formInfo, actionInfo, closeForm, initialValues, initResetFormFn } =
    useCategoriesFormContext();
  const {
    handleSubmit: onSubmit,
    control,
    reset
  } = useForm<SubCategoryFormValues>({
    defaultValues: initialValues
  });
  const { openErrorAlert } = useAlertStatus();
  const { mutate } = actionInfo.create
    ? useCreateSubCategoryMutation({ formInfo })
    : useEditSubCategoryMutation({ formInfo });

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit(
      ({ name, parent, type, zoneType }) => {
        const payload = {
          uuid: formInfo.uuid,
          name,
          parentUuid: parent,
          type,
          zoneType
        };
        if (actionInfo.create) {
          reset(
            getDefaultValues(
              formInfo,
              config?.availableLanguages?.map(({ shortCode }) => shortCode || "") || []
            )
          );
        }
        return mutate(payload);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);

  useEffect(() => {
    initResetFormFn(reset);
  }, [reset]);

  const canCreateOrUpdate = can("create", "Category") || can("update", "Category");

  return (
    <form onSubmit={handleSubmit} data-testid='SubcategoryForm'>
      <FormControl
        component='fieldset'
        variant='standard'
        margin='none'
        sx={{ marginBottom: 2 }}
        fullWidth
      >
        <FormLabel>{`${t("pages.ObjectManagement.Categories.item.parent")}:`}</FormLabel>
        <TextField
          value={getValueByLang(parent.name, true)}
          disabled
          size='small'
          variant='filled'
          hiddenLabel
        />
      </FormControl>

      <MultiLangInput
        label={`${t("pages.ObjectManagement.Categories.item.name")}:`}
        name='name'
        readOnly={!canCreateOrUpdate}
        langsAlwaysOpen={actionInfo.create}
        required
        control={control}
      />

      <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
        <Select
          control={control}
          name='type'
          size='small'
          label={`${t("pages.ObjectManagement.Categories.item.type")}:`}
          readOnly={!canCreateOrUpdate}
          data-testid='SubcategoryForm__select-type'
        >
          <MenuItem value={SUB_CATEGORY_TYPE_PARKINGS}>
            {t("pages.ObjectManagement.Categories.item.sub_category_type_parkings")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_TYPE_PARKOMATS}>
            {t("pages.ObjectManagement.Categories.item.sub_category_type_parkomats")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_TYPE_TERMINALS}>
            {t("pages.ObjectManagement.Categories.item.sub_category_type_terminals")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_TYPE_SCOREBOARDS}>
            {t("pages.ObjectManagement.Categories.item.sub_category_type_scoreboards")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_TYPE_UNSPECIFIED}>
            {t("pages.ObjectManagement.Categories.item.sub_category_type_unspecified")}
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
        <Select
          control={control}
          name='zoneType'
          readOnly={!canCreateOrUpdate}
          size='small'
          label={`${t("pages.ObjectManagement.Categories.item.zone_type")}:`}
          data-testid='SubcategoryForm__select-zone-type'
        >
          <MenuItem value={SUB_CATEGORY_ZONE_TYPE_PAID}>
            {t("pages.ObjectManagement.Categories.item.sub_category_zone_type_paid")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_ZONE_TYPE_FREE}>
            {t("pages.ObjectManagement.Categories.item.sub_category_zone_type_free")}
          </MenuItem>
          <MenuItem value={SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED}>
            {t("pages.ObjectManagement.Categories.item.sub_category_zone_type_unspecified")}
          </MenuItem>
        </Select>
      </FormControl>

      {canCreateOrUpdate && (
        <ConfirmCancelPanel
          onConfirmProps={{ type: "submit" }}
          onConfirm={handleSubmit}
          onCancel={closeForm}
        />
      )}
    </form>
  );
};

export default SubCategoryBody;
