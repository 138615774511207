import { ReactNode } from "react";
import { ConfigProvider } from "./ConfigContext";
import { LayoutProvider } from "./LayoutContext";
import { AuthProvider } from "./AuthContext";
import { AbilityProvider } from "./AbilityContext";
import { LocalizationProvider } from "./LocalizationContext";

const RootProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider>
      <AbilityProvider>
        <ConfigProvider>
          <LocalizationProvider>
            <LayoutProvider>{children}</LayoutProvider>
          </LocalizationProvider>
        </ConfigProvider>
      </AbilityProvider>
    </AuthProvider>
  );
};

export default RootProvider;
