import { ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar, SnackbarKey } from "notistack";
import { AlertStatus } from "components/StatusAlert";

type Message = string | ReactNode;
export type UseAlertStatus = typeof useAlertStatus;
export type UseAlertStatusReturnType = {
  openSuccessAlert: (message?: Message) => (key?: SnackbarKey) => void;
  openErrorAlert: (message?: Message) => (key?: SnackbarKey) => void;
  openInfoAlert: (message?: Message) => (key?: SnackbarKey) => void;
};

export default function useAlertStatus({
  successMessage,
  errorMessage,
  infoMessage
}: {
  successMessage?: string | ReactNode;
  errorMessage?: string | ReactNode;
  infoMessage?: string | ReactNode;
} = {}): UseAlertStatusReturnType {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    openSuccessAlert(message?: string | ReactNode) {
      const key = enqueueSnackbar(message ?? successMessage, {
        variant: AlertStatus.Success,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        action: (
          <IconButton color='default' onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )
      });
      return () => closeSnackbar(key);
    },
    openErrorAlert(message?: string | ReactNode) {
      const key = enqueueSnackbar(message ?? errorMessage, {
        variant: AlertStatus.Error,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        action: (
          <IconButton color='default' onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )
      });
      return () => closeSnackbar(key);
    },
    openInfoAlert(message?: string | ReactNode) {
      const key = enqueueSnackbar(message ?? infoMessage, {
        variant: AlertStatus.Info,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        action: (
          <IconButton color='default' onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )
      });
      return () => closeSnackbar(key);
    }
  };
}
