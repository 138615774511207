import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams, SystemSettingsConfig } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { EDIT_MUTATE_KEY } from "../action-keys";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { SYSTEM_SETTINGS_CONFIG_KEY } from ".";
import { GET_CONFIG_QUERY_KEY } from "../common";
import { useTranslation } from "react-i18next";

export const useEditSystemSettingsConfigMutation = (params?: RequestParams) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: SystemSettingsConfig) => requests.adminGatewaySaveSystemSettings(body),
    mutationKey: [SYSTEM_SETTINGS_CONFIG_KEY, EDIT_MUTATE_KEY, params],
    onSuccess() {
      openSuccessAlert(t("pages.SystemSettings.common.setting_edited"));
      queryClient.invalidateQueries([SYSTEM_SETTINGS_CONFIG_KEY]);
      queryClient.invalidateQueries([GET_CONFIG_QUERY_KEY]);
    },
    onError({ response }) {
      showErrors({
        ...response?.data,
        defaultMessage:
          !response?.data?.details?.length && !response?.data?.message
            ? t("pages.SystemSettings.common.could_not_edit_system_settings")
            : null
      });
      queryClient.invalidateQueries([SYSTEM_SETTINGS_CONFIG_KEY]);
    }
  });
};
