import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { TARIFF_QUERY_KEY } from ".";
import { GET_LIST_QUERY_KEY } from "../action-keys";

const initialTariffs = { tariffs: [] };

export const useGetTariffsQuery = () => {
  const {
    data = initialTariffs,
    isLoading,
    ...rest
  } = useQuery([TARIFF_QUERY_KEY, GET_LIST_QUERY_KEY], () =>
    requests.adminGatewayTariffs().then(({ data }) => data)
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
