import { TFunction } from "i18next";
import { ObjectType } from "../types";
import { MapObjectManager, menuIdTranslates } from "./MapObjectManagers";

export class LineManager extends MapObjectManager {
  static readonly defaultOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWidth: 3,
    draggable: false
  };

  static readonly defaultColors = {
    [ObjectType.Zone]: {
      active: {
        stroke: "0000FF"
      },
      inactive: {
        stroke: "#696969"
      }
    },
    [ObjectType.Parking]: {
      active: {
        stroke: "#006dff"
      },
      inactive: {
        stroke: "#696969"
      }
    },
    [ObjectType.Parkomat]: {
      active: {
        stroke: "0000FF"
      },
      inactive: {
        stroke: "#696969"
      }
    },
    [ObjectType.Terminal]: {
      active: {
        stroke: "0000FF"
      },
      inactive: {
        stroke: "#696969"
      }
    },
    [ObjectType.Scoreboard]: {
      active: {
        stroke: "0000FF"
      },
      inactive: {
        stroke: "#696969"
      }
    }
  };

  constructor(
    ...args: [
      ...ConstructorParameters<typeof MapObjectManager>,
      TFunction<"translation", undefined>
    ]
  ) {
    const baseConstructorArgs = args.slice(0, -1) as ConstructorParameters<typeof MapObjectManager>;
    const t = args.pop();

    super(...baseConstructorArgs);

    this.setOptionsInEditor({
      menuManager: (menuItems: { id: string; title: string }[]) =>
        menuItems.map((item) => ({
          ...item,
          title: t(menuIdTranslates[item.id]) || item.title
        }))
    });
  }

  setEnabledStyle(shouldShowOverOtherObjects = true) {
    this.setOptionsInGeoObject({
      strokeColor: LineManager.defaultColors[this.objectType].active.stroke,
      zIndex: shouldShowOverOtherObjects ? 1000000 : ""
    });

    return this;
  }

  setDisabledStyle() {
    this.setOptionsInGeoObject({
      strokeColor: LineManager.defaultColors[this.objectType].inactive.stroke,
      draggable: false
    });
    return this;
  }

  getCoordinatesToPan() {
    return this.getCenter();
  }

  getCenter(): number[] {
    return [this.object?.center?.lattitude || 0, this.object?.center?.longitude || 0];
  }

  stopChanging(isDiabledStyle = false) {
    this.stopDrawing(isDiabledStyle);
    this.stopEditing(isDiabledStyle);
    return this;
  }

  startEditing(isEnabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.startEditing();
      this.setDraggable();
      if (isEnabledStyle) this.setEnabledStyle(true);
      this.setOptionsInGeoObject({ zIndex: 1000000 });
    }
    return this;
  }

  stopEditing(isDisabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.stopEditing();
      this.setDraggable(false);
      if (isDisabledStyle) this.setDisabledStyle();
      this.setOptionsInGeoObject({ zIndex: "" });
    }
    return this;
  }

  startDrawing(isEnabledStyle = true) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.startDrawing();
      this.setDraggable();
      if (isEnabledStyle) this.setEnabledStyle(true);
    }
    return this;
  }

  stopDrawing(isDisabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.stopDrawing();
      if (isDisabledStyle) this.setDisabledStyle();
    }
    return this;
  }

  startCreating(isEnabledStyle = true) {
    this.startDrawing(isEnabledStyle);
    return this;
  }

  finishCreating(isDisabledStyle = false) {
    this.finishCreating(isDisabledStyle);
    return this;
  }
}
