import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { Parking } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { PARKINGS_KEY } from ".";
import { EDIT_MUTATE_KEY } from "../action-keys";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useEditParkingMutation = (id: string) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();

  return useMutation(
    [PARKINGS_KEY, EDIT_MUTATE_KEY, id],
    (body: Parking) => requests.adminGatewayEditParking(id, body),
    {
      onSuccess() {
        openSuccessAlert(t("pages.ObjectManagement.Objects.item.parking_edited"));
      },
      onError({ response }) {
        showErrors({
          ...response?.data,
          defaultMessage:
            !response?.data?.details?.length && !response?.data?.message
              ? t("pages.ObjectManagement.Objects.item.could_not_edit_parking")
              : null
        });
      }
    }
  );
};
