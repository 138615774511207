import Box from "@mui/material/Box";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import SidebarNavButton from "components/SidebarNavButton";
import {
  PARKINGS_BASE_ROUTE,
  PARKOMATS_BASE_ROUTE,
  SCOREBOARDS_BASE_ROUTE,
  TERMINALS_BASE_ROUTE,
  ZONES_BASE_ROUTE
} from "routes/route-declarations";
import { ObjectOutletContext, ObjectsSidebarContentProps } from "./types";
import { useTranslation } from "react-i18next";

const ObjectsSidebarContent = ({
  activeRoute,
  id,
  formType,
  isViewType,
  isAddType,
  isEditType,
  isFormOpen,
  setCurrentObject,
  currentObject
}: ObjectsSidebarContentProps) => {
  const { t } = useTranslation();
  const contextValue = useMemo<ObjectOutletContext>(
    () => ({
      currentObject,
      setCurrentObject,
      activeRoute,
      formType,
      isViewType,
      isAddType,
      isEditType,
      id
    }),
    [currentObject, activeRoute, currentObject, formType, id]
  );

  return (
    <Box display='flex' flexWrap='wrap'>
      {!isFormOpen && (
        <>
          <SidebarNavButton activeRoute={activeRoute} to={ZONES_BASE_ROUTE}>
            {t("pages.ObjectManagement.Objects.list.zones")}
          </SidebarNavButton>
          <SidebarNavButton activeRoute={activeRoute} to={PARKINGS_BASE_ROUTE}>
            {t("pages.ObjectManagement.Objects.list.parkings")}
          </SidebarNavButton>
          <SidebarNavButton activeRoute={activeRoute} to={PARKOMATS_BASE_ROUTE}>
            {t("pages.ObjectManagement.Objects.list.parkomats")}
          </SidebarNavButton>
          <SidebarNavButton activeRoute={activeRoute} to={TERMINALS_BASE_ROUTE}>
            {t("pages.ObjectManagement.Objects.list.terminals")}
          </SidebarNavButton>
          <SidebarNavButton activeRoute={activeRoute} to={SCOREBOARDS_BASE_ROUTE}>
            {t("pages.ObjectManagement.Objects.list.scoreboards")}
          </SidebarNavButton>
        </>
      )}
      <Outlet context={contextValue} />
    </Box>
  );
};

export default ObjectsSidebarContent;
