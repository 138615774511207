import {
  TariffTariffPeriod,
  TariffTariffPeriods,
  TariffTariffRuleGroup,
  VehicleType
} from "api/generated";
import { RulesData, TariffTariffPeriodKeys } from ".";
import { useMemo } from "react";

const defaultPeriods = { weekdays: [], holidays: [], dayOffs: [] };
const defaultRuleGroups: TariffTariffRuleGroup[] = [];

export const useNormalizedData = (
  periods: TariffTariffPeriods = defaultPeriods,
  ruleGroups: TariffTariffRuleGroup[] = defaultRuleGroups
) =>
  useMemo(
    () =>
      (Object.entries(periods) as [TariffTariffPeriodKeys, TariffTariffPeriod[]][]).map(
        ([period, data]: [TariffTariffPeriodKeys, TariffTariffPeriod[]]) => {
          const vehicleTypes = Array.from(
            new Set(
              data
                .map(
                  (tariffPeriod) =>
                    ruleGroups[tariffPeriod.ruleGroupIndex || 0].rules?.map(
                      (rule) => rule.vehicleType || VehicleType.VEHICLE_TYPE_UNSPECIFIED
                    ) || [VehicleType.VEHICLE_TYPE_UNSPECIFIED]
                )
                .flat()
            )
          );

          const preparedData = {
            period,
            vehicleTypes,
            data: data.map(({ start, end, ruleGroupIndex }) => {
              const rules =
                (typeof ruleGroupIndex === "number" ? ruleGroups[ruleGroupIndex].rules : []) || [];

              return {
                start,
                end,
                rules: (vehicleTypes as unknown as VehicleType[]).reduce((acc, type) => {
                  const rulesForType = rules.filter((rule) => rule.vehicleType === type);

                  if (acc) {
                    if (!acc?.[type]) acc[type] = [];
                    acc[type]?.push(rulesForType);
                    return acc;
                  }

                  return {};
                }, {} as RulesData["rules"])
              };
            })
          };

          return preparedData;
        }
      ),
    [ruleGroups, periods]
  );
