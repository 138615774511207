import { QueryOptions, useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayUserListParams, GetUserListResponse, RequestParams } from "api/generated";

const initialAccounts = { users: [], total: 0 };

export const GET_ACCOUNTS_QUERY_KEY = "get_accounts";

export const useGetAccountsQuery = (
  query: AdminGatewayUserListParams,
  params: RequestParams,
  options?: QueryOptions<GetUserListResponse>
) => {
  const {
    data = initialAccounts,
    isLoading,
    ...rest
  } = useQuery(
    [GET_ACCOUNTS_QUERY_KEY, query, params],
    () => requests.adminGatewayUserList(query, params).then(({ data }) => data),
    { keepPreviousData: true, ...options }
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
