import { Subjects } from "context/AbilityContext";
import { matchPath } from "react-router-dom";
import { Paths } from "routes/const";
import {
  PARKINGS_ADD_ROUTE,
  PARKINGS_EDIT_ROUTE,
  PARKINGS_VIEW_ROUTE,
  ZONES_ADD_ROUTE,
  ZONES_EDIT_ROUTE,
  ZONES_VIEW_ROUTE
} from "routes/route-declarations";

const addRoutes = [ZONES_ADD_ROUTE, PARKINGS_ADD_ROUTE];
const viewRoutes = [ZONES_VIEW_ROUTE, PARKINGS_VIEW_ROUTE];
const editRoutes = [ZONES_EDIT_ROUTE, PARKINGS_EDIT_ROUTE];

export const getFormTypes = (pathname: string) => {
  const isAddType = addRoutes.some((route) => !!matchPath(route, pathname));
  const isViewType = !isAddType && viewRoutes.some((route) => !!matchPath(route, pathname));
  const isEditType = editRoutes.some((route) => !!matchPath(route, pathname));

  return {
    isAddType,
    isViewType,
    isEditType
  };
};

export const getFormTypeInfo = (pathname: string) => {
  const { isAddType, isViewType, isEditType } = getFormTypes(pathname);
  const isFormOpen = isViewType || isAddType || isEditType;
  const formType = isEditType ? "edit" : isAddType ? "add" : isViewType ? "view" : null;

  return {
    isAddType,
    isViewType,
    isEditType,
    isFormOpen,
    formType
  };
};

export const objectManagementPaths: Record<
  Paths.Zones | Paths.Parking | Paths.Parkomats | Paths.Terminals | Paths.Scoreboards,
  string
> = {
  [Paths.Zones]: "zones",
  [Paths.Parking]: "parkings",
  [Paths.Parkomats]: "parkomats",
  [Paths.Terminals]: "terminals",
  [Paths.Scoreboards]: "scoreboards"
} as const;

export const getActiveRoute = (pathname: string): string => {
  for (const path in objectManagementPaths) {
    const objectType = objectManagementPaths[path as keyof typeof objectManagementPaths];
    if (pathname.includes(`${Paths.ObjectsManagment}${Paths.Objects}/${objectType}`))
      return objectType;
  }

  return "";
};

export const permissionSubjectsByObjectManagementPath: Record<
  typeof objectManagementPaths[keyof typeof objectManagementPaths],
  Extract<Subjects, "Zone" | "Parking" | "Parkomats" | "Terminals" | "Scoreboards">
> = {
  [objectManagementPaths[Paths.Zones]]: "Zone",
  [objectManagementPaths[Paths.Parking]]: "Parking",
  [objectManagementPaths[Paths.Parkomats]]: "Parkomats",
  [objectManagementPaths[Paths.Terminals]]: "Terminals",
  [objectManagementPaths[Paths.Scoreboards]]: "Scoreboards"
};

export const getPermissionSubject = (path: string) =>
  permissionSubjectsByObjectManagementPath[path];
