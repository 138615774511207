import { CardSidebarSxProps, GetSx } from "../types";

export const PAPER_SELECTOR = "& .MuiDrawer-paper";

export const DEFAULT_WIDTH = 280;

export const defaultSx: CardSidebarSxProps = {
  position: "fixed",
  width: DEFAULT_WIDTH,
  flexShrink: 0,
  height: "calc(100vh - 92px)",
  [PAPER_SELECTOR]: {
    position: "relative",
    width: DEFAULT_WIDTH,
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: 1
  }
};

export const getSx: GetSx = ({ width, sx } = { width: DEFAULT_WIDTH }) => {
  const resultSx = {
    ...defaultSx,
    width,
    [PAPER_SELECTOR]: {
      ...defaultSx[PAPER_SELECTOR],
      width
    }
  };

  if (sx) {
    return {
      ...resultSx,
      ...sx,
      [PAPER_SELECTOR]: {
        ...resultSx[PAPER_SELECTOR],
        ...sx[PAPER_SELECTOR]
      }
    };
  }

  return resultSx;
};
