import Box from "@mui/material/Box";
import Card from "components/Card";
import CardSidebar from "components/CardSidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header";
import ObjectsSidebarContent from "components/ObjectsSidebarContent";
import { useState } from "react";
import ObjectsMap from "./components/ObjectsMap";
import {
  ZONES_ADD_ROUTE,
  ZONES_BASE_ROUTE,
  PARKINGS_ADD_ROUTE,
  PARKINGS_BASE_ROUTE
} from "routes/route-declarations";
import { CurrentObject } from "components/ObjectsSidebarContent/types";
import ObjectsMapApiProvider from "./components/ObjectsMapApiProvider";
import { getActiveRoute, getFormTypeInfo, getPermissionSubject } from "./utils";
import { useAppAbility } from "context/AbilityContext";

export enum FormMode {
  Add = "add",
  Edit = "edit"
}

type ObjectRoutes = "zones" | "parkings";

const navigationRoutes = {
  zones: {
    add: ZONES_ADD_ROUTE,
    list: ZONES_BASE_ROUTE
  },
  parkings: {
    add: PARKINGS_ADD_ROUTE,
    list: PARKINGS_BASE_ROUTE
  }
};

const Objects = () => {
  const { can } = useAppAbility();
  const [currentObject, setCurrentObject] = useState<CurrentObject>({ type: null, data: null });
  const { pathname } = useLocation();
  const { id } = useParams();
  const activeRoute = getActiveRoute(pathname);

  const { isAddType, isViewType, isEditType, isFormOpen, formType } = getFormTypeInfo(pathname);

  const navigate = useNavigate();

  const handleAddObject = () => {
    navigate(navigationRoutes[activeRoute as ObjectRoutes].add);
  };

  const handleBackToList = () => {
    navigate(navigationRoutes[activeRoute as ObjectRoutes].list);
  };

  const canCreate = !activeRoute || can("create", getPermissionSubject(activeRoute));

  return (
    <ObjectsMapApiProvider currentObject={currentObject}>
      <Box display='flex' flexWrap='nowrap' height='100%'>
        <CardSidebar
          width={400}
          header={
            <Header
              canCreate={canCreate}
              isFormOpen={isFormOpen}
              currentObject={currentObject}
              isViewType={isViewType}
              formType={formType}
              activeRoute={activeRoute}
              onAddObject={handleAddObject}
              onBackToList={handleBackToList}
            />
          }
        >
          <ObjectsSidebarContent
            id={id}
            isViewType={isViewType}
            isAddType={isAddType}
            isEditType={isEditType}
            isFormOpen={isFormOpen}
            formType={formType}
            activeRoute={activeRoute}
            currentObject={currentObject}
            setCurrentObject={setCurrentObject}
          />
        </CardSidebar>
        <Card>
          <ObjectsMap />
        </Card>
      </Box>
    </ObjectsMapApiProvider>
  );
};

export default Objects;
