import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useLegalOperationsReport } from "hooks/queries/reports";
import { useTranslation } from "react-i18next";
import { ReportTableComponentProps, ReportTableContainer } from "../common";
import { useReportPagination } from "pages/Reports/hooks";
import { LegalOperation } from "api/generated";

interface OperationsTableBodyProps {
  rows?: LegalOperation[];
}

const OperationsTableBody = ({ rows }: OperationsTableBodyProps) => {
  const { startIndex, endIndex } = useReportPagination();

  return (
    <TableBody>
      {rows
        ?.slice(startIndex, endIndex)
        .map(
          (
            {
              binLegalCompany,
              confirmingDocument,
              cost,
              date,
              district,
              endTime,
              operationType,
              parking,
              phoneNumber,
              startTime,
              vrp,
              zone
            },
            index
          ) => (
            <TableRow key={index}>
              <TableCell>{startIndex + index + 1}</TableCell>
              <TableCell>{date}</TableCell>
              <TableCell>{binLegalCompany}</TableCell>
              <TableCell>{operationType}</TableCell>
              <TableCell>{startTime}</TableCell>
              <TableCell>{endTime}</TableCell>
              <TableCell>{cost}</TableCell>
              <TableCell>{phoneNumber}</TableCell>
              <TableCell>{vrp}</TableCell>
              <TableCell>{zone}</TableCell>
              <TableCell>{parking}</TableCell>
              <TableCell>{district}</TableCell>
              <TableCell>{confirmingDocument}</TableCell>
            </TableRow>
          )
        )}
    </TableBody>
  );
};

const OperationsTable = ({ filters }: ReportTableComponentProps) => {
  const { t } = useTranslation();

  const { data, isFetching } = useLegalOperationsReport(filters);
  const rows = data?.data.operations;

  return (
    <ReportTableContainer isFetching={isFetching} rowsLength={rows?.length} usePagination>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>{t("pages.Reports.table_columns.date")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.bin")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.operation_type")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.start_time")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.end_time")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.amount")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.phone")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.grz")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.zone")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.parking")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.district")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.confirmation_document")}</TableCell>
          </TableRow>
        </TableHead>
        <OperationsTableBody rows={rows} />
      </Table>
    </ReportTableContainer>
  );
};

export default OperationsTable;
