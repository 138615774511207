import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FEEDBACK_TOPICS_QUERY_KEY } from "./useFeedbackTopicsQuery";
import { ManageTopicsRequest } from "api/generated";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

const useSaveFeedbackTopicsMutation = () => {
  const queryClient = useQueryClient();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (body: ManageTopicsRequest) => requests.adminGatewayManageTopics(body),
    onSuccess: () => {
      openSuccessAlert(t("pages.Feedback.save_topics_success"));
      queryClient.invalidateQueries({ queryKey: [FEEDBACK_TOPICS_QUERY_KEY] });
    },
    onError: () => {
      showErrors({
        defaultMessage: t("pages.Feedback.save_topics_error")
      });
    }
  });
};

export default useSaveFeedbackTopicsMutation;
