import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { HOLIDAYS_KEY } from ".";
import { AdminGatewayGetHolidaysParams } from "api/generated";
import { useTranslation } from "react-i18next";

export const useGetHolidaysByYearQuery = (
  params: AdminGatewayGetHolidaysParams,
  ...rest: any[]
) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([HOLIDAYS_KEY, params], () => requests.adminGatewayGetHolidayByYear(params), {
    onError() {
      openErrorAlert(t("pages.HolidaysCalendar.list.could_not_load_non_working_days"));
    },
    refetchOnWindowFocus: false,
    ...rest
  });
};
