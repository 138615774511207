import { Parking } from "api/generated";
import { CurrentObject } from "components/ObjectsSidebarContent/types";
import { LocalizationContextValue } from "context/LocalizationContext";
import { TFunction } from "i18next";
import { MouseEventHandler } from "react";

export type Titles = {
  [key: string]: {
    [key: string]: string;
    add: string;
    edit: string;
  };
};

export const titles: Titles = {
  zones: {
    view: "pages.ObjectManagement.Objects.item.zones.zone",
    add: "pages.ObjectManagement.Objects.item.zones.creating_zone",
    edit: "pages.ObjectManagement.Objects.item.zones.editing_zone"
  },
  parkings: {
    view: "pages.ObjectManagement.Objects.item.parkings.parking",
    add: "pages.ObjectManagement.Objects.item.parkings.creating_parking",
    edit: "pages.ObjectManagement.Objects.item.parkings.editing_parking"
  },
  parkomats: {
    view: "Паркомат",
    add: "Создание паркомата",
    edit: "Редактирование паркомата"
  },
  terminals: {
    view: "Пункт приема оплаты",
    add: "Создание пункта приема оплаты",
    edit: "Редактирование пункта приема оплаты"
  },
  scoreboards: {
    view: "Информационное табло",
    add: "Создание информационного табло",
    edit: "Редактирование информационного табло"
  }
};

export type Props = {
  canCreate: boolean;
  currentObject: CurrentObject;
  activeRoute: string;
  onAddObject: MouseEventHandler;
  onBackToList: MouseEventHandler;
  isFormOpen: boolean;
  isViewType: boolean;
  formType: string | null;
};

export const getTitle = (
  currentObject: CurrentObject,
  {
    isViewType,
    activeRoute,
    isFormOpen,
    formType,
    t,
    getValueByLang,
    number
  }: {
    activeRoute: string;
    isFormOpen: boolean;
    isViewType: boolean;
    formType: string | null;
    t: TFunction<"translation", undefined>;
    getValueByLang: LocalizationContextValue["getValueByLang"];
    number?: string;
  }
) => {
  if (isViewType) {
    if (activeRoute === "parkings") {
      const data = currentObject?.data as Parking;
      return (
        getValueByLang(data?.name) || t("pages.ObjectManagement.Objects.item.parkings.parking")
      );
    }

    return `${t(titles[activeRoute].view)}${number !== undefined ? ` №${number}` : ""}`;
  }

  return isFormOpen && formType
    ? t(titles[activeRoute][formType])
    : t("pages.ObjectManagement.Objects.list.objects");
};
