import { DateTime } from "luxon";

export enum ReportTables {
  SumDistricts = "sum_districts",
  SumZones = "sum_zones",
  Operations = "operations",
  SumPS = "sum_ps"
}

export enum ReportType {
  ParkinSessionsApp = "parking_sessions_app",
  ParkingSessionsAll = "parking_sessions_all",
  Transactions = "transactions",
  Legal = "legal"
}

export interface ReportFiltersForm {
  reportType: ReportType;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

export interface ReportApiFilters {
  startDate?: string;
  endDate?: string;
}

export interface ReportTablePaginationForm {
  offset?: number;
  limit?: number;
}
