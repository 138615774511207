import { Box, Typography } from "@mui/material";
import { theme } from "config/theme";
import { useLocalizationContext } from "context/LocalizationContext";
import { useTranslation } from "react-i18next";

export type DescriptionProps = {
  description?: Record<string, string>;
  isInList?: boolean;
};

const Description = ({ description, isInList }: DescriptionProps) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();

  return description ? (
    <Box sx={{ marginTop: isInList ? 2 : "initial" }}>
      <Typography color={theme.palette.text.secondary}>
        {t("pages.Tariffs.description")}:
      </Typography>
      <Typography>{getValueByLang(description)}</Typography>
    </Box>
  ) : null;
};

export default Description;
