import { SxProps } from "@mui/material";
import TablePaginationMui from "@mui/material/TablePagination";
import TablePaginationActions from "components/TablePaginationActions";
import { ChangeEvent } from "react";

export type TablePaginationProps = {
  count: number;
  offset: number;
  limit: number;
  setOffset: (offset: number) => void;
  setLimit: (limit: number) => void;
  disabled?: boolean;
  sx?: SxProps;
};

const TablePagination = ({
  count,
  offset,
  limit,
  setOffset,
  setLimit,
  sx,
  disabled,
  ...props
}: TablePaginationProps) => {
  const handlePageInputChange = (page: number) => setOffset(page - 1);
  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(+e?.target.value);
    setOffset(0);
  };

  return (
    <TablePaginationMui
      sx={{
        "& .MuiToolbar-root": {
          flexWrap: "wrap",
          justifyContent: "center",
          paddingLeft: 0,
          "& .MuiTablePagination-spacer": { display: "none" },
          "& > .MuiInputBase-root": { marginRight: "5px" },
          "& .MuiTablePagination-actions": { marginLeft: 0 },
          "& .TablePaginationActions": {
            marginTop: -1,
            flexWrap: "wrap",
            justifyContent: "center",
            "& .TablePaginationActions__TextField-container": {
              display: "flex",
              justifyContent: "center",
              width: "100%"
            }
          }
        },
        ...sx
      }}
      rowsPerPageOptions={[5, 10, 20]}
      component='div'
      count={count}
      rowsPerPage={limit}
      page={offset}
      showFirstButton
      showLastButton
      labelRowsPerPage={null}
      onPageChange={(_, page) => setOffset(page)}
      onRowsPerPageChange={handleRowsPerPageChange}
      {...props}
      ActionsComponent={(props) => (
        <TablePaginationActions
          onInputChange={handlePageInputChange}
          offset={offset}
          limit={limit}
          {...props}
          // В версии МУИ 5.10.16 нет пропа disabled для кнопок пагинации
          // "к первой" и "к последлей" стренице, поэтому приходится использовать костыль
          count={disabled ? 0 : props.count}
        />
      )}
    />
  );
};

export default TablePagination;
