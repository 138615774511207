import { FC, useState } from "react";
import { Controller, Control } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, TimePickerProps as TimePickerPropsMui } from "@mui/x-date-pickers/TimePicker";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { DateTime, Settings } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export type DatePickerProps = {
  control: Control<any>;
  name: string;
  label?: string;
  defaultValue?: DateTime | null;
  inputProps?: TextFieldProps;
  rules?: object;
  helperText?: string;
  error?: { message: string };
  placeholder?: string;
  defaultTime?: any;
  onChange?: (value: DateTime | null, valueStr?: string) => [DateTime | null, string | undefined];
} & Partial<
  Omit<TimePickerPropsMui<DateTime | null | undefined, DateTime | null>, "onFocus" | "onChange">
>;

Settings.defaultZone = "UTC";

const TimePickerComponent: FC<DatePickerProps> = ({
  control,
  value,
  name,
  label,
  rules,
  helperText,
  defaultValue,
  error,
  inputRef,
  onChange,
  placeholder,
  ...rest
}) => {
  const [getValue, setValue] = useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='ru'>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, ...restField }, fieldState }) => {
          const _error = error || fieldState?.error;
          return (
            <>
              <TimePicker
                {...rest}
                {...restField}
                label={label}
                value={getValue || value || defaultValue}
                views={["hours", "minutes"]}
                onChange={(value, valueStr) => {
                  let newValue: any = value;
                  let newValueStr = valueStr;

                  if (onChange) {
                    [newValue, newValueStr] = onChange(value, valueStr);
                  }
                  setValue(newValue);
                  restField.onChange(newValue, newValueStr);
                }}
                inputRef={($ref) => {
                  if (inputRef) {
                    if (inputRef instanceof Function) inputRef($ref);
                    else
                      console.warn(` inputRef prop in Input with name "${name}" is not a function`);
                  }
                  return ref($ref);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder }}
                    error={!!_error}
                  />
                )}
              />
              <FormHelperText error={!!_error} sx={{ margin: 0, marginTop: "3px" }}>
                {_error ? _error?.message || "Invalid Input" : helperText}
              </FormHelperText>
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
