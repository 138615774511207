import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetHolidaysParams, RequestParams } from "api/generated";

const inititalHolidays = { holidays: [] };

export const GET_HOLIDAYS_QUERY_KEY = "get_holidays";

export const useGetHolidaysQuery = (
  query: AdminGatewayGetHolidaysParams,
  params: RequestParams
) => {
  const {
    data = inititalHolidays,
    isLoading,
    ...rest
  } = useQuery([GET_HOLIDAYS_QUERY_KEY, query, params], () =>
    requests.adminGatewayGetHolidays(query, params).then(({ data }) => data)
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
