import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams } from "api/generated";
import { ABONEMENTS_QUERY_KEY } from ".";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const useGetAbonementQuery = (
  uuid: string,
  params?: RequestParams,
  options?: { onError: ((err: unknown) => void) | undefined }
) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [ABONEMENTS_QUERY_KEY, uuid, params],
    () => requests.adminGatewayGetAbonement({ uuid }).then(({ data }) => data),
    {
      onError(err) {
        openErrorAlert(t("pages.Abonements.Active.item.could_not_load_abonement"));
        if (options?.onError) options.onError(err);
      }
    }
  );
};
