import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { PERMISSION_GRANTS_QUERY_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useDeleteGrantMutation = (uuid: string, params?: RequestParams) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation(() => requests.adminGatewayDeletePermissionGrant(uuid || "", params), {
    mutationKey: [PERMISSION_GRANTS_QUERY_KEY, DELETE_MUTATE_KEY, uuid],
    onSuccess() {
      openSuccessAlert(t("pages.Permissions.Grant.item.permission_grant_deleted"));
    },
    onError() {
      openErrorAlert(t("pages.Permissions.Grant.item.could_not_delete_Ppermission_grant"));
    }
  });
};
