import { Box, Typography } from "@mui/material";
import { theme } from "config/theme";
import { useTranslation } from "react-i18next";
import { formatUtcDate } from "utils/date";

export type CreatedAtProps = {
  mt?: number;
  createdAt?: string;
};

const CreatedAt = ({ createdAt, mt = 2 }: CreatedAtProps) => {
  const { t } = useTranslation();

  const createdAtNum = +(createdAt || 0);
  const formattedDate =
    createdAt &&
    formatUtcDate(
      new Date(Number.isNaN(createdAtNum) ? createdAt : createdAtNum),
      "dd.MM.yyyy в HH:mm"
    ).replace("в", t("pages.Tariffs.at"));

  return (
    <Box marginTop={mt}>
      <Typography color={theme.palette.text.secondary}>{t("pages.Tariffs.created")}:</Typography>
      <Typography variant='body1' color={theme.palette.text.primary}>
        {formattedDate}
      </Typography>
    </Box>
  );
};
export default CreatedAt;
