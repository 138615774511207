import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetUserEventsParams, RequestParams, UserEventsResponse } from "api/generated";

const initialEvents: UserEventsResponse = { total: "0", userEvents: [] };

export const GET_USER_EVENTS_QUERY_KEY = "get_user_events";

export const useGetUserEventsQuery = (
  eventParams: AdminGatewayGetUserEventsParams,
  params?: RequestParams
) => {
  const {
    data = initialEvents,
    isLoading,
    ...rest
  } = useQuery(
    [GET_USER_EVENTS_QUERY_KEY, eventParams, params],
    () => requests.adminGatewayGetUserEvents(eventParams, params).then(({ data }) => data),
    {
      keepPreviousData: true
    }
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
