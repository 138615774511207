import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { notSelectedIndex } from "..";
import { useTranslation } from "react-i18next";

export type DeleteModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  shouldDelete: boolean;
  updateLangList: VoidFunction;
  setLangIndex: (index: number) => void;
  langName: string;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

const DeleteModal = ({
  isOpen,
  onClose,
  shouldDelete,
  updateLangList,
  setLangIndex,
  langName,
  onConfirm,
  onCancel
}: DeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          if (shouldDelete) updateLangList();
          setLangIndex(notSelectedIndex);
        }
      }}
    >
      <DialogTitle>
        {t("pages.SystemSettings.AvailableLanguages.delete_lang_warning_title").replace(
          "{langName}",
          langName
        )}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t("pages.SystemSettings.AvailableLanguages.delete_lang_warning_message")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='error' onClick={onConfirm}>
          {t("common.delete")}
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          {t("common.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
