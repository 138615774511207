import { Box, FormControl, FormLabel, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Input from "ui-kit/form/Input";
import { getInputRules } from "../utils";
import { SystemSettingsConfig } from "api/generated";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type LangInputsProps = {
  control: Control<SystemSettingsConfig>;
  index: number;
  total: number;
  isLoading: boolean;
  onDelete: (index: number) => void;
  onIndexChange: (index: number, dir: number) => VoidFunction;
};

const LangInputs = ({
  control,
  isLoading,
  index,
  total,
  onDelete,
  onIndexChange
}: LangInputsProps) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <Box display={"flex"} alignItems={"flex-start"} justifyContent={"flex-start"} mb={2}>
      <FormControl required>
        <FormLabel>{t("pages.SystemSettings.AvailableLanguages.lang_code")}:</FormLabel>
        <Input
          sx={{ width: "100px", minWidth: "100%" }}
          rules={getInputRules(t)}
          control={control}
          disabled={isLoading}
          name={`availableLanguages.${index}.shortCode`}
        />
      </FormControl>

      <FormControl required sx={{ marginLeft: 2 }}>
        <FormLabel>{t("pages.SystemSettings.AvailableLanguages.lang_name")}:</FormLabel>
        <Input
          sx={{ minWidth: "350px" }}
          rules={getInputRules(t)}
          control={control}
          disabled={isLoading}
          name={`availableLanguages.${index}.localizedName`}
        />
      </FormControl>
      <Box display={"flex"} height={"56px"} alignItems={"center"} mt={"23px"} ml={1}>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} height={"56px"} alignItems={"center"} mt={"23px"} ml={1}>
        <IconButton disabled={index === 0} onClick={onIndexChange(index, -1)}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton disabled={index === total - 1} onClick={onIndexChange(index, 1)}>
          <ArrowDownwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LangInputs;
