import { Link, useParams } from "react-router-dom";
import { styled, darken } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import Box from "@mui/material/Box";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import AccountAvatar from "components/Avatar";
import { theme } from "config/theme";
import { Paths } from "routes/const";
import { User } from "api/generated";

interface StyledListItemProps extends ListItemProps {
  active?: boolean;
}

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  width: "100%"
}));

const StyledItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active"
})<StyledListItemProps>(({ active }) => ({
  ...(active && {
    backgroundColor: darken("#fff", 2 / 50)
  })
}));

const SidebarAccounts = ({ firstName, lastName, middleName, phone, uuid }: User) => {
  const { id: userId } = useParams();
  const styledPhone =
    phone && phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");

  return (
    <StyledItem active={userId === uuid?.value} data-testid='sidebarAccountItem'>
      <StyledLink to={`${Paths.Accounts}/${uuid?.value}`}>
        <Box color={theme.palette.text.secondary}>
          <Box display='flex' alignItems='center'>
            <ListItemAvatar>
              <AccountAvatar name={`${firstName} ${lastName}`} />
            </ListItemAvatar>
            <Typography
              variant='body2'
              color={theme.palette.text.primary}
            >{`${firstName} ${middleName} ${lastName}`}</Typography>
          </Box>
          {phone && (
            <Box mt={3}>
              <Typography variant='body1'>
                <PhoneIcon sx={{ marginRight: "6px", verticalAlign: "middle" }} /> {styledPhone}
              </Typography>
            </Box>
          )}
        </Box>
      </StyledLink>
    </StyledItem>
  );
};

export default SidebarAccounts;
