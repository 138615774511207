import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useGetTariffsQuery } from "hooks/queries/tariffs";
import ListItem from "./components/ListItem";
import { useGetSystemSettingsConfigQuery } from "hooks/queries/system-settings";
import { useTranslation } from "react-i18next";

const Tariffs = () => {
  const { t } = useTranslation();
  const {
    data: { currency }
  } = useGetSystemSettingsConfigQuery();
  const { isLoading, data } = useGetTariffsQuery();

  return (
    <Box display='flex' flexWrap='nowrap' height='100%' margin={1}>
      <List sx={{ width: "100%" }} disablePadding>
        {data?.tariffs?.length && data.tariffs.length > 0 && currency ? (
          data.tariffs.map((tariff) => (
            <ListItem key={tariff.uuid?.value} {...tariff} currency={currency} />
          ))
        ) : isLoading ? (
          <Typography padding={1} variant='h6'>
            {t("pages.Tariffs.list.loading_tariffs")}
          </Typography>
        ) : (
          <Typography padding={1} variant='h6'>
            {t("pages.Tariffs.list.no_tariffs")}
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default Tariffs;
