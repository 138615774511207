import { Parking, Zone } from "api/generated";
import { ObjectType, OnGeometryChange } from "../types";

interface Editable {
  startEditing: (objectType?: ObjectType) => this;
  stopEditing: (objectType?: ObjectType) => this;
  isEditing: () => boolean;
}

interface Enableable {
  setEnabledStyle: (objectType?: ObjectType, shouldShowOverOtherObjects?: boolean) => this;
  setDisabledStyle: (objectType?: ObjectType) => this;
}

interface Creatable {
  startCreating: (objectType?: ObjectType) => this;
  finishCreating: (objectType?: ObjectType) => this;
}

export type Coordinates = number[][][] & number[] & number[][];

// Здесь any, потому что у самой либы реф в методе instanceRef это any
export type Ref = any;
export type ObjectToDraw = Zone | Parking;

export type DefaultColorStyle = {
  fill: string;
  stroke: string;
};

export const menuIdTranslates: { [key: string]: string } = {
  removeVertex: "pages.ObjectManagement.Objects.item.geometry_menu_item_remove_vertex",
  startDrawing: "pages.ObjectManagement.Objects.item.geometry_menu_item_start_drawing",
  stopDrawing: "pages.ObjectManagement.Objects.item.geometry_menu_item_stop_drawing"
};

export class MapObjectManager implements Editable, Enableable, Creatable {
  constructor(
    protected object: ObjectToDraw | null = null,
    protected ref: Ref | null = null,
    protected objectType: ObjectType,
    protected onChange: any
  ) {
    if (ref && onChange) {
      this.addGeoObjectEvent("geometrychange", onChange);
    }
  }

  startEditing() {
    console.warn("startEditing is not implemented");
    return this;
  }

  stopEditing() {
    console.warn("stopEditing is not implemented");
    return this;
  }

  isEditing() {
    return this.ref?.editor.state.get("editing");
  }

  setEnabledStyle() {
    console.warn("setEnabledStyle is not implemented");
    return this;
  }

  setHoveredStyle() {
    console.warn("setHoveredStyle is not implemented");
    return this;
  }

  setDisabledStyle() {
    console.warn("setDisabledStyle is not implemented");
    return this;
  }

  getCoordinatesToPan() {
    console.warn("getCoordinatesToPan is not implemented");
    return [0, 0];
  }

  setRef(ref: Ref) {
    this.ref = ref;
    return this;
  }

  getRef() {
    return this.ref;
  }

  setObject(object: ObjectToDraw) {
    this.object = object;
    return this;
  }

  getObject() {
    return this.object;
  }

  getGeoObject() {
    return this.ref?.editor.options.get("geoObject");
  }

  getState() {
    return this.ref?.editor.state.getAll();
  }

  getStateManager() {
    return this.ref?.editor.state;
  }

  setOptionsInEditor(options: { [key: string]: any }) {
    const editor = this.ref?.editor;

    for (const option in options) {
      editor?.options.set(options, options[option]);
    }

    return this;
  }

  setOptionsInGeoObject(options: { [key: string]: any }) {
    if (!this.ref) return this;

    const geoObject = this.getGeoObject();

    for (const option in options) {
      geoObject?.options.set(options, options[option]);
    }
    return this;
  }

  getBounds() {
    return this.ref?.editor?.geometry?.getBounds();
  }

  setCoordinates<Coords = Coordinates>(coordinates: Coords) {
    this.ref?.editor?.geometry?.setCoordinates(coordinates);
    return this;
  }

  getCoordinates(): Coordinates {
    return this.ref?.editor?.geometry?.getCoordinates();
  }

  setDraggable(isDraggable = true) {
    this.ref?.options?.set("draggable", isDraggable);
  }

  addEditorEvent(name: string, handler: (...args: []) => any) {
    this.ref?.editor.events.add(name, handler);
  }

  removeEditorEvent(name: string, handler: (...args: []) => any) {
    this.ref?.editor.events.remove(name, handler);
  }

  addGeoObjectEvent(name: string, handler: OnGeometryChange) {
    this.getGeoObject()?.events.add(name, handler);
  }

  removeGeoObjectEvent(name: string, handler: OnGeometryChange) {
    this.getGeoObject()?.events.remove(name, handler);
  }

  clearMapObject({
    onBeforeClear,
    onAfterClear
  }: {
    onBeforeClear?: (mapObject: MapObjectManager) => void;
    onAfterClear?: (mapObject: MapObjectManager) => void;
  }) {
    if (onBeforeClear instanceof Function) onBeforeClear(this);

    this.object = null;
    this.ref = null;

    if (onAfterClear instanceof Function) onAfterClear(this);

    return this;
  }

  isDrawing() {
    return this.ref?.editor.state.get("drawing");
  }

  startCreating() {
    console.warn("startCreating is not implemented");
    return this;
  }

  finishCreating() {
    console.warn("startCreating is not implemented");
    return this;
  }
}
