import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo } from "react";
import useLocalStorage from "hooks/useLocalStorage";

type LayoutContextValue = {
  isNavSidebarOpen: boolean;
  setIsNavSidebarOpen: Dispatch<SetStateAction<boolean>>;
  drawerWidth: number;
};

const LayoutContext = createContext<LayoutContextValue>({
  isNavSidebarOpen: false,
  setIsNavSidebarOpen: () => undefined,
  drawerWidth: 0
});

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [isNavSidebarOpen, setIsNavSidebarOpen] = useLocalStorage<boolean>(
    "isNavSidebarOpen",
    false
  );
  const value = useMemo(
    () => ({ isNavSidebarOpen, setIsNavSidebarOpen, drawerWidth: 280 }),
    [isNavSidebarOpen]
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutContext");
  }

  return context;
};
