import { FormControl, FormLabel, MenuItem } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { Control, Path, UseFormGetFieldState, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "ui-kit/form/Select";
import { useLocalizationContext } from "context/LocalizationContext";
import { FormInputs } from "./types";

export interface TopicSelectProps {
  label: ReactNode;
  name: Path<FormInputs>;
  control: Control<FormInputs>;
  watch: UseFormWatch<FormInputs>;
  getFieldState: UseFormGetFieldState<FormInputs>;
  "data-testid"?: string;
}

const TopicSelect: FC<TopicSelectProps> = ({
  control,
  watch,
  name,
  label,
  "data-testid": dataTestId
}) => {
  const [selectPaperMaxWidth, setSelectPaperMaxWidth] = useState<number>(0);

  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();

  const topics = watch("topics");

  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <FormLabel sx={{ "&.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" } }}>{label}</FormLabel>
      <Select
        control={control}
        name={name}
        rules={{ required: { value: true, message: t("common.input_rules.required") } }}
        data-testid={dataTestId}
        onOpen={(e) => setSelectPaperMaxWidth(e.currentTarget.clientWidth)}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: "376px",
              maxWidth: selectPaperMaxWidth,
              ".MuiButtonBase-root.MuiMenuItem-root": {
                whiteSpace: "unset",
                wordBreak: "break-all"
              }
            }
          }
        }}
        sx={{
          ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
            whiteSpace: "unset",
            wordWrap: "break-word"
          }
        }}
      >
        {topics.map(({ uuid, name }) => (
          <MenuItem key={uuid} value={uuid}>
            {getValueByLang({
              ...name,
              kk: name.kz
            })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TopicSelect;
