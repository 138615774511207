import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import CardSidebar from "components/CardSidebar";
import Card from "components/Card";
import SidebarAccounts from "./components/SidebarAccounts";
import AccountsCard from "./components/AccountsCard";
import { Paths } from "routes/const";
import { useGetAccountsQuery, useGetUserQuery } from "hooks/queries/accounts";
import { useTranslation } from "react-i18next";
import TablePagination from "../components/TablePagination";
import { CircularProgress } from "@mui/material";

const AccountsItem = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState({ offset: 0, limit: 20 });
  const {
    isLoading,
    isFetching,
    data: accounts
  } = useGetAccountsQuery(
    {
      ...query,
      offset: (query.offset || 0) * (query.limit || 0)
    },
    {}
  );
  const { id } = useParams() as { id: string };
  const {
    data: { user },
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    refetch
  } = useGetUserQuery(id, {});

  const setOffset = (offset: number) => setQuery((prevQuery) => ({ ...prevQuery, offset }));
  const setLimit = (limit: number) => setQuery((prevQuery) => ({ ...prevQuery, limit }));

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div>
      <Box display='flex' flexWrap='nowrap' height='100%'>
        <CardSidebar
          header={
            <Box
              display='flex'
              flexWrap='nowrap'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography component='h2' variant='h5' fontWeight='500' padding={2}>
                {t("pages.Accounts.list.users")}
              </Typography>
              {(isLoading || isFetching) && <CircularProgress size={26} sx={{ marginRight: 2 }} />}
            </Box>
          }
          fitContent
        >
          <Box display='flex' flexDirection='column' height='100%'>
            <Box overflow='scroll' flexGrow={1} marginBottom={2}>
              <List>
                {accounts?.users?.length && accounts.users.length > 0 ? (
                  accounts.users.map((user, index, users) => (
                    <React.Fragment key={user?.uuid?.value}>
                      <SidebarAccounts {...user} />
                      <Divider
                        sx={{
                          marginTop: 2,
                          marginBottom: index === users.length - 1 ? 0 : 2
                        }}
                      />
                    </React.Fragment>
                  ))
                ) : isLoading ? (
                  <Typography padding={1} variant='h6'>
                    {t("pages.Accounts.list.loading_users")}
                  </Typography>
                ) : (
                  <Typography padding={1} variant='h6'>
                    {t("pages.Accounts.list.no_users_found")}
                  </Typography>
                )}
              </List>
            </Box>
            <TablePagination
              count={accounts.total || 0}
              limit={query.limit}
              offset={query.offset}
              setLimit={setLimit}
              setOffset={setOffset}
              sx={{
                "&.MuiTablePagination-root": {
                  flexShrink: 0
                },
                "& .MuiInputBase-root": {
                  marginLeft: 0
                },
                "& .TablePaginationActions": {
                  marginLeft: 0
                }
              }}
            />
            <Button variant='contained' href={`${Paths.Accounts}${Paths.AccountsList}`}>
              {t("pages.Accounts.list.user_list")}
            </Button>
          </Box>
        </CardSidebar>
        <Card>
          {isLoadingUser || isFetchingUser ? (
            <Typography padding={1} variant='h6'>
              {t("pages.Accounts.list.loading_users")}
            </Typography>
          ) : (
            <AccountsCard {...user} />
          )}
        </Card>
      </Box>
    </div>
  );
};

export default AccountsItem;
