import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetSumB2BOperationsByDistrictsParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const PARKINGS_ALL_SUM_DISTRICTS_REPORT_QUERY_KEY = "parkings_all_sum_districts_report";

const useParkingsAllSumDistrictsReport = (
  params: AdminGatewayGetSumB2BOperationsByDistrictsParams
) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [PARKINGS_ALL_SUM_DISTRICTS_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetSumB2BOperationsByDistricts(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useParkingsAllSumDistrictsReport;
