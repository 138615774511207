import { useMemo, useRef } from "react";

const updateState = <T extends { hovered: boolean; [key: string]: boolean }>(
  state: T,
  { type, payload }: { type: keyof T; payload?: any }
) => {
  if (type === "unhovered") {
    return Object.keys(state).reduce(
      (acc, name: keyof T) => {
        acc[name] = false as T[keyof T];
        return acc;
      },
      { hovered: false } as T
    );
  }

  const hovered =
    payload ||
    Object.entries(state)
      .filter(([key]) => key !== "hovered" && key !== type)
      .some(([, value]) => value);

  return {
    ...state,
    hovered,
    [type]: payload
  };
};

export default function useMapObjectHover<T extends { hovered: boolean; [key: string]: boolean }>(
  initialState: T = { hovered: false } as T
) {
  const stateRef = useRef(initialState);

  if (stateRef.current.hovered && !Object.values(stateRef.current).some(Boolean)) {
    stateRef.current = updateState(stateRef.current, { type: "unhovered" });
  }

  const objects: [keyof T, boolean][] = Object.entries(stateRef.current);

  return useMemo(
    () => ({
      getState: () => stateRef.current,
      getHandlers: () =>
        objects.reduce(
          (acc, [name]) => {
            if (name === "hovered") return acc;

            acc[`${String(name)}MouseEnter`] = (cb?: (state: T) => void) => {
              stateRef.current = updateState(stateRef.current, { type: name, payload: true });
              if (cb) cb(stateRef.current);
            };

            acc[`${String(name)}MouseLeave`] = (cb?: (state: T) => void) => {
              // Нужно вызывать через таймаут, т.к. leave вызывается раньше чем enter,
              // и получается, когда без таймаута перемещаем курсов внутри полигона на его
              // плейсмарку сначала снимаете состояние hovered с полигона, а поскольку на
              // плейсмарке еще не висит это состояние, то и весь объект считается unhovered.
              // С таймаутом сначала срабатывает enter, плеймарке ставится состояние hovered = true,
              // затем снимается hovered с полигона, и поскльку плейсмарка hovered, то и весь объект
              // остается hovered.
              setTimeout(() => {
                stateRef.current = updateState(stateRef.current, { type: name, payload: false });
                if (cb) cb(stateRef.current);
              });
            };

            acc[`${String(name)}MouseMove`] = (cb?: (state: T) => void) => {
              if (!stateRef.current[name]) {
                stateRef.current = updateState(stateRef.current, { type: name, payload: true });
              }

              if (cb) cb(stateRef.current);
            };

            return acc;
          },
          {
            unhoverAll: () => {
              stateRef.current = updateState(stateRef.current, { type: "unhovered" });
            }
          } as { [K in string]: (cb?: (state: T) => void) => void }
        )
    }),
    [stateRef.current]
  );
}
