import {
  Box,
  BoxProps,
  FormControl,
  FormLabel,
  MenuItem,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import { useConfigContext } from "context/ConfigContext";
import { useEffect, useState } from "react";
import {
  Control,
  FieldValues,
  FormState,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from "react-hook-form";
import IMaskedInput from "ui-kit/form/MaskInput";
import Select, { SelectProps } from "ui-kit/form/Select";
import SwitchMui from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

export type VehicleInputsProps<T extends FieldValues = any> = {
  control: Control<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  setValue: UseFormSetValue<T>;
  formState: FormState<T>;
  isLoading?: boolean;
  onVehicleChange?: SelectProps["onChange"];
  rules?: object;
  vehicleBoxProps?: BoxProps;
  vrpBoxProps?: BoxProps;
};

const VehicleInputs = ({
  isLoading,
  watch,
  setValue,
  trigger,
  rules,
  formState,
  control,
  onVehicleChange
}: VehicleInputsProps) => {
  const [foreign, setForeign] = useState<boolean>(false);
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { config, isInitialLoading: isConfigLoading } = useConfigContext();
  const { vehicles = [] } = config || {};

  const vehicleType = watch("vehicle");
  const vrp = watch("vrp");
  const vehicle = vehicles.find((vehicle) => vehicle.type === vehicleType);

  const { vrpMask, vrpRegexp = "", foreignVrpRegexp = "", placeholder } = vehicle || {};

  const handleVehicleChange: SelectProps["onChange"] = (e, child) => {
    const {
      target: { value }
    } = e;
    setValue("vehicle", value);
    onVehicleChange?.(e, child);
  };

  useEffect(() => {
    if (formState.isSubmitted) trigger(["vehicle", "vrp"]);
  }, [vehicleType, vrp, formState.isSubmitted]);

  return (
    <>
      <Box display='flex' flex='nowrap' marginTop={2}>
        <FormGroup>
          <FormControlLabel
            sx={{ marginLeft: 0 }}
            label={t("common.foreign_or_special")}
            labelPlacement='start'
            control={
              <SwitchMui
                checked={foreign}
                onChange={(e: any) => {
                  setForeign(e.target.checked);
                }}
                name='foreign_or_special'
              />
            }
          />
        </FormGroup>
      </Box>
      <Box display='flex' flex='nowrap' marginTop={2}>
        <Box width='100%' marginRight={2}>
          <FormLabel required>{t("common.vehicle_type")}:</FormLabel>
          <FormControl fullWidth>
            <Select
              fullWidth
              disabled={isConfigLoading || isLoading}
              control={control}
              name='vehicle'
              onChange={handleVehicleChange}
              rules={{
                required: { value: true, message: t("common.input_rules.required") }
              }}
            >
              {vehicles?.map(({ type, title }) => (
                <MenuItem sx={{ whiteSpace: "normal" }} key={type} value={type}>
                  {getValueByLang(title, true)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box width='100%'>
          <FormControl fullWidth>
            <FormLabel required>{t("common.vehicle")}:</FormLabel>
            <IMaskedInput
              control={control}
              name='vrp'
              convert='upper'
              mask={!foreign ? vrpRegexp : foreignVrpRegexp}
              lazy={false}
              placeholder={!foreign ? placeholder || vrpMask : ""}
              disabled={!vehicleType || isLoading}
              helperText={!vehicleType ? t("common.choose_vehicle_type") : ""}
              rules={{
                required: { value: true, message: t("common.input_rules.required") },
                validate(value: string) {
                  if (value.trim().length < 3) {
                    return t("common.input_rules.minimum_3_symbols_without_spaces");
                  }
                  if (
                    !new RegExp(!foreign ? vrpRegexp : foreignVrpRegexp).test(
                      value.replace(/\s+/g, "")
                    )
                  ) {
                    return t("common.input_rules.invalid_format");
                  }
                },
                ...rules
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default VehicleInputs;
