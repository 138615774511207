import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AbonementsResponse, RequestParams } from "api/generated";
import { ABONEMENTS_QUERY_KEY } from ".";

const initialAbonements: AbonementsResponse = { abonements: [] };

export const GET_ABONEMETS_QUERY_KEY = "get_abonements";

export const useGetAbonementsQuery = (
  params?: RequestParams,
  options?: Parameters<typeof useQuery>[2]
) => {
  const { data = initialAbonements, ...rest } = useQuery(
    [ABONEMENTS_QUERY_KEY, GET_ABONEMETS_QUERY_KEY, params],
    () => requests.adminGatewayAbonements(params).then(({ data }) => data),
    options
  );

  return {
    data,
    ...rest
  };
};
