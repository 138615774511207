import { Placemark, Polygon, Polyline } from "@pbe/react-yandex-maps";
import { GisType, ParkingLocation } from "api/generated";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import {
  GeometryType,
  MapActionMode,
  MapGeometryObjectRef,
  ParkingWithCoordinates
} from "pages/ObjectManagment/Objects/context/types";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PARKINGS_BASE_ROUTE } from "routes/route-declarations";
import ymaps from "yandex-maps";
import { LineManager } from "pages/ObjectManagment/Objects/context/utils/LineManager";
import { PlacemarkManager } from "pages/ObjectManagment/Objects/context/utils/PlacemarkManager";
import { DefaultColorStyle } from "pages/ObjectManagment/Objects/context/utils/MapObjectManagers";
import { getGeometryStyle, getGeometryType } from "./utils";
import { parkingSvgIcon } from "assets/icons/map-objects";

export type Props = {
  mapActionMode: MapContextValue["mapActionMode"];
  getMapActionMode: MapContextValue["getMapActionMode"];
  selectedObject: MapContextValue["selectedObject"];
  geometryToDraw: MapContextValue["geometryToDraw"];
  getCurrentActiveObject: MapContextValue["getCurrentActiveObject"];
  object: ParkingWithCoordinates;
  onLoad: ((api: typeof ymaps) => void) | undefined;
  instanceRef: (object: ParkingWithCoordinates) => (ref: MapGeometryObjectRef) => void; // TODO
};

const { GIS_TYPE_LINE, GIS_TYPE_POLYGON } = GisType;

const Parking = ({
  mapActionMode,
  selectedObject,
  object,
  instanceRef,
  getMapActionMode,
  ...restProps
}: Props) => {
  const $placemark = useRef<any>(null);
  const navigate = useNavigate();
  const geometry = getGeometryType(
    selectedObject,
    mapActionMode,
    object,
    object.location?.type || GisType.GIS_TYPE_UNSPECIFIED
  );

  const objectForRef = (
    selectedObject?.uuid && selectedObject?.uuid?.value === object.uuid?.value
      ? selectedObject
      : object
  ) as ParkingWithCoordinates;

  const placemarkInstanceRef = (ref: MapGeometryObjectRef) => {
    $placemark.current = ref;
    if (geometry === GeometryType.Point) {
      instanceRef(objectForRef as ParkingWithCoordinates)(ref);
    }
  };

  const [colorStyle, placemarkColorStyle] =
    mapActionMode === MapActionMode.ViewList || selectedObject?.uuid?.value === object.uuid?.value
      ? [
          geometry ? getGeometryStyle(geometry)?.parking?.active : null,
          PlacemarkManager.defaultColors.parking.active
        ]
      : [
          geometry ? getGeometryStyle(geometry)?.parking?.inactive : null,
          PlacemarkManager.defaultColors.parking.inactive
        ];

  return (
    <>
      {objectForRef?.location?.type === GIS_TYPE_POLYGON ? (
        <Polygon
          instanceRef={instanceRef(objectForRef)}
          geometry={[
            objectForRef.location?.polygon?.coordinates?.map(({ longitude, lattitude }) => [
              lattitude,
              longitude
            ])
          ]}
          defaultOptions={{
            ...PolygonManager.defaultOptions,
            fillColor: (colorStyle as DefaultColorStyle)?.fill,
            strokeColor: colorStyle?.stroke
          }}
          {...restProps}
        />
      ) : objectForRef?.location?.type === GIS_TYPE_LINE ? (
        <Polyline
          instanceRef={instanceRef(objectForRef)}
          geometry={(objectForRef.location as ParkingLocation)?.line?.coordinates?.map(
            ({ longitude, lattitude }) => [lattitude, longitude]
          )}
          defaultOptions={{
            ...LineManager.defaultOptions,
            strokeColor: colorStyle?.stroke
          }}
          {...restProps}
        />
      ) : null}
      <Placemark
        instanceRef={placemarkInstanceRef}
        geometry={[object.center?.lattitude, object.center?.longitude]}
        onLoad={() => {
          // Почему-то без таумаута эвент не вешается
          setTimeout(() => {
            $placemark.current.editor.options.get("geoObject").events.add(["click"], () => {
              if (
                getMapActionMode() === MapActionMode.ViewList ||
                getMapActionMode() === MapActionMode.ViewObject
              )
                navigate(`${PARKINGS_BASE_ROUTE}/${object?.uuid?.value || ""}`, { replace: true });
            });
          });
        }}
        properties={{
          iconContent: parkingSvgIcon
        }}
        options={{
          ...placemarkColorStyle,
          zIndex:
            mapActionMode === MapActionMode.ViewList ||
            selectedObject?.uuid?.value === object.uuid?.value
              ? 10000
              : 0
        }}
      />
    </>
  );
};

export default Parking;
