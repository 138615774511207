import { DateTime } from "luxon";
import { ReportFiltersForm, ReportType } from "./types";
import { legalReportTabs } from "./components/LegalReports";
import { parkingSessionsAppTabs } from "./components/ParkingSessionsAppReports";
import { parkingSessionsAllTabs } from "./components/ParkingSessionsAllReports";
import { transactionsTabs } from "./components/TransactionsReports";

export const getApiStartDate = (date?: DateTime | null): string | undefined =>
  date
    ?.setZone("Asia/Almaty")
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toMillis()
    .toString();

export const getApiEndDate = (date?: DateTime | null): string | undefined =>
  date
    ?.setZone("Asia/Almaty")
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .toMillis()
    .toString();

export const mapReportFiltersFormToApi = ({
  startDate,
  endDate
}: Omit<ReportFiltersForm, "reportType">) => ({
  startDate: getApiStartDate(startDate),
  endDate: getApiEndDate(endDate)
});

export const getReportTabs = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.Legal:
      return legalReportTabs;

    case ReportType.ParkinSessionsApp:
      return parkingSessionsAppTabs;

    case ReportType.ParkingSessionsAll:
      return parkingSessionsAllTabs;

    case ReportType.Transactions:
      return transactionsTabs;
  }
};

export const getPaginationRowNumber = (startIndex: number) => (index: number) =>
  startIndex + index + 1;
