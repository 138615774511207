import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ZONES_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useGetZoneQuery = (id: string) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([ZONES_KEY, id], () => requests.adminGatewayZone(id), {
    onError() {
      openErrorAlert(t("pages.ObjectManagement.Objects.item.could_not_load_zone"));
    }
  });
};
