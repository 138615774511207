import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { PermissionGrant, PermissionsResponse, CreatePermissionsGrantRequest } from "api/generated";
import { DateTime } from "luxon";
import { useGetPermissionsQuery, useCreateGrantMutation } from "hooks/queries/permissions";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PERMISSION_GRANTS_BASE_ROUTE } from "routes/route-declarations";
import LinkButton from "ui-kit/buttons/LinkButton";
import DataPicker, { DatePickerProps } from "ui-kit/form/DataPicker";
import Select, { SelectProps } from "ui-kit/form/Select";
import VehicleInputs from "components/VehicleInputs";

export type Props = {
  submit: ReturnType<typeof useCreateGrantMutation>["mutateAsync"];
  grant?: PermissionGrant;
  isAddType?: boolean;
  isEditType?: boolean;
  isLoading?: boolean;
};

const defaultValues: Omit<CreatePermissionsGrantRequest, "start" | "end" | "permissionUuid"> & {
  permissionUuid: undefined | string;
  start: undefined | DateTime | null;
  end: undefined | DateTime | null;
  vehicle: undefined | string;
} = {
  permissionUuid: undefined,
  vrp: "",
  start: null,
  end: null,
  vehicle: undefined
};

const Form = ({ submit, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading: isPermissionsLoading, data: permissionsData } = useGetPermissionsQuery();
  const permissions = (permissionsData as PermissionsResponse)?.permissions;

  const {
    handleSubmit: onSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState,
    watch
  } = useForm({
    defaultValues
  });

  const handlePermissionChange: SelectProps["onChange"] = ({ target }) => {
    const value = target.value as string;

    const permission = permissions?.find((_permission) => _permission.uuid === value);
    const start = getValues("start");

    if (permission && start) {
      const { duration } = permission;
      let end = start.plus({
        years: duration?.years ?? 0,
        months: duration?.months ?? 0,
        days: duration?.days ?? 0
      });

      if (duration?.days) end = end.minus({ milliseconds: 1 });

      setValue("end", end);
    }

    setValue("permissionUuid", value);
    if (value) clearErrors("permissionUuid");
  };

  const handleStartChange: DatePickerProps["onChange"] = (value, valueStr) => {
    const permission = permissions?.find(
      (_permission) => _permission.uuid === getValues("permissionUuid")
    );

    if (permission && value && value.toString() !== "Invalid Date") {
      const { duration } = permission;
      let end = value.plus({
        years: duration?.years ?? 0,
        months: duration?.months ?? 0,
        days: duration?.days ?? 0
      });

      if (duration?.days) end = end.minus({ milliseconds: 1 });

      setValue("end", end);
    }

    return [value, valueStr];
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) =>
    await onSubmit(({ vehicle: _, ...formValues }) => {
      return submit({
        ...formValues,
        permissionUuid: formValues.permissionUuid,
        start: formValues.start ? `${formValues.start.valueOf()}` : undefined,
        end: formValues.end ? `${formValues.end.valueOf()}` : undefined
      }).then((res) => {
        navigate(PERMISSION_GRANTS_BASE_ROUTE);
        return res;
      });
    })(e);

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <Box width='100%' marginTop={2}>
        <FormLabel required>{t("pages.Permissions.common.permission")}:</FormLabel>
        <FormControl fullWidth>
          <Select
            fullWidth
            disabled={isPermissionsLoading || isLoading}
            control={control}
            name='permissionUuid'
            onChange={handlePermissionChange}
            rules={{
              required: { value: true, message: t("common.input_rules.required") }
            }}
          >
            {permissions?.map((permission) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={permission?.uuid}
                value={permission?.uuid}
              >
                {permission.name?.ru || permission.name?.en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <VehicleInputs
        control={control}
        watch={watch}
        setValue={setValue}
        trigger={trigger}
        formState={formState}
        isLoading={isLoading}
      />
      <Box display='flex' flexWrap='nowrap' marginTop={2}>
        <Box width='100%' marginRight={2}>
          <FormControl fullWidth>
            <FormLabel required>{t("pages.Permissions.Grant.item.start_date")}:</FormLabel>
            <DataPicker
              control={control}
              name='start'
              inputProps={{
                placeholder: ""
              }}
              onChange={handleStartChange}
              inputFormat='dd.MM.yyyy'
              maxDate={watch("end")}
              disabled={isLoading}
              rules={{
                required: { value: true, message: t("common.input_rules.required") },
                validate(value: Date | null) {
                  if (value && value.toString() === "Invalid Date")
                    return t("pages.Permissions.Grant.item.input_rules.invalid_date");
                }
              }}
            />
          </FormControl>
        </Box>
        <Box width='100%'>
          <FormControl fullWidth>
            <FormLabel>{t("pages.Permissions.Grant.item.expiration_date_including")}:</FormLabel>
            <DataPicker
              control={control}
              name='end'
              inputProps={{
                placeholder: ""
              }}
              disabled
              inputFormat='dd.MM.yyyy'
            />
          </FormControl>
        </Box>
      </Box>

      <Box display='flex' justifyContent='start' marginTop={2}>
        <Button disabled={isLoading} type='submit' variant='contained' sx={{ marginRight: 2 }}>
          {t("common.save")}
        </Button>
        <LinkButton variant='outlined' to={PERMISSION_GRANTS_BASE_ROUTE}>
          {t("common.cancel")}
        </LinkButton>
      </Box>
    </Box>
  );
};

export default Form;
