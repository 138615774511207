import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetOperationsParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const PARKINGS_APP_OPERATIONS_REPORT_QUERY_KEY = "parkings_app_operations_report";

const useParkingsAppOperationsReport = (params: AdminGatewayGetOperationsParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [PARKINGS_APP_OPERATIONS_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetOperations(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useParkingsAppOperationsReport;
