import { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PermissionFull } from "api/generated";
import { useGetPermissionsQuery } from "hooks/queries/permissions";
import LinkButton from "ui-kit/buttons/LinkButton";
import ListPermission from "./components/ListItem";
import { PERMISSIONS_ADD_ROUTE } from "routes/route-declarations";
import { useAppAbility } from "context/AbilityContext";
import { useGetSystemSettingsConfigQuery } from "hooks/queries/system-settings";
import { useTranslation } from "react-i18next";

const PermissionList = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const { refetch, isLoading, data } = useGetPermissionsQuery({});
  const {
    data: { currency }
  } = useGetSystemSettingsConfigQuery();
  const permissions = useMemo(() => {
    // const permissions = (data as { permissions?: Permission[] })?.permissions;
    // return permissions?.filter((permission) => permission.active);
    return (data as { permissions?: PermissionFull[] })?.permissions;
  }, [data]);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography component='h2' variant='h5' padding='1rem 0'>
          {t("pages.Permissions.Active.active_permissions")}
        </Typography>
        {can("create", "Permission") && (
          <LinkButton variant='contained' to={PERMISSIONS_ADD_ROUTE}>
            {t("pages.Permissions.Active.add_permission")}
          </LinkButton>
        )}
      </Box>
      {isLoading || !currency ? (
        <Typography padding='1rem' variant='h6'>
          {t("pages.Permissions.Active.loading_permissions")}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {permissions?.map((permission) => (
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={permission.uuid}
              data-testid='benefit-item'
            >
              <ListPermission refetchList={refetch} {...permission} currency={currency} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default PermissionList;
