import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import DialogMui, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";
import { theme } from "config/theme";
import { useTranslation } from "react-i18next";

export enum DialogType {
  Info = "primary",
  Error = "error",
  Warning = "warning"
}

export type Props = DialogProps & {
  open: boolean;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  title?: ReactNode;
  message?: ReactNode;
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  confirmButtonProps?: LoadingButtonProps;
  cancelButtonProps?: LoadingButtonProps;
  showDeleteWarning?: boolean;
  deleteWarningMessage?: ReactNode;
  typeError?: boolean;
  typeInfo?: boolean;
  typeWarning?: boolean;
  type?: DialogType;
  testId?: string;
};

const Dialog = ({
  title,
  confirmButtonText = "common.confirm",
  confirmButtonProps,
  cancelButtonText = "common.cancel",
  cancelButtonProps,
  message,
  open,
  onCancel,
  onConfirm,
  type,
  typeError,
  typeInfo,
  typeWarning,
  showDeleteWarning,
  deleteWarningMessage,
  testId,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const dialogType =
    type || typeError
      ? DialogType.Error
      : typeWarning
      ? DialogType.Warning
      : typeInfo
      ? DialogType.Info
      : DialogType.Info;

  return (
    <DialogMui open={open} onClose={onCancel} {...rest} data-testid={testId || "Dialog"}>
      {title && (
        <DialogTitle textAlign='center' variant='h4' color={theme.palette[dialogType].main}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          <Box textAlign='center'>{message}</Box>
          {showDeleteWarning && (
            <Typography textAlign='center' data-testid={`${testId || "Dialog"}__delete-warning`}>
              {deleteWarningMessage ?? `${t("common.delete_cancelation_warning")}.`}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
        <LoadingButton
          data-testid={`${testId || "Dialog"}__confirm-button`}
          variant='contained'
          color={dialogType}
          onClick={onConfirm}
          {...confirmButtonProps}
        >
          {typeof confirmButtonText === "string" ? t(confirmButtonText) : confirmButtonText}
        </LoadingButton>
        <LoadingButton
          data-testid={`${testId || "Dialog"}__cancel-button`}
          variant='outlined'
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {typeof cancelButtonText === "string" ? t(cancelButtonText) : cancelButtonText}
        </LoadingButton>
      </DialogActions>
    </DialogMui>
  );
};

export default Dialog;
