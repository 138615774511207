import { TFunction } from "i18next";
import { PermissionCreateRequest, PermissionFull, StartType } from "api/generated";
import { useCreatePermissionMutation } from "hooks/queries/permissions";
import { DateTime } from "luxon";
import {
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError
} from "react-hook-form";
import getWordByNumber from "utils/getWordByNumber";

export type Props = {
  submit: ReturnType<typeof useCreatePermissionMutation>["mutateAsync"];
  permission?: PermissionCreateRequest & PermissionFull;
  isAddType?: boolean;
  isEditType?: boolean;
  isLoading?: boolean;
};

export const allZonesValue = "common.all_zones";

export type Duration = number | string | undefined;

export const defaultNames: Record<string, string> = {
  ru: "",
  en: ""
};

export const defaultValues: Omit<PermissionCreateRequest, "duration"> & {
  dayOfWeeks?: number[];
  startDt?: DateTime;
  endDt?: DateTime;
  duration: { years: Duration; months: Duration; days: Duration };
} = {
  dayOfWeeks: [],
  name: undefined,
  duration: {
    years: undefined,
    months: undefined,
    days: undefined
  },
  startType: StartType.START_TYPE_FROMTODAY,
  price: "",
  zones: []
};

export const getDurationUnitText = (
  value: number,
  name: string,
  isError: boolean,
  words: { [key: string]: string[] }
): (string | undefined)[] => {
  const valueTextRu =
    words.ru && !isError && value ? `${value} ${getWordByNumber(words.ru, value)}` : undefined;

  const { ru: _, ...restLangsWords } = words;

  return [valueTextRu].concat(
    Object.entries(restLangsWords).map(([lang, langWords]) => {
      return !isError && value ? `${value} ${getWordByNumber(langWords, value, lang)}` : undefined;
    })
  );
};

export const getDurationFinalText = (
  [yearsTextRu, ...restYearsText]: (string | undefined)[],
  [monthsTextRu, ...restMonthsText]: (string | undefined)[],
  [daysTextRu, ...restDaysText]: (string | undefined)[]
) => {
  const durationTextsRu = [yearsTextRu, monthsTextRu, daysTextRu].filter(Boolean);
  let durationTextRu = durationTextsRu.join(" и ");

  if (durationTextsRu.length === 3) {
    durationTextRu = durationTextRu.replace(" и", ",");
  }

  const restDurationTexts = [restYearsText, restMonthsText, restDaysText]
    .reduce((acc, texts, index) => {
      texts.forEach((text, i) => {
        if (!acc[i]) acc[i] = [];
        acc[i][index] = text;
      });
      return acc;
    }, [] as (string | undefined)[][])
    .map((texts) => {
      texts = texts.filter(Boolean);

      let text = texts.join(" and ");

      if (texts.length === 3) {
        text = texts.join(" and ").replace(" and", ",").replace(" and", ", and");
      }

      return text;
    });
  return [durationTextRu, ...restDurationTexts];
};

export type DurationNames = ["duration.years", "duration.months", "duration.days"];

export const durationNames: DurationNames = ["duration.years", "duration.months", "duration.days"];

export const getErrorMessages = (t: TFunction<"translation", undefined>) => ({
  atLeastOne: t("pages.Permissions.Active.item.input_rules.at_least_one"),
  onlyDigits: t("common.input_rules.only_digits"),
  more: t("pages.Permissions.Active.item.input_rules.more"),
  less: t("pages.Permissions.Active.item.input_rules.less")
});

export const handleDurationValidation = (
  message: string | undefined | [string | undefined, typeof durationNames[number]][],
  targetName: typeof durationNames[number] | "all",
  {
    errors,
    setError,
    clearErrors
  }: {
    errors: Partial<
      FieldErrorsImpl<{
        duration: {
          years: NonNullable<Duration>;
          months: NonNullable<Duration>;
          days: NonNullable<Duration>;
        };
      }>
    >;
    setError: UseFormSetError<typeof defaultValues>;
    clearErrors: UseFormClearErrors<typeof defaultValues>;
  },
  t: TFunction<"translation", undefined>
) => {
  const errorMessages = getErrorMessages(t);
  if (message && targetName) {
    if (targetName === "all" && !Array.isArray(message)) {
      durationNames.forEach((durationName) => {
        setError(durationName, { message });
      });
    } else if (Array.isArray(message)) {
      message.forEach((_message) => {
        handleDurationValidation(
          ..._message,
          {
            errors,
            setError,
            clearErrors
          },
          t
        );
      });
    } else if (targetName !== "all") {
      setError(targetName, { message });
    }
    return;
  }

  if (targetName !== "all") {
    clearErrors([targetName]);
  }

  const namesToClear = durationNames.filter((name) => {
    const [, durationUnit] = name.split(".") as [string, "years" | "months" | "days"];
    return errors?.duration?.[durationUnit]?.message === errorMessages.atLeastOne;
  });
  clearErrors(namesToClear);
};

type DurationName = typeof durationNames[number] | "all";
type ErrorMessages = [string | undefined, DurationName];

export type ValidateDuration = (
  value: string,
  durationValues: string[],
  name: DurationName,
  t: TFunction<"translation", undefined>
) =>
  | [string | undefined, DurationName]
  | [[ErrorMessages, ErrorMessages, ErrorMessages], DurationName];

export const validateDuration: ValidateDuration = (value, durationValues, name, t) => {
  const errorMessages = getErrorMessages(t);
  const parsedValue = parseInt(value);

  if (value && !/^\d+$/.test(value)) {
    const indexesOfNaN = durationValues.map((durationValue, index) =>
      !/^\d+$/.test(durationValue) ? index : -1
    );

    if (indexesOfNaN.some((index) => index !== -1)) {
      return [
        durationValues.map((durationValue, index) =>
          indexesOfNaN.includes(index)
            ? [errorMessages.onlyDigits, name]
            : validateDuration(durationValue, durationValues, durationNames[index], t)
        ),
        name
      ] as [[ErrorMessages, ErrorMessages, ErrorMessages], DurationName];
    }

    return [errorMessages.onlyDigits, name];
  }

  if (durationValues.every((durationValue) => !durationValue || +durationValue === 0)) {
    return [errorMessages.atLeastOne, "all"];
  }

  if (
    parsedValue === 0 &&
    durationValues.every((durationValue) => !durationValue && durationValue !== "0")
  ) {
    return [errorMessages.more, name];
  }

  if (parsedValue > 1000) {
    return [errorMessages.less, name];
  }

  return [undefined, name];
};

export const numberInputRules = {
  pattern: {
    value: /^\d+$/,
    message: "Поле только для цифр"
  }
};

export const yearsDictRu = ["год", "года", "лет"];
export const yearsDictEn = ["year", "years"];

export const monthsDictRu = ["месяц", "месяца", "месяцев"];
export const monthsDictEn = ["month", "months"];

export const daysDictRu = ["день", "дня", "дней"];
export const daysDictEn = ["day", "days"];

const getYearsString = (years: number) => [
  years ? `${years} ${getWordByNumber(yearsDictRu, years)}` : "",
  years ? `${years} ${getWordByNumber(yearsDictEn, years, "en")}` : ""
];

const getMonthsString = (months: number) => [
  months ? `${months} ${getWordByNumber(monthsDictRu, months)}` : "",
  months ? `${months} ${getWordByNumber(monthsDictEn, months, "en")}` : ""
];

const getDaysString = (days: number) => [
  days ? `${days} ${getWordByNumber(daysDictRu, days)}` : "",
  days ? `${days} ${getWordByNumber(daysDictEn, days, "en")}` : ""
];

export const shouldAllowAutoUpdateName = (
  names: Record<string, string> = defaultNames,
  duration: PermissionCreateRequest["duration"]
) => {
  let templateRu: string | null = null;
  let templateEn: string | null = null;

  const [yearsRegExpStringRu, yearsRegExpStringEn] = getYearsString(duration?.years || 0);
  const [monthsRegExpStringRu, monthsRegExpStringEn] = getMonthsString(duration?.months || 0);
  const [daysRegExpStringRu, daysRegExpStringEn] = getDaysString(duration?.days || 0);

  const { ru, ...restNames } = names;

  if (duration?.years && duration?.months && duration?.days) {
    if (ru) {
      templateRu = `${defaultNames.ru}${yearsRegExpStringRu}, ${monthsRegExpStringRu} и ${daysRegExpStringRu}`;
    }
    templateEn = `${defaultNames.en}${yearsRegExpStringEn}, ${monthsRegExpStringEn}, and ${daysRegExpStringEn}`;
  } else if (
    (duration?.years && duration?.months) ||
    (duration?.years && duration?.days) ||
    (duration?.months && duration?.days)
  ) {
    if (ru) {
      // prettier-ignore
      templateRu = `${defaultNames.ru}${yearsRegExpStringRu || monthsRegExpStringRu} и ${daysRegExpStringRu || monthsRegExpStringRu}`;
    }
    // prettier-ignore
    templateEn = `${defaultNames.en}${yearsRegExpStringEn || monthsRegExpStringEn} and ${daysRegExpStringEn || monthsRegExpStringEn}`;
  } else if (duration?.years || duration?.days || duration?.months) {
    if (ru) {
      // prettier-ignore
      templateRu = `${defaultNames.ru}${yearsRegExpStringRu || monthsRegExpStringRu || daysRegExpStringRu}`;
    }
    // prettier-ignore
    templateEn = `${defaultNames.en}${yearsRegExpStringEn || monthsRegExpStringEn || daysRegExpStringEn}`;
  }

  return Object.values(restNames).every((name) => name === templateEn) && ru
    ? ru === templateRu
    : true;
};

export const getDurationInputRules = (
  name: typeof durationNames[number],
  getValues: UseFormGetValues<
    Omit<PermissionCreateRequest, "duration"> & {
      dayOfWeeks?: number[];
      startDt?: DateTime;
      endDt?: DateTime;
      duration: {
        years: Duration;
        months: Duration;
        days: Duration;
      };
    }
  >,
  t: TFunction<"translation", undefined>
) => ({
  ...numberInputRules,
  validate(value: string) {
    const durationValues = getValues(durationNames);

    const [message] = validateDuration(
      value,
      durationValues.map((durationValue) => `${durationValue}`),
      name,
      t
    );

    return message;
  }
});
