import { Box, CircularProgress, ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFeedbackTopicsQuery } from "hooks/queries/feedback";
import { useConfigContext } from "context/ConfigContext";
import { FormInputs } from "./types";
import { getDefaultAuthTopic, getDefaultQuickReactionsTopic, getDefaultTopics } from "./utils";
import Form from "./Form";
import { createFeedbackTheme } from "./theme";

const FeedbackTopicsForm = () => {
  const { data, isLoading } = useFeedbackTopicsQuery();
  const { isInitialLoading } = useConfigContext();
  const { t } = useTranslation();

  const topics = data?.data.topics;

  const defaultValues: FormInputs | undefined = topics && {
    authTopic: getDefaultAuthTopic(topics),
    quickReactionsTopic: getDefaultQuickReactionsTopic(topics),
    topics: getDefaultTopics(topics)
  };

  return (
    <ThemeProvider theme={createFeedbackTheme}>
      <Box>
        <Typography component='h2' variant='h5'>
          {t("pages.Feedback.form_title")}
        </Typography>

        {isLoading || isInitialLoading ? (
          <CircularProgress sx={{ mt: 2 }} size={26} />
        ) : (
          <Form defaultValues={defaultValues} />
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FeedbackTopicsForm;
