import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import {
  AbonementsGrantsRequest,
  AbonementsGrantsRequestStatus,
  AbonementsResponse
} from "api/generated";
import { useGetAbonementsQuery } from "hooks/queries/abonements";
import Select from "ui-kit/form/Select";
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Paths } from "routes/const";
import LinkButton from "ui-kit/buttons/LinkButton";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const statusOptions = [
  {
    label: "pages.Abonements.common.grant_status_current",
    value: AbonementsGrantsRequestStatus.STATUS_CURRENT
  },
  {
    label: "pages.Abonements.common.grant_status_expired",
    value: AbonementsGrantsRequestStatus.STATUS_PAST
  },
  {
    label: "pages.Abonements.common.grant_status_future",
    value: AbonementsGrantsRequestStatus.STATUS_FUTURE
  },
  {
    label: "pages.Abonements.common.grant_status_all",
    value: AbonementsGrantsRequestStatus.STATUS_ALL
  }
];

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<AbonementsGrantsRequest>;
  getValues: UseFormGetValues<AbonementsGrantsRequest>;
  watch: UseFormWatch<AbonementsGrantsRequest>;
  status: AbonementsGrantsRequestStatus;
  onStatusChange: (value: AbonementsGrantsRequestStatus) => void;
};

const SidebarGrants = ({ control, setValue, watch, status, getValues, onStatusChange }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const lastSelectedRef = useRef("");
  const abonementsUuids = watch("abonementsUuids");
  const { isLoading, data } = useGetAbonementsQuery({});

  const abonements = (data as AbonementsResponse)?.abonements;

  const getButtonVariant = (
    value: AbonementsGrantsRequestStatus,
    name: AbonementsGrantsRequestStatus
  ) => (value === name ? "contained" : "outlined");

  const handleStatusChange = (statusArg: AbonementsGrantsRequestStatus) => () =>
    onStatusChange(statusArg);

  const handleAllSelect = () => {
    lastSelectedRef.current = "";
  };

  const handleSelectBenefit = (e: React.SyntheticEvent<HTMLLIElement>) => {
    lastSelectedRef.current = e.currentTarget.dataset.value || "";
  };

  const handleSelectAbonements = () => {
    const value = lastSelectedRef.current;
    if (value === "") {
      setValue("abonementsUuids", [""]);
      return;
    }

    const currentValue = getValues("abonementsUuids");

    let isValueInList = false;

    const newValue = (currentValue || []).filter((uuid: string) => {
      if (uuid === value) isValueInList = true;
      return uuid !== "" && uuid !== value;
    });

    if (!isValueInList) {
      newValue.push(value);
    }

    if (!newValue.length) {
      newValue.push("");
    }

    setValue("abonementsUuids", newValue);
  };

  const getHelperText = () => {
    if (!abonementsUuids?.length || abonementsUuids?.[0] === "") {
      return "";
    }

    return `${t("pages.Abonements.Grant.list.total_selected")} ${abonementsUuids?.length}`;
  };

  const canCreate = can("create", "AbonementGrant");

  return (
    <Box display='flex' flexWrap='wrap'>
      {canCreate && (
        <LinkButton fullWidth variant='contained' to={`${Paths.Abonements}${Paths.Grants}/add`}>
          {t("pages.Abonements.Grant.list.add_grant")}
        </LinkButton>
      )}
      <Box width='100%' marginTop={canCreate ? 2 : 0}>
        <FormControl>
          <FormLabel>{t("pages.Abonements.Grant.list.status")}:</FormLabel>
          <Box>
            {statusOptions.map((option) => (
              <Button
                key={option.value}
                sx={{ marginRight: 1, marginTop: 1 }}
                variant={getButtonVariant(status, option.value)}
                onClick={handleStatusChange(option.value)}
              >
                {t(option.label)}
              </Button>
            ))}
          </Box>
        </FormControl>
      </Box>
      <Box width='100%' marginTop={2}>
        <FormLabel>{t("pages.Abonements.Grant.list.abonements")}:</FormLabel>
        <FormControl sx={{ width: "100%" }}>
          <Select
            sx={{ marginTop: "8px" }}
            multiple
            multiline
            fullWidth
            disabled={isLoading}
            control={control}
            placeholder={t("pages.Abonements.Grant.list.unimportant")}
            name='abonementsUuids'
            onChange={handleSelectAbonements}
            helperText={getHelperText()}
          >
            <MenuItem onClick={handleAllSelect} value=''>
              {isLoading ? t("common.loading") : t("pages.Abonements.Grant.list.unimportant")}
            </MenuItem>
            {abonements?.map((abonement) => (
              <MenuItem
                onClick={handleSelectBenefit}
                sx={{ whiteSpace: "normal" }}
                key={abonement?.uuid?.value}
                value={abonement?.uuid?.value}
              >
                {getValueByLang(abonement.name, true)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SidebarGrants;
