import { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TopicInput, { TopicInputProps } from "./TopicInput";

type SortableTopicInputProps = {
  id: string;
} & Omit<TopicInputProps, "attributes" | "listeners">;

const SortableTopicInput: FC<SortableTopicInputProps> = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <TopicInput
      attributes={attributes}
      listeners={listeners}
      ref={setNodeRef}
      style={style}
      activeNodeRef={setActivatorNodeRef}
      {...props}
    />
  );
};

export default SortableTopicInput;
