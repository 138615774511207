import ButtonMui, { ButtonProps } from "@mui/material/Button";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

export type Props = ButtonProps & LinkProps;

const Button = ButtonMui as FC<Props>;

const LinkButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Button LinkComponent={Link} {...rest}>
      {children}
    </Button>
  );
};

export default LinkButton;
