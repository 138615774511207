import Box from "@mui/material/Box";
import { YMaps, Map } from "@pbe/react-yandex-maps";
import { useMapContext } from "../../context/MapContext";
import { useEffect, useMemo } from "react";
import Zone from "./components/Zone";
import {
  FormObject,
  GeometryType,
  MapActionMode,
  MapGeometryObjectRef,
  ObjectType
} from "../../context/types";
import { changeMapObjectsActivityStyle, drawNewObject } from "./utils";
import { PolygonManager } from "../../context/utils/PolygonManager";
import { PlacemarkManager } from "../../context/utils/PlacemarkManager";
import { LineManager } from "../../context/utils/LineManager";
import { GisType, Parking as ParkingType } from "api/generated";
import Parking from "./components/Parking";
import { createObjectByType } from "../../context/utils";
import { useConfigContext } from "context/ConfigContext";
import { useTranslation } from "react-i18next";

const ObjectsMap = () => {
  const { t } = useTranslation();
  const { config } = useConfigContext();

  const [mapState, mapStateSrt] = useMemo(() => {
    const _mapState = {
      center: [
        config?.mapSettings?.defaultMapCenterLatitude || 43.593427349745895,
        config?.mapSettings?.defaultMapCenterLongitude || 39.732338037701055
      ],
      zoom: !config?.mapSettings ? 4 : 14
    };

    return [_mapState, JSON.stringify(_mapState)];
  }, [config]);

  const {
    mapRef,
    objects,
    selectedObject,
    mapActionMode,
    geometryToDraw,
    objectTypeToDraw,
    isAllObjectsOnMapInitialized,
    setIsAllObjectsOnMapInitialized,
    onChange,
    setNewObject,
    getMapActionMode,
    newObject,
    getGeometryToDraw,
    getSelectedObject,
    stopWorkingWithMapObject,
    getCurrentActiveObject,
    getMapObjects,
    hoveredObject,
    setHoveredObject
  } = useMapContext();

  const zoneInstanceRef = (object: FormObject) => (ref: MapGeometryObjectRef) => {
    const objects = getMapObjects();
    if (objects) {
      objects[`${object.uuid?.value}`] = new PolygonManager(object, ref, ObjectType.Zone, null, t);
      return objects[`${object.uuid?.value}`];
    }

    return null;
  };

  const parkingInstanceRef = (object: FormObject) => (ref: MapGeometryObjectRef) => {
    const objects = getMapObjects();
    objects[`${object.uuid?.value}`] = createObjectByType(
      (object as ParkingType).location?.type || GisType.GIS_TYPE_UNSPECIFIED,
      object,
      ref,
      ObjectType.Parking,
      t,
      selectedObject?.uuid?.value === object.uuid?.value && mapActionMode === MapActionMode.Edit
        ? onChange
        : null
    );

    return objects[`${object.uuid?.value}`];
  };

  const newObjectInstanceRef = (ref: MapGeometryObjectRef) => {
    if (!ref || !objectTypeToDraw) return;

    const currentRef = newObject?.getRef();
    if (currentRef && currentRef === ref) return;

    const newObjectController =
      getGeometryToDraw() === GeometryType.Polygon
        ? new PolygonManager(null, ref, objectTypeToDraw, onChange, t)
        : getGeometryToDraw() === GeometryType.Point
        ? new PlacemarkManager(null, ref, objectTypeToDraw, onChange)
        : getGeometryToDraw() === GeometryType.Line
        ? new LineManager(null, ref, objectTypeToDraw, onChange, t)
        : null;

    if (!newObjectController) {
      console.warn("Abort creating new object. Geometry type is not specified.");
      return;
    }

    newObjectController.startCreating();
    setNewObject(newObjectController);
  };

  useEffect(() => {
    if (isAllObjectsOnMapInitialized) {
      const objects = getMapObjects();

      if (mapActionMode !== MapActionMode.ViewList) {
        changeMapObjectsActivityStyle(objects, {
          isActive: false,
          exeptions: selectedObject?.uuid ? [selectedObject?.uuid] : []
        });
      }

      switch (mapActionMode) {
        case MapActionMode.Edit: {
          objects[selectedObject?.uuid?.value || ""]?.startEditing(true);
          break;
        }

        case MapActionMode.ViewObject:
          objects[selectedObject?.uuid?.value || ""]?.setEnabledStyle();
          break;

        case MapActionMode.ViewList:
          {
            if (objects) {
              changeMapObjectsActivityStyle(objects, {
                isActive: true
              });
            }
          }
          break;

        default:
          break;
      }
    }
  }, [mapActionMode, selectedObject, isAllObjectsOnMapInitialized]);

  return (
    <Box height='100%' component={YMaps}>
      <Map
        key={mapStateSrt}
        instanceRef={mapRef}
        defaultState={mapState}
        modules={["geoObject.addon.editor"]}
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        {objects.zones.map((object, index, arr) => {
          const onLoad =
            index === arr.length - 1 ? () => setIsAllObjectsOnMapInitialized(true) : undefined;

          return (
            <Zone
              key={object.uuid?.value}
              instanceRef={zoneInstanceRef}
              hoveredObject={hoveredObject}
              setHoveredObject={setHoveredObject}
              getSelectedObject={getSelectedObject}
              mapActionMode={mapActionMode}
              stopWorkingWithMapObject={stopWorkingWithMapObject}
              selectedObject={selectedObject}
              object={object}
              onLoad={onLoad}
            />
          );
        })}
        {objects.parking.map((object, index, arr) => {
          const onLoad =
            index === arr.length - 1 ? () => setIsAllObjectsOnMapInitialized(true) : undefined;

          return (
            <Parking
              geometryToDraw={geometryToDraw}
              key={object.uuid?.value}
              getCurrentActiveObject={getCurrentActiveObject}
              instanceRef={parkingInstanceRef}
              getMapActionMode={getMapActionMode}
              mapActionMode={mapActionMode}
              selectedObject={selectedObject}
              object={object}
              onLoad={onLoad}
            />
          );
        })}
        {mapActionMode === MapActionMode.Add &&
          objectTypeToDraw &&
          geometryToDraw &&
          drawNewObject(
            { type: objectTypeToDraw, geometry: geometryToDraw },
            newObjectInstanceRef,
            mapRef.current,
            getCurrentActiveObject
          )}
      </Map>
    </Box>
  );
};

export default ObjectsMap;
