import { useParkingsAppSumZonesReport } from "hooks/queries/reports";
import { ReportTableComponentProps, SumZonesReportTable } from "../common";

const SumZonesTable = ({ filters }: ReportTableComponentProps) => {
  const { data, isFetching } = useParkingsAppSumZonesReport(filters);

  return <SumZonesReportTable isFetching={isFetching} rows={data?.data.sums} />;
};

export default SumZonesTable;
