import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetCompaniesParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const COMPANIES_QUERY_KEY = "companies";

const useCompaniesQuery = (params: AdminGatewayGetCompaniesParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([COMPANIES_QUERY_KEY, params], () => requests.adminGatewayGetCompanies(params), {
    onError() {
      openErrorAlert(t("pages.LegalEntityBalance.list.could_not_load_companies"));
    },
    refetchOnWindowFocus: false
  });
};

export default useCompaniesQuery;
