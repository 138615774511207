import { SxProps, Theme } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  UseFormSetError,
  UseFormTrigger
} from "react-hook-form";
import { IMaskMixin, IMask } from "react-imask";
import { useState } from "react";

export const {
  Masked,
  MaskedRegExp,
  MaskedFunction,
  MaskedPattern,
  MaskedNumber,
  MaskedDate,
  MaskedDynamic
} = IMask;

export type MaskType =
  | typeof Masked
  | typeof MaskedRegExp
  | typeof MaskedFunction
  | typeof MaskedPattern
  | typeof MaskedNumber
  | typeof MaskedDate
  | typeof MaskedDynamic
  | string
  | RegExp;

export type MaskedInputProps<V extends FieldValues = any> = {
  control: Control<V>;
  name: Path<V>;
  convert?: "upper" | "lower";
  vrpregexp?: string;
  triggerValidation?: () => ReturnType<UseFormTrigger<any>>; // Передаем, если нужна валидация инпута
  autoComplete?: string;
  autoFocus?: boolean;
  classes?: object;
  defaultValue?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  FormHelperTextProps?: object;
  helperText?: string;
  hiddenLabel?: boolean;
  id?: string;
  InputLabelProps?: object;
  inputProps?: object;
  label?: string;
  margin?: "dense" | "none" | "normal";
  maxRows?: number | string;
  minRows?: number | string;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  rules?: object;
  size?: "small" | "medium" | undefined;
  sx?: SxProps<Theme>;
  type?:
    | "color"
    | "date"
    | "datetime-local"
    | "datetime"
    | "email"
    | "file"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

  variant?: "filled" | "outlined" | "standard";
  error?: FieldError;
  lazy?: boolean;
  placeholderChar?: string;
  setError?: UseFormSetError<V>;
} & {
  mask: MaskType;
} & Omit<TextFieldProps, "ref" | "name">;

/**
 * регулярки которые приходят с бэка, не кушаются данной либой, нужно их парсить и строить корректный массив
 */
function maskParse(mask: RegExp | string) {
  // при инициализации, маски нет, и если нет дефолтной, то не работает корректно
  if (!mask) return [{ mask: "*", placeholderChar: "" }];
  const maskArr: any = []; // поставил такой тип, потому что возникла путаница с MaskType
  // заменяем нюансы на регулярки, что бы было удобнее с ними работать
  const parseVrpRegexp = mask
    .toString()
    .replace(/[\^$()]/g, "")
    .replace("\\d", "[0-9]")
    .replace("][", "]{1}[")
    .replace("\\S", "[\\S]")
    .split("|");

  parseVrpRegexp.forEach((element: string) => {
    let iMask = "";
    let iDefinitions = {};
    let prevIncludes = "";
    const find = element.match(new RegExp("\\[(.*?)\\]|{(.*?)}", "g"));
    find &&
      find.forEach((e: string) => {
        e.includes("[0") && (prevIncludes = "[0");
        if (e.includes("[\\S]")) {
          prevIncludes = "[\\S]";
        }
        if (e.includes("[") && !e.includes("[0") && !e.includes("[\\S]")) {
          prevIncludes = "[";
          iDefinitions = { ...iDefinitions, a: new RegExp(e) };
        }

        if (e.includes("{")) {
          const m = e.match(/(\{*(\d+)*,*(\d+)*\})/);
          if (m) {
            new Array(+m[2]).fill(0).forEach((_z) => {
              prevIncludes === "[\\S]" && (iMask += "*");
              prevIncludes === "[0" && (iMask += "0");
              prevIncludes !== "[0" && prevIncludes === "[" && (iMask += "a");
            });
            m[3] &&
              new Array(+m[3] - +m[2]).fill(0).forEach((_z) => {
                prevIncludes === "[\\S]" && (iMask += "[*]");
                prevIncludes === "[0" && (iMask += "[0]");
                prevIncludes !== "[0" && prevIncludes === "[" && (iMask += "[a]");
              });
          }
        }
      });
    maskArr.push({
      mask: iMask,
      definitions: iDefinitions
    });
  });

  return maskArr;
}

const IMaskedInput = IMaskMixin((props) => <TextField {...(props as TextFieldProps)} />);

const MaskedInput = <V extends FieldValues>({
  control,
  name,
  defaultValue,
  rules,
  helperText,
  inputRef,
  error,
  mask,
  inputProps,
  convert,
  lazy,
  setError,
  triggerValidation,
  InputProps,
  ...rest
}: MaskedInputProps<V>) => {
  const [_isFocused, setIsFocused] = useState(false);
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ""}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, onChange, onBlur, ...restField }, fieldState }) => {
        const isError = error || fieldState?.error;

        return (
          <IMaskedInput
            {...restField}
            {...rest}
            lazy={lazy}
            // @ts-expect-error: по идее все корректно, но тс ругается
            inputProps={{
              ...inputProps,
              required: false
            }}
            prepare={(str: string) =>
              convert === "upper"
                ? str.toUpperCase()
                : convert === "lower"
                ? str.toLocaleLowerCase()
                : str
            }
            onAccept={(value) => {
              onChange(value);

              const { required } = (rules || {}) as {
                required?: { value?: boolean; message?: string };
              };

              if (required?.value && value === (inputProps?.placeholder || "")) {
                setError?.(name, { message: required?.message || "" });
              } else {
                triggerValidation?.();
              }
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={(e: any) => {
              if (!lazy && inputProps?.placeholder && e.target.value === inputProps?.placeholder) {
                onChange("");
                // Устанавливаем руками значение инпута, т.к. библиотека с маской
                // почему-то подставляет предыдущее знаение
                triggerValidation?.().then(() => {
                  e.target.value = "";
                });
              }

              setIsFocused(false);
              onBlur();
            }}
            InputProps={{
              ...InputProps
            }}
            // { mask: "*", placeholderChar: "" } e
            mask={maskParse(mask.toString())}
            error={!!isError}
            inputRef={($ref) => {
              if (inputRef) {
                if (inputRef instanceof Function) inputRef($ref);
                else console.warn(`inputRef prop in Input with name "${name}" is not a function`);
              }

              return ref($ref);
            }}
            helperText={isError ? isError?.message || "Invalid Input" : helperText}
          />
        );
      }}
    />
  );
};

export default MaskedInput;
