import { FC } from "react";
import { useTranslation } from "react-i18next";
import Input, { InputProps } from "ui-kit/form/Input";

const ContractNumberInput: FC<InputProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t("pages.LegalEntityBalance.contract_dialog.labels.contract_number")}
      rules={{
        required: {
          value: true,
          message: t("common.input_rules.required")
        }
      }}
      {...props}
    />
  );
};

export default ContractNumberInput;
