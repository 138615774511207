import { Box, Button } from "@mui/material";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes/const";
import { useSaveFeedbackTopicsMutation } from "hooks/queries/feedback";
import { CreateTopicAction, DeleteTopicAction, EditTopicAction } from "api/generated";
import { FormInputs } from "./types";
import TopicSelect from "./TopicSelect";
import TopicsContainer from "./TopicsContainer";

interface FormProps {
  defaultValues?: FormInputs;
}

const Form: FC<FormProps> = ({ defaultValues }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const navigateToList = () => navigate(Paths.FeedbackTopicsList);

  const { control, watch, getValues, setValue, handleSubmit, getFieldState } = useForm<FormInputs>({
    defaultValues
  });

  const [deletedTopics, setDeletedTopics] = useState<string[]>([]);

  const { mutate: saveTopics } = useSaveFeedbackTopicsMutation();

  const handleSubmitValid: SubmitHandler<FormInputs> = ({
    authTopic,
    topics,
    quickReactionsTopic
  }) => {
    const topicsToCreate: CreateTopicAction[] = [];
    const topicsToEdit: EditTopicAction[] = [];
    const topicsToDelete: DeleteTopicAction[] = deletedTopics.map((uuid) => ({
      uuid
    }));

    topics.forEach(({ emails, isNew, name, uuid }, index) => {
      const topicToCreate: CreateTopicAction = {
        name,
        emails: emails.map(({ value }) => value),
        order: index + 1,
        isAuth: uuid === authTopic,
        isQuickReaction: uuid === quickReactionsTopic
      };

      if (isNew) {
        topicsToCreate.push(topicToCreate);
      } else {
        topicsToEdit.push({
          ...topicToCreate,
          uuid
        });
      }
    });

    saveTopics(
      {
        creates: topicsToCreate,
        deletes: topicsToDelete,
        edits: topicsToEdit
      },
      {
        onSuccess: navigateToList
      }
    );
  };

  return (
    <Box sx={{ display: "grid " }} component='form' onSubmit={handleSubmit(handleSubmitValid)}>
      <TopicSelect
        control={control}
        watch={watch}
        getFieldState={getFieldState}
        label={t("pages.Feedback.labels.auth_topic")}
        name='authTopic'
        data-testid='auth_topic_select'
      />

      <TopicSelect
        control={control}
        watch={watch}
        getFieldState={getFieldState}
        label={t("pages.Feedback.labels.quick_reactions_topic")}
        name='quickReactionsTopic'
        data-testid='quick_reactions_topic_select'
      />

      <TopicsContainer
        control={control}
        getFieldState={getFieldState}
        getValues={getValues}
        onDelete={(uuid) => setDeletedTopics([...deletedTopics, uuid])}
        setValue={setValue}
      />

      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
        <Button variant='contained' type='submit'>
          {t("common.save")}
        </Button>
        <Button variant='outlined' onClick={navigateToList}>
          {t("common.cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
