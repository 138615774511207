import { TFunction } from "i18next";

export const getInputRules = (
  t: TFunction<"translation", undefined>,
  { isOnlyDigits = false } = {}
) => {
  const baseRules = {
    required: { value: true, message: t("common.input_rules.required") }
  };

  if (isOnlyDigits) {
    return {
      ...baseRules,
      pattern: { value: /^\d+$/, message: t("common.input_rules.only_digits") }
    };
  }

  return baseRules;
};
