import { SystemSettingsConfigCurrency, TariffRuleGroupRule } from "api/generated";
import { millisecondsToHours, millisecondsToMinutes } from "date-fns";
import { TFunction } from "i18next";

export const printTime = (start: string, end: string, t: TFunction<"translation", undefined>) => {
  if (start === "0" && end === "86400000") return t("pages.Tariffs.all_day");
  const startHours = millisecondsToHours(Number(start)).toString();
  const endHours = millisecondsToHours(Number(end)).toString();
  const ss = startHours.length === 1 ? `0${startHours}:00` : `${startHours}:00`;
  const se = endHours.length === 1 ? `0${endHours}:00` : `${endHours}:00`;
  return `${ss} - ${se === "24:00" ? "00:00" : se}`;
};

export const printTariff = (
  rules: TariffRuleGroupRule[],
  currency: SystemSettingsConfigCurrency,
  t: TFunction<"translation", undefined>,
  isInTable = false
) => {
  if (rules.length > 1) {
    const message: Array<string | HTMLElement> = [];
    rules.map(({ start, end, price }) => {
      if (start === "0") {
        message.push(
          t("pages.Tariffs.from_start_to_minutes")
            .replace("{minutes}", `${millisecondsToMinutes(Number(end))}`)
            .replace("{price}", `${price?.toString().replace(/\d{2}$/, ".$&")}`)
            .replace("{currency}", `${currency.symbol}`)
            .replace("{hour}", t("pages.Tariffs.hour"))
        );
      } else if (start !== "0" && end !== "0" && end !== "86400000") {
        message.push(
          t("pages.Tariffs.from_minutes_to_minutes")
            .replace("{startMinutes}", `${millisecondsToMinutes(Number(start))}`)
            .replace("{endMinutes}", `${millisecondsToMinutes(Number(end))}`)
            .replace("{price}", `${price?.toString().replace(/\d{2}$/, ".$&")}`)
            .replace("{currency}", `${currency.symbol}`)
            .replace("{hour}", t("pages.Tariffs.hour"))
        );
      } else {
        message.push(
          t("pages.Tariffs.from_minutes_to_end")
            .replace("{minutes}", `${millisecondsToMinutes(Number(start))}`)
            .replace("{price}", `${price?.toString().replace(/\d{2}$/, ".$&")}`)
            .replace("{currency}", `${currency.symbol}`)
            .replace("{hour}", t("pages.Tariffs.hour"))
        );
      }
    });
    return message.join("\n");
  }

  if ((isInTable && rules[0].price) || (rules[0].start === rules[0].end && rules[0].price)) {
    return (
      rules[0].price?.toString().replace(/\d{2}$/, ".$&") +
      ` ${currency.symbol}/${t("pages.Tariffs.hour")}`
    );
  }

  return t("pages.Tariffs.free");
};
