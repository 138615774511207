import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

type Props = {
  name: string;
  src?: string;
  size?: number;
  fontSize?: string;
};

const AccountAvatar = ({ name, src, size = 40, fontSize = "1.25rem" }: Props) => {
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (name: string, size: number, fontSize: string) => {
    const iconSize = size !== 40 ? "5rem" : "1.5rem";
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: size,
        height: size,
        fontSize
      },
      children: !name.trim().length ? (
        <PersonIcon sx={{ fontSize: iconSize }} />
      ) : name.trim().length === 1 ? (
        `${name.split(" ")[0][0]}`
      ) : (
        `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      )
    };
  };
  return src ? (
    <Avatar
      alt={name}
      src={src}
      sx={{ width: size, height: size, fontSize }}
      data-testid='avatar'
    />
  ) : (
    <Avatar {...stringAvatar(name, size, fontSize)} data-testid='avatar' />
  );
};

export default AccountAvatar;
