import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFeedbackTopicsQuery } from "hooks/queries/feedback";
import { Paths } from "routes/const";
import LinkButton from "ui-kit/buttons/LinkButton";
import { useLocalizationContext } from "context/LocalizationContext";
import TopicBox from "./TopicBox";

const FeedbackTopicsList = () => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();

  const { data, isLoading } = useFeedbackTopicsQuery();

  const topics = data?.data.topics
    ?.sort((a, b) => (a.order ?? 1) - (b.order ?? 1))
    .map(({ uuid, name, isAuth, emails, isQuickReaction }) => ({
      uuid,
      title: getValueByLang({ ...name, kk: name?.kz }),
      isAuth,
      isQuickReaction,
      emails: emails ?? []
    }));

  const authTopic = topics?.find(({ isAuth }) => isAuth);
  const quickReactionsTopic = topics?.find(({ isQuickReaction }) => isQuickReaction);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          flexWrap: "wrap",
          padding: "1rem 0"
        }}
      >
        <Typography component='h2' variant='h5'>
          {t("pages.Feedback.title")}
        </Typography>

        <LinkButton variant='contained' to={Paths.FeedbackTopicsForm}>
          {t("common.edit")}
        </LinkButton>
      </Box>

      <Typography sx={{ mt: "16px" }}>
        {`${t("pages.Feedback.labels.auth_topic")}: ${authTopic?.title ?? ""}`}
      </Typography>

      <Typography sx={{ mt: "16px" }}>
        {`${t("pages.Feedback.labels.quick_reactions_topic")}: ${quickReactionsTopic?.title ?? ""}`}
      </Typography>

      {isLoading ? (
        <CircularProgress sx={{ mt: 2 }} size={26} />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", mt: "16px" }}>
          {topics?.map(({ emails, uuid, title }) => (
            <TopicBox key={uuid} title={title} emails={emails} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FeedbackTopicsList;
