import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useGetParkingQuery, useDeleteParkingMutation } from "hooks/queries/parkings";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ObjectOutletContext } from "components/ObjectsSidebarContent/types";
import { useEffect, useMemo } from "react";
import { PARKINGS_BASE_ROUTE } from "routes/route-declarations";
import Dialog from "components/Dialog";
import { AxiosError } from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetCategoriesQuery } from "hooks/queries/category";
import { useGetZonesQuery } from "hooks/queries/zones";
import { GisType, SubCategory } from "api/generated";
import { theme } from "config/theme";
import { useAppAbility } from "context/AbilityContext";
import useDialog from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const { GIS_TYPE_POLYGON, GIS_TYPE_LINE, GIS_TYPE_POINT } = GisType;

type Props = {
  id: string;
};

const View = ({ id }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const navigate = useNavigate();

  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetCategoriesQuery();
  const { data: zonesData, isLoading: isZonesLoading } = useGetZonesQuery();
  const zones = zonesData?.data?.zones || [];

  const categories = useMemo(
    () =>
      categoriesData?.categories?.reduce((acc, category) => {
        if (category.children) {
          return acc.concat(
            category.children.map((subCategory) => ({
              uuid: subCategory.uuid,
              name: subCategory.name
            }))
          );
        }

        return acc;
      }, [] as SubCategory[]),
    [categoriesData]
  );

  const { setCurrentObject } = useOutletContext<ObjectOutletContext>();
  const { startViewingObject, showObjectOnMap, isAllObjectsOnMapInitialized } = useMapContext();
  const { mutateAsync, isLoading: isDeleting } = useDeleteParkingMutation(
    { value: id } || {},
    true
  );
  const { isOpen, openDialog, confirm, closeDialog, cancel } = useDialog({
    onConfirm: () => {
      closeDialog();
      mutateAsync().then((res) => {
        if (res.status === 200) {
          navigate(PARKINGS_BASE_ROUTE);
        }
        return res;
      });
    }
  });

  const { data, error, isInitialLoading } = useGetParkingQuery(id);
  const parking = data?.data?.parking;

  const handleEdit = () => navigate(`${PARKINGS_BASE_ROUTE}/${id}/edit`);

  const handleShowObjectOnMap = () => showObjectOnMap(parking?.uuid?.value || "");

  useEffect(() => {
    if (data?.data?.parking) {
      setCurrentObject({ type: "parking", data: data.data.parking });
      startViewingObject(data.data.parking);
    }

    if (!data && error && (error as AxiosError)?.response?.status === 404) {
      navigate(PARKINGS_BASE_ROUTE);
    }
  }, [data, error]);

  useEffect(() => {
    if (data?.data?.parking && isAllObjectsOnMapInitialized) {
      handleShowObjectOnMap();
    }
  }, [data, isAllObjectsOnMapInitialized]);

  return isInitialLoading ? (
    <CircularProgress sx={{ margin: "auto" }} />
  ) : (
    <Box display='flex' flexWrap='wrap'>
      <FormLabel>{t("pages.ObjectManagement.Objects.item.title")}:</FormLabel>
      <Typography width='100%' component='p'>
        {getValueByLang(parking?.name)}
      </Typography>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.category")}:</FormLabel>
        <Select size='small' disabled fullWidth value={parking?.categoryUuid?.value}>
          {isCategoriesLoading && <MenuItem value='loading'>{t("common.loading")}</MenuItem>}
          {categories?.map((category) => (
            <MenuItem key={category.uuid?.value} value={category.uuid?.value}>
              {getValueByLang(category.name)}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.description")}:</FormLabel>
        <Typography width='100%' component='p'>
          {getValueByLang(parking?.description)}
        </Typography>
      </Box>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.parkings.address")}:</FormLabel>
        <Typography width='100%' component='p'>
          {getValueByLang(parking?.address?.street)}, {getValueByLang(parking?.address?.house)}
        </Typography>
      </Box>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.zones.zone")}:</FormLabel>
        <Select size='small' disabled fullWidth value={parking?.zoneUuid?.value}>
          {isZonesLoading && <MenuItem value='loading'>{t("common.loading")}</MenuItem>}
          {zones?.map((zone) => (
            <MenuItem key={zone.uuid?.value} value={zone.uuid?.value}>
              {t("pages.ObjectManagement.Objects.item.zones.zone")}: {zone.number}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Typography width='100%' component='p' color={theme.palette.text.disabled}>
        {getValueByLang(
          zones.find((zone) => zone.uuid?.value === parking?.zoneUuid?.value)?.description
        )}
      </Typography>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.contacts")}:</FormLabel>
        <Typography width='100%' component='p'>
          {getValueByLang(parking?.contacts)}
        </Typography>
      </Box>

      <Box marginTop={1} width='100%'>
        <FormLabel>{t("pages.ObjectManagement.Objects.item.on_map")}:</FormLabel>
        <Select size='small' disabled fullWidth value={parking?.location?.type}>
          <MenuItem value={GIS_TYPE_POLYGON}>
            {t("pages.ObjectManagement.Objects.item.on_map_type_polygon")}
          </MenuItem>
          <MenuItem value={GIS_TYPE_LINE}>
            {t("pages.ObjectManagement.Objects.item.on_map_type_line")}
          </MenuItem>
          <MenuItem value={GIS_TYPE_POINT}>
            {t("pages.ObjectManagement.Objects.item.on_map_type_point")}
          </MenuItem>
        </Select>
      </Box>

      <Box display='flex' flexWrap='wrap' justifyContent={"stretch"} width='100%'>
        <Button
          disabled={isDeleting}
          sx={{ marginTop: 2, flexGrow: 1 }}
          variant='contained'
          onClick={handleShowObjectOnMap}
        >
          {t("pages.ObjectManagement.Objects.item.show_on_map")}
        </Button>
        {can("update", "Parking") && (
          <Button
            disabled={isDeleting}
            sx={{ marginTop: 2, marginLeft: 2, flexGrow: 1 }}
            variant='contained'
            onClick={handleEdit}
          >
            {t("common.edit")}
          </Button>
        )}
      </Box>
      {can("delete", "Parking") && (
        <LoadingButton
          sx={{ marginTop: 1, marginLeft: "auto" }}
          variant='contained'
          color='error'
          loading={isDeleting}
          onClick={openDialog}
        >
          {t("common.delete")}
        </LoadingButton>
      )}

      <Dialog
        testId='ObjectsDialog'
        title={t("pages.ObjectManagement.Objects.item.parkings.delete_parking_title")}
        message={
          <Typography>
            {`${t("pages.ObjectManagement.Objects.item.parkings.delete_parking_message")}`.replace(
              "{name}",
              getValueByLang(parking?.name)
            )}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onConfirm={confirm}
        onCancel={cancel}
      />
    </Box>
  );
};

export default View;
