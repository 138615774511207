import { CSSObject, styled, Theme, darken } from "@mui/material/styles";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import BoxBase, { BoxProps as MuiBoxProps } from "@mui/material/Box";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";
import CollapseBase, { CollapseProps } from "@mui/material/Collapse";

export type DrawerProps = {
  open?: boolean;
  drawerWidth: number;
} & MuiDrawerProps;

export type BoxProps = {
  nestedLevel: number;
} & MuiBoxProps;

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth"
})<DrawerProps>(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerWidth)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

export const ListItemButton = styled(MuiListItemButton)<{ open: boolean }>(({ open }) => ({
  minHeight: 48,
  justifyContent: open ? "initial" : "center",
  padding: "0 18px"
}));

export const ListItemIcon = styled(MuiListItemIcon)<{ open: boolean }>(({ open }) => ({
  minWidth: 0,
  marginRight: open ? "12px" : "auto",
  justifyContent: "center"
}));

export const ListItemText = styled(MuiListItemText)<{ open: boolean }>(({ open }) => ({
  opacity: open ? 1 : 0,
  "& .MuiTypography-root": {
    fontSize: 14
  }
}));

export const Box = styled(BoxBase, {
  shouldForwardProp: (prop) => prop !== "nestedLevel"
})<BoxProps>(({ nestedLevel = 0 }) => ({
  backgroundColor: darken("#fff", nestedLevel / 50)
}));

export const Collapse = styled(CollapseBase)<CollapseProps>({
  "& .MuiList-root": {
    padding: 0
  }
});
