import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import { styled } from "@mui/system";
import MuiAppBar from "@mui/material/AppBar";
import SelectMui, { SelectProps } from "@mui/material/Select";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth?: number;
}

const CHECK_DRAWER_WIDTH = 65;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth"
})<AppBarProps>(({ open, drawerWidth }) => ({
  zIndex: 1000,
  transition: "all 0.3s ease",
  marginLeft: open ? drawerWidth : CHECK_DRAWER_WIDTH,
  width: `calc(100% - ${open ? drawerWidth : CHECK_DRAWER_WIDTH}px)`
}));

export const Select = styled<typeof SelectMui<string>>(SelectMui)<SelectProps<string>>({
  color: "white",
  "& svg": { color: "white" },
  "& fieldset": {
    borderColor: "transparent"
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input:focus ~ fieldset":
    // pretier-ignore
    { borderColor: "rgba(255, 255, 255, 1)" },
  "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, .23)"
  }
});
