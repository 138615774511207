import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { PARKINGS_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useGetParkingQuery = (id: string) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([PARKINGS_KEY, id], () => requests.adminGatewayParking(id), {
    onError() {
      openErrorAlert(t("pages.ObjectManagement.Objects.item.could_not_load_parking"));
    }
  });
};
