import { PropsWithChildren } from "react";
import CardMui from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Card = ({ children, className }: PropsWithChildren & { className?: string }) => {
  return (
    <CardMui
      sx={{ height: "100%", width: "100%", padding: 1 }}
      variant='elevation'
      className={className}
    >
      <CardContent
        sx={{
          padding: 0,
          "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
          height: "100%"
        }}
      >
        {children}
      </CardContent>
    </CardMui>
  );
};

export default Card;
