import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  id: string;
  isLoading: boolean;
  onAddClick?: VoidFunction;
  onViewClick?: VoidFunction;
  onEditClick?: VoidFunction;
  onDeleteClick?: VoidFunction;
};

const ButtonsPanel = ({
  id,
  isLoading,
  onAddClick,
  onEditClick,
  onDeleteClick,
  onViewClick
}: Props) =>
  isLoading ? (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={18} />
    </Box>
  ) : (
    <Box
      data-testid='ButtonsPanel'
      visibility='hidden'
      className={`ButtonsPanel-${id}`}
      display='flex'
      flexWrap='nowrap'
      alignContent='center'
    >
      {onAddClick && (
        <IconButton
          data-testid='ButtonsPanel__create-button'
          sx={{ maxHeight: 40, alignSelf: "flex-start" }}
          onClick={onAddClick}
        >
          <AddIcon />
        </IconButton>
      )}
      {onViewClick && (
        <IconButton
          data-testid='ButtonsPanel__view-button'
          sx={{ maxHeight: 40, alignSelf: "flex-start" }}
          onClick={onViewClick}
        >
          <VisibilityIcon />
        </IconButton>
      )}
      {onEditClick && (
        <IconButton
          data-testid='ButtonsPanel__edit-button'
          sx={{ maxHeight: 40, alignSelf: "flex-start" }}
          onClick={onEditClick}
        >
          <ModeEditIcon />
        </IconButton>
      )}
      {onDeleteClick && (
        <IconButton
          data-testid='ButtonsPanel__delete-button'
          sx={{ maxHeight: 40, alignSelf: "flex-start" }}
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );

export default ButtonsPanel;
