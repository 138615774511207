import { Sum } from "api/generated";
import { useReportPagination } from "pages/Reports/hooks";
import SumReportTableBody from "./SubReportTableBody";
import { getPaginationRowNumber } from "pages/Reports/utils";

export interface SumReportTableBodyProps {
  rows?: Sum[];
}

const SumReportTablePaginationBody = ({ rows }: SumReportTableBodyProps) => {
  const { startIndex, endIndex } = useReportPagination();

  return (
    <SumReportTableBody
      rows={rows?.slice(startIndex, endIndex)}
      getTableRowNumber={getPaginationRowNumber(startIndex)}
    />
  );
};

export default SumReportTablePaginationBody;
