import { VehicleType } from "api/generated";

export const vehicleTypeNames = {
  [VehicleType.VEHICLE_TYPE_CAR]: "pages.Tariffs.vehicle_type.car",
  [VehicleType.VEHICLE_TYPE_MOTORCYCLE]: "pages.Tariffs.vehicle_type.motorcycle",
  [VehicleType.VEHICLE_TYPE_TRUCK]: "pages.Tariffs.vehicle_type.truck",
  [VehicleType.VEHICLE_TYPE_BUS]: "pages.Tariffs.vehicle_type.bus",
  [VehicleType.VEHICLE_TYPE_UNSPECIFIED]: "pages.Tariffs.vehicle_type.unspecified"
};

export type DayTypeProps = {
  weekdays: string;
  holidays: string;
  dayOffs: string;
};

export const dayType: DayTypeProps = {
  weekdays: "pages.Tariffs.day_type.weekdays",
  holidays: "pages.Tariffs.day_type.holidays",
  dayOffs: "pages.Tariffs.day_type.dayOffs"
};
