import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetSumOperationsByZonesParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const PARKINGS_APP_SUM_ZONES_REPORT_QUERY_KEY = "parkings_app_sum_zones_report";

const useParkingsAppSumZonesReport = (params: AdminGatewayGetSumOperationsByZonesParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [PARKINGS_APP_SUM_ZONES_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetSumOperationsByZones(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useParkingsAppSumZonesReport;
