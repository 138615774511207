export * from "./useGetAbonementsQuery";
export * from "./useDeleteAbonementMutation";
export * from "./useCreateAbonementMutation";
export * from "./useEditAbonementMutation";
export * from "./useGetAbonementQuery";
export * from "./useGetGrantsQuery";
export * from "./useDeleteGrantMutation";
export * from "./useCreateGrantMutation";

export const ABONEMENTS_QUERY_KEY = "abonements";
export const ABONEMENT_GRANTS_QUERY_KEY = "abonement_grants";
