import Box from "@mui/material/Box";
import CardBase, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ButtonsPanel from "../ButtonsPanel";
import { SubCategory, Uuid } from "api/generated";
import { useDeleteSubCategoryMutation } from "hooks/queries/sub-category";
import { useCategoriesFormContext } from "../../context/CategoriesFormContext";
import { CategoryFormType, FormInfo, SetFormInfo } from "../../context/types";
import { ButtonsPanelCardComponent } from "hoc/ButtonsPanelCardComponent";
import { useAppAbility } from "context/AbilityContext";
import useDialog from "hooks/useDialog";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

export type Props = {
  onChangeCategory: (action: CategoryFormType, uuid?: Uuid, parentUuid?: Uuid) => VoidFunction;
  formInfo: FormInfo;
  setFormInfo: SetFormInfo;
} & SubCategory;

const Card = ButtonsPanelCardComponent<CardProps>(CardBase, "parking-item");

const SubCategoryCard = ({ uuid, name, parentUuid, onChangeCategory }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can, cannot } = useAppAbility();
  const { formInfo, setFormInfo } = useCategoriesFormContext();
  const { mutate, isLoading } = useDeleteSubCategoryMutation({
    name,
    formInfo,
    uuid,
    resetForm: () => setFormInfo(null)
  });
  const { isOpen, openDialog, confirm, cancel } = useDialog({ onConfirm: mutate });

  return (
    <Card data-testid='SubCategoryCard'>
      <CardContent
        sx={{
          padding: 1,
          "&.MuiCardContent-root:last-child": { paddingBottom: 1 },
          "&.MuiCardContent-root > .MuiBox-root": { minHeight: 40 }
        }}
      >
        <Box display='flex' justifyContent='space-between' alignContent='center'>
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle1'>{getValueByLang(name, true)}</Typography>
          </Box>
          <ButtonsPanel
            id='parking-item'
            isLoading={isLoading}
            onEditClick={
              can("update", "Category")
                ? onChangeCategory(CategoryFormType.EditSubCategory, uuid, parentUuid)
                : undefined
            }
            onViewClick={
              can("read", "Category") && cannot("update", "Category")
                ? onChangeCategory(CategoryFormType.ViewSubCategory, uuid, parentUuid)
                : undefined
            }
            onDeleteClick={can("delete", "Category") ? openDialog : undefined}
          />
        </Box>
      </CardContent>

      <Dialog
        testId='CategoriesDialog'
        title={t("pages.ObjectManagement.Categories.list.delete_subcategory_title")}
        message={
          <Typography>
            {t("pages.ObjectManagement.Categories.list.delete_subcategory_message").replace(
              "{name}",
              getValueByLang(name, true)
            )}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onCancel={cancel}
        onConfirm={confirm}
      />
    </Card>
  );
};

export default SubCategoryCard;
