import { FC } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";

interface FormInputProps {
  name: string;
  control: any;
  options: Array<OptionProps>;
  defaultValue?: any;
}

type OptionProps = {
  label: string;
  value: string;
};

const InputRadio: FC<FormInputProps> = ({ name, control, options, defaultValue }) => {
  const generateRadioOptions = (options: Array<OptionProps>) => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.label}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ""}
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup value={value} onChange={onChange}>
          {generateRadioOptions(options)}
        </RadioGroup>
      )}
    />
  );
};

export default InputRadio;
