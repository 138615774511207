import { BaseSyntheticEvent, FocusEventHandler, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import {
  useEditSystemSettingsConfigMutation,
  useGetSystemSettingsConfigQuery
} from "hooks/queries/system-settings";
import Input from "ui-kit/form/Input";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { Divider, FormControl, FormLabel } from "@mui/material";
import {
  NUMBER_REGEX,
  POSITIVE_NUMBER_REGEX,
  getRangeValidator,
  getInputRules,
  getMapCenterInputRules
} from "./utils";
import { useTranslation } from "react-i18next";

const Clusters = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetSystemSettingsConfigQuery();
  const { mutateAsync, isLoading: isSaving } = useEditSystemSettingsConfigMutation();

  const {
    handleSubmit: onSubmit,
    control,
    reset,
    setValue,
    trigger
  } = useForm({ defaultValues: data });

  const { openErrorAlert } = useAlertStatus();

  const handleSubmit = (e: BaseSyntheticEvent) => {
    return onSubmit(
      async (values) => {
        mutateAsync(values);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);
  };

  const handleBlur =
    (
      name: Extract<
        Parameters<typeof trigger>[0],
        | "mapSettings.defaultMapCenterLatitude"
        | "mapSettings.defaultMapCenterLongitude"
        | `zoomCoefficients.${number | string}`
        | "clusterOffZoomThreshold"
        | "oneClusterZoomThreshold"
      >,
      onlyPositive = false
    ): FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    (e) => {
      const { value } = e.target;
      if ((onlyPositive ? POSITIVE_NUMBER_REGEX : NUMBER_REGEX).test(value)) {
        setValue(name, +(value.endsWith(".") ? value.slice(0, -1) : value));
        trigger(name);
      }
    };

  useEffect(() => {
    reset(data);
  }, [data]);

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Card>
      <Box marginX='-8px'>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.SystemSettings.Map.system_map_settings")}
        </Typography>
        <Divider />
      </Box>
      <CardContent>
        {isLoading ? (
          <Typography variant='h5'>{t("common.loading")}</Typography>
        ) : (
          <Box component='form' onSubmit={handleSubmit}>
            <Typography variant='h5' marginBottom={2}>
              {t("pages.SystemSettings.Map.map_center")}:
            </Typography>
            <FormControl required sx={{ width: "194px" }}>
              <FormLabel>{t("pages.SystemSettings.Map.latitude")}:</FormLabel>
              <Input
                control={control}
                disabled={isSaving || isFetching}
                rules={{
                  ...getMapCenterInputRules(t),
                  validate: getRangeValidator(
                    -90,
                    90,
                    t("common.input_rules.allowed_from_min_to_max")
                  )
                }}
                onBlur={handleBlur("mapSettings.defaultMapCenterLatitude")}
                name='mapSettings.defaultMapCenterLatitude'
              />
            </FormControl>
            <FormControl required sx={{ marginLeft: 2, width: "194px" }}>
              <FormLabel>{t("pages.SystemSettings.Map.longitude")}:</FormLabel>
              <Input
                control={control}
                disabled={isSaving || isFetching}
                rules={{
                  ...getMapCenterInputRules(t),
                  validate: getRangeValidator(
                    -180,
                    180,
                    t("common.input_rules.allowed_from_min_to_max")
                  )
                }}
                onBlur={handleBlur("mapSettings.defaultMapCenterLongitude")}
                name='mapSettings.defaultMapCenterLongitude'
              />
            </FormControl>

            <Typography variant='h5' marginBottom={2} marginTop={4}>
              {t("pages.SystemSettings.Map.zoom_coefficients")}:
            </Typography>
            <Grid container spacing={2} maxWidth={850}>
              {Object.entries(data.zoomCoefficients || [])?.map(([name]) => (
                <Grid
                  item
                  xs={3}
                  key={name}
                  display='flex'
                  alignItems='start'
                  justifyContent='flex-end'
                >
                  <Typography
                    variant='h6'
                    component='label'
                    lineHeight='56px'
                    htmlFor={`zoomCoefficients.${name}`}
                    marginRight={1}
                    flexBasis='25%'
                    textAlign='right'
                  >
                    {name}:
                    <Box component='sup' fontSize='1rem' lineHeight='1.4375em'>
                      *
                    </Box>
                  </Typography>
                  <Input
                    rules={getInputRules(t)}
                    sx={{ flexBasis: "calc(75% - 8px)" }}
                    control={control}
                    disabled={isSaving || isFetching}
                    name={`zoomCoefficients.${name}`}
                    onBlur={handleBlur(`zoomCoefficients.${name}`, true)}
                  />
                </Grid>
              ))}
            </Grid>

            <Typography variant='h5' marginBottom={2} marginTop={4}>
              {t("pages.SystemSettings.Map.zoom_value_to_turn_off_clusterization")}:
              <Box component='sup' fontSize='1rem' lineHeight='1.4375em'>
                *
              </Box>
            </Typography>
            <Input
              control={control}
              disabled={isSaving || isFetching}
              rules={{
                ...getInputRules(t),
                max: {
                  value: 21,
                  message: t("pages.SystemSettings.Map.input_rules.not_more_than_21")
                }
              }}
              name='clusterOffZoomThreshold'
              onBlur={handleBlur("clusterOffZoomThreshold", true)}
            />

            <Typography variant='h5' marginBottom={2} marginTop={4}>
              {t("pages.SystemSettings.Map.one_cluster_zoom_threshold")}:
              <Box component='sup' fontSize='1rem' lineHeight='1.4375em'>
                *
              </Box>
            </Typography>
            <Input
              control={control}
              disabled={isSaving || isFetching}
              rules={{
                ...getInputRules(t)
              }}
              name='oneClusterZoomThreshold'
              onBlur={handleBlur("oneClusterZoomThreshold", true)}
            />

            <ConfirmCancelPanel
              stackProps={{ sx: { maxWidth: 300, marginTop: 3 } }}
              onConfirmProps={{ type: "submit", disabled: isLoading || isSaving || isFetching }}
              onCancelProps={{ disabled: isLoading || isSaving || isFetching }}
              confirmLabel={
                isSaving ? t("common.saving") : isFetching ? t("common.loading") : t("common.save")
              }
              onConfirm={handleSubmit}
              onCancel={handleCancel}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Clusters;
