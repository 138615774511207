import {
  Control,
  useFieldArray,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";
import { FormInputs } from "./types";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getDefaultTopicEmails, getDefaultTopicName } from "./utils";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortableTopicInput from "./SortableTopicInput";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteTopicConfirmDialog from "./DeleteTopicConfirmDialog";

interface TopicsContainerProps {
  control: Control<FormInputs>;
  onDelete: (uuid: string) => void;
  setValue: UseFormSetValue<FormInputs>;
  getValues: UseFormGetValues<FormInputs>;
  getFieldState: UseFormGetFieldState<FormInputs>;
}

const TopicsContainer: FC<TopicsContainerProps> = ({
  control,
  getValues,
  setValue,
  onDelete,
  getFieldState
}) => {
  const { t } = useTranslation();
  const { fields, remove, append, swap } = useFieldArray({ control, name: "topics" });
  const [confirmDeleteTopicIndex, setConfirmDeleteTopicIndex] = useState<number | null>(null);

  const handleAddClick = () =>
    append({
      uuid: uuidv4(),
      emails: getDefaultTopicEmails(),
      name: getDefaultTopicName(),
      isNew: true
    });

  const handleSubmitDelete = () => {
    if (confirmDeleteTopicIndex === null) {
      return;
    }

    const field = fields[confirmDeleteTopicIndex];

    if (!field) {
      setConfirmDeleteTopicIndex(null);
      return;
    }

    if (!field.isNew) {
      onDelete(field.uuid);
    }

    const authTopicUuid = getValues("authTopic");

    if (field.uuid === authTopicUuid) {
      setValue("authTopic", "");
    }

    const quickReactionsTopicUuid = getValues("quickReactionsTopic");

    if (field.uuid === quickReactionsTopicUuid) {
      setValue("quickReactionsTopic", "");
    }

    remove(confirmDeleteTopicIndex);
    setConfirmDeleteTopicIndex(null);
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex(({ id }) => active.id === id);
      const newIndex = fields.findIndex(({ id }) => over.id === id);
      swap(oldIndex, newIndex);
    }
  };

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((topic, index) => (
            <SortableTopicInput
              key={topic.id}
              id={topic.id}
              control={control}
              index={index}
              onDelete={setConfirmDeleteTopicIndex}
              getFieldState={getFieldState}
            />
          ))}
        </SortableContext>
      </DndContext>

      <Button
        variant='contained'
        sx={{ mt: 2, marginX: "auto", display: "block" }}
        onClick={handleAddClick}
      >
        {t("pages.Feedback.add")}
      </Button>

      <DeleteTopicConfirmDialog
        open={confirmDeleteTopicIndex !== null}
        onClose={() => setConfirmDeleteTopicIndex(null)}
        onSubmit={handleSubmitDelete}
      />
    </>
  );
};

export default TopicsContainer;
