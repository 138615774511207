import Typography from "@mui/material/Typography";
import { SystemSettingsConfigCurrency, Tariff } from "api/generated";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";
import { CardContent } from "@mui/material";
import Card from "components/Card";
import Header from "../Header";
import { CreatedAt, Description, Scope, Periods } from "../../../components";

export type TariffType = Tariff & { isInList?: boolean; currency: SystemSettingsConfigCurrency };

const CardView = ({
  name,
  createdAt,
  description,
  zones,
  periods,
  ruleGroups,
  isInList,
  currency
}: TariffType) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();

  return (
    <Card>
      <CardContent>
        <Header title={t("pages.Tariffs.item.viewing_tariff")} />

        <Typography variant='h6' data-testid='tariff-name'>
          {getValueByLang(name)}
        </Typography>

        <CreatedAt createdAt={createdAt} />
        <Description description={description} isInList={isInList} />
        <Scope zones={zones} />

        <Periods mt={2} periods={periods} currency={currency} ruleGroups={ruleGroups} />
      </CardContent>
    </Card>
  );
};

export default CardView;
