import { Polygon, Placemark, Polyline } from "@pbe/react-yandex-maps";
import { Map } from "yandex-maps";
import { Uuid } from "api/generated";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import {
  GeometryType,
  MapGeometryObjectRef,
  ObjectType
} from "pages/ObjectManagment/Objects/context/types";
import React, { FC, useEffect } from "react";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { PlacemarkManager } from "pages/ObjectManagment/Objects/context/utils/PlacemarkManager";
import { LineManager } from "pages/ObjectManagment/Objects/context/utils/LineManager";

export type MapObjectComponents = {
  zone: typeof Polygon;
  parking: {
    point: typeof Placemark;
    line: typeof Polyline;
    polygon: typeof Polygon;
  };
  parkomat: typeof Placemark;
  terminal: typeof Placemark;
  scoreboard: typeof Placemark;
};

export const mapObjectComponents: MapObjectComponents = {
  zone: Polygon,
  parking: {
    point: Placemark,
    line: Polyline,
    polygon: Polygon
  },
  parkomat: Placemark,
  terminal: Placemark,
  scoreboard: Placemark
};

export const { setGetCurrentActiveObjectFn, setPlacemarkCoordinates } = (() => {
  let getCurrentActiveObject: MapContextValue["getCurrentActiveObject"];

  return {
    setGetCurrentActiveObjectFn: (fn: MapContextValue["getCurrentActiveObject"]) => {
      getCurrentActiveObject = fn;
    },
    setPlacemarkCoordinates: (e: any) => {
      const currentActiveObject = getCurrentActiveObject?.();
      currentActiveObject?.setCoordinates([e.get("coords")]);
    }
  };
})();

const GeometryComponent = ({
  Component,
  unmountFn,
  ...props
}: {
  Component: FC;
  unmountFn: VoidFunction;
}) => {
  useEffect(() => unmountFn, []);

  return React.createElement(Component, props);
};

export const addPutPlacemarkOnMapHandler = (map: Map | null) => {
  map?.events?.add("click", setPlacemarkCoordinates);
  map?.cursors?.push("arrow");
};

export const removePutPlacemarkOnMapHandler = (map: Map | null) => {
  map?.cursors?.push("grab");
  map?.events?.remove("click", setPlacemarkCoordinates);
};

export const drawNewObject = (
  object: { type: ObjectType; geometry: GeometryType },
  ref: MapGeometryObjectRef,
  map: Map | null,
  getCurrentActiveObject: MapContextValue["getCurrentActiveObject"]
) => {
  setGetCurrentActiveObjectFn(getCurrentActiveObject);

  const { type, geometry } = object;

  if (!object.type) {
    console.warn("Object type for drawing new object is not defined");
    return null;
  }

  const getOptions = () => {
    const options = {
      draggable: true
    };

    switch (geometry) {
      case "polygon":
        return {
          ...options,
          ...PolygonManager.defaultOptions,
          strokeColor:
            type === ObjectType.Parking
              ? PolygonManager.defaultColors.parking.active.stroke
              : PolygonManager.defaultOptions.strokeColor
        };
      case "point":
        return {
          ...options,
          ...PlacemarkManager.defaultOptions
        };
      case "line":
        return {
          ...options,
          ...LineManager.defaultOptions
        };

      default:
        return options;
    }
  };

  removePutPlacemarkOnMapHandler(map);

  if (geometry === GeometryType.Point) {
    addPutPlacemarkOnMapHandler(map);
  }

  let Component = mapObjectComponents[type];

  if (type === ObjectType.Parking && geometry) {
    Component = (Component as MapObjectComponents["parking"])[geometry] as FC<any>;
  }

  return React.createElement(GeometryComponent as FC<any>, {
    Component,
    instanceRef: ref,
    geometry: [],
    options: getOptions(),
    unmountFn: () => {
      removePutPlacemarkOnMapHandler(map);
    }
  });
};

export const changeMapObjectsActivityStyle = (
  mapObjects: ReturnType<MapContextValue["getMapObjects"]>,
  {
    isActive = false,
    isInteractive = false,
    exeptions = []
  }: { isActive?: boolean; isInteractive?: boolean; exeptions?: Uuid[] }
) => {
  for (const objectUuid in mapObjects) {
    const mapObject = mapObjects[objectUuid];

    if (!mapObject) return;

    const object = mapObject.getObject();
    const ref = mapObject.getRef();

    if (exeptions.find((exeptionUuid) => object?.uuid?.value === exeptionUuid.value)) continue;

    if (!ref) return;

    if (!isInteractive) {
      mapObject.stopChanging();
    }

    if (isActive) {
      mapObject.setEnabledStyle();
    } else {
      mapObject.setDisabledStyle();
    }
    mapObject.setDraggable(false);
  }
};
