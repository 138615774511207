import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AmountInput from "./AmountInput";
import { useCompanyBalanceMutation } from "hooks/queries/legal-entity-balance";
import ConfirmationDocumentInput from "./ConfirmationDocumentInput";
import { TransitionProps } from "@mui/material/transitions";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { parseAmount } from "./utils";

interface ReplenishDialogProps {
  companyUuid: string;
  companyBalance: number;
  open: boolean;
  onClose: VoidFunction;
  TransitionProps?: TransitionProps;
}

interface ReplenishFormInputs {
  sum: string;
  confirmationDocument: string;
}

const ReplenishDialog = ({
  companyUuid,
  companyBalance,
  open,
  onClose,
  TransitionProps
}: ReplenishDialogProps) => {
  const { t } = useTranslation();
  const [transactionUuid, setTransactionUuid] = useState<string>(uuidv4());

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid }
  } = useForm<ReplenishFormInputs>({
    defaultValues: {
      sum: "",
      confirmationDocument: ""
    }
  });

  const { mutate } = useCompanyBalanceMutation();

  const handleSubmitValid: SubmitHandler<ReplenishFormInputs> = ({ sum, confirmationDocument }) => {
    const amount = parseAmount(sum) * 100;

    mutate(
      {
        amount: String(amount),
        transactionUuid,
        confirmingDocument: confirmationDocument,
        companyUuid
      },
      {
        onSuccess: () => {
          setTransactionUuid(uuidv4());
          onClose();
        }
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={TransitionProps}
      PaperProps={{
        sx: { width: "100%", maxWidth: "600px" }
      }}
    >
      <form onSubmit={handleSubmit(handleSubmitValid)}>
        <DialogTitle sx={{ pb: 0 }}>
          {t("pages.LegalEntityBalance.replenish_dialog.title")}
        </DialogTitle>
        <DialogContent sx={{ ".MuiDialogTitle-root + &": { pt: "20px" } }}>
          <AmountInput
            fullWidth
            control={control}
            name='sum'
            watch={watch}
            balance={companyBalance}
          />

          <ConfirmationDocumentInput
            fullWidth
            sx={{ mt: 2 }}
            control={control}
            name='confirmationDocument'
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            {t("common.cancelation")}
          </Button>
          <Button variant='contained' type='submit' disabled={!isValid}>
            {t("pages.LegalEntityBalance.replenish")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReplenishDialog;
