import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams } from "api/generated";

const inititalUser = { user: {} };

export const GET_USER_QUERY_KEY = "get_user";

export const useGetUserQuery = (uuid: string, params: RequestParams) => {
  const {
    data = inititalUser,
    isLoading,
    ...rest
  } = useQuery([GET_USER_QUERY_KEY], () =>
    requests.adminGatewayUser(uuid, params).then(({ data }) => data)
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
