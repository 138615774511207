import { FC } from "react";
import Input, { InputProps } from "./Input";
import { useTranslation } from "react-i18next";

const emailRegexp = /^[\w\-_.]+@([\w\-_.]+\.)+\w+$/;

const EmailInput: FC<InputProps> = ({ rules, ...props }) => {
  const { t } = useTranslation();

  return (
    <Input
      rules={{
        pattern: {
          value: emailRegexp,
          message: t("common.input_rules.invalid_email")
        },
        ...rules
      }}
      {...props}
    />
  );
};

export default EmailInput;
