import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { SUB_CATEGORY_KEY } from ".";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { GET_CATEGORIES_QUERY_KEY } from "../category";
import { Params } from "./";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

export const useDeleteSubCategoryMutation = ({ name, formInfo, uuid, resetForm }: Params) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => requests.adminGatewayDeleteSubCategory(`${uuid?.value}`),
    mutationKey: [SUB_CATEGORY_KEY, DELETE_MUTATE_KEY, uuid?.value],
    onSettled() {
      queryClient.invalidateQueries([GET_CATEGORIES_QUERY_KEY]);
    },
    onSuccess() {
      if (formInfo.uuid?.value === uuid?.value) resetForm();
      openSuccessAlert(
        t("pages.ObjectManagement.Categories.item.subcategory_deleted").replace(
          "{category}",
          getValueByLang(name)
        )
      );
    },
    onError() {
      openErrorAlert(
        t("pages.ObjectManagement.Categories.item.could_not_delete_subcategory").replace(
          "{category}",
          getValueByLang(name)
        )
      );
    }
  });
};
