import { SubCategory, Uuid } from "api/generated";
import { FormInfo } from "pages/ObjectManagment/Categories/context/types";

export * from "./useCreateCategoryMutation";
export * from "./useDeleteCategoryMutation";
export * from "./useEditCategoryMutation";
export * from "./useGetCategoriesQuery";

export const CATEGORY_MUTATE_KEY = "category";
export type Params = {
  name?: Record<string, string>;
  uuid?: Uuid;
  formInfo: FormInfo;
  subCategories: SubCategory[] | undefined;
  resetForm: VoidFunction;
};
