import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TariffCard from "./components/Card";

import { useGetTariffQuery } from "hooks/queries/tariffs";
import { useGetSystemSettingsConfigQuery } from "hooks/queries/system-settings";
import { useTranslation } from "react-i18next";

const TariffItem = () => {
  const { t } = useTranslation();
  const {
    data: { currency }
  } = useGetSystemSettingsConfigQuery();
  const { uuid } = useParams() as { uuid: string };
  const {
    data: { tariff },
    isLoading
  } = useGetTariffQuery(uuid, {});

  return (
    <Box display='flex' height='100%' flexDirection='column'>
      {isLoading || !currency ? (
        <Typography padding={1} variant='h6'>
          {t("pages.Tariffs.item.loading_tariff")}
        </Typography>
      ) : (
        <TariffCard {...tariff} currency={currency} />
      )}
    </Box>
  );
};

export default TariffItem;
