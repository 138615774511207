import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams, Uuid } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { GRANTS_QUERY_KEY } from ".";
import { useTranslation } from "react-i18next";

export const useDeleteGrantMutation = (uuid: Uuid, params?: RequestParams) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();
  const queryClient = useQueryClient();

  return useMutation(() => requests.adminGatewayDeleteBenefitGrant(uuid?.value || "", params), {
    mutationKey: [GRANTS_QUERY_KEY, DELETE_MUTATE_KEY, uuid?.value],
    onSettled() {
      queryClient.invalidateQueries([GRANTS_QUERY_KEY]);
    },
    onSuccess() {
      openSuccessAlert(t("pages.Benefits.Grants.item.benefit_grant_deleted"));
      queryClient.invalidateQueries([GRANTS_QUERY_KEY]);
    },
    onError() {
      openErrorAlert(t("pages.Benefits.Grants.item.could_not_delete_benefit_grant"));
      queryClient.invalidateQueries([GRANTS_QUERY_KEY]);
    }
  });
};
