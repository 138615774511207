import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { COMPANIES_QUERY_KEY } from "./";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { requests } from "api";
import { UpdateLicenseExpiryRequest } from "api/generated";

const useUpdateLicenseExpiryMutation = (successMessage: string, errorMessage: string) => {
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: UpdateLicenseExpiryRequest) =>
      requests.adminGatewayUpdateLicenseExpiry(body),
    onSuccess() {
      openSuccessAlert(successMessage);
      queryClient.invalidateQueries([COMPANIES_QUERY_KEY]);
    },
    onError() {
      showErrors({
        defaultMessage: errorMessage
      });
    }
  });
};

export default useUpdateLicenseExpiryMutation;
