import Form from "../Form";
import Body from "../Body";
import { useEditAbonementMutation, useGetAbonementQuery } from "hooks/queries/abonements";
import { useNavigate } from "react-router-dom";
import { ABONEMENTS_ACTIVE_ROUTE } from "routes/route-declarations";
import { useTranslation } from "react-i18next";

export type Props = { uuid: string };

const EditForm = ({ uuid }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync, isLoading: isEditLoading } = useEditAbonementMutation(uuid);
  const { data, isLoading } = useGetAbonementQuery(uuid, undefined, {
    onError() {
      navigate(ABONEMENTS_ACTIVE_ROUTE);
    }
  });

  return (
    <Body title={t("pages.Abonements.Active.item.abonement_editing")}>
      <Form
        isEditType
        abonement={data}
        submit={mutateAsync}
        isLoading={isLoading || isEditLoading}
      />
    </Body>
  );
};

export default EditForm;
