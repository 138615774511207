import AutocompleteMui, {
  AutocompleteProps as AutocompleteMuiProps,
  AutocompleteChangeReason
} from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import { Control, Controller, ControllerFieldState, FieldError } from "react-hook-form";
import { ChipTypeMap } from "@mui/material/Chip";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { BaseSyntheticEvent } from "react";

export type OnChange = (
  e: BaseSyntheticEvent,
  value: any,
  reason: AutocompleteChangeReason,
  newValue?: { option: any }
) => any;

export type DefaultAutocompleteProps<T> = Omit<
  AutocompleteMuiProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined,
    ChipTypeMap["defaultComponent"]
  >,
  "onChange"
> & { onChange?: OnChange };

export type AutocompleteProps<T> = {
  // Required Props
  control: Control<any>;
  name: string;

  // Optional Props
  renderInput?: (fieldState: ControllerFieldState) => DefaultAutocompleteProps<T>["renderInput"];
  label?: string;
  inputRef?: TextFieldProps["inputRef"];
  rules?: object;
  helperText?: string;
  error?: FieldError;
} & Partial<Omit<TextFieldProps, "onChange">> &
  Omit<DefaultAutocompleteProps<T>, "error" | "renderInput">;

const Autocomplete = <T,>({
  control,
  name,
  rules,
  helperText,
  variant,
  inputRef,
  label,
  id,
  error,
  multiple,
  renderInput,
  onChange,
  ...rest
}: AutocompleteProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...restField }, fieldState }) => {
        const _error = error || fieldState?.error;

        return (
          <>
            {label && (
              <InputLabel size='small' id={id}>
                {label}
              </InputLabel>
            )}
            <AutocompleteMui
              {...restField}
              {...rest}
              onChange={(e, data, ...restArgs) => {
                let newValue = data;
                if (typeof onChange === "function") {
                  newValue = onChange(e, data, ...restArgs);
                }
                return restField.onChange(newValue);
              }}
              multiple={multiple}
              id={id}
              renderInput={
                renderInput
                  ? renderInput(fieldState)
                  : (params) => (
                      // @ts-expect-error: по какой-то причине тс ругается на несовместимость типа variant, хотя до установки i18n всё было ок
                      <TextField
                        {...params}
                        variant={variant}
                        error={!!_error}
                        inputRef={($ref) => {
                          if (inputRef) {
                            if (inputRef instanceof Function) inputRef($ref);
                            else
                              console.warn(
                                ` inputRef prop in Input with name "${name}" is not a function`
                              );
                          }
                          return ref($ref);
                        }}
                        helperText={_error ? _error?.message || "Invalid Input" : helperText}
                      />
                    )
              }
            />
          </>
        );
      }}
    />
  );
};

export default Autocomplete;
