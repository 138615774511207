import MenuItem from "@mui/material/MenuItem";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import ruLocal from "date-fns/locale/ru";
import { UseMutateAsyncFunction, UseMutationResult } from "@tanstack/react-query";
import { CreateDefaultResponse, Holiday, HolidayType } from "api/generated";
import { AxiosResponse } from "axios";
import { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "ui-kit/form/Input";
import Select from "ui-kit/form/Select";
import { useAppAbility } from "context/AbilityContext";
import { formatUtcDate, getNowUtc } from "utils/date";
import { useTranslation } from "react-i18next";

export type Props = Omit<DialogProps, "children"> & {
  submit: UseMutateAsyncFunction<
    AxiosResponse<CreateDefaultResponse>,
    unknown,
    Partial<Holiday>,
    unknown
  >;
  deleteHolidayMutation: UseMutationResult<AxiosResponse<object>, unknown, HolidayType, unknown>;
  date: Date | null;
  holiday: Holiday | null;
};

const defaultValues: Holiday = {
  uuid: undefined,
  type: HolidayType.HOLIDAY_TYPE_DAYOFF,
  date: undefined,
  title: ""
};

const Modal = ({ date, submit, holiday, deleteHolidayMutation, ...rest }: Props) => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const [changingDates, setChangingDates] = useState<{ [key: string]: boolean }>({});
  const { mutateAsync: deleteHoliday } = deleteHolidayMutation;

  const formValues = useMemo<Holiday>(() => {
    if (holiday) return holiday;

    if (!date) {
      date = getNowUtc();
    }

    return (
      holiday || {
        ...defaultValues,
        date: `${Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())}`
      }
    );
  }, [holiday, date]);

  const {
    handleSubmit: onSubmit,
    control,
    reset,
    watch
  } = useForm({
    defaultValues: formValues
  });

  const title = watch("title");

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit((values) => {
      const { uuid: _, ...body } = values;

      setChangingDates((dates) => ({
        ...dates,
        [`${date?.valueOf()}`]: true
      }));

      return submit(body)
        .then((res) => {
          rest.onClose?.({}, "backdropClick");
          return res;
        })
        .finally(() => {
          setChangingDates((dates) => ({
            ...dates,
            [`${date?.valueOf()}`]: false
          }));
        });
    })(e);

  const handleDelete = () => {
    setChangingDates((dates) => ({
      ...dates,
      [`${date?.valueOf()}`]: true
    }));

    deleteHoliday(formValues.type as HolidayType)
      .then((res) => {
        rest.onClose?.({}, "backdropClick");
        return res;
      })
      .finally(() => {
        setChangingDates((dates) => ({
          ...dates,
          [`${date?.valueOf()}`]: false
        }));
      });
  };

  useEffect(() => {
    reset(formValues);
  }, [formValues]);

  useEffect(() => {
    return () => {
      rest.open && reset(defaultValues);
    };
  }, [rest.open]);

  const canCreateOrUpdate = can(holiday?.uuid ? "update" : "create", "Holiday");

  return (
    <Dialog
      {...rest}
      sx={{ ".MuiDialog-container > .MuiPaper-root": { width: "100%" } }}
      data-testid='HolidayModal'
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {`${
            canCreateOrUpdate
              ? t("pages.HolidaysCalendar.item.date_editing")
              : t("pages.HolidaysCalendar.item.date")
          } ${formatUtcDate(date || new Date(), "dd.MM.yyyy", { locale: ruLocal })}`}
        </DialogTitle>
        <DialogContent>
          <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
            <Select
              fullWidth
              size='small'
              name='type'
              readOnly={!canCreateOrUpdate}
              control={control}
              label={`${t("pages.HolidaysCalendar.item.non_working_day_type")}:`}
              data-testid='HolidayModal__type-input'
            >
              <MenuItem
                key={HolidayType.HOLIDAY_TYPE_DAYOFF}
                value={HolidayType.HOLIDAY_TYPE_DAYOFF}
              >
                {t("pages.HolidaysCalendar.item.weekend")}
              </MenuItem>
              <MenuItem
                key={HolidayType.HOLIDAY_TYPE_HOLIDAY}
                value={HolidayType.HOLIDAY_TYPE_HOLIDAY}
              >
                {t("pages.HolidaysCalendar.item.holiday")}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
            <Input
              data-testid='HolidayModal__title-input'
              fullWidth
              inputProps={{
                readOnly: !canCreateOrUpdate
              }}
              InputLabelProps={{
                shrink: canCreateOrUpdate ? undefined : !!title
              }}
              size='small'
              name='title'
              control={control}
              label={`${t("pages.HolidaysCalendar.item.title")}:`}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          {canCreateOrUpdate && (
            <LoadingButton
              data-testid='HolidayModal__save-button'
              loading={changingDates[`${date?.valueOf()}`]}
              variant='contained'
              type='submit'
              onClick={handleSubmit}
            >
              {t("common.save")}
            </LoadingButton>
          )}
          <Button
            data-testid='HolidayModal__close-button'
            variant='outlined'
            type='button'
            onClick={(e) => rest?.onClose?.(e, "escapeKeyDown")}
          >
            {changingDates[`${date?.valueOf()}`] ? t("common.close") : t("common.cancel")}
          </Button>
          {can("delete", "Holiday") && holiday?.uuid && (
            <LoadingButton
              data-testid='HolidayModal__delete-button'
              variant='contained'
              color='error'
              loading={changingDates[`${date?.valueOf()}`]}
              onClick={handleDelete}
            >
              {t("common.delete")}
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Modal;
