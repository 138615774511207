import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DividerBase from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CategoryCard from "./components/CategoryCard";
import Card from "components/Card";
import CardSidebar from "components/CardSidebar";
import { useGetCategoriesQuery } from "hooks/queries/category";
import { CategoriesFormProvider } from "./context/CategoriesFormContext";
import SidebarContent from "./components/SidebarContent";
import { useTranslation } from "react-i18next";

const Divider = styled(DividerBase)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(),
  marginRight: theme.spacing()
}));

const Categories = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useGetCategoriesQuery();

  return (
    <CategoriesFormProvider categories={data.categories} data-testid='Categories'>
      <Box display='flex' flexWrap='nowrap' height='100%'>
        <CardSidebar title={t("pages.ObjectManagement.Categories.item.categories")}>
          <SidebarContent />
        </CardSidebar>
        <Card>
          {data?.categories?.length && data.categories.length > 0 ? (
            data.categories.map((category) => (
              <React.Fragment key={category.uuid?.value}>
                <CategoryCard {...category} />
                <Divider />
              </React.Fragment>
            ))
          ) : isLoading ? (
            <Typography padding={1} variant='h6'>
              {t("pages.ObjectManagement.Categories.item.loading_categories")}
            </Typography>
          ) : (
            <Typography padding={1} variant='h6'>
              {t("pages.ObjectManagement.Categories.item.no_categories_created")}
            </Typography>
          )}
        </Card>
      </Box>
    </CategoriesFormProvider>
  );
};

export default Categories;
