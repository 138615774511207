import { FC } from "react";
import { useTranslation } from "react-i18next";
import Input, { InputProps } from "ui-kit/form/Input";

const ConfirmationDocumentInput: FC<InputProps> = ({ inputProps, ...props }) => {
  const { t } = useTranslation();

  return (
    <Input
      label={t("pages.LegalEntityBalance.replenish_dialog.labels.confirmation_document")}
      multiline
      rules={{
        required: {
          value: true,
          message: "required"
        }
      }}
      inputProps={{ maxlength: 1000, ...inputProps }}
      {...props}
    />
  );
};

export default ConfirmationDocumentInput;
