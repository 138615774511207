import { ReportTables } from "../../types";
import SumDistrictsTable from "./SumDistrictsTable";
import SumZonesTable from "./SumZonesTable";
import OperationsTable from "./OperationsTable";
import SumPSTable from "./SumPSTable";
import { ReportTab } from "../common";

export const parkingSessionsAllTabs: ReportTab[] = [
  { table: ReportTables.SumPS, component: SumPSTable },
  { table: ReportTables.SumDistricts, component: SumDistrictsTable },
  { table: ReportTables.SumZones, component: SumZonesTable },
  { table: ReportTables.Operations, component: OperationsTable }
];
