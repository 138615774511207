import { useState } from "react";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardSidebar from "components/CardSidebar";
import Card from "components/Card";
import { useGetGrantsQuery } from "hooks/queries/permissions";
import SidebarGrants from "./components/SidebarGrants";
import { useForm } from "react-hook-form";
import {
  PermissionGrant,
  PermissionsGrantsRequest,
  PermissionsGrantsRequestStatus
} from "api/generated";
import GrantTable from "./components/GrantTable";
import { useTranslation } from "react-i18next";

const initialValues: PermissionsGrantsRequest = {
  status: PermissionsGrantsRequestStatus.STATUS_CURRENT,
  query: "",
  permissionsUuids: [""],
  limit: 20,
  offset: 0
};

const emptyPermissionUuids: string[] = [];

const Grants = () => {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [query, setQuery] = useState("");

  const { handleSubmit, control, setValue, getValues, watch } = useForm({
    defaultValues: initialValues
  });

  const status = watch("status");
  const limit = watch("limit") || 0;
  const offset = watch("offset") || 0;
  const permissionsUuids = watch("permissionsUuids");

  const { isFetching, isLoading, data, refetch } = useGetGrantsQuery(
    {
      query,
      permissionsUuids: permissionsUuids?.[0] === "" ? emptyPermissionUuids : permissionsUuids,
      status,
      limit,
      offset: offset * limit
    },
    {},
    {
      keepPreviousData: true,
      onSuccess() {
        setIsInitialLoading(false);
      }
    }
  );

  const grants = (data as { grants?: (PermissionGrant & { zones: string[] })[] })?.grants;
  const total = (data as { total?: number }).total;

  const handleStatusChange = (newStatus: PermissionsGrantsRequestStatus) => {
    setValue("status", newStatus);
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(() => refetch())}
      display='flex'
      flexWrap='nowrap'
      height='100%'
    >
      <CardSidebar title={t("pages.Permissions.Grant.list.grants")} width={330}>
        <SidebarGrants
          setValue={setValue}
          getValues={getValues}
          control={control}
          watch={watch}
          status={status || PermissionsGrantsRequestStatus.STATUS_UNSPECIFIED}
          onStatusChange={handleStatusChange}
        />
      </CardSidebar>
      <Card>
        <CardContent>
          <GrantTable
            onQueryChange={setQuery}
            query={query}
            setValue={setValue}
            isLoading={isLoading}
            isFetching={isFetching}
            isInitialLoading={isInitialLoading}
            refetch={refetch}
            grants={grants}
            control={control}
            total={total}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default Grants;
