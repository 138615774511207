import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { theme } from "config/theme";
import { UseShowBackendErrors } from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export type Props = Required<
  Required<Required<Parameters<ReturnType<UseShowBackendErrors>>>[0]>
>["details"][number];

const ValidationError = ({ fieldViolations }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant='h6' textAlign='center'>
        {t("common.validation_error.title")}
      </Typography>
      {fieldViolations?.map((violation, index) => (
        <div key={index}>
          <Box display='flex' flexWrap='wrap' alignItems='start'>
            <Typography component='span' fontWeight='bold'>
              {t("common.validation_error.field")}:&nbsp;
            </Typography>
            <Typography component='i'>{violation.field}</Typography>
          </Box>
          <Box display='flex' flexWrap='wrap' alignItems='start'>
            <Typography component='span' fontWeight='bold'>
              {t("common.validation_error.description")}:&nbsp;
            </Typography>
            <Typography component='i' maxWidth={280} display='inline-block'>
              {violation.description}
            </Typography>
          </Box>
          {index !== fieldViolations.length - 1 && (
            <Divider sx={{ backgroundColor: theme.palette.background.default, marginY: 1 }} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ValidationError;
