import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemBase from "@mui/material/ListItem";
import { ListItemButton, ListItemIcon, ListItemText } from "../../styled";
import { NavItem, Paths } from "routes/const";
import NavLink from "../NavLink";
import { useLocation } from "react-router-dom";
import { Theme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export type Props = {
  title: string;
  Icon?: typeof ChevronRightIcon;
  nestedLevel?: number;
  path?: Paths;
  cp?: string;
  children?: NavItem[];
  isNavSidebarOpen: boolean;
  pathComparator?: PathComparator;
};

export type PathComparator = typeof defaultPathComparator;

const getBoxShadow = (active: boolean) => (theme: Theme) =>
  `inset ${active ? 4 : 0}px 0px 0px ${theme.palette.primary.main};`;

const defaultPathComparator = (itemPath: string | undefined, currentPath: string | undefined) =>
  itemPath === currentPath;

const ListItem = ({
  title,
  isNavSidebarOpen,
  Icon,
  path,
  cp,
  pathComparator = defaultPathComparator,
  nestedLevel = 0
}: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isActive = pathComparator(path, cp || pathname);

  return (
    <NavLink to={path as Paths}>
      <ListItemBase
        disablePadding
        sx={{
          display: "block",
          transition: ".35s",
          boxShadow: getBoxShadow(isActive),
          paddingLeft: isNavSidebarOpen ? `${nestedLevel * 4}px` : 0
        }}
      >
        <Tooltip title={t(title)} disableHoverListener={isNavSidebarOpen} arrow placement='right'>
          <ListItemButton open={isNavSidebarOpen}>
            <ListItemIcon open={isNavSidebarOpen}>{Icon && <Icon />}</ListItemIcon>
            <ListItemText primary={t(title)} open={isNavSidebarOpen} />
          </ListItemButton>
        </Tooltip>
      </ListItemBase>
    </NavLink>
  );
};

export default ListItem;
