import { Container } from "./styled";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader = () => (
  <Container>
    <CircularProgress />
  </Container>
);

export default PageLoader;
