import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTransactionsOperationsReport } from "hooks/queries/reports";
import { useTranslation } from "react-i18next";
import { ReportTableComponentProps, ReportTableContainer } from "../common";
import { TransactionPayment } from "api/generated";
import { useReportPagination } from "pages/Reports/hooks";

interface OperationsTableBodyProps {
  rows?: TransactionPayment[];
}

const OperationsTableBody = ({ rows }: OperationsTableBodyProps) => {
  const { startIndex, endIndex } = useReportPagination();

  return (
    <TableBody>
      {rows
        ?.slice(startIndex, endIndex)
        .map(({ cost, date, paymentSystem, paymentTime }, index) => (
          <TableRow key={index}>
            <TableCell>{startIndex + index + 1}</TableCell>
            <TableCell>{date}</TableCell>
            <TableCell>{paymentSystem}</TableCell>
            <TableCell>{paymentTime}</TableCell>
            <TableCell>{cost}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

const OperationsTable = ({ filters }: ReportTableComponentProps) => {
  const { t } = useTranslation();

  const getPSColumnTitle = () => {
    const ps = t("pages.Reports.table_columns.pay_system");
    const bank = t("pages.Reports.table_columns.bank");
    const merchant = t("pages.Reports.table_columns.merchant");
    const halyk = t("pages.Reports.table_columns.app_halyk");

    return `${ps}\\${bank}\\${merchant}\\${halyk}`;
  };

  const { data, isFetching } = useTransactionsOperationsReport(filters);
  const rows = data?.data.payments;

  return (
    <ReportTableContainer isFetching={isFetching} rowsLength={rows?.length} usePagination>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>{t("pages.Reports.table_columns.date")}</TableCell>
            <TableCell>{getPSColumnTitle()}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.payment_time")}</TableCell>
            <TableCell>{t("pages.Reports.table_columns.sum")}</TableCell>
          </TableRow>
        </TableHead>
        <OperationsTableBody rows={rows} />
      </Table>
    </ReportTableContainer>
  );
};

export default OperationsTable;
