import { SxProps, Theme } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC } from "react";
import { Control, Controller, FieldError } from "react-hook-form";

export type InputProps = {
  // Required Props
  control: Control<any>;
  name: string;

  // Optional Prop
  autoComplete?: string;
  autoFocus?: boolean;
  classes?: object;
  color?: TextFieldProps["color"];
  defaultValue?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  FormHelperTextProps?: object;
  helperText?: string;
  hiddenLabel?: boolean;
  id?: string;
  inputRef?: TextFieldProps["inputRef"];
  InputLabelProps?: object;
  inputProps?: object;
  InputProps?: TextFieldProps["InputProps"];
  label?: string;
  margin?: "dense" | "none" | "normal";
  maxRows?: number | string;
  minRows?: number | string;
  multiline?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number | string;
  rules?: object;
  size?: "small" | "medium" | undefined;
  sx?: SxProps<Theme>;
  type?:
    | "color"
    | "date"
    | "datetime-local"
    | "datetime"
    | "email"
    | "file"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

  variant?: "filled" | "outlined" | "standard";
  error?: FieldError;
  onChange?: TextFieldProps["onChange"];
  onBlur?: TextFieldProps["onBlur"];
};

const Input: FC<InputProps> = ({
  control,
  name,
  defaultValue,
  rules,
  helperText,
  variant,
  inputRef,
  error,
  ...rest
}) => {
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : ""}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...restField }, fieldState }) => {
        const _error = error || fieldState?.error;

        return (
          <TextField
            {...restField}
            {...rest}
            variant={variant}
            error={!!_error}
            inputRef={($ref) => {
              if (inputRef) {
                if (inputRef instanceof Function) inputRef($ref);
                else console.warn(` inputRef prop in Input with name "${name}" is not a function`);
              }
              return ref($ref);
            }}
            helperText={_error ? _error?.message || "Invalid Input" : helperText}
          />
        );
      }}
    />
  );
};

export default Input;
