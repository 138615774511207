import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams, SystemSettingsConfig } from "api/generated";
import { SYSTEM_SETTINGS_CONFIG_KEY } from ".";

const initialSettingsConfig: SystemSettingsConfig = {
  availableLanguages: undefined,
  currency: undefined,
  vehicles: undefined,
  mapSettings: undefined,
  env: undefined,
  clusterOffZoomThreshold: undefined
};

let previousConfig: SystemSettingsConfig = initialSettingsConfig;

export const useGetSystemSettingsConfigQuery = (
  params?: RequestParams,
  options?: UseQueryOptions<SystemSettingsConfig>
) => {
  const {
    data = previousConfig || initialSettingsConfig,
    status,
    ...rest
  } = useQuery<SystemSettingsConfig>(
    [SYSTEM_SETTINGS_CONFIG_KEY, params],
    () => requests.adminGatewayGetSystemSettings(params).then(({ data }) => data),
    {
      keepPreviousData: true,
      ...options
    }
  );

  if (data) previousConfig = data;

  return {
    data,
    status,
    ...rest,
    isLoading: status === "loading"
  };
};
