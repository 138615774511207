import { TFunction } from "i18next";
import { ObjectType } from "../types";
import { MapObjectManager, menuIdTranslates } from "./MapObjectManagers";

export class PolygonManager extends MapObjectManager {
  static readonly defaultOptions = {
    cursor: "crosshair",
    fillColor: "#FF0000AB",
    fillOpacity: 0.35,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWidth: 2,
    draggable: false,
    zIndex: 0
  };

  static readonly overOtherObjectsStyle = {
    zIndex: 1000000
  };

  static readonly defaultColors = {
    [ObjectType.Zone]: {
      active: {
        fill: "#FF0000AB",
        stroke: "#FF0000"
      },
      inactive: {
        fill: "#696969A1",
        stroke: "#696969"
      },
      hovered: {
        fill: "#FF0000FF",
        stroke: "#FF0000"
      }
    },
    [ObjectType.Parking]: {
      active: {
        fill: "#1E98FFAB",
        stroke: "#0000FF"
      },
      inactive: {
        fill: "#696969A1",
        stroke: "#696969"
      },
      hovered: {
        fill: "#FF0000DD",
        stroke: "#FF0000"
      }
    },
    [ObjectType.Parkomat]: {
      active: {
        fill: "#1E98FFAB",
        stroke: "#0000FF"
      },
      inactive: {
        fill: "#696969A1",
        stroke: "#696969"
      },
      hovered: {
        fill: "#FF0000DD",
        stroke: "#FF0000"
      }
    },
    [ObjectType.Terminal]: {
      active: {
        fill: "#1E98FFAB",
        stroke: "#0000FF"
      },
      inactive: {
        fill: "#696969A1",
        stroke: "#696969"
      },
      hovered: {
        fill: "#FF0000DD",
        stroke: "#FF0000"
      }
    },
    [ObjectType.Scoreboard]: {
      active: {
        fill: "#1E98FFAB",
        stroke: "#0000FF"
      },
      inactive: {
        fill: "#696969A1",
        stroke: "#696969"
      },
      hovered: {
        fill: "#FF0000DD",
        stroke: "#FF0000"
      }
    }
  };

  constructor(
    ...args: [
      ...ConstructorParameters<typeof MapObjectManager>,
      TFunction<"translation", undefined>
    ]
  ) {
    const baseConstructorArgs = args.slice(0, -1) as ConstructorParameters<typeof MapObjectManager>;
    const t = args.pop();

    super(...baseConstructorArgs);

    this.setOptionsInEditor({
      useAutoPanInDrawing: false,
      // Убираем пункт "Добавить внутренний контур" в меню действий при клике на ноду контура
      menuManager: (menuItems: { id: string; title: string }[]) =>
        menuItems
          .filter((item) => item.id !== "addInterior")
          .map((item) => ({
            ...item,
            title: t(menuIdTranslates[item.id]) || item.title
          }))
    });
  }

  setEnabledStyle(zIndex = PolygonManager.defaultOptions.zIndex) {
    this.setOptionsInGeoObject({
      fillColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].active.fill,
      strokeColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].active.stroke,
      zIndex: zIndex ?? "",
      fillOpacity: PolygonManager.defaultOptions.fillOpacity,
      strokeWidth: PolygonManager.defaultOptions.strokeWidth
    });

    return this;
  }

  setHoveredStyle(zIndex?: number) {
    this.setOptionsInGeoObject({
      fillColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].hovered.fill,
      strokeColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].hovered.stroke,
      zIndex,
      fillOpacity: 0.45,
      strokeWidth: 4
    });

    return this;
  }

  setDisabledStyle() {
    this.setOptionsInGeoObject({
      fillColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].inactive.fill,
      strokeColor: PolygonManager.defaultColors[this.objectType || ObjectType.Zone].inactive.stroke,
      draggable: false,
      zIndex: PolygonManager.defaultOptions.zIndex,
      fillOpacity: PolygonManager.defaultOptions.fillOpacity,
      strokeWidth: PolygonManager.defaultOptions.strokeWidth
    });
    return this;
  }

  getCoordinatesToPan() {
    return this.getCenter();
  }

  getCenter(): number[] {
    return [this.object?.center?.lattitude || 0, this.object?.center?.longitude || 0];
  }

  stopChanging(isDisabledStyle = false) {
    this.stopDrawing(isDisabledStyle);
    this.stopEditing(isDisabledStyle);
    return this;
  }

  startEditing(isEnabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.startEditing();
      this.setDraggable();
      if (isEnabledStyle) this.setEnabledStyle();
      this.setOptionsInGeoObject({
        zIndex: PolygonManager.overOtherObjectsStyle.zIndex,
        draggable: true
      });
    }
    return this;
  }

  stopEditing(isDisabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.stopEditing();
      this.setDraggable(false);
      if (isDisabledStyle) this.setDisabledStyle();
      this.setOptionsInGeoObject({ zIndex: "" });
    }
    return this;
  }

  startDrawing(isEnabledStyle = true) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.startDrawing();
      this.setDraggable();
      if (isEnabledStyle) this.setEnabledStyle();
    }
    return this;
  }

  stopDrawing(isDisabledStyle = false) {
    if (!this.ref) {
      console.warn("ref is null");
    } else {
      this.ref?.editor.stopDrawing();
      if (isDisabledStyle) this.setDisabledStyle();
    }
    return this;
  }

  startCreating(isEnabledStyle = true) {
    this.startDrawing(isEnabledStyle);
    this.startEditing();
    return this;
  }

  finishCreating(isDisabledStyle = false) {
    this.stopEditing();
    this.stopDrawing(isDisabledStyle);
    return this;
  }
}
