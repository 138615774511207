import { useCallback, useState } from "react";

export default function useSwitches(
  initialValue = false
): [boolean, VoidFunction, VoidFunction, VoidFunction] {
  const [value, setValue] = useState(initialValue);

  const switchOn = useCallback(() => setValue(true), [setValue]);
  const switchOff = useCallback(() => setValue(false), [setValue]);

  const toggle = useCallback(() => setValue((currentValue) => !currentValue), [setValue]);

  return [value, switchOn, switchOff, toggle];
}
