import { TableBody, TableCell, TableRow } from "@mui/material";
import { Sum } from "api/generated";

export interface SumReportTableBodyProps {
  rows?: Sum[];
  getTableRowNumber?: (index: number) => number;
}

const SumReportTableBody = ({
  rows,
  getTableRowNumber = (index) => index + 1
}: SumReportTableBodyProps) => {
  const formatTotalSum = (totalSum = 0) => totalSum.toFixed(2);

  return (
    <TableBody>
      {rows?.map(({ name, totalSum }, index) => (
        <TableRow key={name}>
          <TableCell>{getTableRowNumber(index)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{formatTotalSum(totalSum)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SumReportTableBody;
