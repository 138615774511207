import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  SystemSettingsConfigCurrency,
  TariffRuleGroupRule,
  TariffTariffPeriods,
  VehicleType
} from "api/generated";
import { DayTypeProps, dayType, vehicleTypeNames } from "pages/Tariffs/const";
import { useTranslation } from "react-i18next";
import { printTariff, printTime } from "./utils";

export type RulesData = {
  start?: string;
  end?: string;
  rules?: {
    [K in VehicleType]?: TariffRuleGroupRule[][];
  };
};

export type PeriodTableProps = {
  currency: SystemSettingsConfigCurrency;
  period: TariffTariffPeriodKeys;
  vehicleTypes: VehicleType[];
  data: RulesData[];
};

export type TariffTariffPeriodKeys = keyof TariffTariffPeriods;
export type DayTypeKeys = keyof DayTypeProps;

const PeriodTable = ({ period, vehicleTypes, data, currency }: PeriodTableProps) => {
  const { t } = useTranslation();

  return (
    <TableContainer sx={{ "&:not(:last-of-type)": { marginBottom: 2 } }}>
      <Table aria-label='tariff-table'>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 150, padding: 1, fontSize: "1rem", fontWeight: "bold" }}>
              {t(dayType[period])}
            </TableCell>
            {vehicleTypes.map((type, index) => (
              <TableCell
                key={type}
                sx={{
                  minWidth: 150,
                  width: index < vehicleTypes.length - 1 ? 357 : "auto",
                  padding: 1,
                  fontSize: "1rem",
                  fontWeight: "bold"
                }}
              >
                {t(vehicleTypeNames[type || VehicleType.VEHICLE_TYPE_UNSPECIFIED])}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(({ start, end, rules }, index) => {
            return (
              <TableRow key={index}>
                <TableCell sx={{ border: 0, padding: 1, fontSize: "1rem" }}>
                  {start && end && printTime(start, end, t)}
                </TableCell>
                {vehicleTypes.map((type) =>
                  rules?.[type]?.map((rule, i) => (
                    <TableCell key={i} sx={{ border: 0, padding: 1, fontSize: "1rem" }}>
                      {rule && Number(rule?.length) > 1 && (
                        <Typography>{t("pages.Tariffs.dynamic_tariffication")}</Typography>
                      )}
                      <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {printTariff(rule || [], currency, t, true)}
                      </Typography>
                    </TableCell>
                  ))
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PeriodTable;
