import SwitchMui, { SwitchProps as SwitchPropsMui } from "@mui/material/Switch";
import { Control, Controller } from "react-hook-form";

export type SwitchProps = {
  // Required Props
  control: Control<any>;
  name: string;

  // Optional Props
  rules?: object;
  defaultValue?: boolean;
} & SwitchPropsMui;

const Switch = ({ control, name, defaultValue, rules, inputRef, ...rest }: SwitchProps) => {
  return (
    <Controller
      defaultValue={defaultValue ? defaultValue : false}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, onChange, ...restField } }) => (
        <SwitchMui
          {...restField}
          {...rest}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          inputRef={($ref) => {
            if (inputRef) {
              if (inputRef instanceof Function) inputRef($ref);
              else console.warn(`inputRef prop in Input with name "${name}" is not a function`);
            }
            return ref($ref);
          }}
        />
      )}
    />
  );
};

export default Switch;
