import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { Paths } from "routes/const";
import { StyledLinkButton } from "./components/styled";
import Calendar from "./components/Calendar";
import { useMemo, useState } from "react";
import { useGetHolidaysByYearQuery } from "hooks/queries/holidays";
import { Holiday } from "api/generated";
import HolidayModal from "./components/HolidayModal";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { getNowUtc } from "utils/date";

export type HandleDateClick = (date: Date, holiday?: Holiday | null) => () => void;

const getYear = (year?: string) => +(year || (getNowUtc().getFullYear() as number));

const HolidaysItem = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [holiday, setHoliday] = useState<Holiday | null>(null);

  const { year = "" } = useParams();
  const { data, isLoading } = useGetHolidaysByYearQuery({
    start: `${new Date(Date.UTC(getYear(year), 0, 1, 0, 0, 0)).valueOf()}`,
    end: `${new Date(Date.UTC(getYear(year), 11, 31, 23, 59, 59, 999)).valueOf()}`
  });

  const holidays = data?.data?.holidays || [];

  const calendarDates = useMemo(
    () => Array.from({ length: 12 }, (_, index) => new Date(Date.UTC(getYear(year), index, 1))),
    [year]
  );

  const handleDateClick: HandleDateClick =
    (date, holiday = null) =>
    () => {
      setIsModalOpen(true);
      setDate(date);
      setHoliday(holiday);
    };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDate(null);
    setHoliday(null);
  };

  const canCreateOrUpdate = can(holiday?.uuid ? "update" : "create", "Holiday");

  return (
    <Box display='flex' height='100%' flexDirection='column' data-testid='HolidaysItem'>
      {isLoading ? (
        <Typography padding={1} variant='h6' data-testid='HolidaysItem__loading-text'>
          {t("pages.HolidaysCalendar.list.loading_holidays")}
        </Typography>
      ) : (
        <Card>
          <CardHeader
            subheader={
              <>
                <Box display='flex' flexWrap='nowrap' alignItems='center'>
                  <Typography padding={1} variant='h5' data-testid='HolidaysItem__page-title'>
                    {t("pages.HolidaysCalendar.item.holiday_calendar_for_year").replace(
                      "{year}",
                      year
                    )}
                  </Typography>
                  <StyledLinkButton
                    variant='contained'
                    LinkComponent={Link}
                    to={Paths.CalendarYears}
                    data-testid='HolidaysItem__back-to-list-button'
                  >
                    {t("pages.HolidaysCalendar.item.back_to_list")}
                  </StyledLinkButton>
                </Box>
                <Divider sx={{ marginTop: 2 }} />
              </>
            }
          ></CardHeader>
          <CardContent>
            <Box display='flex' flexWrap='wrap' data-testid='HolidaysItem__calendar-container'>
              {calendarDates.map((date, index) => (
                <Calendar
                  key={index}
                  canCreateOrUpdate={canCreateOrUpdate}
                  currentDate={date}
                  holidays={holidays}
                  onDateClick={handleDateClick}
                />
              ))}
            </Box>
          </CardContent>
          <HolidayModal
            open={isModalOpen}
            onClose={handleCloseModal}
            date={date}
            holiday={holiday}
          />
        </Card>
      )}
    </Box>
  );
};

export default HolidaysItem;
