import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { ZONES_KEY } from ".";

export const useGetZonesQuery = (
  { limit = 0, offset = 0 }: { limit: number; offset: number } = { limit: 0, offset: 0 }
) =>
  useQuery([ZONES_KEY, limit, offset], () => requests.adminGatewayZones({ limit, offset }), {
    keepPreviousData: true
  });
