import { SystemSettingsConfigCurrency, Tariff } from "api/generated";
import { useTranslation } from "react-i18next";
import { Box, CardActions, CardContent } from "@mui/material";
import Card from "components/Card";
import Header from "../Header";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import { useForm } from "react-hook-form";
import { FormEvent, useEffect } from "react";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import { CreatedAt, Periods, Scope } from "pages/Tariffs/components";
import { useUpdateTariffMutation } from "hooks/queries/tariffs/useUpdateTariffMutation";

export type TariffType = Tariff & {
  currency: SystemSettingsConfigCurrency;
  isInList?: boolean;
  isLoading?: boolean;
};

type DefaultValues = {
  name: Tariff["name"];
  description: Tariff["description"];
};

const defaultValues: DefaultValues = {
  name: {},
  description: {}
};

const CardForm = ({
  uuid,
  name,
  createdAt,
  description,
  zones,
  isLoading: isLoadingProp,
  periods,
  ruleGroups,
  currency
}: TariffType) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit: onSubmit
  } = useForm<DefaultValues>({
    defaultValues
  });

  const { mutate, isLoading: isMutating } = useUpdateTariffMutation(uuid);

  const handleSubmit = (e: FormEvent) => onSubmit((values) => mutate(values))(e);

  const handleCancel = () => {
    reset({
      name,
      description
    });
  };

  useEffect(() => {
    reset({
      name,
      description
    });
  }, [name, description]);

  const isLoading = isLoadingProp || isMutating;

  return (
    <Card>
      <CardContent>
        <Header title={t("pages.Tariffs.item.editing_tariff")} />
        <form onSubmit={handleSubmit}>
          <Box mt={3}>
            <MultiLangInput
              control={control}
              name='name'
              label={`${t("pages.Tariffs.title")}:`}
              variant='outlined'
              disabled={isLoading}
              langsAlwaysOpen
              required
            />
          </Box>

          <Box mt={3}>
            <MultiLangInput
              control={control}
              name='description'
              label={`${t("pages.Tariffs.description")}:`}
              variant='outlined'
              disabled={isLoading}
              langsAlwaysOpen
              multiline
              required
            />
          </Box>

          <Scope mt={3} zones={zones} />

          <CreatedAt createdAt={createdAt} />

          <Periods mt={2} periods={periods} ruleGroups={ruleGroups} currency={currency} />
        </form>
      </CardContent>
      <CardActions>
        <ConfirmCancelPanel
          stackProps={{ sx: { justifyContent: "stretch", marginTop: 1 } }}
          onConfirmProps={{ type: "submit", disabled: isLoading }}
          onCancelProps={{ disabled: isLoading }}
          confirmLabel={isMutating ? t("common.saving") : t("common.save")}
          onConfirm={handleSubmit}
          onCancel={handleCancel}
        />
      </CardActions>
    </Card>
  );
};

export default CardForm;
