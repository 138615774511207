import { DateTime } from "luxon";

export const getApiContractDate = (date?: DateTime | null): string | undefined =>
  date
    ?.setZone("Asia/Almaty")
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toUTC()
    .toISO() || undefined;

export const getApiLicenseExpiry = (date?: DateTime | null): string | undefined =>
  date
    ?.setZone("Asia/Almaty")
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    .toUTC()
    .toISO() || undefined;

export const parseApiDate = (dateString?: string) => {
  const dateNumber = dateString ? parseInt(dateString) : NaN;

  if (isNaN(dateNumber) || dateNumber < 0) {
    return null;
  }

  return DateTime.fromMillis(dateNumber, { zone: "Asia/Almaty" });
};
