import { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useMemo } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { useConfigContext } from "./ConfigContext";
import { Language } from "api/generated";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";

export type LocalizationContextValue = {
  getValueByLang: <T = string>(
    obj?: Record<string, T | undefined>,
    shouldGetAnyAvailable?: boolean
  ) => T;
  currentLang: string;
  langCodes: string[];
  setLang: (lang: string) => ReturnType<typeof changeLanguage>;
  availableLanguages: Language[];
};

const LocalizationContext = createContext<LocalizationContextValue>({
  getValueByLang<T = string>() {
    console.warn("getValueByLangs is not implemented");
    return "" as T;
  },
  currentLang: "",
  langCodes: [],
  setLang: () => {
    console.warn("setCurrentLang is not implemented");
    return Promise.reject();
  },
  availableLanguages: []
});

export const LocalizationProvider = ({ children }: { children: ReactNode }) => {
  const { config } = useConfigContext();
  const { i18n } = useTranslation();
  const { availableLanguages = [] } = config || {};

  const langCodes = useMemo(
    () => availableLanguages.map(({ shortCode }) => shortCode).filter(Boolean) as string[],
    [availableLanguages]
  );

  const [storedLang, setStoredLang] = useLocalStorage<string>(
    "lang",
    config?.availableLanguages?.[0]?.shortCode || "ru"
  );

  const setLang = useCallback((lang: string) => {
    setStoredLang(lang);
    return i18n.changeLanguage(lang);
  }, []);

  const getValueByLang = useCallback<LocalizationContextValue["getValueByLang"]>(
    (obj: Record<string, any> = {}, shouldGetAnyAvailable = false) => {
      const value = obj[storedLang];

      if (value === undefined && shouldGetAnyAvailable) {
        for (const langCode of langCodes) {
          if (obj[langCode] !== undefined) {
            return obj[langCode];
          }
        }
      }

      return value;
    },
    [availableLanguages, storedLang]
  );

  const value = useMemo(
    () => ({ currentLang: storedLang, getValueByLang, langCodes, availableLanguages, setLang }),
    [availableLanguages, storedLang]
  );

  useLayoutEffect(() => {
    if (storedLang !== i18n.language) {
      setLang(storedLang);
    }
  }, []);

  return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
};

export const useLocalizationContext = () => {
  const context = useContext(LocalizationContext);

  if (context === undefined) {
    throw new Error("useLocalizationContext must be used within a LocalizationContext");
  }

  return context;
};
