import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { useCallback } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { BenefitsGrant, BenefitsGrantsRequest } from "api/generated";
import SearchBar from "../SearchBar";
import ActionGrantCell from "../ActionGrantCell";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import RefetchTableDataButton from "components/RefetchTableDataButton";
import Pagination from "components/Pagination";
import { formatUtcDate } from "utils/date";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<BenefitsGrantsRequest>;
  isLoading: boolean;
  isFetching: boolean;
  isInitialLoading: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  grants?: BenefitsGrant[];
  onQueryChange: (query: string) => void;
  query: string;
  total?: number;
};

const GrantTable = ({
  setValue,
  isLoading,
  isFetching,
  isInitialLoading,
  refetch,
  grants,
  control,
  onQueryChange,
  query,
  total
}: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const doSearch = useDebouncedCallback(
    useCallback(
      (value: string) => {
        onQueryChange(value);
      },
      [refetch, query]
    ),
    300
  );

  const handleSearch = (value: string) => {
    setValue("query", value);
    doSearch(value);
  };

  const refreshTable = () => refetch();

  const shouldShowTable = !isInitialLoading && Array.isArray(grants);

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
        <SearchBar
          placeholder={t("pages.Benefits.Grants.list.search_by_srp")}
          control={control}
          onSearch={handleSearch}
        />
        <RefetchTableDataButton
          refetch={refreshTable}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Box>
      {shouldShowTable ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Benefits.Grants.list.srp_number")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Benefits.Grants.list.benefit")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Benefits.Grants.list.validity")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Benefits.Grants.list.created_at")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("common.zones")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("common.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grants?.map((grant) => (
                  <TableRow key={grant.uuid?.value}>
                    <TableCell sx={{ padding: 1 }}>{grant.vrp}</TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {getValueByLang(grant.benefit?.name, true)}
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Box display='flex' flexWrap='wrap'>
                        <span>
                          {t("pages.Benefits.Grants.list.from")}{" "}
                          {grant.start &&
                            formatUtcDate(new Date(+(grant.start || 0)), "dd.MM.yyyy")}
                          &nbsp;
                        </span>
                        <span>
                          {t("pages.Benefits.Grants.list.to")}{" "}
                          {grant.end && formatUtcDate(new Date(+(grant.end || 0)), "dd.MM.yyyy")}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {grant.createdAt && grant.createdAt.length === 13
                        ? formatUtcDate(new Date(+(grant.createdAt || 0)), "dd.MM.yyyy")
                        : t("pages.Benefits.Grants.list.not_specified")}
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {!grant.zones?.length
                        ? t("pages.Benefits.Grants.list.all")
                        : grant.zones?.join(", ")}
                    </TableCell>
                    <ActionGrantCell uuid={grant.uuid} vrp={grant.vrp} name={grant.benefit?.name} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination control={control} total={+(total || 0)} setValue={setValue} />
        </>
      ) : isLoading || isFetching ? (
        <Typography padding={1} variant='h6'>
          {t("pages.Benefits.Grants.list.loading_grants")}
        </Typography>
      ) : (
        <Typography padding={1} variant='h6'>
          {t("pages.Benefits.Grants.list.no_grants_found")}
        </Typography>
      )}
    </>
  );
};

export default GrantTable;
