import { /* City, Country, */ Parking } from "api/generated";
import { TableBodyCell } from "../styled";
import { Link } from "react-router-dom";
import LinkMui from "@mui/material/Link";
import { PARKINGS_VIEW_ROUTE } from "routes/route-declarations";
import { useLocalizationContext } from "context/LocalizationContext";

// const countries = {
//   [Country.COUNTRY_RUSSIA]: "Россия",
//   [Country.COUNTRY_UNSPECIFIED]: "Не указано"
// };
//
// const cities = {
//   [City.CITY_SOCHI]: "Сочи",
//   [City.CITY_UNSPECIFIED]: "Не указано"
// };

// const cities = {
//   [City.CITY_SOCHI]: "Сочи",
//   [City.CITY_UNSPECIFIED]: "Не указано"
// };

const ParkingCell = ({ /* address,*/ name, uuid }: Parking) => {
  const { getValueByLang } = useLocalizationContext();
  return (
    <TableBodyCell>
      <LinkMui
        width='100%'
        display='block'
        component={Link}
        to={PARKINGS_VIEW_ROUTE.replace(":id", uuid?.value || "")}
      >
        {getValueByLang(name)}
      </LinkMui>
      &#13;&#10;
      {/* {[*/}
      {/*  countries[address?.country || Country.COUNTRY_UNSPECIFIED],*/}
      {/*  cities[address?.city || City.CITY_UNSPECIFIED],*/}
      {/*  address?.street?.ru || address?.street?.en,*/}
      {/*  address?.house?.ru || address?.house?.en*/}
      {/* ]*/}
      {/*  .filter(Boolean)*/}
      {/*  .join(", ")}*/}
    </TableBodyCell>
  );
};

export default ParkingCell;
