import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ListItemMui from "@mui/material/ListItem";

import { SystemSettingsConfigCurrency, Tariff } from "api/generated";
import { Paths } from "routes/const";
import Card from "components/Card";
import { CardContent, Typography } from "@mui/material";
import { useLocalizationContext } from "context/LocalizationContext";
import { CreatedAt, Description, Scope, Periods } from "../../../components";

export type TariffShortCardProps = Tariff & { currency: SystemSettingsConfigCurrency };

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  width: "100%"
}));

const ListItem = ({
  name,
  uuid,
  createdAt,
  periods,
  currency,
  description,
  zones,
  ruleGroups
}: TariffShortCardProps) => {
  const { getValueByLang } = useLocalizationContext();

  return (
    <ListItemMui disablePadding sx={{ "&:not(:last-of-type)": { marginBottom: 2 } }}>
      <StyledLink to={`${Paths.Tariffs}/${uuid?.value}`}>
        <Card>
          <CardContent>
            <Typography variant='h6' data-testid='tariff-name'>
              {getValueByLang(name)}
            </Typography>

            <CreatedAt mt={1} createdAt={createdAt} />
            <Description description={description} isInList />
            <Scope zones={zones} />
            <Periods mt={2} periods={periods} currency={currency} ruleGroups={ruleGroups} />
          </CardContent>
        </Card>
      </StyledLink>
    </ListItemMui>
  );
};

export default ListItem;
