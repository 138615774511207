import { useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import {
  BenefitsGrantsRequest,
  BenefitsGrantsRequestStatus,
  BenefitsResponse
} from "api/generated";
import { useGetBenefitsQuery } from "hooks/queries/benefits";
import Select from "ui-kit/form/Select";
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Paths } from "routes/const";
import LinkButton from "ui-kit/buttons/LinkButton";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const statusOptions = [
  {
    label: "pages.Benefits.Grants.list.grant_status_current",
    value: BenefitsGrantsRequestStatus.STATUS_CURRENT
  },
  {
    label: "pages.Benefits.Grants.list.grant_status_expired",
    value: BenefitsGrantsRequestStatus.STATUS_PAST
  },
  {
    label: "pages.Benefits.Grants.list.grant_status_future",
    value: BenefitsGrantsRequestStatus.STATUS_FUTURE
  },
  {
    label: "pages.Benefits.Grants.list.grant_status_all",
    value: BenefitsGrantsRequestStatus.STATUS_ALL
  }
];

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<BenefitsGrantsRequest>;
  getValues: UseFormGetValues<BenefitsGrantsRequest>;
  watch: UseFormWatch<BenefitsGrantsRequest>;
  status: BenefitsGrantsRequestStatus;
  onStatusChange: (value: BenefitsGrantsRequestStatus) => void;
};

const MenuProps = {
  PaperProps: {
    style: {
      width: 297
    }
  }
};

const SidebarGrants = ({ control, setValue, watch, status, getValues, onStatusChange }: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const lastSelectedRef = useRef("");
  const benefitUuids = watch("benefitUuids");
  const { isLoading, data } = useGetBenefitsQuery({});

  const benefits = (data as BenefitsResponse)?.benefits;

  const getButtonVariant = (
    value: BenefitsGrantsRequestStatus,
    name: BenefitsGrantsRequestStatus
  ) => (value === name ? "contained" : "outlined");

  const handleStatusChange = (statusArg: BenefitsGrantsRequestStatus) => () =>
    onStatusChange(statusArg);

  const handleAllSelect = () => {
    lastSelectedRef.current = "";
  };

  const handleSelectBenefit = (e: React.SyntheticEvent<HTMLLIElement>) => {
    lastSelectedRef.current = e.currentTarget.dataset.value || "";
  };

  const handleSelectBenefits = () => {
    const value = lastSelectedRef.current;
    if (value === "") {
      setValue("benefitUuids", [""]);
      return;
    }

    const currentValue = getValues("benefitUuids");

    let isValueInList = false;

    const newValue = (currentValue || []).filter((uuid: string) => {
      if (uuid === value) isValueInList = true;
      return uuid !== "" && uuid !== value;
    });

    if (!isValueInList) {
      newValue.push(value);
    }

    if (!newValue.length) {
      newValue.push("");
    }

    setValue("benefitUuids", newValue);
  };

  const getHelperText = () => {
    if (!benefitUuids?.length || benefitUuids?.[0] === "") {
      return "";
    }

    return `Всего выбрано: ${benefitUuids?.length}`;
  };

  const canCreate = can("create", "BenefitGrant");

  return (
    <Box display='flex' flexWrap='wrap'>
      {canCreate && (
        <LinkButton fullWidth variant='contained' to={`${Paths.Benefits}${Paths.Grants}/add`}>
          {t("pages.Benefits.Grants.list.add_grant")}
        </LinkButton>
      )}
      <Box width='100%' marginTop={canCreate ? 2 : 0}>
        <FormControl>
          <FormLabel>{t("pages.Benefits.Grants.list.status")}:</FormLabel>
          <Box>
            {statusOptions.map((option) => (
              <Button
                key={option.value}
                sx={{ marginRight: 1, marginTop: 1 }}
                variant={getButtonVariant(status, option.value)}
                onClick={handleStatusChange(option.value)}
              >
                {t(option.label)}
              </Button>
            ))}
          </Box>
        </FormControl>
      </Box>
      <Box width='100%' marginTop={2}>
        <FormLabel>{t("pages.Benefits.Grants.list.benefits")}:</FormLabel>
        <FormControl sx={{ width: "100%" }}>
          <Select
            sx={{ marginTop: "8px" }}
            multiple
            multiline
            fullWidth
            disabled={isLoading}
            control={control}
            placeholder={t("pages.Benefits.Grants.list.unimportant")}
            name='benefitUuids'
            onChange={handleSelectBenefits}
            helperText={getHelperText()}
            MenuProps={MenuProps}
            renderValue={(value) => {
              const _value = value as unknown as string[];
              if (_value.includes("")) return t("pages.Benefits.Grants.list.unimportant");

              return (
                benefits
                  ?.filter((_benefit) => _value.includes(_benefit.uuid?.value || ""))
                  ?.map((_benefit) => _benefit?.name?.ru || _benefit?.name?.en)
                  ?.join(", ") || ""
              );
            }}
          >
            <MenuItem onClick={handleAllSelect} value=''>
              <Checkbox checked={benefitUuids?.length === 1 && benefitUuids[0] === ""} />
              {isLoading ? t("common.loading") : t("pages.Benefits.Grants.list.unimportant")}
            </MenuItem>
            {benefits?.map((benefit) => (
              <MenuItem
                onClick={handleSelectBenefit}
                sx={{ whiteSpace: "normal" }}
                key={benefit?.uuid?.value}
                value={benefit?.uuid?.value}
              >
                <Checkbox
                  checked={(benefitUuids?.indexOf(benefit?.uuid?.value || "") ?? -1) > -1}
                />
                <ListItemText primary={getValueByLang(benefit.name)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SidebarGrants;
