import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { StartType, GetZonesResponse } from "api/generated";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { BaseSyntheticEvent, ChangeEventHandler, useEffect, useMemo, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Autocomplete from "ui-kit/form/Autocomplete/index";
import MultiLangInput, { getDefaultLangTemplate } from "ui-kit/form/MultiLangInput";
import { PERMISSIONS_ACTIVE_ROUTE } from "routes/route-declarations";
import LinkButton from "ui-kit/buttons/LinkButton";
import { DateTime } from "luxon";
import {
  getDurationInputRules,
  durationNames,
  validateDuration,
  handleDurationValidation,
  shouldAllowAutoUpdateName,
  defaultValues,
  numberInputRules,
  Props,
  defaultNames,
  allZonesValue
} from "./utils";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useGetZonesQuery } from "hooks/queries/zones";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useConfigContext } from "context/ConfigContext";
import Input from "ui-kit/form/Input";
import Select from "ui-kit/form/Select";
import { useGetSystemSettingsConfigQuery } from "hooks/queries/system-settings";
import { startTypes } from "../../List/components/ListItem";
import TimePicker from "ui-kit/form/TimePicker";

const { START_TYPE_FROMTOMORROW, START_TYPE_FROMTODAY, START_TYPE_FROMYESTERDAY } = StartType;

const Form = ({ permission, submit, isLoading, isEditType }: Props) => {
  const { t } = useTranslation();
  const {
    data: { currency }
  } = useGetSystemSettingsConfigQuery();

  const { can } = useAppAbility();
  const navigate = useNavigate();
  const { openErrorAlert } = useAlertStatus();
  const isNameAutoUpdateAllowed = useRef(true);

  const { config } = useConfigContext();
  const { availableLanguages = [] } = config || {};

  const allZones = t(allZonesValue);

  const { isLoading: isZonesLoading, data: zonesData } = useGetZonesQuery({
    limit: 0,
    offset: 0
  });
  const zones: string[] = useMemo(
    () =>
      [allZones].concat(
        (zonesData?.data as GetZonesResponse)?.zones?.map((zone) => zone.number as string) ?? []
      ),
    [zonesData?.data, isZonesLoading]
  );

  const weeksData: { day: number; title: string }[] = [
    { day: 1, title: t("pages.Permissions.Active.item.mon") },
    { day: 2, title: t("pages.Permissions.Active.item.tue") },
    { day: 3, title: t("pages.Permissions.Active.item.wed") },
    { day: 4, title: t("pages.Permissions.Active.item.thu") },
    { day: 5, title: t("pages.Permissions.Active.item.fri") },
    { day: 6, title: t("pages.Permissions.Active.item.sat") },
    { day: 7, title: t("pages.Permissions.Active.item.sun") }
  ];
  const weeks: number[] = useMemo(() => weeksData.map((w) => w.day), [weeksData]);

  const initialValues = useMemo(() => {
    if (permission) {
      isNameAutoUpdateAllowed.current = shouldAllowAutoUpdateName(
        permission.name,
        permission?.duration
      );

      const { duration, dayOfWeeks, zones, start, end, ...restPermission } = permission;
      return {
        ...restPermission,
        start,
        end,
        zones: zones?.length ? zones : [allZones],
        dayOfWeeks: dayOfWeeks,
        duration: {
          years: duration?.years ? `${duration.years}` : "",
          months: duration?.months ? `${duration.months}` : "",
          days: duration?.days ? `${duration.days}` : ""
        }
      };
    }

    return {
      ...defaultValues,
      name: config?.availableLanguages?.reduce((acc, { shortCode }) => {
        if (shortCode) acc[shortCode] = defaultNames.en;
        if (shortCode === "ru") {
          acc[shortCode] = defaultNames.ru;
        }
        return acc;
      }, {} as Record<string, string>)
    };
  }, [permission, config]);

  const {
    handleSubmit: onSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    trigger,
    reset
  } = useForm<typeof defaultValues>({
    defaultValues: initialValues
  });

  const formState = useFormState({ control });

  const langs = useMemo(() => {
    const handleNameChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
      isNameAutoUpdateAllowed.current = false;

      const name = e.target.name as `name.${string}`;
      setValue(name, e.target.value);

      if (formState.isSubmitted) trigger(name);
    };

    return availableLanguages.map(({ shortCode }) => {
      const template = getDefaultLangTemplate({ shortCode });
      if (template.inputProps) template.inputProps.onChange = handleNameChanged;
      return template;
    });
  }, [availableLanguages]);

  const handleChangeZone = (
    _e: BaseSyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
    newValue?: { option: string }
  ) => {
    if (
      reason === "clear" ||
      (reason === "removeOption" && (newValue?.option === allZones || !value.length))
    ) {
      return [allZones];
    }

    if (newValue?.option === allZones) {
      if (value.includes(allZones)) {
        return [allZones];
      } else {
        return value.filter((_value: string) => _value !== allZones);
      }
    }
    return value.filter((_value: string) => _value !== allZones);
  };

  const handleDurationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.name as typeof durationNames[number];
    const value = e.target.value;

    if (value.length > 4) return;

    setValue(name, value);
    const durationValues = getValues(durationNames);

    const [message, targetName] = validateDuration(
      value,
      durationValues.map((durationValue) => `${durationValue}`),
      name,
      t
    );

    handleDurationValidation(
      message as string,
      targetName,
      {
        errors: formState.errors,
        setError,
        clearErrors
      },
      t
    );
  };

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit(
      (formValues) => {
        const {
          start,
          end,
          duration,
          zones,
          tarificationType,
          dayOfWeeks,
          price,
          name,
          startType
        } = formValues;

        return submit({
          name,
          startType,
          price: price || "0",
          dayOfWeeks,
          start:
            start && Number.isInteger(start)
              ? Math.floor(start / 1000)
              : Math.floor((start as any)?.toMillis() / 1000),
          end:
            end && Number.isInteger(end)
              ? Math.floor(end / 1000)
              : Math.floor((end as any)?.toMillis() / 1000),
          duration: {
            years: +(duration.years || "") || undefined,
            months: +(duration.months || "") || undefined,
            days: +(duration.days || "") || undefined
          },
          /** Тип тарификации */
          tarificationType: tarificationType,
          zones: zones?.includes(allZones) ? [] : zones
        }).then((res) => {
          navigate(PERMISSIONS_ACTIVE_ROUTE);
          return res;
        });
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);

  const startTime = DateTime.now().setLocale("ru").set({ hour: 0, minute: 0 });
  const endTime = DateTime.now().setLocale("ru").set({ hour: 23, minute: 59 });

  useEffect(() => {
    if (permission || availableLanguages) reset(initialValues);
    if (permission) {
      initialValues?.start && setValue("start", initialValues.start * 1000);
      initialValues?.end && setValue("end", initialValues.end * 1000);
    } else {
      !getValues("start") && setValue("start", startTime.toMillis());
      !getValues("end") && setValue("end", endTime.toMillis());
    }
  }, [initialValues]);

  const isPermissionLoading = isEditType && !permission && isLoading;
  const canUpdateOrCreate = can("update", "Permission") || can("create", "Permission");
  console.log("abonement", permission);
  return (
    <Box component='form' onSubmit={handleSubmit} position='relative'>
      {isPermissionLoading && (
        <Box
          display='flex'
          height='100%'
          width='100%'
          position='absolute'
          zIndex={1}
          top={0}
          left={0}
          sx={{
            backgroundColor: "rgba(255, 255, 255, .3)"
          }}
        >
          <Box margin='auto'>
            <CircularProgress />
          </Box>
        </Box>
      )}
      <Box width='100%' marginTop={2}>
        <MultiLangInput
          label={`${t("pages.Permissions.Active.item.title")}:`}
          control={control}
          name='name'
          readOnly={!canUpdateOrCreate}
          required
          langsAlwaysOpen
          disabled={isLoading}
          langs={langs}
          variant='outlined'
        />
      </Box>
      {!permission?.currentUsesCount && !permission?.isDeleted && !isPermissionLoading && (
        <>
          <Box width='100%' marginTop={2}>
            <FormControl fullWidth>
              <FormLabel required>{t("pages.Permissions.Active.item.price")}:</FormLabel>
              <Input
                control={control}
                name='price'
                disabled={isLoading}
                rules={{
                  required: { value: true, message: t("common.input_rules.required") },
                  ...numberInputRules
                }}
                inputProps={{
                  readOnly: !canUpdateOrCreate
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography fontSize='2rem'>{currency?.symbol}</Typography>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Box>
          <Box width='100%' marginTop={2}>
            <FormControl fullWidth>
              <FormLabel required>{t("pages.Permissions.Active.item.days_of_week")}:</FormLabel>
              <Autocomplete
                control={control}
                name='dayOfWeeks'
                readOnly={!canUpdateOrCreate}
                options={weeks}
                getOptionLabel={(option) => {
                  let weeksLabel = "";
                  weeksData?.find((w) => {
                    w.day?.toString() === option.toString() && (weeksLabel = w.title);
                  });
                  return weeksLabel;
                }}
                variant='outlined'
                multiple
                required
                disableCloseOnSelect
                disabled={isLoading}
                rules={{
                  required: { value: true, message: t("common.input_rules.required") }
                }}
              />
            </FormControl>
          </Box>
        </>
      )}
      {!permission?.currentUsesCount && !permission?.isDeleted && !isPermissionLoading && (
        <>
          <Box display='flex' flexWrap='nowrap' marginTop={2}>
            <Box width='100%' marginRight={2}>
              <FormControl fullWidth>
                <FormLabel required>
                  {t("pages.Permissions.Active.item.work_hours_start")}:
                </FormLabel>
                <TimePicker
                  control={control}
                  name='start'
                  value={
                    getValues("start") && Number.isInteger(getValues("start"))
                      ? DateTime.fromMillis(getValues("start") as number)
                      : startTime
                  }
                  defaultValue={startTime}
                  disabled={isLoading}
                  rules={{
                    required: { value: true, message: t("common.input_rules.required") }
                  }}
                />
              </FormControl>
            </Box>
            <Box width='100%'>
              <FormControl fullWidth>
                <FormLabel required>{t("pages.Permissions.Active.item.work_hours_end")}:</FormLabel>
                <TimePicker
                  control={control}
                  name='end'
                  value={
                    getValues("end") && Number.isInteger(getValues("end"))
                      ? DateTime.fromMillis(getValues("end") as number)
                      : endTime
                  }
                  defaultValue={endTime}
                  disabled={isLoading}
                  rules={{
                    required: { value: true, message: t("common.input_rules.required") }
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </>
      )}
      {!permission?.currentUsesCount && !permission?.isDeleted && !isPermissionLoading && (
        <>
          <Box display='flex' marginTop={2}>
            <Box width='33%'>
              <FormControl fullWidth>
                <FormLabel>{t("pages.Permissions.Active.item.number_of_years")}:</FormLabel>
                <Input
                  control={control}
                  name='duration.years'
                  inputProps={{
                    readOnly: !canUpdateOrCreate
                  }}
                  disabled={isLoading}
                  rules={getDurationInputRules("duration.years", getValues, t)}
                  onChange={handleDurationChange}
                />
              </FormControl>
            </Box>
            <Box width='33%' marginX={2}>
              <FormControl fullWidth>
                <FormLabel>{t("pages.Permissions.Active.item.number_of_months")}:</FormLabel>
                <Input
                  control={control}
                  name='duration.months'
                  inputProps={{
                    readOnly: !canUpdateOrCreate
                  }}
                  disabled={isLoading}
                  rules={getDurationInputRules("duration.months", getValues, t)}
                  onChange={handleDurationChange}
                />
              </FormControl>
            </Box>
            <Box width='33%'>
              <FormControl fullWidth>
                <FormLabel>{t("pages.Permissions.Active.item.number_of_days")}:</FormLabel>
                <Input
                  control={control}
                  name='duration.days'
                  inputProps={{
                    readOnly: !canUpdateOrCreate
                  }}
                  disabled={isLoading}
                  rules={getDurationInputRules("duration.days", getValues, t)}
                  onChange={handleDurationChange}
                />
              </FormControl>
            </Box>
          </Box>
          <Box width='100%' marginTop={2}>
            <FormLabel required>
              {t("pages.Permissions.Active.item.grant_duration_start_after_purchase")}:
            </FormLabel>
            <FormControl fullWidth>
              <Select
                fullWidth
                disabled={isLoading}
                control={control}
                name='startType'
                readOnly={!canUpdateOrCreate}
                rules={{
                  required: { value: true, message: t("common.input_rules.required") }
                }}
              >
                <MenuItem value={START_TYPE_FROMYESTERDAY}>
                  {t(startTypes[START_TYPE_FROMYESTERDAY])}
                </MenuItem>
                <MenuItem value={START_TYPE_FROMTODAY}>
                  {t(startTypes[START_TYPE_FROMTODAY])}
                </MenuItem>
                <MenuItem value={START_TYPE_FROMTOMORROW}>
                  {t(startTypes[START_TYPE_FROMTOMORROW])}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box width='100%' marginTop={2}>
            <FormLabel>{t("pages.Permissions.common.tariffication_type")}:</FormLabel>
            <Typography>{t("pages.Permissions.common.discount_100")}</Typography>
          </Box>
        </>
      )}

      {!permission?.currentUsesCount && !permission?.isDeleted && !isPermissionLoading && (
        <>
          <Box display='flex' marginTop={2}>
            <FormControl fullWidth>
              <FormLabel required>{t("common.zones")}:</FormLabel>
              <Autocomplete<string>
                control={control}
                name='zones'
                options={zones}
                variant='outlined'
                multiple
                required
                disableCloseOnSelect
                disabled={isZonesLoading || isLoading}
                onChange={handleChangeZone}
                rules={{
                  required: { value: true, message: t("common.input_rules.required") }
                }}
              />
            </FormControl>
          </Box>
        </>
      )}

      {canUpdateOrCreate && (
        <>
          <Box display='flex' justifyContent='start' marginTop={2}>
            <Button
              onClick={(e) => handleSubmit(e)}
              disabled={isLoading}
              type='submit'
              variant='contained'
              sx={{ marginRight: 2 }}
            >
              {t("common.save")}
            </Button>
            <LinkButton disabled={isLoading} variant='outlined' to={PERMISSIONS_ACTIVE_ROUTE}>
              {t("common.cancel")}
            </LinkButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Form;
