import { SystemSettingsConfigCurrency, Tariff } from "api/generated";
import { useAppAbility } from "context/AbilityContext";
import CardForm from "../CardForm";
import CardView from "../CardView";

export type TariffType = Tariff & { short?: boolean; currency: SystemSettingsConfigCurrency };

const Card = (props: TariffType) => {
  const { can } = useAppAbility();

  if (can("update", "Tariff")) {
    return <CardForm {...props} />;
  }

  return <CardView {...props} />;
};

export default Card;
