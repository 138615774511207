import { BaseSyntheticEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "components/Card";
import { useForm } from "react-hook-form";
import {
  useEditSystemSettingsConfigMutation,
  useGetSystemSettingsConfigQuery
} from "hooks/queries/system-settings";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import Divider from "@mui/material/Divider";
import LangInputs from "./components/LangInputs";
import { Button } from "@mui/material";
import useSwitches from "hooks/useSwitches";
import DeleteModal from "./components/DeleteModal";
import { useTranslation } from "react-i18next";

export const notSelectedIndex = -1;

const AvailableLanguages = () => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen, setIsDeleteModalClose] = useSwitches(false);
  const [shouldDelete, setShouldDelete, setShouldNotDelete] = useSwitches(false);

  const [langIndexToDelete, setLangIndexToDelete] = useState<number>(notSelectedIndex);

  const { data, isLoading, isFetching } = useGetSystemSettingsConfigQuery();
  const { mutateAsync, isLoading: isSaving } = useEditSystemSettingsConfigMutation();

  const {
    watch,
    handleSubmit: onSubmit,
    control,
    reset,
    getValues,
    formState: { isSubmitted },
    trigger,
    setValue
  } = useForm({ defaultValues: data });

  const availableLanguages = watch("availableLanguages");

  const { openErrorAlert } = useAlertStatus();

  const handleAddLang = () => {
    setValue("availableLanguages", [
      ...(availableLanguages || []),
      {
        shortCode: "",
        localizedName: ""
      }
    ]);
  };

  const handleDeleteClick = (index: number) => {
    setLangIndexToDelete(index);
    setIsDeleteModalOpen();
  };

  const updateLangList = () => {
    setValue(
      "availableLanguages",
      availableLanguages?.filter((_, i) => i !== langIndexToDelete) || []
    );
  };

  const handleConfirmDeleting = () => {
    setShouldDelete();
    setIsDeleteModalClose();
  };

  const handleCancelDeleting = () => {
    setShouldNotDelete();
    setIsDeleteModalClose();
  };

  const handleIndexChange = (index: number, dir: number) => () => {
    const newAvailableLanguages = [...(getValues("availableLanguages") || [])];
    [newAvailableLanguages[index], newAvailableLanguages[index + dir]] = [
      newAvailableLanguages[index + dir],
      newAvailableLanguages[index]
    ];

    setValue("availableLanguages", newAvailableLanguages);
    if (isSubmitted) trigger([`availableLanguages.${index}`, `availableLanguages.${index + dir}`]);
  };

  const handleSubmit = (e: BaseSyntheticEvent) => {
    return onSubmit(
      async (values) => {
        mutateAsync(values);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);
  };

  const handleCancel = () => {
    reset(data);
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  const isAddButtonDisabled =
    !!availableLanguages &&
    availableLanguages.some(({ shortCode, localizedName }) => !shortCode || !localizedName);

  return (
    <Card>
      <Box marginX='-8px'>
        <Typography component='h2' variant='h5' padding='1rem'>
          {t("pages.SystemSettings.AvailableLanguages.available_langs_settings")}
        </Typography>
        <Divider />
      </Box>
      <CardContent>
        {isLoading ? (
          <Typography variant='h5'>{t("common.loading")}</Typography>
        ) : (
          <Box component='form' onSubmit={handleSubmit}>
            <Typography sx={{ marginBottom: 2 }}>
              {t("pages.SystemSettings.AvailableLanguages.description")}
            </Typography>
            {availableLanguages?.map((_, index) => (
              <LangInputs
                key={index}
                control={control}
                isLoading={isLoading}
                index={index}
                total={availableLanguages?.length || 0}
                onDelete={handleDeleteClick}
                onIndexChange={handleIndexChange}
              />
            ))}
            <Button disabled={isAddButtonDisabled} onClick={handleAddLang}>
              {t("pages.SystemSettings.AvailableLanguages.add_lang")}
            </Button>

            <DeleteModal
              isOpen={isDeleteModalOpen}
              onClose={setIsDeleteModalClose}
              shouldDelete={shouldDelete}
              updateLangList={updateLangList}
              setLangIndex={setLangIndexToDelete}
              langName={
                ~langIndexToDelete
                  ? getValues("availableLanguages")?.[langIndexToDelete]?.localizedName || ""
                  : ""
              }
              onConfirm={handleConfirmDeleting}
              onCancel={handleCancelDeleting}
            />

            <ConfirmCancelPanel
              stackProps={{ sx: { maxWidth: 300, marginTop: 3 } }}
              onConfirmProps={{ type: "submit", disabled: isLoading || isSaving || isFetching }}
              onCancelProps={{ disabled: isLoading || isSaving || isFetching }}
              confirmLabel={
                isSaving ? t("common.saving") : isFetching ? t("common.loading") : t("common.save")
              }
              onConfirm={handleSubmit}
              onCancel={handleCancel}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AvailableLanguages;
