import useSwitches from "hooks/useSwitches";

const isNotFunctionWarn = (name: string) => console.warn(`${name} is not a function`);
const checkFuncTypeAndWarn = (fn: unknown = undefined, name: string) =>
  fn !== undefined && isNotFunctionWarn(name);

type Params = {
  onConfirm: unknown;
  onCancel?: unknown;
  beforeOpen?: unknown;
  beforeClose?: unknown;
  onClose?: unknown;
  onOpen?: unknown;
  initialValue?: boolean;
};

export default function useDialog({
  initialValue = false,
  onConfirm,
  onCancel,
  beforeOpen,
  beforeClose,
  onOpen,
  onClose
}: Params) {
  const [isOpen, switchOn, switchOff] = useSwitches(initialValue);

  const handleCloseDialog = () => {
    if (beforeClose instanceof Function) beforeClose();
    else checkFuncTypeAndWarn(beforeClose, "beforeClose");

    switchOff();

    if (onClose instanceof Function) return onClose();
    checkFuncTypeAndWarn(onClose, "onClose");
  };
  const handleOpenDialog = () => {
    if (beforeOpen instanceof Function) beforeOpen();
    else checkFuncTypeAndWarn(beforeOpen, "beforeOpen");

    switchOn();

    if (onOpen instanceof Function) return onOpen();
    checkFuncTypeAndWarn(onOpen, "onOpen");
  };
  const handleConfirm = (...args: unknown[]) => {
    if (onConfirm instanceof Function) onConfirm(...args);
    else isNotFunctionWarn("onConfirm");
  };
  const handleCancel = (...args: unknown[]) => {
    if (onCancel instanceof Function) onCancel(...args);
    else if (onCancel) isNotFunctionWarn("onCancel");
    handleCloseDialog();
  };

  return {
    isOpen,
    openDialog: handleOpenDialog,
    closeDialog: handleCloseDialog,
    confirm: handleConfirm,
    cancel: handleCancel
  };
}
