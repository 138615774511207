import Box from "@mui/material/Box";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MultiLangInput from "ui-kit/form/MultiLangInput";

type Props = {
  control: Control<any>;
  required?: boolean;
  name?: string;
  isLoading?: boolean;
  isAddType?: boolean;
};

const AddressInput = ({ required, control, isLoading, isAddType, name = "address" }: Props) => {
  const { t } = useTranslation();
  return (
    <Box display='flex' data-testid='AddressInput'>
      <Box marginRight={0.5}>
        <MultiLangInput
          required={required}
          label={`${t("pages.ObjectManagement.Objects.item.street")}:`}
          name={`${name}.street`}
          control={control}
          langsAlwaysOpen={isAddType}
          disabled={isLoading}
        />
      </Box>
      <Box marginLeft={0.5}>
        <MultiLangInput
          required={required}
          label={`${t("pages.ObjectManagement.Objects.item.building")}:`}
          name={`${name}.house`}
          control={control}
          langsAlwaysOpen={isAddType}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default AddressInput;
