import { useLayoutEffect, useRef } from "react";
import { theme } from "./config/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { DateFnsProvider } from "./config/dateFnsProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/react-query";
import { SnackbarProvider } from "notistack";
import RootProvider from "./context";
import { interceptors } from "api";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";

let configResolve = (_?: unknown) => {
  console.warn("Resolve function is not initialized");
};

let configReject = (_?: unknown) => {
  console.warn("Reject function is not initialized");
};

const configRequestPromise = new Promise((res, rej) => {
  configResolve = res;
  configReject = rej;
});

const AllProviders = ({ children }: { children: React.ReactNode }) => {
  const requestInterceptorIdRef = useRef(0);
  const responseInterceptorIdRef = useRef(0);

  useLayoutEffect(() => {
    /**
     * Перехватчик запросов
     */
    requestInterceptorIdRef.current = interceptors.request.use(
      async (req) => {
        if (
          req.url !== "/v1/config" &&
          req.url !== "/v1/auth/login" &&
          req.url !== "/v1/auth/refresh"
        ) {
          // Ждём получения конфига
          await configRequestPromise;
        }

        return req;
      },
      (error) => {
        if (error.config.url === "/v1/config") configReject(error);
        return Promise.reject(error);
      }
    );

    /**
     * Перехватчик ответов на запросы.
     */
    responseInterceptorIdRef.current = interceptors.response.use(
      (res) => {
        if (res.config.url === "/v1/config") {
          configResolve(res);
        }

        return res;
      },
      (error) => {
        if (error.config.url === "/v1/config") configReject(error);
        return Promise.reject(error);
      }
    );

    return () => {
      interceptors.request.eject(requestInterceptorIdRef.current);
      interceptors.request.eject(responseInterceptorIdRef.current);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DateFnsProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <RootProvider>
              <Router>{children}</Router>
            </RootProvider>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DateFnsProvider>
    </ThemeProvider>
  );
};
export default AllProviders;
