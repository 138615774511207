import { FC } from "react";
import { useTranslation } from "react-i18next";
import DataPicker, { DatePickerProps } from "ui-kit/form/DataPicker";

type ContractDatePickerProps = Omit<DatePickerProps, "label" | "rules" | "inputFormat">;

const ContractDatePicker: FC<ContractDatePickerProps> = (props) => {
  const { t } = useTranslation();

  return (
    <DataPicker
      label={t("pages.LegalEntityBalance.contract_dialog.labels.contract_date")}
      rules={{
        required: {
          value: true,
          message: t("common.input_rules.required")
        }
      }}
      inputFormat='dd.MM.yyyy'
      {...props}
    />
  );
};

export default ContractDatePicker;
