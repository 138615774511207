import { FC } from "react";
import { Controller, Control } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps as DatePickerPropsMui } from "@mui/x-date-pickers/DatePicker";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { DateTime, Settings } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export type DatePickerProps = {
  control: Control<any>;
  name: string;
  inputFormat: string;
  label?: string;
  maxDate?: DateTime | null;
  minDate?: DateTime | null;
  inputProps?: TextFieldProps;
  rules?: object;
  helperText?: string;
  error?: { message: string };
  placeholder?: string;
  onChange?: (value: DateTime | null, valueStr?: string) => [DateTime | null, string | undefined];
} & Partial<
  Omit<DatePickerPropsMui<DateTime | null | undefined, DateTime | null>, "onFocus" | "onChange">
>;

Settings.defaultZone = "UTC";

const DataPicker: FC<DatePickerProps> = ({
  control,
  name,
  label,
  inputFormat,
  maxDate,
  minDate,
  rules,
  helperText,
  error,
  inputRef,
  onChange,
  placeholder,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='ru'>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, ...restField }, fieldState }) => {
          const _error = error || fieldState?.error;
          return (
            <>
              <DatePicker
                {...rest}
                {...restField}
                label={label}
                onChange={(value, valueStr) => {
                  let newValue = value;
                  let newValueStr = valueStr;

                  if (onChange) {
                    [newValue, newValueStr] = onChange(value, valueStr);
                  }

                  restField.onChange(newValue, newValueStr);
                }}
                inputRef={($ref) => {
                  if (inputRef) {
                    if (inputRef instanceof Function) inputRef($ref);
                    else
                      console.warn(` inputRef prop in Input with name "${name}" is not a function`);
                  }
                  return ref($ref);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder }}
                    error={!!_error}
                  />
                )}
                inputFormat={inputFormat}
                maxDate={maxDate}
                minDate={minDate}
              />
              <FormHelperText error={!!_error} sx={{ margin: 0, marginTop: "3px" }}>
                {_error ? _error?.message || "Invalid Input" : helperText}
              </FormHelperText>
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DataPicker;
