import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ReportApiFilters, ReportType } from "pages/Reports/types";
import { useTranslation } from "react-i18next";
import { convertStringToCsvContent } from "utils/convertStringToCsvContent";
import { downloadFileWithLink } from "utils/downloadFile";

export const LEGAL_REPORT_CSV_MUTATION_KEY = "legal_report_csv";

interface DownloadReportProps {
  reportType: ReportType;
  filters: ReportApiFilters;
}

const getReportCsvRequestFn = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.Legal:
      return requests.adminGatewayGetLegalOperationsToCsv.bind(requests);

    case ReportType.ParkinSessionsApp:
      return requests.adminGatewayGetOperationsToCsv.bind(requests);

    case ReportType.ParkingSessionsAll:
      return requests.adminGatewayGetB2BOperationsToCsv.bind(requests);

    case ReportType.Transactions:
      return requests.adminGatewayGetTransactionPaymentsToCsv.bind(requests);
  }
};

const downloadReport = async ({ reportType, filters }: DownloadReportProps) => {
  const requestFn = getReportCsvRequestFn(reportType);
  const response = await requestFn(filters);
  const csvString = response.data.csv || "";
  const csvContent = convertStringToCsvContent(csvString);
  downloadFileWithLink({
    content: csvContent,
    fileName: `${reportType}.csv`
  });
};

const useReportCsvMutation = () => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useMutation({
    mutationFn: downloadReport,
    onError() {
      openErrorAlert(t("pages.Reports.could_not_load_report"));
    }
  });
};

export default useReportCsvMutation;
