import { Control } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import SearchInput from "ui-kit/form/SearchInput";
import { TextFieldProps } from "@mui/material";

type SearchType = {
  placeholder: string;
  onSearch: (value: string) => void;
  control: Control<any>;
};

const SearchBar = ({ placeholder, onSearch, control }: SearchType) => {
  const handleSearch: TextFieldProps["onChange"] = (e) => {
    onSearch(e.target.value);
  };

  return (
    <FormControl
      sx={{
        width: {
          xs: "100%",
          sm: "auto"
        }
      }}
    >
      <SearchInput
        sx={{
          flexGrow: {
            xs: 1,
            sm: 0
          },
          minWidth: {
            xs: 0,
            sm: "400px"
          }
        }}
        onChange={handleSearch}
        label={placeholder}
        control={control}
        name='q'
      />
    </FormControl>
  );
};

export default SearchBar;
