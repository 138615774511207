import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { CreateSubCategoryRequest } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { EDIT_MUTATE_KEY } from "../action-keys";
import { GET_CATEGORIES_QUERY_KEY } from "../category";
import { SUB_CATEGORY_KEY, Params } from "./";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useEditSubCategoryMutation = ({ formInfo }: Pick<Params, "formInfo">) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, type, zoneType }: CreateSubCategoryRequest) =>
      requests.adminGatewayEditSubCategory((formInfo.uuid as { value: string }).value, {
        name,
        type,
        zoneType
      }),
    {
      mutationKey: [
        SUB_CATEGORY_KEY,
        EDIT_MUTATE_KEY,
        formInfo.uuid?.value,
        formInfo.parentUuid?.value
      ],
      onSuccess() {
        openSuccessAlert(t("pages.ObjectManagement.Categories.item.subcategory_edited"));
        queryClient.invalidateQueries([GET_CATEGORIES_QUERY_KEY]);
      },
      onError({ response }) {
        showErrors({
          ...response?.data,
          defaultMessage:
            !response?.data?.details?.length && !response?.data?.message
              ? t("pages.ObjectManagement.Categories.item.could_not_edit_subcategory")
              : null
        });
        queryClient.invalidateQueries([GET_CATEGORIES_QUERY_KEY]);
      }
    }
  );
};
