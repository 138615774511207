import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TopicBoxProps {
  title: string;
  emails: string[];
}

const TopicBox = ({ emails, title }: TopicBoxProps) => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        display: "flex",
        padding: "32px",
        flexWrap: "wrap",
        boxSizing: "border-box",
        gap: "8px"
      }}
    >
      <Box sx={{ minWidth: "450px", flex: 1 }}>
        <Typography>{t("pages.Feedback.labels.topic")}</Typography>
        <Typography>{title}</Typography>
      </Box>
      <Box sx={{ minWidth: "450px", flex: 1 }}>
        <Typography>{t("pages.Feedback.labels.emails")}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            listStyleType: "none",
            margin: 0,
            padding: 0
          }}
          component='ul'
        >
          {emails.map((email) => (
            <Typography key={email} component='li'>
              {email}
            </Typography>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default TopicBox;
