import Form from "../Form";
import Body from "../Body";
import { useCreateAbonementMutation } from "hooks/queries/abonements";
import { useTranslation } from "react-i18next";

const AddForm = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useCreateAbonementMutation();
  return (
    <Body title={t("pages.Abonements.Active.item.new_abonement")}>
      <Form isAddType submit={mutateAsync} isLoading={isLoading} />
    </Body>
  );
};

export default AddForm;
