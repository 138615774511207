import { useGetZonesQuery } from "hooks/queries/zones";
import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useState,
  useCallback,
  useRef,
  useEffect,
  Context
} from "react";
import { MapObjectManager } from "pages/ObjectManagment/Objects/context/utils/MapObjectManagers";
import { Map } from "yandex-maps";
import {
  FormObject,
  FormObjects,
  GeometryManager,
  GeometryType,
  InternalMapApi,
  MapActionMode,
  ObjectType,
  OnGeometryChange,
  ReactRefObject,
  ReactSetState
} from "./types";
import { defaultOnChangeHandler, getInitialCoordinates } from "./utils";
import { useGetParkingsQuery } from "hooks/queries/parkings";
import { AnyFunction } from "@pbe/react-yandex-maps/typings/util/typing";

export type MapContextValue = {
  showObjectOnMap: (id: string) => void;
  selectedObject: FormObject | null;
  getSelectedObject: InternalMapApi["getSelectedObject"];
  hoveredObject: InternalMapApi["hoveredObject"];
  setHoveredObject: <T extends GeometryManager>(
    object: T | null,
    options?: { forced?: boolean; unhover?: boolean }
  ) => GeometryManager | null;
  objects: FormObjects;
  getMapObjects: () => InternalMapApi["mapObjectsRef"]["current"];
  mapRef: ReactRefObject<Map | null>;
  mapActionMode: MapActionMode | null;
  setMapActionMode: InternalMapApi["setMapActionMode"];
  getMapActionMode: InternalMapApi["getMapActionMode"];
  startDrawingNewObject: <OnChange extends (...args: any[]) => any>(args: {
    type: ObjectType;
    geometry?: GeometryType;
    onChange: OnChange;
  }) => void;
  startEditingObject: (
    objectToEdit: FormObject,
    options?: { geometry?: GeometryType; onChange?: OnGeometryChange }
  ) => void;
  geometryToDraw: GeometryType | null;
  objectTypeToDraw: ObjectType | null;
  getGeometryToDraw: InternalMapApi["getGeometryToDraw"];
  setObjectTypeToDraw: InternalMapApi["setObjectTypeToDraw"];
  getCurrentActiveObject: <T extends MapObjectManager>() => T | null;
  setIsAllObjectsOnMapInitialized: ReactSetState<boolean>;
  stopWorkingWithMapObject: (shouldResetChanges?: boolean) => void;
  stopViewingMapObject: () => void;
  startViewingObject: (objectToView: FormObject) => void;
  isAllObjectsOnMapInitialized: boolean;
  onChange: (...args: any[]) => any | null;
  setNewObject: InternalMapApi["setNewObject"];
  resetNewObject: (objectType: ObjectType) => Promise<unknown>;
  newObject: GeometryManager | null;
  getNewObject: InternalMapApi["getNewObject"];
  updateChangeHandler: (handler: AnyFunction) => void;
};

// Дофига свойств и сложно читается поэтому буду это разбивать на отдельные компоненты
export const MapContext: Context<MapContextValue> = createContext<MapContextValue>({
  showObjectOnMap: () => console.warn("onShowMapClick is not implemented"),
  selectedObject: null,
  getSelectedObject: () => {
    console.warn("setSelectedObject is not implemented");
    return null;
  },
  hoveredObject: null,
  setHoveredObject: () => {
    console.warn("setHoveredObject is not implemented");
    return null;
  },
  objects: {
    zones: [],
    parking: []
  },
  mapRef: { current: null },
  getMapObjects: () => {
    console.warn("getMapObjects is not implemented");
    return {};
  },
  mapActionMode: null,
  setMapActionMode: () => {
    console.warn("setMapActionMode is not implemented");
    return null;
  },
  getMapActionMode: () => {
    console.warn("getMapActionMode is not implemented");
    return null;
  },
  getGeometryToDraw: () => {
    console.warn("getGeometryToDraw is not implemented");
    return null;
  },
  startDrawingNewObject: () => console.warn("startDrawingNewObject is not implemented"),
  startEditingObject: () => console.warn("startEditingObject is not implemented"),
  geometryToDraw: null,
  objectTypeToDraw: null,
  setObjectTypeToDraw: () => console.warn("setObjectTypeToDraw is not implemented"),
  getCurrentActiveObject: () => {
    console.warn("getCurrentActiveObject is not implemented");
    return null;
  },
  setIsAllObjectsOnMapInitialized: () =>
    console.warn("setIsAllObjectsOnMapInitialized is not implemented"),
  stopWorkingWithMapObject: () => console.warn("stopWorkingWithMapObject is not implemented"),
  stopViewingMapObject: () => console.warn("stopViewingMapObject is not implemented"),
  startViewingObject: () => console.warn("startViewingObject is not implemented"),
  isAllObjectsOnMapInitialized: false,
  onChange: () => console.warn("onChange is not implemented"),
  setNewObject: () => {
    console.warn("setNewObject is not implemented");
    return null;
  },
  resetNewObject: () => {
    console.warn("resetNewObject is not implemented");
    return Promise.resolve(new Error("resetNewObject is not implemented"));
  },
  newObject: null,
  getNewObject: () => {
    console.warn("getNewObject is not implemented");
    return null;
  },
  updateChangeHandler: () => {
    console.warn("updateChangeHandler is not implemented");
  }
});

export const MapProvider = ({
  children,
  internalApi
}: {
  children: ReactNode;
  internalApi: InternalMapApi;
}) => {
  const {
    selectedObject,
    selectedObjectRef,
    setSelectedObject,
    getSelectedObject,
    hoveredObject,
    hoveredObjectRef,
    setHoveredObject: _setHoveredObject,
    mapActionMode,
    setMapActionMode,
    setGeometryToDraw,
    mapObjectsRef,
    geometryToDraw,
    geometryToDrawRef,
    objectTypeToDraw,
    setObjectTypeToDraw,
    setNewObject,
    newObject,
    getNewObject,
    mapActionModeRef,
    getMapActionMode,
    handleOnChangeRef
  } = internalApi;
  const { data: zonesData, refetch: refetchZones } = useGetZonesQuery();
  const { data: parkingsData, refetch: refetchParkings } = useGetParkingsQuery();

  const [typeChanged, setTypeChanged] = useState(false);
  const isPanning = useRef(false);

  const [isAllObjectsOnMapInitialized, setIsAllObjectsOnMapInitialized] =
    useState<MapContextValue["isAllObjectsOnMapInitialized"]>(false);

  const mapRef: MapContextValue["mapRef"] = useRef(null);

  const objects = useMemo<FormObjects>(
    () => ({
      zones: zonesData?.data.zones || [],
      parking: parkingsData?.data.parkings || []
    }),
    [zonesData, parkingsData]
  );

  const getCurrentActiveObject = useCallback(
    (() => {
      const currentNewObject = getNewObject();

      if (currentNewObject) {
        return currentNewObject;
      }
      if (mapActionMode === MapActionMode.Edit && selectedObject?.uuid?.value) {
        return mapObjectsRef.current[selectedObject.uuid.value];
      }
      return null;
    }) as MapContextValue["getCurrentActiveObject"],
    [newObject, selectedObject, mapActionMode]
  );

  const showObjectOnMap = useCallback<MapContextValue["showObjectOnMap"]>((id: string) => {
    const object = mapObjectsRef.current[id];
    if (!object) {
      console.warn(`Object with id '${id}' not found to be shown on map`);
      return;
    }

    // Из-за того, что реакт в стрикт моде дважды маутит компоненты,
    // в форме где мы вызывает showObjectOnMap эта функция вызывается дважды,
    // из-за чего карта не двигается к объекту. Поэтому приходится
    // пилить костыль, чтобы panTo не вызывался дважды
    if (!isPanning.current) {
      mapRef.current?.setBounds(object.getBounds(), { duration: 300 }).then(() => {
        isPanning.current = false;
      });
      isPanning.current = true;
    }
  }, []);

  const startDrawingNewObject = useCallback<MapContextValue["startDrawingNewObject"]>(
    ({ type, geometry = GeometryType.Polygon, onChange }) => {
      setGeometryToDraw(geometry);

      setMapActionMode(MapActionMode.Add);
      setObjectTypeToDraw(type);
      handleOnChangeRef.current = onChange;
    },
    []
  );

  const updateChangeHandler = useCallback(
    (handler: AnyFunction) => {
      const currentActiveObject = getCurrentActiveObject();

      if (currentActiveObject?.getGeoObject()) {
        currentActiveObject.removeGeoObjectEvent("geometrychange", handleOnChangeRef.current);
        currentActiveObject.addGeoObjectEvent("geometrychange", handler);
      }

      handleOnChangeRef.current = handler;
    },
    [getCurrentActiveObject]
  );

  const startEditingObject = useCallback<MapContextValue["startEditingObject"]>(
    (objectToEdit, { geometry, onChange = defaultOnChangeHandler } = {}) => {
      if (geometry !== geometryToDraw && geometryToDraw) setTypeChanged(true);

      setGeometryToDraw(geometry ?? GeometryType.Polygon);
      setSelectedObject(objectToEdit);
      setMapActionMode(MapActionMode.Edit);

      const mapObjectController = mapObjectsRef.current[objectToEdit.uuid?.value || ""];
      handleOnChangeRef.current = onChange;

      if (!mapObjectController) return;
      if (mapObjectController.getGeoObject()) {
        mapObjectController.addGeoObjectEvent("geometrychange", onChange);
      }
    },
    [geometryToDraw]
  );

  const startViewingObject = useCallback<MapContextValue["startViewingObject"]>((objectToView) => {
    setSelectedObject(objectToView);
    setMapActionMode(MapActionMode.ViewObject);
  }, []);

  const stopViewingMapObject = useCallback<MapContextValue["stopViewingMapObject"]>(() => {
    setSelectedObject(null);
    setMapActionMode(MapActionMode.ViewList);
  }, []);

  const stopWorkingWithMapObject = useCallback<MapContextValue["stopWorkingWithMapObject"]>(
    (shouldResetChanges = true) => {
      setGeometryToDraw(null);
      setObjectTypeToDraw(null);

      if (shouldResetChanges) {
        const currentMapObject = Object.values(mapObjectsRef.current).find(
          (obj) => obj?.getObject()?.uuid?.value === selectedObjectRef.current?.uuid?.value
        );

        if (currentMapObject) {
          currentMapObject?.setCoordinates(
            getInitialCoordinates(selectedObjectRef.current, geometryToDraw)
          );
        }
      }

      const mapObjectController = mapObjectsRef.current[selectedObject?.uuid?.value || ""];

      setSelectedObject(null);
      setNewObject(null);

      if (!mapObjectController) return;

      mapObjectController.stopChanging();
      mapObjectController.removeGeoObjectEvent("geometrychange", handleOnChangeRef.current);
      handleOnChangeRef.current = defaultOnChangeHandler;
    },
    [geometryToDraw, selectedObject]
  );

  const setHoveredObject = <T extends GeometryManager>(
    object: T | null,
    { forced = false, unhover = false } = {}
  ) => {
    if (forced) return _setHoveredObject(unhover ? null : object);

    if (hoveredObjectRef.current?.getRef() !== object?.getRef()) return _setHoveredObject(object);
    else if (unhover) return _setHoveredObject(null);

    return object;
  };

  const resetNewObject = useCallback(
    async (objectType: ObjectType) => {
      try {
        const response =
          objectType === ObjectType.Zone
            ? await refetchZones()
            : objectType === ObjectType.Parking
            ? await refetchParkings()
            : null;

        if (!response) throw new Error("Could not refetch object list");

        newObject?.setCoordinates([]);
        newObject?.startCreating();

        return response;
      } catch (error) {
        return error;
      }
    },
    [newObject]
  );

  const getGeometryToDraw = useCallback(() => {
    return geometryToDrawRef.current;
  }, []);

  const getMapObjects = useCallback(() => {
    return mapObjectsRef.current;
  }, []);

  const value = useMemo(() => {
    return {
      mapActionMode,
      mapActionModeRef,
      setMapActionMode,
      getMapActionMode,

      startDrawingNewObject,
      startEditingObject,
      startViewingObject,
      stopViewingMapObject,
      stopWorkingWithMapObject,

      geometryToDraw,
      getGeometryToDraw,

      objectTypeToDraw,
      setObjectTypeToDraw,

      newObject,
      setNewObject,
      getNewObject,
      resetNewObject,

      hoveredObject,
      setHoveredObject,
      selectedObject,
      getSelectedObject,
      objects,
      mapRef,
      getCurrentActiveObject,
      setIsAllObjectsOnMapInitialized,
      isAllObjectsOnMapInitialized,
      onChange: handleOnChangeRef.current,
      typeChanged,
      updateChangeHandler,
      getMapObjects,
      showObjectOnMap
    };
  }, [
    selectedObject,
    geometryToDraw,
    objectTypeToDraw,
    objects,
    typeChanged,
    mapActionMode,
    hoveredObject,
    setHoveredObject,
    getCurrentActiveObject,
    stopWorkingWithMapObject,
    setMapActionMode,
    newObject,
    handleOnChangeRef.current,
    isAllObjectsOnMapInitialized
  ]);

  useEffect(() => {
    if (!mapActionMode) {
      refetchZones();
      refetchParkings();
    }
  }, [mapActionMode]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMapContext = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error("useMapContext must be used within a MapContext");
  }

  return context;
};
