import { Control } from "react-hook-form";
import { ReportFiltersForm, ReportType } from "../../types";
import Select from "ui-kit/form/Select";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem } from "@mui/material";

interface ReportTypeSelectProps {
  control: Control<ReportFiltersForm>;
}

const ReportTypeSelect = ({ control }: ReportTypeSelectProps) => {
  const { t } = useTranslation();

  const reportTypes = Object.values(ReportType).map((reportType) => ({
    value: reportType,
    title: t(`pages.Reports.report_types.${reportType}`)
  }));

  return (
    <FormControl>
      <Select
        control={control}
        name='reportType'
        required
        label={t("pages.Reports.labels.report_type")}
      >
        {reportTypes.map(({ title, value }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReportTypeSelect;
