import { Box, Button, FormControl, FormLabel, IconButton, Paper } from "@mui/material";
import { Control, useFieldArray, UseFormGetFieldState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInputs } from "./types";
import { CSSProperties, forwardRef, Ref } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FeedbackEmailInput from "./FeedbackEmailInput";
import TopicNameInput from "./TopicNameInput";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

export interface TopicInputProps {
  index: number;
  control: Control<FormInputs>;
  onDelete: (index: number) => void;
  getFieldState: UseFormGetFieldState<FormInputs>;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  style?: CSSProperties;
  activeNodeRef?: Ref<HTMLDivElement>;
}

const TopicInput = forwardRef<HTMLDivElement, TopicInputProps>(
  (
    { index, control, onDelete, getFieldState, attributes, listeners, style, activeNodeRef },
    ref
  ) => {
    const { t } = useTranslation();

    const {
      fields: emails,
      append: appendEmail,
      remove: removeEmail
    } = useFieldArray({
      control,
      name: `topics.${index}.emails` as "topics.0.emails"
    });

    return (
      <Paper
        sx={{
          mt: 2,
          position: "relative",
          display: "flex",
          padding: "32px",
          flexWrap: "wrap",
          boxSizing: "border-box",
          gap: "48px",
          cursor: "move"
        }}
        style={style}
        ref={ref}
        data-testid='TopicInput-paper'
        {...attributes}
      >
        <Box
          sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          ref={activeNodeRef}
          {...listeners}
        />

        <Box sx={{ minWidth: "450px", flex: 1 }}>
          <TopicNameInput control={control} topicIndex={index} />
        </Box>

        <Box sx={{ minWidth: "450px", flex: 1 }}>
          <FormControl component='fieldset' variant='standard' margin='none' fullWidth>
            <FormLabel>{t("pages.Feedback.labels.emails")}</FormLabel>
            {emails.map(({ id }, emailIndex) => (
              <FeedbackEmailInput
                key={id}
                control={control}
                emailIndex={emailIndex}
                getFieldState={getFieldState}
                onDelete={removeEmail}
                topicIndex={index}
              />
            ))}
          </FormControl>

          <Button
            variant='contained'
            onClick={() => appendEmail({ value: "" })}
            sx={{ margin: "16px auto 0 auto", display: "block", transform: "translateX(-24px)" }}
          >
            {t("pages.Feedback.add")}
          </Button>
        </Box>

        <IconButton sx={{ position: "absolute", top: 2, right: 2 }} onClick={() => onDelete(index)}>
          <DeleteIcon />
        </IconButton>
      </Paper>
    );
  }
);

export default TopicInput;
