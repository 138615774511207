import { GisType } from "api/generated";
import { MapContextValue } from "../MapContext";
import { GeometryType, MapGeometryObjectRef, ObjectType } from "../types";
import { Coordinates, ObjectToDraw } from "./MapObjectManagers";
import { LineManager } from "./LineManager";
import { PlacemarkManager } from "./PlacemarkManager";
import { PolygonManager } from "./PolygonManager";
import { TFunction } from "i18next";

export const defaultOnChangeHandler = () => console.warn("onChange handler is not defined");

export const getInitialCoordinates = (
  selectedObject: MapContextValue["selectedObject"],
  geometryToDraw: MapContextValue["geometryToDraw"]
) => {
  let initialCoords: Coordinates = [];

  switch (geometryToDraw) {
    case GeometryType.Polygon:
      initialCoords = [
        selectedObject?.coordinates?.map(({ longitude, lattitude }) => [lattitude, longitude])
      ] as Coordinates;
      break;

    case GeometryType.Point:
      {
        const { longitude, lattitude } = selectedObject?.coordinates?.[0] ?? {
          longitude: 0,
          lattitude: 0
        };
        initialCoords = [[lattitude, longitude]] as Coordinates;
      }
      break;

    case GeometryType.Line:
      initialCoords = selectedObject?.coordinates?.map(({ longitude, lattitude }) => [
        longitude,
        lattitude
      ]) as Coordinates;
      break;

    default:
      break;
  }
  return initialCoords;
};

export const getMapObjectCoordinatesToPan = (
  activeObject: ReturnType<MapContextValue["getCurrentActiveObject"]>
) => activeObject?.getCoordinatesToPan() || [0, 0];

export const createObjectByType = (
  type: GisType | GeometryType,
  object: ObjectToDraw | null = null,
  ref: MapGeometryObjectRef | null = null,
  objectTypeToDraw: ObjectType,
  t: TFunction<"translation", undefined>,
  onChange?: any
) => {
  switch (type) {
    case GeometryType.Line:
    case GisType.GIS_TYPE_LINE:
      return new LineManager(object, ref, objectTypeToDraw, onChange, t);

    case GeometryType.Point:
    case GisType.GIS_TYPE_POINT:
      return new PlacemarkManager(object, ref, objectTypeToDraw, onChange);

    case GeometryType.Polygon:
    case GisType.GIS_TYPE_POLYGON:
      return new PolygonManager(object, ref, objectTypeToDraw, onChange, t);

    default:
      return null;
  }
};

export const getLineLength = (start: number[], end: number[]) => {
  const [x1, y1] = start;
  const [x2, y2] = end;

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};

export const getSubline = (start: number[], end: number[], lengthToSubstract: number) => {
  const [x1, y1] = start;
  const [x2, y2] = end;

  const segmentLength = getLineLength(start, end);
  const proportionCooficent = (segmentLength - lengthToSubstract) / segmentLength;

  const newX2 = x1 + proportionCooficent * (x2 - x1);
  const newY2 = y1 + proportionCooficent * (y2 - y1);
  return [
    [x1, y1],
    [newX2, newY2]
  ];
};

export const pathToLineCoordinates = (path: number[][][]) =>
  path.reduce<number[][][]>((acc, coords, index, arr) => {
    if (arr[index + 1] === undefined) {
      return acc;
    }

    if (!Array.isArray(acc[index])) {
      acc[index] = [];
    }

    acc[index].push(coords as Coordinates);
    acc[index].push(arr[index + 1] as Coordinates);

    return acc;
  }, []) || [[]];

export const getPolylineCenter = (rawCoordinates: number[][][]): number[] => {
  const coordinates = pathToLineCoordinates(rawCoordinates);

  const lineLengths = coordinates.map(([start, end]) => getLineLength(start, end));
  const halfOfLinesSum = lineLengths.reduce((acc, length) => acc + length, 0) / 2;

  let lineWithCenterPlacemarkCoordinates = coordinates[0];
  let lengthSumOfListedLines = 0;

  if (!Array.isArray(lineWithCenterPlacemarkCoordinates)) {
    return getSubline(
      rawCoordinates[0] as unknown as number[],
      rawCoordinates[0] as unknown as number[],
      halfOfLinesSum
    )[1];
  }

  for (let index = 0; index < lineLengths.length; index++) {
    const lineLength = lineLengths[index];
    lengthSumOfListedLines += lineLength;

    let lineLengthsDiff = halfOfLinesSum - lengthSumOfListedLines;

    if (lineLengthsDiff > 0) continue;
    lineWithCenterPlacemarkCoordinates = coordinates[index];

    lineLengthsDiff = Math.abs(lineLengthsDiff);

    return getSubline(
      lineWithCenterPlacemarkCoordinates[0],
      lineWithCenterPlacemarkCoordinates[1],
      lineLengthsDiff
    )[1];
  }

  return getSubline(
    lineWithCenterPlacemarkCoordinates[0],
    lineWithCenterPlacemarkCoordinates[1],
    halfOfLinesSum
  )[1];
};
