import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ContractNumberInput from "./ContractNumberInput";
import { ContractFormInputs } from "./types";
import ContractDatePicker from "./ContractDatePicker";
import LicenseExpiryDatePicker from "./LicenseExpiryDatePicker";
import { Company } from "api/generated";
import {
  getApiContractDate,
  getApiLicenseExpiry,
  parseApiDate
} from "pages/LegalEntityBalance/utils";
import { useUpdateLicenseExpiryMutation } from "hooks/queries/legal-entity-balance";

export interface ContractDialogProps {
  actionType: "confirmRegistration" | "extendContract";
  company: Company;
  open: boolean;
  onClose: VoidFunction;
  TransitionProps?: TransitionProps;
}

const ContractDialog: FC<ContractDialogProps> = ({
  actionType,
  company,
  open,
  onClose,
  TransitionProps
}) => {
  const { t } = useTranslation();
  const isConfirmRegistration = actionType === "confirmRegistration";

  const { handleSubmit, control } = useForm<ContractFormInputs>({
    defaultValues: {
      contractDate: isConfirmRegistration ? null : parseApiDate(company.contractDate),
      contractNumber: (!isConfirmRegistration && company.contractNumber) || "",
      licenseExpiry: isConfirmRegistration ? null : parseApiDate(company.licenseExpiry)
    }
  });

  const successMessage = isConfirmRegistration
    ? t("pages.LegalEntityBalance.contract_dialog.registration_confirmed")
    : t("pages.LegalEntityBalance.contract_dialog.contract_extended");

  const errorMessage = isConfirmRegistration
    ? t("pages.LegalEntityBalance.contract_dialog.could_not_confirm_registration")
    : t("pages.LegalEntityBalance.contract_dialog.could_not_extend_contract");

  const { mutate } = useUpdateLicenseExpiryMutation(successMessage, errorMessage);

  const handleSubmitValid: SubmitHandler<ContractFormInputs> = ({
    contractDate,
    contractNumber,
    licenseExpiry
  }) => {
    mutate(
      {
        uuid: company.uuid,
        contractDate: getApiContractDate(contractDate),
        contractNumber: contractNumber || "",
        licenseExpiry: getApiLicenseExpiry(licenseExpiry)
      },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  const actionTitle = isConfirmRegistration
    ? t("pages.LegalEntityBalance.confirm_registration")
    : t("pages.LegalEntityBalance.extend_contract");

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={TransitionProps}>
      <form onSubmit={handleSubmit(handleSubmitValid)}>
        <DialogTitle sx={{ pb: 0 }}>
          {t("pages.LegalEntityBalance.contract_dialog.title")}
        </DialogTitle>

        <DialogContent sx={{ ".MuiDialogTitle-root + &": { pt: "20px" } }}>
          <ContractNumberInput control={control} name='contractNumber' fullWidth />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <ContractDatePicker control={control} name='contractDate' />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <LicenseExpiryDatePicker control={control} name='licenseExpiry' />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            {t("common.cancelation")}
          </Button>

          <Button variant='contained' type='submit'>
            {actionTitle}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContractDialog;
