import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { PARKINGS_KEY } from ".";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { Uuid } from "api/generated";
import { queryClient } from "config/react-query";
import { useTranslation } from "react-i18next";

export const useDeleteParkingMutation = (uuid: Uuid, shouldInvalidateList = false) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation(
    [PARKINGS_KEY, DELETE_MUTATE_KEY, uuid?.value],
    () => requests.adminGatewayDeleteParking(`${uuid?.value}`),
    {
      onSuccess() {
        openSuccessAlert(t("pages.ObjectManagement.Objects.item.parking_deleted"));
        if (shouldInvalidateList) queryClient.invalidateQueries([PARKINGS_KEY]);
      },
      onError() {
        openErrorAlert(t("pages.ObjectManagement.Objects.item.could_not_delete_parking"));
      }
    }
  );
};
