import Form from "../Form";
import Body from "../Body";
import { useCreateGrantMutation } from "hooks/queries/permissions";
import { useTranslation } from "react-i18next";

const AddForm = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useCreateGrantMutation();
  return (
    <Body title={t("pages.Permissions.Grant.item.new_permission_grant")}>
      <Form isAddType submit={mutateAsync} isLoading={isLoading} />
    </Body>
  );
};

export default AddForm;
