import CssBaseline from "@mui/material/CssBaseline";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useLoginMutation } from "hooks/queries/auth";
import { useForm } from "react-hook-form";
import Input from "ui-kit/form/Input";
import { useTranslation } from "react-i18next";

type FormValue = {
  email: string;
  password: string;
};

const defaultValues = {
  email: "",
  password: ""
};

const Login = () => {
  const { t } = useTranslation();
  const { mutate: login, isLoading, isError } = useLoginMutation();
  const { control, handleSubmit } = useForm<FormValue>({ defaultValues });
  const logo = `/${window.LOGO_NAME}_logo.png`;

  const onSubmit = handleSubmit((data) => {
    login(data);
  });

  return (
    <Grid container component='main' sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://www.javeatravelguide.com/wp-content/uploads/2022/01/Parking-Javea-Arenal.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box
            width={window.LOGO_NAME === "almaty" ? 190 : 130}
            m={1}
            overflow='hidden'
            sx={{ textAlign: "center" }}
          >
            <img src={logo} />
          </Box>
          <Typography component='h1' variant='h5'>
            {t("pages.Login.authorization")}
          </Typography>
          <form onSubmit={onSubmit}>
            <Input
              control={control}
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
            />
            <Input
              control={control}
              margin='normal'
              required
              fullWidth
              type='password'
              name='password'
              label={t("pages.Login.password")}
            />
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              loading={isLoading}
            >
              {t("pages.Login.login")}
            </LoadingButton>

            <Grid container>
              <Grid item xs>
                {isError && (
                  <Typography variant='body2' color='error'>
                    {t("pages.Login.authorization_error")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
