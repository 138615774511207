import { Outlet } from "react-router-dom";
import NavSidebar from "components/NavSidebar";
import PageLayout from "components/PageLayout";
import { WorkspaceWrapper } from "./styled";
import { sideMenuItems } from "routes/const";
import Header from "../Header";

const Index = () => {
  return (
    <PageLayout header={<Header />} sidebar={<NavSidebar navItems={sideMenuItems} />}>
      <WorkspaceWrapper>
        <Outlet />
      </WorkspaceWrapper>
    </PageLayout>
  );
};

export default Index;
