import { Box, CircularProgress, Paper, TableContainer } from "@mui/material";
import { ReactNode } from "react";
import NoDataTypography from "./NoDataTypography";
import ReportTablePaginationContainer from "./ReportTablePaginationContainer";

type ReportTableContainerProps = {
  isFetching?: boolean;
  rowsLength?: number;
  children?: ReactNode;
  usePagination?: boolean;
};

const ReportTableContainer = ({
  isFetching,
  rowsLength,
  usePagination,
  children
}: ReportTableContainerProps) => {
  if (isFetching) {
    return <CircularProgress />;
  }

  if (!rowsLength || rowsLength === 0) {
    return <NoDataTypography />;
  }

  const table = (
    <Box display='grid'>
      <TableContainer
        component={Paper}
        sx={{ ".MuiTableCell-root.MuiTableCell-head": { fontWeight: "bold" } }}
      >
        {children}
      </TableContainer>
    </Box>
  );

  return usePagination ? (
    <ReportTablePaginationContainer total={rowsLength ?? 0}>{table}</ReportTablePaginationContainer>
  ) : (
    table
  );
};

export default ReportTableContainer;
