import { Navigate, useOutletContext } from "react-router-dom";
import { ObjectOutletContext } from "components/ObjectsSidebarContent/types";
import AddForm from "../AddForm";
import EditForm from "../EditForm";
import View from "../View";
import { useAppAbility } from "context/AbilityContext";
import { PARKINGS_BASE_ROUTE } from "routes/route-declarations";
import { Paths } from "routes/const";

const ParkingForms = () => {
  const { can } = useAppAbility();
  const { isViewType, isEditType, isAddType, id } = useOutletContext<ObjectOutletContext>();

  if (isAddType && !can("create", "Parking")) {
    return <Navigate to={PARKINGS_BASE_ROUTE} />;
  }

  if (isEditType && !can("update", "Parking")) {
    return <Navigate to={`${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parking}/${id}`} />;
  }

  return isAddType ? (
    <AddForm />
  ) : isEditType ? (
    <EditForm id={id || ""} />
  ) : isViewType ? (
    <View id={id || ""} />
  ) : null;
};

export default ParkingForms;
