import { ReactNode, Suspense } from "react";
import Loader from "ui-kit/PageLoader";
import { StyledContent, StyledRoot } from "./styled";

type Props = {
  children: ReactNode;
  sidebar?: ReactNode;
  header?: ReactNode;
};

const PageLayout = ({ children, sidebar, header }: Props) => {
  return (
    <StyledRoot>
      {header}
      {sidebar}
      <StyledContent>
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </StyledContent>
    </StyledRoot>
  );
};

export default PageLayout;
