import { Box, Typography } from "@mui/material";
import { TariffTariffZone } from "api/generated";
import { theme } from "config/theme";
import { useTranslation } from "react-i18next";

export type ScopeProps = {
  mt?: number;
  zones?: TariffTariffZone[];
};

const Scope = ({ zones, mt = 2 }: ScopeProps) => {
  const { t } = useTranslation();
  return zones?.length && zones.length > 0 ? (
    <Box marginTop={mt}>
      <Typography color={theme.palette.text.secondary}>{t("pages.Tariffs.scope")}:</Typography>
      <Typography variant='body1'>
        {zones
          .map((zone) => {
            if (zone.active) return zone.number;
          })
          .join(", ")}
      </Typography>
    </Box>
  ) : null;
};

export default Scope;
