import { SystemSettingsConfigCurrency, Tariff, TariffTariffPeriods } from "api/generated";
import PeriodTable from "../PeriodTable";
import { useNormalizedData } from "../PeriodTable/hooks";
import { Box } from "@mui/material";

export type PeriodsProps = {
  currency: SystemSettingsConfigCurrency;
  mt?: number;
  periods?: TariffTariffPeriods;
  ruleGroups?: Tariff["ruleGroups"];
};

const Periods = ({ periods, currency, ruleGroups, mt = 1 }: PeriodsProps) => {
  const normalizedData = useNormalizedData(periods, ruleGroups);
  return periods ? (
    <Box marginTop={mt}>
      {normalizedData.map((data) => {
        return <PeriodTable key={data.period} currency={currency} {...data} />;
      })}
    </Box>
  ) : null;
};

export default Periods;
