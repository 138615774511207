import { FC } from "react";
import { useTranslation } from "react-i18next";
import DataPicker, { DatePickerProps } from "ui-kit/form/DataPicker";

type LicenseExpiryDatePickerProps = Omit<DatePickerProps, "label" | "rules" | "inputFormat">;

const LicenseExpiryDatePicker: FC<LicenseExpiryDatePickerProps> = (props) => {
  const { t } = useTranslation();

  return (
    <DataPicker
      label={t("pages.LegalEntityBalance.contract_dialog.labels.license_expiry")}
      rules={{
        required: {
          value: true,
          message: t("common.input_rules.required")
        }
      }}
      inputFormat='dd.MM.yyyy'
      {...props}
    />
  );
};

export default LicenseExpiryDatePicker;
