import { createContext, ReactNode, useContext, useMemo } from "react";
import { useGetConfigQuery } from "hooks/queries/common";
import { ConfigResponse } from "api/generated";

type ConfigContextValue = {
  config: ConfigResponse | null;
  isInitialLoading: boolean;
};

const ConfigContext = createContext<ConfigContextValue>({ config: null, isInitialLoading: false });

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const { data: config = null, isInitialLoading } = useGetConfigQuery();
  const value = useMemo(() => ({ config, isInitialLoading }), [config, isInitialLoading]);

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("useConfigContext must be used within a ConfigContext");
  }

  return context;
};
