import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { Parking } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { PARKINGS_KEY } from ".";
import { CREATE_MUTATE_KEY } from "../action-keys";
import { queryClient } from "config/react-query";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useCreateParkingMutation = ({
  shouldInvalidateListOnSuccess = true,
  shouldInvalidateListOnError = false
} = {}) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();

  return useMutation(
    [PARKINGS_KEY, CREATE_MUTATE_KEY],
    (body: Parking) => requests.adminGatewayCreateParking(body),
    {
      onSuccess() {
        openSuccessAlert(t("pages.ObjectManagement.Objects.item.parking_created"));
        if (shouldInvalidateListOnSuccess) queryClient.invalidateQueries([PARKINGS_KEY, 0, 0]);
      },
      onError({ response }) {
        const errorMessage = t("pages.ObjectManagement.Objects.item.could_not_create_parking");
        showErrors({
          ...response?.data,
          defaultMessage:
            !response?.data?.details?.length && !response?.data?.message ? errorMessage : null
        });
        openErrorAlert(errorMessage);
        if (shouldInvalidateListOnError) queryClient.invalidateQueries([PARKINGS_KEY, 0, 0]);
      }
    }
  );
};
