import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoDataTypography = () => {
  const { t } = useTranslation();

  return (
    <Typography variant='h6' component='p'>
      {t("pages.Reports.no_data")}
    </Typography>
  );
};

export default NoDataTypography;
