import { GisType } from "api/generated";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import {
  GeometryType,
  MapActionMode,
  ParkingWithCoordinates
} from "pages/ObjectManagment/Objects/context/types";
import { LineManager } from "pages/ObjectManagment/Objects/context/utils/LineManager";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { Props } from "..";

const { GIS_TYPE_LINE, GIS_TYPE_POINT, GIS_TYPE_POLYGON } = GisType;

export const getGeometryType = (
  selectedObject: MapContextValue["selectedObject"],
  mapActionMode: Props["mapActionMode"],
  object: ParkingWithCoordinates,
  type: GisType
) => {
  if (
    mapActionMode === MapActionMode.Edit &&
    selectedObject &&
    selectedObject.uuid?.value === object.uuid?.value
  ) {
    return selectedObject.onMap;
  }

  switch (type) {
    case GIS_TYPE_LINE:
      return GeometryType.Line;
    case GIS_TYPE_POINT:
      return GeometryType.Point;
    case GIS_TYPE_POLYGON:
      return GeometryType.Polygon;

    default:
      return null;
  }
};

export const getGeometryStyle = (type: GeometryType) => {
  switch (type) {
    case GeometryType.Line:
      return LineManager.defaultColors;
    case GeometryType.Polygon:
      return PolygonManager.defaultColors;

    default:
      return null;
  }
};
