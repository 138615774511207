export * from "./useGetPermissionsQuery";
export * from "./useDeletePermissionMutation";
export * from "./useCreatePermissionMutation";
export * from "./useEditPermissionMutation";
export * from "./useGetPermissionQuery";
export * from "./useGetGrantsQuery";
export * from "./useDeleteGrantMutation";
export * from "./useCreateGrantMutation";

export const PERMISSIONS_QUERY_KEY = "permissions";
export const PERMISSION_GRANTS_QUERY_KEY = "permission_grants";
