import { Box, Card, CardContent, Divider, FormControl, FormLabel, Grid } from "@mui/material";
import { SystemSettingsConfig, SystemSettingsConfigVehicleReference } from "api/generated";
import { theme } from "config/theme";
import { memo } from "react";
import { Control } from "react-hook-form";
import Input from "ui-kit/form/Input";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

export type VehicleProps = SystemSettingsConfigVehicleReference & {
  control: Control<SystemSettingsConfig>;
  name: string;
  // onDeleteClick: (index: number) => void;
  // index: number;
};

const getInputRules = (t: TFunction<"translation", undefined>) => ({
  required: { value: true, message: t("common.input_rules.required") }
});

const Vehicle = ({ control, type, name /* , index  , onDeleteClick*/ }: VehicleProps) => {
  const { t } = useTranslation();
  // const handleDeleteClick = () => onDeleteClick(index);

  return (
    <Card elevation={3}>
      <CardContent>
        <Box display='flex' width='100%'>
          <Box flexGrow={1} minWidth={250} marginRight={2} maxWidth='30%'>
            <FormControl fullWidth>
              <MultiLangInput
                langsAlwaysOpen
                control={control}
                label={`${t("pages.SystemSettings.Vehicles.title")}:`}
                required
                name={`${name}.title`}
                variant='outlined'
                multiline
              />
            </FormControl>
          </Box>

          <Divider orientation='vertical' flexItem />

          <Grid container width='100%' height='100%' alignItems='flex-start' marginLeft={2}>
            <Grid item xs={12} md={12} xl={4}>
              <FormControl fullWidth required>
                <FormLabel id={type}>{t("pages.SystemSettings.Vehicles.type")}:</FormLabel>
                <Input
                  rules={getInputRules(t)}
                  required
                  inputProps={{
                    readOnly: true
                  }}
                  control={control}
                  name={`${name}.type`}
                  id={type}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Box
                marginLeft={1}
                sx={{
                  [theme.breakpoints.down("xl")]: {
                    marginLeft: 0,
                    marginTop: 1
                  }
                }}
              >
                <FormControl fullWidth required>
                  <FormLabel id={type}>{t("pages.SystemSettings.Vehicles.vrp_mask")}:</FormLabel>
                  <Input
                    rules={getInputRules(t)}
                    required
                    multiline
                    control={control}
                    name={`${name}.vrpMask`}
                    id={type}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Box
                marginLeft={1}
                sx={{
                  [theme.breakpoints.down("xl")]: {
                    marginTop: 1
                  }
                }}
              >
                <FormControl fullWidth>
                  <FormLabel id={type}>{t("pages.SystemSettings.Vehicles.placeholder")}:</FormLabel>
                  <Input
                    // rules={getInputRules(t)}
                    // required
                    multiline
                    control={control}
                    name={`${name}.placeholder`}
                    id={type}
                  />
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box marginTop={1}>
                <FormControl fullWidth required>
                  <FormLabel id={type}>{t("pages.SystemSettings.Vehicles.vrp_regexp")}:</FormLabel>
                  <Input
                    rules={getInputRules(t)}
                    required
                    multiline
                    control={control}
                    name={`${name}.vrpRegexp`}
                    id={type}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box marginBottom={0} marginTop={1}>
                <FormControl fullWidth required>
                  <FormLabel id={type}>
                    {t("pages.SystemSettings.Vehicles.foreign_vrp_regexp")}:
                  </FormLabel>
                  <Input
                    rules={getInputRules(t)}
                    required
                    multiline
                    control={control}
                    name={`${name}.foreignVrpRegexp`}
                    id={type}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      {/* <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button variant='contained' color='error' onClick={handleDeleteClick}>
          {t("common.delete")}
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default memo(Vehicle);
