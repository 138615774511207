import { useLegalSumDistrictsReport } from "hooks/queries/reports";
import { ReportTableComponentProps, SumDistrictsReportTable } from "../common";

const SumDistrictsTable = ({ filters }: ReportTableComponentProps) => {
  const { data, isFetching } = useLegalSumDistrictsReport(filters);

  return <SumDistrictsReportTable isFetching={isFetching} rows={data?.data.sums} />;
};

export default SumDistrictsTable;
