import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TranslateIcon from "@mui/icons-material/Translate";
import Input from "ui-kit/form/Input";
import { useMemo, useRef } from "react";
import { DefaultLangTemplate, InputRef, Props } from "./types";
import useToggle from "hooks/useToggle";
import { t } from "i18next";
import { useConfigContext } from "context/ConfigContext";
import { useGetConfigQuery } from "hooks/queries/common";

export const getDefaultLangTemplate = ({ shortCode = "" }): DefaultLangTemplate => {
  const label = `${shortCode.toUpperCase()}:`;
  return {
    name: shortCode,
    inputProps: {
      size: "medium",
      InputProps: {
        startAdornment: (
          <InputAdornment position='start'>
            <Typography variant='caption'>{label}</Typography>
          </InputAdornment>
        )
      },
      variant: "standard",
      hiddenLabel: true
    }
  };
};

const MultiLangInput = ({
  control,
  required,
  name,
  label,
  langsAlwaysOpen,
  multiline,
  variant,
  readOnly,
  langs,
  disabled
}: Props) => {
  const { isLoading, isFetching } = useGetConfigQuery();
  const { config } = useConfigContext();
  const { availableLanguages = [] } = config || {};

  const [langsActive, toggleLangsActive] = useToggle(!!langsAlwaysOpen);
  const focusTimeoutRef = useRef<NodeJS.Timeout>();
  const $inputs = useRef<InputRef>({});
  const langInputs = useMemo(() => {
    const passRefPropsToInputs = (langProps: DefaultLangTemplate): DefaultLangTemplate => {
      return {
        ...langProps,
        inputProps: {
          ...langProps.inputProps,
          inputProps: {
            readOnly
          },
          inputRef: ($ref) => ($inputs.current[langProps.name] = $ref)
        }
      };
    };

    return availableLanguages.map((lang, index) =>
      passRefPropsToInputs({
        ...getDefaultLangTemplate(lang),
        ...langs?.[index]
      })
    );
  }, [availableLanguages, readOnly]);

  const handleToggleLangsClick = () => {
    if (!langsActive) {
      clearTimeout(focusTimeoutRef.current);

      // Не нашел другого способа утановить фокус
      // https://github.com/mui/material-ui/issues/1594#issuecomment-272547735
      focusTimeoutRef.current = setTimeout(() => {
        for (const langKey in $inputs.current) {
          $inputs.current[langKey]?.focus();
          return;
        }
      });
    }
    toggleLangsActive();
  };

  const langsOpen = langsAlwaysOpen || langsActive;

  return (
    <FormControl
      component='fieldset'
      required={required}
      variant='standard'
      margin='none'
      data-testid='MultiLangInput'
      fullWidth
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <FormLabel>
          <span>{label}</span>
        </FormLabel>
        {!langsAlwaysOpen && (
          <IconButton onClick={handleToggleLangsClick} color={langsOpen ? "primary" : "default"}>
            <TranslateIcon />
          </IconButton>
        )}
      </Box>
      {langInputs?.map(({ name: langName, label: langLabel, inputProps }, index) => (
        <Input
          data-testid={`MultiLangInput__input--${langName}`}
          key={langName}
          required={required}
          rules={
            required
              ? { required: { value: true, message: t("common.input_rules.required") } }
              : undefined
          }
          label={langLabel}
          name={`${name}.${langName}`}
          control={control}
          sx={{ marginTop: index !== 0 ? 1 : 0, display: index === 0 || langsOpen ? "" : "none" }}
          multiline={multiline}
          disabled={disabled || isLoading || isFetching}
          {...inputProps}
          variant={variant || inputProps?.variant}
        />
      ))}
    </FormControl>
  );
};

export default MultiLangInput;
