import { Paths } from "./const";

export const ZONES_BASE_ROUTE = `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Zones}` as Paths;
export const ZONES_VIEW_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Zones}/:id` as Paths;
export const ZONES_ADD_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Zones}/add` as Paths;
export const ZONES_EDIT_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Zones}/:id/edit` as Paths;

export const PARKINGS_BASE_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parking}` as Paths;
export const PARKINGS_VIEW_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parking}/:id` as Paths;
export const PARKINGS_ADD_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parking}/add` as Paths;
export const PARKINGS_EDIT_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parking}/:id/edit` as Paths;

export const ACCOUNT_ROLES_BASE_ROUTE = `${Paths.Accounts}${Paths.Roles}` as Paths;

export const PARKOMATS_BASE_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Parkomats}` as Paths;

export const TERMINALS_BASE_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Terminals}` as Paths;

export const SCOREBOARDS_BASE_ROUTE =
  `${Paths.ObjectsManagment}${Paths.Objects}${Paths.Scoreboards}` as Paths;

export const BENEFIT_GRANT_BASE_ROUTE = `${Paths.Benefits}${Paths.Grants}` as Paths;
export const BENEFIT_GRANT_ADD_ROUTE = `${Paths.Benefits}${Paths.Grants}/add` as Paths;
export const BENEFIT_GRANT_VIEW_ROUTE = `${Paths.Benefits}${Paths.Grants}/:id` as Paths;

export const ABONEMENTS_BASE_ROUTE = `${Paths.Abonements}` as Paths;
export const ABONEMENTS_ACTIVE_ROUTE = `${Paths.Abonements}${Paths.AbonementsActive}` as Paths;
export const ABONEMENTS_ADD_ROUTE = `${Paths.Abonements}${Paths.AbonementsActive}/add` as Paths;
export const ABONEMENTS_EDIT_ROUTE = `${Paths.Abonements}/:id/edit` as Paths;

export const ABONEMENT_GRANTS_BASE_ROUTE = `${Paths.Abonements}${Paths.Grants}` as Paths;
export const ABONEMENT_GRANTS_ADD_ROUTE = `${Paths.Abonements}${Paths.Grants}/add` as Paths;

export const MONITORING_SMS_ROUTE = `${Paths.Monitoring}${Paths.Sms}` as Paths;

export const NOTIFICATIONS_PUSH_ROUTE = `${Paths.Notifications}${Paths.Push}` as Paths;

export const PARKING_SESSIONS_ROUTE = `${Paths.Parking}${Paths.Sessions}` as Paths;

export const SYSTEM_SETTING_MAP_ROUTE = `${Paths.SystemSettings}${Paths.Map}` as Paths;

export const SYSTEM_SETTING_MOBILE_APP_ROUTE = `${Paths.SystemSettings}${Paths.Mobile}` as Paths;

export const SYSTEM_SETTING_AVAILABLE_LANGUAGES_ROUTE =
  `${Paths.SystemSettings}${Paths.AvailableLanguages}` as Paths;

export const SYSTEM_SETTING_VEHICLES_ROUTE = `${Paths.SystemSettings}${Paths.Vehicles}` as Paths;

export const SYSTEM_SETTING_CURRENCY_ROUTE = `${Paths.SystemSettings}${Paths.Currency}` as Paths;

export const PERMISSIONS_BASE_ROUTE = `${Paths.Permissions}` as Paths;
export const PERMISSIONS_ACTIVE_ROUTE = `${Paths.Permissions}${Paths.PermissionsActive}` as Paths;
export const PERMISSIONS_ADD_ROUTE = `${Paths.Permissions}/add` as Paths;
export const PERMISSIONS_EDIT_ROUTE = `${Paths.Permissions}/:id/edit` as Paths;

export const PERMISSION_GRANTS_BASE_ROUTE = `${Paths.Permissions}${Paths.Grants}` as Paths;
export const PERMISSION_GRANTS_ADD_ROUTE = `${Paths.Permissions}${Paths.Grants}/add` as Paths;
