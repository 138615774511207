import { MutationOptions, useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { PermissionCreateRequest, RequestParams } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { CREATE_MUTATE_KEY } from "../action-keys";
import { PERMISSIONS_QUERY_KEY } from ".";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useCreatePermissionMutation = (
  params?: RequestParams,
  options?: MutationOptions<any, any, any, any>
) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();

  return useMutation(
    (body: PermissionCreateRequest) => requests.adminGatewayCreatePermission(body, params),
    {
      mutationKey: [PERMISSIONS_QUERY_KEY, CREATE_MUTATE_KEY],
      ...options,
      onSuccess(...args) {
        openSuccessAlert(t("pages.Permissions.Active.item.permission_created"));
        if (options?.onSuccess) options.onSuccess(...args);
      },
      onError(...args) {
        const { response } = args[0];
        showErrors({
          ...response?.data,
          defaultMessage:
            !response?.data?.details?.length && !response?.data?.message
              ? t("pages.Permissions.Active.item.could_not_create_permission")
              : null
        });
        if (options?.onError) options.onError(...args);
      }
    }
  );
};
