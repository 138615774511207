import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { CreateAbonementsGrantRequest, RequestParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ABONEMENT_GRANTS_QUERY_KEY } from ".";
import { CREATE_MUTATE_KEY } from "../action-keys";
import { useTranslation } from "react-i18next";

export const useCreateGrantMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation({
    mutationFn: (body: CreateAbonementsGrantRequest, params?: RequestParams) =>
      requests.adminGatewayCreateAbonementGrant(body, params),
    mutationKey: [ABONEMENT_GRANTS_QUERY_KEY, CREATE_MUTATE_KEY],
    onSuccess() {
      openSuccessAlert(t("pages.Abonements.Grant.item.abonement_grant_created"));
    },
    onError() {
      openErrorAlert(t("pages.Abonements.Grant.item.could_not_create_abonement_grant"));
    }
  });
};
