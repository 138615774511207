import useMapObjectHover from "hooks/useMapObjecHover";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import { GeometryManager } from "pages/ObjectManagment/Objects/context/types";

export const createMouseHandlers = ({
  hoveredObject,
  setHoveredObject,
  $polygon,
  polygonManagerRef,
  getState,
  getHandlers
}: {
  hoveredObject: MapContextValue["hoveredObject"];
  setHoveredObject: MapContextValue["setHoveredObject"];
  $polygon: React.MutableRefObject<any>;
  polygonManagerRef: React.MutableRefObject<GeometryManager | null>;
  getState: ReturnType<typeof useMapObjectHover>["getState"];
  getHandlers: ReturnType<typeof useMapObjectHover>["getHandlers"];
}) => {
  const handlers = getHandlers();

  return {
    polygonMouseEnterHandler: () => {
      handlers.polygonMouseEnter(() => {
        if (hoveredObject?.getRef() !== $polygon.current) {
          setHoveredObject(polygonManagerRef.current);
        }
      });
    },

    polygonMouseMoveHandler: () => {
      handlers.polygonMouseMove(() => {
        if (hoveredObject?.getRef() !== $polygon.current) {
          setHoveredObject(polygonManagerRef.current);
        }
      });
    },

    polygonMouseLeaveHandler: () => {
      handlers.polygonMouseLeave(() => {
        if (!getState().placemark) {
          setHoveredObject(polygonManagerRef.current, { unhover: true });
        }
      });
    },

    placemarkMouseEnterHandler: () => {
      handlers.placemarkMouseEnter(() => {
        if (hoveredObject?.getRef() !== $polygon.current) {
          setHoveredObject(polygonManagerRef?.current);
        }
      });
    },

    placemarkMouseMoveHandler: () => {
      handlers.placemarkMouseMove(() => {
        if (hoveredObject?.getRef() !== $polygon.current) {
          setHoveredObject(polygonManagerRef?.current);
        }
      });
    },

    placemarkMouseLeaveHandler: () => {
      handlers.placemarkMouseLeave(() => {
        if (!getState().polygon) {
          setHoveredObject(polygonManagerRef?.current, { unhover: true });
        }
      });
    }
  };
};
