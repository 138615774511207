import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetLegalOperationsParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const LEGAL_OPERATIONS_REPORT_QUERY_KEY = "legal_operations_report";

const useLegalOperationsReport = (params: AdminGatewayGetLegalOperationsParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [LEGAL_OPERATIONS_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetLegalOperations(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useLegalOperationsReport;
