import { formatUtcDate } from "utils/date";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { useCallback } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { AbonementGrant, AbonementsGrantsRequest } from "api/generated";
import SearchBar from "../SearchBar";
import DeleteGrantCell from "../DeleteGrantCell";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import RefetchTableDataButton from "components/RefetchTableDataButton";
import { Link } from "react-router-dom";
import { Paths } from "routes/const";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";
import Pagination from "components/Pagination";

export type Props = {
  control: Control<any>;
  setValue: UseFormSetValue<AbonementsGrantsRequest>;
  isLoading: boolean;
  isFetching: boolean;
  isInitialLoading: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  grants?: AbonementGrant[];
  onQueryChange: (query: string) => void;
  query: string;
  total?: number;
};

const GrantTable = ({
  setValue,
  isLoading,
  isFetching,
  isInitialLoading,
  refetch,
  grants,
  control,
  onQueryChange,
  query,
  total
}: Props) => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const doSearch = useDebouncedCallback(
    useCallback(
      (value: string) => {
        onQueryChange(value);
      },
      [refetch, query]
    ),
    300
  );

  const handleSearch = (value: string) => {
    setValue("query", value);
    doSearch(value);
  };

  const refreshTable = () => refetch();

  const shouldShowTable = !isInitialLoading && Array.isArray(grants);

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
        <SearchBar
          placeholder={t("pages.Abonements.Grant.list.search_by_srp")}
          control={control}
          onSearch={handleSearch}
        />
        <RefetchTableDataButton
          refetch={refreshTable}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </Box>
      {shouldShowTable ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Abonements.Grant.list.srp_number")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Abonements.common.abonement")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Abonements.Grant.list.validity")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Abonements.Grant.list.created_at")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("common.zones")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("pages.Abonements.Grant.list.external_id")}
                  </TableCell>
                  <TableCell
                    sx={{ width: "300px", padding: 1, fontSize: "1rem", fontWeight: "bold" }}
                  >
                    {t("common.delete")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grants?.map((grant) => (
                  <TableRow key={grant.uuid?.value}>
                    <TableCell sx={{ padding: 1 }}>{grant.vrp}</TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Link to={`${Paths.Abonements}/${grant.abonement?.uuid?.value || ""}/edit`}>
                        {getValueByLang(grant.abonement?.name, true)}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Box display='flex' flexWrap='wrap'>
                        <span>
                          {t("pages.Abonements.Grant.list.from")}{" "}
                          {grant.start &&
                            formatUtcDate(new Date(+(grant.start || 0)), "dd.MM.yyyy")}
                          &nbsp;
                        </span>
                        <span>
                          {t("pages.Abonements.Grant.list.to")}{" "}
                          {grant.end && formatUtcDate(new Date(+(grant.end || 0)), "dd.MM.yyyy")}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {grant.createdAt
                        ? formatUtcDate(
                            new Date(
                              // Дата может приходить в секундах, поэтому проводим доп проверку
                              +(grant.createdAt.length === 13
                                ? grant.createdAt
                                : +grant.createdAt * 1000) || 0
                            ),
                            "dd.MM.yyyy"
                          )
                        : t("pages.Abonements.Grant.list.not_specified")}
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {!grant.abonement?.zones?.length
                        ? t("pages.Abonements.Grant.list.all")
                        : grant.abonement?.zones?.join(", ")}
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      {/* На бэке пока нет типа для внешнего идентификатора */}
                    </TableCell>
                    <DeleteGrantCell
                      refetchList={refetch}
                      uuid={grant.uuid}
                      vrp={grant.vrp}
                      name={grant.abonement?.name}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination control={control} total={+(total || 0)} setValue={setValue} />
        </>
      ) : isLoading || isFetching ? (
        <Typography padding={1} variant='h6'>
          {t("pages.Abonements.Grant.list.loading_grants")}
        </Typography>
      ) : (
        <Typography padding={1} variant='h6'>
          {t("pages.Abonements.Grant.list.no_grants_found")}
        </Typography>
      )}
    </>
  );
};

export default GrantTable;
