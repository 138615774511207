import { format, addMinutes } from "date-fns";

export const formatUtcDate = (
  date: Date,
  dateFormat: string,
  locale?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): string => {
  const offset: number = date.getTimezoneOffset();
  const utcDate: Date = addMinutes(date, offset);

  return format(utcDate, dateFormat, locale);
};

export const getNowUtc = (): Date => {
  const date = new Date();
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
};
