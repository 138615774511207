import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { theme } from "config/theme";
import { Abonement, StartType, SystemSettingsConfigCurrency } from "api/generated";
import { useDeleteAbonementMutation, useGetAbonementsQuery } from "hooks/queries/abonements";
import LinkButton from "ui-kit/buttons/LinkButton";
import { ABONEMENTS_BASE_ROUTE } from "routes/route-declarations";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "components/Dialog";
import useDialog from "hooks/useDialog";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const {
  START_TYPE_FROMTOMORROW,
  START_TYPE_FROMTODAY,
  START_TYPE_FROMYESTERDAY,
  START_TYPE_UNSPECIFIED
} = StartType;

// value is a key from translation json
export const startTypes = {
  [START_TYPE_FROMTOMORROW]: "pages.Abonements.common.start_from_next_day",
  [START_TYPE_FROMTODAY]: "pages.Abonements.common.start_from_day_of_purchase",
  [START_TYPE_FROMYESTERDAY]: "pages.Abonements.common.start_from_previous_day",
  [START_TYPE_UNSPECIFIED]: "pages.Abonements.common.start_not_specified"
};

export type Props = Abonement & {
  refetchList: ReturnType<typeof useGetAbonementsQuery>["refetch"];
  currency: SystemSettingsConfigCurrency;
};

const AbonementListItem = ({
  refetchList,
  uuid,
  name,
  price,
  startType,
  currentUsesCount,
  zones,
  currency
}: Props) => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const { getValueByLang } = useLocalizationContext();
  const { mutateAsync, isLoading: isDeleting } = useDeleteAbonementMutation(undefined, undefined, {
    onSuccess() {
      refetchList();
    }
  });

  const { isOpen, openDialog, closeDialog, confirm, cancel } = useDialog({
    onConfirm: () => {
      mutateAsync(uuid).finally(() => refetchList());
      closeDialog();
    }
  });

  const canUpdate = can("update", "Abonement");
  const canDelete = can("delete", "Abonement");

  return (
    <Paper sx={{ padding: "1rem" }}>
      <Typography component='h3' variant='h6'>
        {getValueByLang(name)}
      </Typography>

      <Typography fontSize='2rem' color={theme.palette.text.secondary}>
        <Typography fontSize='2rem' component='span' color='#058d36'>
          {(+(price || "0")).toLocaleString("ru")}.00 {currency.symbol}
        </Typography>
      </Typography>

      <Divider />

      <Box display='flex' marginTop={1}>
        <Typography minWidth='150px' color={theme.palette.text.secondary}>
          {t("pages.Abonements.Active.list.grant_duration_start")}:
        </Typography>
        {` ${t(startTypes[startType || START_TYPE_UNSPECIFIED])}`}
      </Box>

      <Box display='flex'>
        <Typography minWidth='150px' color={theme.palette.text.secondary}>
          {t("pages.Abonements.common.tariffication_type")}:
        </Typography>
        {t("pages.Abonements.common.discount_100")}
      </Box>

      <Typography color={theme.palette.text.secondary}>
        {currentUsesCount
          ? `${t("pages.Abonements.common.active_grants")}: ${currentUsesCount}`
          : t("pages.Abonements.common.no_active_grants")}
      </Typography>

      <Box display='flex'>
        <Typography color={theme.palette.text.secondary}>
          {zones?.length ? `${t("common.zones")}:` : t("common.all_zones")}
        </Typography>
        &nbsp;{zones?.join(", ")}
      </Box>

      {(canUpdate || canDelete) && (
        <>
          <Divider sx={{ marginTop: 1 }} />

          <Box marginTop={2}>
            {canUpdate && (
              <LinkButton
                fullWidth
                variant='contained'
                disabled={isDeleting}
                to={`${ABONEMENTS_BASE_ROUTE}/${uuid?.value}/edit`}
              >
                {t("common.edit")}
              </LinkButton>
            )}
            {canDelete && (
              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                variant='contained'
                color='error'
                onClick={openDialog}
                loading={isDeleting}
              >
                {t("common.delete")}
              </LoadingButton>
            )}
          </Box>

          <Dialog
            title={t("pages.Abonements.common.delete_abonement_title")}
            message={
              <Typography>
                {t("pages.Abonements.common.delete_abonement_body")} &ldquo;{getValueByLang(name)}
                &ldquo;?
              </Typography>
            }
            showDeleteWarning
            confirmButtonText={t("common.delete")}
            typeError
            open={isOpen}
            onConfirm={confirm}
            onCancel={cancel}
          />
        </>
      )}
    </Paper>
  );
};

export default AbonementListItem;
