import { QueryClient } from "@tanstack/react-query";
import { Parking, Point, Zone } from "api/generated";
import { AxiosResponse } from "axios";
import { FieldValues, Path, UseFormClearErrors, UseFormSetError } from "react-hook-form";
import { ParkingFormValues } from "../Parkings/Form/utils";
import { ZoneFormValues } from "../Zones/Form";
import { TFunction } from "i18next";

export type DefaultResponseType = { [key: string]: (Zone | Parking)[] };

export type DataUpdateFunction<TInput, TOutput> = (input: TInput) => TOutput;

export type UpdateFnArg<ResponseType extends DefaultResponseType> =
  | AxiosResponse<ResponseType>
  | undefined;

export type UpdateFn<RT extends DefaultResponseType> = DataUpdateFunction<
  UpdateFnArg<RT>,
  UpdateFnArg<RT>
>;

export const defaultSetter =
  <RT extends DefaultResponseType>(
    uuid: string,
    keyInList: string,
    parsedCoordinates: Point[]
  ): UpdateFn<RT> =>
  (prevItemsResponse: UpdateFnArg<RT>) => {
    if (!prevItemsResponse) return prevItemsResponse;

    const items = prevItemsResponse.data?.[keyInList] ?? [];
    const itemIndex = items.findIndex(({ uuid: argsUuid }) => {
      return argsUuid?.value === uuid;
    });
    const itemToUpdate = items[itemIndex];

    if (itemToUpdate) {
      const newItems = [...items];
      newItems.splice(itemIndex, 1, {
        ...itemToUpdate,
        location: { coordinates: parsedCoordinates }
      });

      return {
        ...prevItemsResponse,
        data: { ...prevItemsResponse.data, [keyInList]: newItems }
      };
    }
    return prevItemsResponse;
  };

export const setOptimisticMutateResultInList = <ResponseType extends DefaultResponseType>(
  queryClient: QueryClient,
  uuid: string,
  queryKey: string,
  keyInList: string,
  parsedCoordinates: Point[],
  updateFn?: UpdateFn<ResponseType>
) => {
  queryClient.setQueryData<AxiosResponse<ResponseType>>(
    [queryKey, 0, 0],
    updateFn || defaultSetter<ResponseType>(uuid, keyInList, parsedCoordinates)
  );
};

export const parseCoordinatesToNumberFormat = (value: string): number[][] => {
  try {
    return JSON.parse(`[${value.replace(/\n/g, "")}]`);
  } catch {
    console.warn("Could not parse stringified coordinates");
    return [];
  }
};

export const convertCoordinatesToObjectFormat = (coordinates: number[][]): Point[] => {
  return coordinates.map(([lattitude, longitude]) => ({
    longitude,
    lattitude
  }));
};

export const convertCoordinatesToNumberFormat = (coordinates: Point[]): number[][] => {
  return coordinates.map(({ longitude = 0, lattitude = 0 }) => [lattitude, longitude]);
};

export const parseCoordinatesToObjectFormat = (value: string): Point[] => {
  const parsed = parseCoordinatesToNumberFormat(value);
  return parsed.map(([lattitude, longitude]) => ({
    longitude,
    lattitude
  }));
};

export const stringifyCoordinates = <T extends number[][]>(coordinates?: T) =>
  coordinates?.reduce<string>((acc, coord, index, coords) => {
    let longitude;
    let lattitude;

    if (Array.isArray(coord)) {
      [lattitude, longitude] = coord;

      let string = "";
      if (typeof longitude === "number") {
        string = `[${lattitude}, ${longitude}]${index !== coords.length - 1 ? ",\n" : ""}`;
      } else {
        string = stringifyCoordinates(coord as unknown as number[][]);
      }

      return acc + string;
    } else if (coord) {
      ({ longitude = 0, lattitude = 0 } = coord);
    } else {
      return acc;
    }

    const string = `[${lattitude}, ${longitude}]${index !== coords.length - 1 ? ",\n" : ""}`;
    return acc + string;
  }, "") ?? "";

export const getCoordinatesWithCenter = (rawCoordinates: number[][]) => {
  const coordinates = convertCoordinatesToObjectFormat(rawCoordinates);

  const center = rawCoordinates
    ?.reduce(
      (acc, [ltt, lng]) => {
        acc[0] += ltt || 0;
        acc[1] += lng || 0;
        return acc;
      },
      [0, 0]
    )
    .map((coord: number) => coord / coordinates.length);

  return { coordinates, center: { longitude: center[1], lattitude: center[0] } };
};

export const handleObjectOnMapValidation = <
  T extends FieldValues = ZoneFormValues | ParkingFormValues
>(
  setError: UseFormSetError<T>,
  clearErrors: UseFormClearErrors<T>,
  t: TFunction<"translation", undefined>,
  coordinatesStringified?: string,
  extraValidators?: ((...args: any[]) => string | null | undefined)[]
) => {
  if (!coordinatesStringified) {
    return setError("objectOnMap" as Path<T>, {
      message: t("pages.ObjectManagement.Objects.item.input_rules.put_object_on_map")
    });
  }

  if (extraValidators) {
    for (const validate of extraValidators) {
      const message = validate();
      if (message) {
        return setError("objectOnMap" as Path<T>, { message });
      }
    }
  }

  clearErrors(["objectOnMap" as Path<T>]);
};

export type HandleObjectOnMapValidation = typeof handleObjectOnMapValidation;
