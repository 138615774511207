import { Placemark, Polygon } from "@pbe/react-yandex-maps";
import { Zone as ZoneType } from "api/generated";
import useMapObjectHover from "hooks/useMapObjecHover";
import { MapContextValue } from "pages/ObjectManagment/Objects/context/MapContext";
import {
  FormObject,
  GeometryManager,
  MapActionMode,
  MapGeometryObjectRef
} from "pages/ObjectManagment/Objects/context/types";
import { PlacemarkManager } from "pages/ObjectManagment/Objects/context/utils/PlacemarkManager";
import { PolygonManager } from "pages/ObjectManagment/Objects/context/utils/PolygonManager";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ZONES_BASE_ROUTE } from "routes/route-declarations";
import ymaps from "yandex-maps";
import { createMouseHandlers } from "./utils";
import { useTranslation } from "react-i18next";

type Props = {
  mapActionMode: MapContextValue["mapActionMode"];
  selectedObject: MapContextValue["selectedObject"];
  stopWorkingWithMapObject: MapContextValue["stopWorkingWithMapObject"];
  hoveredObject: MapContextValue["hoveredObject"];
  setHoveredObject: MapContextValue["setHoveredObject"];
  getSelectedObject: MapContextValue["getSelectedObject"];
  object: ZoneType;
  onLoad: ((api: typeof ymaps) => void) | undefined;
  instanceRef: (object: FormObject) => (ref: MapGeometryObjectRef) => GeometryManager | null;
};

const Zone = ({
  mapActionMode,
  selectedObject,
  getSelectedObject,
  hoveredObject,
  setHoveredObject,
  object,
  instanceRef,
  stopWorkingWithMapObject,
  ...restProps
}: Props) => {
  const { t } = useTranslation();
  const { getState, getHandlers } = useMapObjectHover({
    placemark: false,
    polygon: false,
    hovered: false
  });

  const $placemark = useRef<any>(null);
  const $polygon = useRef<any>(null);
  const polygonManagerRef = useRef<GeometryManager | null>(null);

  const navigate = useNavigate();
  const [colorStyle, placemarkColorStyle] =
    mapActionMode === MapActionMode.ViewList || selectedObject?.uuid?.value === object.uuid?.value
      ? [PolygonManager.defaultColors.zone.active, PlacemarkManager.defaultColors.zone.active]
      : [PolygonManager.defaultColors.zone.inactive, PlacemarkManager.defaultColors.zone.inactive];

  useEffect(() => {
    if (mapActionMode === MapActionMode.Add || mapActionMode === MapActionMode.Edit) return;

    const {
      polygonMouseEnterHandler,
      polygonMouseMoveHandler,
      polygonMouseLeaveHandler,
      placemarkMouseEnterHandler,
      placemarkMouseMoveHandler,
      placemarkMouseLeaveHandler
    } = createMouseHandlers({
      hoveredObject,
      setHoveredObject,
      $polygon,
      polygonManagerRef,
      getState,
      getHandlers
    });

    $placemark.current?.events.add("mouseenter", placemarkMouseEnterHandler);
    $placemark.current?.events.add("mousemove", placemarkMouseMoveHandler);
    $placemark.current?.events.add("mouseleave", placemarkMouseLeaveHandler);

    $polygon.current?.events.add("mouseenter", polygonMouseEnterHandler);
    $polygon.current?.events.add("mousemove", polygonMouseMoveHandler);
    $polygon.current?.events.add("mouseleave", polygonMouseLeaveHandler);

    return () => {
      $placemark.current?.events.remove("mouseenter", placemarkMouseEnterHandler);
      $placemark.current?.events.remove("mousemove", placemarkMouseMoveHandler);
      $placemark.current?.events.remove("mouseleave", placemarkMouseLeaveHandler);

      $polygon.current?.events.remove("mouseenter", polygonMouseEnterHandler);
      $polygon.current?.events.remove("mousemove", polygonMouseMoveHandler);
      $polygon.current?.events.remove("mouseleave", polygonMouseLeaveHandler);
    };
  }, [$polygon.current, $placemark.current, hoveredObject, mapActionMode]);

  useEffect(() => {
    if (!selectedObject && mapActionMode !== MapActionMode.Add) {
      if (hoveredObject && hoveredObject?.getRef() === polygonManagerRef.current?.getRef()) {
        polygonManagerRef.current?.setHoveredStyle();
      } else {
        polygonManagerRef.current?.setEnabledStyle();
      }
    }
  }, [selectedObject, hoveredObject, $polygon.current, mapActionMode]);

  return (
    <>
      <Polygon
        key={object.uuid?.value}
        instanceRef={(ref) => {
          polygonManagerRef.current = instanceRef(object)(ref);
          $polygon.current = ref;
        }}
        geometry={[
          object.location?.coordinates?.map(({ longitude, lattitude }) => [lattitude, longitude])
        ]}
        defaultOptions={{
          ...PolygonManager.defaultOptions,
          fillColor: colorStyle.fill,
          strokeColor: colorStyle.stroke
        }}
        {...restProps}
      />
      <Placemark
        instanceRef={$placemark}
        geometry={[object.center?.lattitude, object.center?.longitude]}
        onLoad={() => {
          // Почему-то без таумаута эвент не вешается
          setTimeout(() => {
            $placemark.current.editor.options.get("geoObject").events.add(["click"], () => {
              if (getSelectedObject()?.uuid?.value !== object.uuid?.value) {
                stopWorkingWithMapObject();
              }
              navigate(`${ZONES_BASE_ROUTE}/${object?.uuid?.value || ""}`, { replace: true });
            });
          });
        }}
        properties={{
          iconContent: `${t("pages.ObjectManagement.Objects.item.zones.zone")} <b>#${
            object.number
          }</b>`
        }}
        options={{
          ...placemarkColorStyle,
          zIndex:
            mapActionMode === MapActionMode.ViewList ||
            selectedObject?.uuid?.value === object.uuid?.value
              ? 10000
              : 0
        }}
      />
    </>
  );
};

export default Zone;
