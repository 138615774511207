import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { BenefitsResponse, RequestParams } from "api/generated";

const initialBenefits: BenefitsResponse = { benefits: [] };

export const GET_BENEFITS_QUERY_KEY = "get_benefits";

export const useGetBenefitsQuery = (
  params?: RequestParams,
  options?: Parameters<typeof useQuery>[2]
) => {
  const {
    data = initialBenefits,
    isLoading,
    ...rest
  } = useQuery(
    [GET_BENEFITS_QUERY_KEY, params],
    () => requests.adminGatewayBenefits(params).then(({ data }) => data),
    options
  );

  return {
    data,
    isLoading,
    ...rest
  };
};
