import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select, { SelectProps } from "ui-kit/form/Select";
import EditIcon from "@mui/icons-material/Edit";
import Input from "ui-kit/form/Input";
import useSwitches from "hooks/useSwitches";
import {
  Control,
  UseFormClearErrors,
  UseFormGetFieldState,
  UseFormSetError,
  UseFormSetValue,
  useFormState,
  useWatch
} from "react-hook-form";
import { ChangeEvent, useEffect, useRef } from "react";
import { GeometryType } from "pages/ObjectManagment/Objects/context/types";
import { theme } from "config/theme";
import { HandleObjectOnMapValidation } from "../utils";
import { useTranslation } from "react-i18next";

export type OnMapTypeInputProps = {
  onSave: (value: string) => void;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  getFieldState: UseFormGetFieldState<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  defaultValue?: string;
  onTypeChange?: SelectProps["onChange"];
  disabled?: boolean;
  required?: boolean;
  onOpenInput?: VoidFunction;
  validateObjectOnMap?: HandleObjectOnMapValidation;
};

const isCoordinatesValid = (value: string | null) => {
  if (value === "" || value === null) return true;
  let parsed;

  try {
    parsed = JSON.parse("[" + value + "]");
  } catch (value) {
    return false;
  }

  return Array.isArray(parsed)
    ? parsed.every((coords) => {
        const [lattitude, longitude] = coords;

        return (
          typeof longitude === "number" &&
          typeof lattitude === "number" &&
          longitude > -180 &&
          longitude < 180 &&
          lattitude > -90 &&
          lattitude < 90
        );
      })
    : false;
};

const OnMapTypeInput = ({
  disabled,
  control,
  onSave,
  getFieldState,
  setValue,
  defaultValue = "polygon",
  setError,
  onOpenInput,
  onTypeChange,
  validateObjectOnMap,
  clearErrors
}: OnMapTypeInputProps) => {
  const { t } = useTranslation();
  const [coordsDialogOpen, openCoordsDialog, closeCoordsDialog] = useSwitches();
  const value = useWatch({ control, name: "coordinatesStringified" });
  const prevValue = useRef(value);
  const formState = useFormState({ control });

  const handleChangeType: OnMapTypeInputProps["onTypeChange"] = (e, ...rest) => {
    if (onTypeChange) onTypeChange(e, ...rest);
    const { value } = e.target;
    setValue("onMap", value);
    prevValue.current = value;
    setValue("coordinatesStringified", value);
    clearErrors(["coordinatesStringified"]);
  };

  const handleSave = () => {
    prevValue.current = value;
    onSave(value);
    closeCoordsDialog();
  };

  const handleOpenDialog = () => {
    prevValue.current = value;
    if (onOpenInput) onOpenInput();
    openCoordsDialog();
  };

  const handleClose = () => {
    setValue("coordinatesStringified", prevValue.current);
    closeCoordsDialog();
    clearErrors(["coordinatesStringified"]);
  };

  useEffect(() => {
    if (formState.submitCount && formState.isSubmitSuccessful && validateObjectOnMap) {
      validateObjectOnMap(setError, clearErrors, t, value);
    }
  }, [formState.submitCount]);

  return (
    <Box display='flex' alignItems='flex-end' data-testid='OnMapTypeInput'>
      <Box width='100%'>
        <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth required>
          <Select
            data-testid='OnMapTypeInput__select'
            control={control}
            name='onMap'
            size='small'
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={handleChangeType}
            error={getFieldState("objectOnMap").error}
            label={`${t("pages.ObjectManagement.Objects.item.on_map")}:`}
            sx={{
              "&.Mui-disabled.Mui-error fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.error.main
              }
            }}
          >
            <MenuItem value={GeometryType.Polygon}>
              {t("pages.ObjectManagement.Objects.item.on_map_type_polygon")}
            </MenuItem>
            <MenuItem value={GeometryType.Line}>
              {t("pages.ObjectManagement.Objects.item.on_map_type_line")}
            </MenuItem>
            <MenuItem value={GeometryType.Point}>
              {t("pages.ObjectManagement.Objects.item.on_map_type_point")}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <IconButton
        sx={{ margin: "24px 0 3px 10px", alignSelf: "flex-start" }}
        onClick={handleOpenDialog}
        data-testid='OnMapTypeInput__edit-button'
      >
        <EditIcon />
      </IconButton>

      <Dialog open={coordsDialogOpen} onClose={handleClose} data-testid='OnMapTypeInput__dialog'>
        <DialogTitle width='400px'>
          <Typography component='h2' variant='h4'>
            {t("pages.ObjectManagement.Objects.item.coordinates_input")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl margin='none' fullWidth>
            <FormLabel>{t("pages.ObjectManagement.Objects.item.coordinates")}:</FormLabel>
            <Input
              data-testid='OnMapTypeInput__coordinates-input'
              rules={{
                onChange(e: ChangeEvent<HTMLInputElement>) {
                  const value = e.target.value;
                  const isValid = isCoordinatesValid(value);

                  if (!isValid)
                    setError("coordinatesStringified", {
                      message: t(
                        "pages.ObjectManagement.Objects.item.input_rules.invalid_coordinates_format"
                      )
                    });
                  else clearErrors(["coordinatesStringified"]);

                  if (validateObjectOnMap) validateObjectOnMap(setError, clearErrors, t, value);
                }
              }}
              error={getFieldState("coordinatesStringified").error}
              name='coordinatesStringified'
              control={control}
              variant='standard'
              multiline
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginBottom: 1 }}>
          <Button
            data-testid='OnMapTypeInput__save-button'
            disabled={!!getFieldState("coordinatesStringified").error}
            variant='contained'
            color='success'
            onClick={handleSave}
          >
            {t("common.save")}
          </Button>
          <Button
            data-testid='OnMapTypeInput__cancel-button'
            variant='outlined'
            onClick={handleClose}
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OnMapTypeInput;
