import { useCallback, useEffect, useRef } from "react";
import useSyncRef from "./useSyncRef";

export type DebouncedCallback = (...args: any[]) => any;

const useDebouncedCallback = (fn: DebouncedCallback, delay = 500) => {
  const timeoutRef = useRef<NodeJS.Timeout | number>(-1);
  const [callbackRef] = useSyncRef(fn);

  const callback: DebouncedCallback = useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    },
    [delay]
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), [callback, delay]);

  return callback;
};

export default useDebouncedCallback;
