import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { HolidayType, RequestParams, Uuid } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { HOLIDAYS_KEY } from ".";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

export const useDeleteHolidayMutation = (uuid: Uuid, params?: RequestParams) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<object>, unknown, HolidayType, unknown>({
    mutationFn: () => requests.adminGatewayDeleteHoliday(uuid?.value || "", params),
    mutationKey: [HOLIDAYS_KEY, DELETE_MUTATE_KEY, uuid],
    onSuccess(_, type) {
      openSuccessAlert(
        `${
          type === HolidayType.HOLIDAY_TYPE_DAYOFF
            ? t("pages.HolidaysCalendar.item.weekend")
            : t("pages.HolidaysCalendar.item.festive")
        } ${t("pages.HolidaysCalendar.item.day_deleted")}`
      );
      queryClient.invalidateQueries([HOLIDAYS_KEY]);
    },
    onError(_, type) {
      openErrorAlert(
        t("pages.HolidaysCalendar.item.could_not_delete_day").replace(
          "{dayType}",
          (type === HolidayType.HOLIDAY_TYPE_DAYOFF
            ? t("pages.HolidaysCalendar.item.weekend")
            : t("pages.HolidaysCalendar.item.festive").toLowerCase()
          ).toLowerCase()
        )
      );
      queryClient.invalidateQueries([HOLIDAYS_KEY]);
    }
  });
};
