import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Drawer, DrawerHeader, ListItemButton, ListItemIcon, ListItemText } from "./styled";
import { NavItem } from "routes/const";
import ListItem from "./components/ListItem";
import NestedListItem from "./components/NestedListItem";
import { useLayoutContext } from "../../context/LayoutContext";
import { useAppAbility } from "../../context/AbilityContext";

type Props = {
  navItems: NavItem[];
};

const NavSidebar = (props: Props) => {
  const { navItems } = props;
  const { isNavSidebarOpen, setIsNavSidebarOpen, drawerWidth } = useLayoutContext();
  const { can } = useAppAbility();
  return (
    <Drawer variant='permanent' open={isNavSidebarOpen} drawerWidth={drawerWidth}>
      <DrawerHeader>
        <IconButton onClick={() => setIsNavSidebarOpen(!isNavSidebarOpen)}>
          {!isNavSidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List component='nav'>
        {navItems.map(
          ({ children, abilitySubjectName, ...itemProps }, index) =>
            (abilitySubjectName === "Unknown" || can("read", abilitySubjectName)) &&
            (children ? (
              <NestedListItem key={index} {...itemProps} isNavSidebarOpen={isNavSidebarOpen}>
                {children}
              </NestedListItem>
            ) : (
              <ListItem key={index} {...itemProps} isNavSidebarOpen={isNavSidebarOpen} />
            ))
        )}
        {window.STATISTICS_HREF && window.STATISTICS_HREF != "" && (
          <>
            <ListItemButton
              open={true}
              onClick={() => {
                window.open(window.STATISTICS_HREF);
              }}
            >
              <ListItemIcon open={true}>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary='Metabase' open={true} />
            </ListItemButton>
          </>
        )}
      </List>
      <Divider />
    </Drawer>
  );
};

export default NavSidebar;
