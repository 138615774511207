import { MutationOptions, useMutation } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams } from "api/generated";

import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { EDIT_MUTATE_KEY } from "../action-keys";
import { ABONEMENTS_QUERY_KEY } from ".";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";

export const useEditAbonementMutation = (
  uuid: string,
  params?: RequestParams,
  options?: MutationOptions<any, any, any, any>
) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();

  return useMutation((body: any) => requests.adminGatewayEditAbonement(uuid, body, params), {
    mutationKey: [ABONEMENTS_QUERY_KEY, EDIT_MUTATE_KEY, uuid],
    ...options,
    onSuccess(...args) {
      openSuccessAlert(t("pages.Abonements.Active.item.abonement_edited"));
      if (options?.onSuccess) options.onSuccess(...args);
    },
    onError(...args) {
      const { response } = args[0];
      showErrors({
        ...response?.data,
        defaultMessage:
          !response?.data?.details?.length && !response?.data?.message
            ? t("pages.Abonements.Active.item.could_not_edit_abonement")
            : null
      });
      if (options?.onError) options.onError(...args);
    }
  });
};
