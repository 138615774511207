import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "components/Card";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { BENEFIT_GRANT_BASE_ROUTE } from "routes/route-declarations";
import LinkButton from "ui-kit/buttons/LinkButton";

export type Body = {
  children: ReactNode;
  title: ReactNode;
};

const Body = ({ children, title }: Body) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <Typography lineHeight='1.5' variant='h5'>
            {title}
          </Typography>
          <LinkButton variant='contained' to={BENEFIT_GRANT_BASE_ROUTE}>
            {t("pages.Benefits.Grants.item.grant_list")}
          </LinkButton>
        </Box>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        {children}
      </CardContent>
    </Card>
  );
};

export default Body;
