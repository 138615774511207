import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requests } from "api";
import { Tariff, Uuid } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { EDIT_MUTATE_KEY } from "../action-keys";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { TARIFF_QUERY_KEY } from ".";

export const useUpdateTariffMutation = (uuid?: Uuid) => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation(
    (tariff: Tariff) => requests.adminGatewayEditTariff(uuid?.value || "", tariff),
    {
      mutationKey: [TARIFF_QUERY_KEY, EDIT_MUTATE_KEY, uuid?.value],
      onSuccess() {
        openSuccessAlert(t("pages.Tariffs.item.tariff_updated"));
        queryClient.invalidateQueries([TARIFF_QUERY_KEY]);
      },
      onError({ response }) {
        showErrors({
          ...response?.data,
          defaultMessage:
            !response?.data?.details?.length && !response?.data?.message
              ? t("pages.Tariffs.item.could_not_update_tariff")
              : null
        });
        queryClient.invalidateQueries({ queryKey: [TARIFF_QUERY_KEY] });
      }
    }
  );
};
