import { BaseSyntheticEvent, useEffect } from "react";
import ConfirmCancelPanel from "components/ConfirmCancelPanel";
import { useForm } from "react-hook-form";
import MultiLangInput from "ui-kit/form/MultiLangInput";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { Category } from "api/generated";
import { useCategoriesFormContext } from "pages/ObjectManagment/Categories/context/CategoriesFormContext";
import { getDefaultValues } from "pages/ObjectManagment/Categories/context/utils";
import { useEditCategoryMutation, useCreateCategoryMutation } from "hooks/queries/category";
import { useAppAbility } from "context/AbilityContext";
import { useTranslation } from "react-i18next";
import { useConfigContext } from "context/ConfigContext";

const CategoryBody = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const { config } = useConfigContext();
  const { formInfo, actionInfo, initialValues, closeForm, initResetFormFn } =
    useCategoriesFormContext();

  const {
    handleSubmit: onSubmit,
    control,
    reset
  } = useForm<Category>({
    defaultValues: initialValues
  });
  const { openErrorAlert } = useAlertStatus();
  const { mutate } = actionInfo.create
    ? useCreateCategoryMutation({ formInfo })
    : useEditCategoryMutation({ formInfo });

  const handleSubmit = async (e: BaseSyntheticEvent) =>
    await onSubmit(
      ({ name }) => {
        const payload = {
          name
        };
        if (actionInfo.create)
          reset(
            getDefaultValues(
              formInfo,
              config?.availableLanguages?.map(({ shortCode }) => shortCode || "") || []
            )
          );
        return mutate(payload);
      },
      () => openErrorAlert(t("common.form_filled_incorrectly"))
    )(e);

  useEffect(() => {
    initResetFormFn(reset);
  }, [reset]);

  const canCreateOrUpdate = can("create", "Category") || can("update", "Category");

  return (
    <form onSubmit={handleSubmit} data-testid='CategoryForm'>
      <MultiLangInput
        label={`${t("pages.ObjectManagement.Categories.item.name")}:`}
        name='name'
        readOnly={!canCreateOrUpdate}
        langsAlwaysOpen={actionInfo.create}
        required
        control={control}
      />

      {canCreateOrUpdate && (
        <ConfirmCancelPanel
          onConfirmProps={{ type: "submit" }}
          onConfirm={handleSubmit}
          onCancel={closeForm}
        />
      )}
    </form>
  );
};

export default CategoryBody;
