import { useMutation } from "@tanstack/react-query";
import { requests } from "api";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ZONES_KEY } from ".";
import { DELETE_MUTATE_KEY } from "../action-keys";
import { Uuid } from "api/generated";
import { queryClient } from "config/react-query";
import { useTranslation } from "react-i18next";

export const useDeleteZoneMutation = (uuid: Uuid, shouldInvalidateList = false) => {
  const { t } = useTranslation();
  const { openSuccessAlert, openErrorAlert } = useAlertStatus();

  return useMutation(
    [ZONES_KEY, DELETE_MUTATE_KEY, uuid?.value],
    () => requests.adminGatewayDeleteZone(`${uuid?.value}`),
    {
      onSuccess() {
        openSuccessAlert(t("pages.ObjectManagement.Objects.item.zone_deleted"));
        if (shouldInvalidateList) queryClient.invalidateQueries([ZONES_KEY]);
      },
      onError() {
        openErrorAlert(t("pages.ObjectManagement.Objects.item.could_not_delete_zone"));
      }
    }
  );
};
