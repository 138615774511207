import { useRef } from "react";

export const defaultShouldUpdate = <T = any>(valueRef: React.MutableRefObject<T>, value: T) =>
  valueRef.current !== value;

export default function useSyncRef<T = any>(
  value: T,
  shouldUpdate = defaultShouldUpdate<T>
): [React.MutableRefObject<T>, T] {
  const valueRef = useRef(value);
  if (shouldUpdate(valueRef, value)) valueRef.current = value;
  return [valueRef, value];
}
