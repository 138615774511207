import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { AdminGatewayGetB2BOperationsParams } from "api/generated";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";

export const PARKINGS_ALL_OPERATIONS_REPORT_QUERY_KEY = "parkings_all_operations_report";

const useParkingsAllOperationsReport = (params: AdminGatewayGetB2BOperationsParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [PARKINGS_ALL_OPERATIONS_REPORT_QUERY_KEY, params],
    () => requests.adminGatewayGetB2BOperations(params),
    {
      onError() {
        openErrorAlert(t("pages.Reports.could_not_load_report"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useParkingsAllOperationsReport;
