import { useQuery } from "@tanstack/react-query";
import { requests } from "api";

const initialCategories = { categories: [] };

export const GET_CATEGORIES_QUERY_KEY = "get_categories";

export const useGetCategoriesQuery = () => {
  const {
    data = initialCategories,
    status,
    ...rest
  } = useQuery([GET_CATEGORIES_QUERY_KEY], () =>
    requests.adminGatewayCategories().then(({ data }) => data)
  );

  return {
    data,
    status,
    ...rest,
    isLoading: status === "loading"
  };
};
