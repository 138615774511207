import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { User } from "api/generated";
import MainInfo from "./components/MainInfo";
import { Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import RefetchTableDataButton from "components/RefetchTableDataButton";
import EventsTab from "./components/EventsTab";
import { useForm } from "react-hook-form";
import { useGetUserEventsQuery } from "hooks/queries/accounts";
import { useTranslation } from "react-i18next";

export enum TabNames {
  Events = "pages.Accounts.item.operation_history",
  Activities = "pages.Accounts.item.activity_log"
}

const AccountsCard = (props: User) => {
  const { t } = useTranslation();
  const { uuid } = props;
  const { control, setValue, reset, watch } = useForm<{ offset?: number; limit?: number }>({
    defaultValues: {
      offset: 0,
      limit: 20
    }
  });

  const offset = watch("offset") ?? 0;
  const limit = watch("limit") ?? 20;

  // TODO: uncomment once backend is ready
  // const {
  //   refetch: refetchActivities,
  //   data: activitiesData,
  //   isLoading: isActivitiesLoading,
  //   isFetching: isActivitiesFetching
  // } = useGetUserActivitiesQuery({
  //   userUuid: uuid?.value || "",
  //   offset: `${offset * limit}`,
  //   limit: `${limit}`
  // });

  const {
    refetch: refetchEvents,
    data: eventsData,
    isLoading: isEventsLoading,
    isFetching: isEventsFetching
  } = useGetUserEventsQuery({
    userUuid: uuid?.value || "",
    offset: `${offset * limit}`,
    limit: `${limit}`
  });

  const [activeTab, setActiveTab] = useState(TabNames.Events);

  const handleTabChange = (_: SyntheticEvent<Element, Event>, tab: TabNames) => {
    reset();
    setActiveTab(tab);
  };

  return (
    <Box display='flex' width='100%' flexWrap='wrap' data-testid='AccountsCard'>
      <MainInfo {...props} />
      <Divider sx={{ marginTop: 2, flex: "0 100%" }} />
      {/* <Box width="100%" padding='0 1rem'>
        <Typography variant="overline" fontWeight="bold" color={theme.palette.text.secondary}>
          Баланс счета: <Typography variant="caption" fontSize="2rem" fontWeight="normal" color={theme.palette.text.primary}>{balance}</Typography>
        </Typography>
      </Box> */}
      <Box sx={{ borderColor: "divider" }} width='100%' borderBottom={1} display='flex'>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={t(TabNames.Events)} value={TabNames.Events} />
          {/* TODO: uncomment once backend is ready */}
          {/* <Tab label={TabNames.Activities} value={TabNames.Activities} /> */}
        </Tabs>
        <RefetchTableDataButton
          sx={{ marginLeft: "auto" }}
          refetch={() => refetchEvents()}
          isLoading={isEventsLoading}
          isFetching={isEventsFetching}
          // TODO: uncomment once backend is ready
          // refetch={
          //   activeTab === TabNames.Events ? () => refetchEvents() : () => refetchActivities()
          // }
          // isLoading={activeTab === TabNames.Events ? isEventsLoading : isActivitiesLoading}
          // isFetching={activeTab === TabNames.Events ? isEventsFetching : isActivitiesFetching}
        />
      </Box>
      {/* // TODO: uncomment once backend is ready */}
      {/* <ActivitiesTab
        setValue={setValue}
        activities={activitiesData.userActivities ?? []}
        total={+(activitiesData.total || 0)}
        control={control}
        activeTab={activeTab}
      /> */}
      <EventsTab
        setValue={setValue}
        events={eventsData.userEvents ?? []}
        total={+(eventsData.total || 0)}
        control={control}
        activeTab={activeTab}
      />
    </Box>
  );
};

export default AccountsCard;
