import { createTheme, Theme } from "@mui/material";

export const createFeedbackTheme = (baseTheme: Theme) =>
  createTheme({
    ...baseTheme,
    components: {
      ...baseTheme.components,
      MuiFormHelperText: {
        ...baseTheme.components?.MuiFormHelperText,
        styleOverrides: {
          root: {
            marginLeft: 0
          }
        }
      }
    }
  });
