import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { MouseEventHandler } from "react";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { Props, getTitle } from "./utils";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "context/LocalizationContext";

const Header = ({
  canCreate,
  activeRoute,
  isFormOpen,
  currentObject,
  formType,
  isViewType,
  onAddObject,
  onBackToList
}: Props) => {
  const { stopWorkingWithMapObject } = useMapContext();
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();

  // В парковках нет number
  let number: string | undefined = "";
  if (currentObject && currentObject?.data && "number" in currentObject.data) {
    number = currentObject?.data.number;
  }

  const title = getTitle(currentObject, {
    isViewType,
    activeRoute,
    number,
    isFormOpen,
    formType,
    t,
    getValueByLang
  });

  const handleBackToList: MouseEventHandler<HTMLButtonElement> = (e) => {
    stopWorkingWithMapObject(true);
    onBackToList(e);
  };

  return (
    <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
      <Typography
        component='h2'
        variant='h5'
        fontWeight='500'
        width='100%'
        padding={2}
        paddingBottom={canCreate ? 2 : 0}
      >
        {title}
      </Typography>
      <Box display='flex' alignItems='center' padding={2} paddingTop={0}>
        {isFormOpen || isViewType ? (
          <Button variant='contained' onClick={handleBackToList}>
            {t("pages.ObjectManagement.Objects.item.to_object_list")}
          </Button>
        ) : canCreate ? (
          <Button variant='contained' onClick={onAddObject}>
            {t("pages.ObjectManagement.Objects.list.add_object")}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default Header;
