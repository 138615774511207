import Form from "../Form";
import Body from "../Body";
import { useCreateGrantMutation } from "hooks/queries/benefits";
import { useTranslation } from "react-i18next";

const AddForm = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useCreateGrantMutation();
  return (
    <Body title={t("pages.Benefits.Grants.item.new_benefit_grant")}>
      <Form isAddType submit={mutateAsync} isLoading={isLoading} />
    </Body>
  );
};

export default AddForm;
