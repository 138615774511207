import { ListItem, Typography } from "@mui/material";
import { FC } from "react";

export interface DetailItemProps {
  label: string;
  value: string;
}

const DetailItem: FC<DetailItemProps> = ({ label, value }) => {
  return (
    <ListItem
      sx={{
        flexDirection: "column",
        alignItems: "start",
        padding: 0
      }}
    >
      <Typography color='text.secondary'>{label}</Typography>
      <Typography
        sx={{
          wordWrap: "break-word"
        }}
        aria-label={label}
      >
        {value}
      </Typography>
    </ListItem>
  );
};

export default DetailItem;
