import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, LocalizationProviderProps } from "@mui/x-date-pickers";
import ruLocale from "date-fns/locale/ru";

export type DateFnsProviderProps = Omit<LocalizationProviderProps, "dateAdapter"> & {
  dateAdapter?: LocalizationProviderProps["dateAdapter"];
};

export function DateFnsProvider({ children, ...props }: DateFnsProviderProps) {
  const { dateAdapter, ...localizationProps } = props;
  return (
    <LocalizationProvider
      dateAdapter={dateAdapter || AdapterDateFns}
      {...localizationProps}
      adapterLocale={ruLocale}
    >
      {children}
    </LocalizationProvider>
  );
}
