import { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import { Link, useOutletContext } from "react-router-dom";
import { theme } from "config/theme";
import { Parking } from "api/generated";
import ActionTableCell, {
  OnDeleteClickHandler
} from "pages/ObjectManagment/Objects/components/ActionTableCell";
import { useMapContext } from "pages/ObjectManagment/Objects/context/MapContext";
import { PARKINGS_BASE_ROUTE } from "routes/route-declarations";
import { ObjectOutletContext } from "components/ObjectsSidebarContent/types";
import { MapActionMode, ObjectType } from "pages/ObjectManagment/Objects/context/types";
import { useGetParkingsQuery } from "hooks/queries/parkings";
import { useAppAbility } from "context/AbilityContext";
import Dialog from "components/Dialog";
import TablePagination from "components/ObjectsSidebarContent/components/TablePagination";
import useDialog from "hooks/useDialog";
import { useLocalizationContext } from "context/LocalizationContext";
import { useTranslation } from "react-i18next";

const initialConfirmFn = () => console.warn("Confirm is not assigned");
const defaultData: Parking[] = [];

const List = () => {
  const { t } = useTranslation();
  const { getValueByLang } = useLocalizationContext();
  const { can } = useAppAbility();
  const { setMapActionMode, showObjectOnMap } = useMapContext();
  const confirmFnRef = useRef(initialConfirmFn);
  const [parkingToDelete, setParkingToDelete] = useState<Parking | null>(null);
  const { isOpen, openDialog, closeDialog, confirm, cancel } = useDialog({
    onConfirm: () => confirmFnRef.current(),
    onClose: () => {
      setParkingToDelete(null);
      confirmFnRef.current = initialConfirmFn;
    }
  });
  const { setCurrentObject } = useOutletContext<ObjectOutletContext>();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const { data: _data, isSuccess, isLoading, refetch } = useGetParkingsQuery();
  const data = _data?.data?.parkings ?? defaultData;

  const dataToShow = useMemo(() => {
    return data.slice(offset * limit, limit * offset + limit);
  }, [data, offset, limit]);

  const handleShowOnMapClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    showObjectOnMap(e.currentTarget.dataset.zoneId || "");
  };

  const handleEditClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setCurrentObject({
      type: "parking",
      data:
        data.find((parking) => parking?.uuid?.value === e.currentTarget.dataset.parkingId) || null
    });
  };

  const handleConfirm = () => {
    confirm();
    closeDialog();
  };

  const handleDeleteClick: OnDeleteClickHandler = (deleteFn, entity) => {
    confirmFnRef.current = deleteFn;
    setParkingToDelete(entity as Parking);
    openDialog();
  };

  useEffect(() => {
    if (!isLoading && isSuccess) refetch();
    setMapActionMode(MapActionMode.ViewList);
  }, []);

  const canDelete = can("delete", "Zone");
  const canUpdate = can("update", "Zone");

  return (
    <Box marginTop={2} width='100%'>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableBody>
            {dataToShow.map((row) => {
              const pathWithId = `${PARKINGS_BASE_ROUTE}/${row.uuid?.value}`;
              const pathEdit = `${pathWithId}/edit`;

              return (
                <TableRow
                  data-testid='ParkingList__row'
                  key={row.uuid?.value}
                  sx={{ "&:last-child td": { border: 0 } }}
                >
                  <TableCell padding='none' sx={{ padding: 1 }} width={40} align='center'>
                    <IconButton
                      data-zone-id={row.uuid?.value}
                      onClick={handleShowOnMapClick}
                      data-testid='ParkingList__location-button'
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell padding='none' sx={{ padding: 1, paddingLeft: 0 }} scope='row'>
                    <Box
                      data-zone-id={row.uuid?.value}
                      color={theme.palette.primary.dark}
                      component={Link}
                      to={pathWithId}
                      onClick={handleEditClick}
                      data-testid='ParkingList__view-link'
                    >
                      {getValueByLang(row.name)}
                    </Box>
                  </TableCell>
                  <ActionTableCell
                    isDeleteButtonHidden={!canDelete}
                    isEditButtonHidden={!canUpdate}
                    onDeleteClick={handleDeleteClick}
                    objectType={ObjectType.Parking}
                    row={row}
                    pathEdit={pathEdit}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        data-testid='ParkingList__pagination'
        count={data.length}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
      />

      <Dialog
        testId='ObjectsDialog'
        title={`${t("pages.ObjectManagement.Objects.item.parkings.delete_parking_title")}`}
        message={
          <Typography>
            {`${t("pages.ObjectManagement.Objects.item.parkings.delete_parking_message")}`.replace(
              "{name}",
              getValueByLang(parkingToDelete?.name)
            )}
          </Typography>
        }
        showDeleteWarning
        confirmButtonText={t("common.delete")}
        typeError
        open={isOpen}
        onConfirm={handleConfirm}
        onCancel={cancel}
      />
    </Box>
  );
};

export default List;
