import { ReportTables } from "../../types";
import SumDistrictsTable from "./SumDistrictsTable";
import SumZonesTable from "./SumZonesTable";
import OperationsTable from "./OperationsTable";
import { ReportTab } from "../common";

export const legalReportTabs: ReportTab[] = [
  { table: ReportTables.SumDistricts, component: SumDistrictsTable },
  { table: ReportTables.SumZones, component: SumZonesTable },
  { table: ReportTables.Operations, component: OperationsTable }
];
