import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Role } from "api/generated";
import { theme } from "config/theme";
import { useLocalizationContext } from "context/LocalizationContext";
import { useTranslation } from "react-i18next";

const Item = ({ name, permissions, showDivider }: Role & { showDivider: boolean }) => {
  const { getValueByLang } = useLocalizationContext();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant='h5' sx={{ marginTop: 1, marginBottom: 1 }}>
        {getValueByLang(name)}
      </Typography>
      {permissions?.length ? (
        permissions?.map((permission) => (
          <Box key={permission?.object} marginBottom={0.5}>
            <Typography>
              {permission?.object}:{" "}
              <Typography component='span' color={theme.palette.text.secondary}>
                {permission.scopes?.join(", ")}.
              </Typography>
            </Typography>
          </Box>
        ))
      ) : (
        <Typography sx={{ marginBottom: 1 }}>
          {t("pages.Roles.permissions_not_specified")}
        </Typography>
      )}
      {showDivider && <Divider />}
    </Box>
  );
};

export default Item;
