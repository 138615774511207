import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { theme } from "config/theme";
import { addMonths, format } from "date-fns";
import { Holiday } from "api/generated";
import addDays from "date-fns/addDays/index.js";
import { StyledDatePicker, StyledPickersDay } from "../styled";
import { HandleDateClick } from "../..";
import { useTranslation } from "react-i18next";

type Props = {
  canCreateOrUpdate: boolean;
  currentDate: Date;
  holidays: Holiday[];
  onDateClick: HandleDateClick;
};

const Calendar = ({ canCreateOrUpdate, currentDate, holidays, onDateClick }: Props) => {
  const { t } = useTranslation();
  const currentDateValueOf = currentDate.valueOf();
  const endOfCurrentMonthValueOf = addDays(addMonths(currentDate, 1), -1).valueOf();

  const holidaysInCurrentMonth = holidays.filter((holiday) => {
    const date = parseInt(holiday.date ?? "0", 10);
    return date >= currentDateValueOf && date <= endOfCurrentMonthValueOf;
  });

  return (
    <Box
      data-testid='Calendar'
      flex='0 0 calc(25% - 16px)'
      display='inline-block'
      border={1}
      marginLeft='8px'
      marginRight='8px'
      marginBottom={3}
      borderColor={theme.palette.primary.main}
      sx={{
        [theme.breakpoints.down(1482)]: {
          flex: "0 0 calc(33.3333% - 16px)"
        },
        [theme.breakpoints.down(1144)]: {
          flex: "0 0 calc(50% - 16px)"
        },
        [theme.breakpoints.down(808)]: {
          flex: "0 0 calc(100% - 16px)"
        }
      }}
    >
      <Typography
        data-testid='Calendar__month-title'
        textAlign='center'
        marginTop={1.5}
        fontWeight='bold'
        sx={{
          "&::first-letter": {
            textTransform: "capitalize"
          }
        }}
      >
        {t(`pages.HolidaysCalendar.item.${format(currentDate, "LLL").toLowerCase()}`)}
      </Typography>
      <StyledDatePicker
        onChange={() => null}
        value={null}
        disableHighlightToday
        displayStaticWrapperAs='desktop'
        defaultCalendarMonth={currentDate}
        renderDay={(value, _, props) => {
          const [currentYear, currentMonth, currentDay] = ((_value) => [
            _value.getFullYear(),
            _value.getMonth(),
            _value.getDate()
          ])(value as Date);

          const holiday = holidaysInCurrentMonth.find((holiday) => {
            const holidayDate = new Date(parseInt(holiday.date ?? "0", 10));
            const [holidayYear, holidayMonth, holidayDay] = [
              holidayDate.getFullYear(),
              holidayDate.getMonth(),
              holidayDate.getDate()
            ];

            return (
              currentYear === holidayYear &&
              currentMonth === holidayMonth &&
              currentDay === holidayDay
            );
          });

          const classNames = [
            !canCreateOrUpdate && !holiday && "not-clickable",
            holiday && holiday?.type
          ];

          return (
            <StyledPickersDay
              {...props}
              data-testid='Calendar__day'
              data-date={`${currentMonth + 1}.${currentDay}.${currentYear}`}
              className={classNames.filter(Boolean).join(" ")}
              onClick={
                canCreateOrUpdate || holiday?.uuid ? onDateClick(value as Date, holiday) : undefined
              }
            />
          );
        }}
        components={{
          ActionBar: () => null,
          SwitchViewButton: () => null
        }}
        showToolbar={false}
        openTo='day'
        renderInput={() => <span style={{ display: "none" }} />}
      />
    </Box>
  );
};

export default Calendar;
