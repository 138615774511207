import { Control, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DataPicker from "ui-kit/form/DataPicker";
import { ReportFiltersForm } from "../../types";
import { FormControl } from "@mui/material";

interface PeriodDatePickerProps {
  label?: string;
  control: Control<ReportFiltersForm>;
  name: Path<ReportFiltersForm>;
}

const PeriodDatePicker = (props: PeriodDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <DataPicker
        rules={{
          required: {
            value: true,
            message: t("common.input_rules.required")
          }
        }}
        inputFormat='dd.MM.yyyy'
        {...props}
      />
    </FormControl>
  );
};

export default PeriodDatePicker;
