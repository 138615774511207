import { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Abonement } from "api/generated";
import { useGetAbonementsQuery } from "hooks/queries/abonements";
import LinkButton from "ui-kit/buttons/LinkButton";
import ListAbonement from "./components/ListItem";
import { ABONEMENTS_ADD_ROUTE } from "routes/route-declarations";
import { useAppAbility } from "context/AbilityContext";
import { useGetSystemSettingsConfigQuery } from "hooks/queries/system-settings";
import { useTranslation } from "react-i18next";

const AbonementList = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const { refetch, isLoading, data } = useGetAbonementsQuery({});
  const {
    data: { currency }
  } = useGetSystemSettingsConfigQuery();
  const abonements = useMemo(() => {
    const abonements = (data as { abonements?: Abonement[] })?.abonements;
    return abonements?.filter((abonement) => abonement.active);
  }, [data]);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography component='h2' variant='h5' padding='1rem 0'>
          {t("pages.Abonements.Active.active_abonements")}
        </Typography>
        {can("create", "Abonement") && (
          <LinkButton variant='contained' to={ABONEMENTS_ADD_ROUTE}>
            {t("pages.Abonements.Active.add_abonement")}
          </LinkButton>
        )}
      </Box>
      {isLoading || !currency ? (
        <Typography padding='1rem' variant='h6'>
          {t("pages.Abonements.Active.loading_abonements")}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {abonements?.map((abonement) => (
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={abonement.uuid?.value}
              data-testid='benefit-item'
            >
              <ListAbonement refetchList={refetch} {...abonement} currency={currency} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default AbonementList;
