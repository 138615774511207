import { Category, SubCategoryType, SubCategoryZoneType, Uuid } from "api/generated";
import { getActionInfo } from "../utils";

export enum CategoryFormType {
  CreateCategory = "create_category",
  EditCategory = "edit_category",
  ViewCategory = "view_category",
  DeleteCategory = "delete_category",
  CreateSubCategory = "create_sub_category",
  EditSubCategory = "edit_sub_category",
  ViewSubCategory = "view_sub_category",
  DeleteSubCategory = "delete_sub_category"
}
export type SetFormInfo = (
  type?: CategoryFormType | null,
  uuid?: Uuid | null,
  parentUuid?: Uuid | null
) => void;

export type FormInfo = {
  type?: CategoryFormType | null;
  uuid?: Uuid | null;
  parentUuid?: Uuid | null;
};

export type SubCategoryFormValues = {
  parent: Uuid;
  type: SubCategoryType;
  zoneType: SubCategoryZoneType;
} & Category;

export type ActionInfo = ReturnType<typeof getActionInfo>;
