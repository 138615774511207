import React, { useState } from "react";
import CardSidebar from "components/CardSidebar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Card from "components/Card";
import AccountShortCard from "./components/AccountShortCard";
import SidebarAccountsList, { SidebarAccountsListProps } from "./components/SidebarAccountList";
import { useGetAccountsQuery } from "hooks/queries/accounts";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { UserStatus } from "api/generated";

const AccountsList = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<SidebarAccountsListProps["query"]>({
    offset: 0,
    limit: 20,
    isEmployee: false,
    period: "all",
    status: UserStatus.USER_STATUS_UNSPECIFIED
  });

  const { period: _, ...requestQuery } = query;
  const { isLoading, isFetching, data, refetch } = useGetAccountsQuery(
    { ...requestQuery, offset: (requestQuery.offset || 0) * (requestQuery.limit || 0) },
    {}
  );

  return (
    <div>
      <Box display='flex' flexWrap='nowrap' height='100%'>
        <CardSidebar
          header={
            <Box
              display='flex'
              flexWrap='nowrap'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography component='h2' variant='h5' fontWeight='500' padding={2}>
                {t("pages.Accounts.list.users")}
              </Typography>
              {(isLoading || isFetching) && <CircularProgress size={26} sx={{ marginRight: 2 }} />}
            </Box>
          }
        >
          <SidebarAccountsList
            handleFilter={setQuery}
            refetch={refetch}
            query={query}
            count={data.total || 0}
            isLoading={isLoading || isFetching}
          />
        </CardSidebar>
        <Card>
          {/* <div>Поиск</div>
          <Divider sx={{ marginTop: 2 }} /> */}
          <List>
            {data?.users?.length && data.users.length > 0 ? (
              data.users.map((user) => (
                <React.Fragment key={user.uuid?.value}>
                  <AccountShortCard {...user} />
                  <Divider sx={{ marginTop: 2 }} />
                </React.Fragment>
              ))
            ) : isLoading || isFetching ? (
              <Typography padding={1} variant='h6'>
                {t("pages.Accounts.list.loading_users")}
              </Typography>
            ) : (
              <Typography padding={1} variant='h6'>
                {t("pages.Accounts.list.no_users_found")}
              </Typography>
            )}
          </List>
        </Card>
      </Box>
    </div>
  );
};

export default AccountsList;
