import { useForm } from "react-hook-form";
import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  formatISO
} from "date-fns";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Select from "ui-kit/form/Select";
import InputRadio from "ui-kit/form/Radio";
import DataPicker from "ui-kit/form/DataPicker";
import { useTranslation } from "react-i18next";
import Input from "ui-kit/form/Input";
import TablePagination from "../../../components/TablePagination";
import { AdminGatewayUserListParams, GetUserListResponse, UserStatus } from "api/generated";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";

const { USER_STATUS_ACTIVE, USER_STATUS_BLOCKED, USER_STATUS_UNSPECIFIED } = UserStatus;

export type SidebarAccountsListProps = {
  handleFilter: React.Dispatch<React.SetStateAction<SidebarAccountsListProps["query"]>>;
  query: AdminGatewayUserListParams & { period?: string };
  count: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GetUserListResponse, unknown>>;
  isLoading?: boolean;
};

type DefaultValues = Omit<
  SidebarAccountsListProps["query"],
  "activityStartDate" | "activityEndDate"
> & {
  activityStartDate?: DateTime | null | undefined;
  activityEndDate?: DateTime | null | undefined;
};

const formHasChanges = (cur: DefaultValues, prev: DefaultValues) =>
  Object.entries(prev).some(([key, value]) => {
    if (key === "offset" || key === "limit") return false;

    return `${cur[key as keyof DefaultValues]}` !== `${value}`;
  });

const SidebarAccountsList = ({ handleFilter, count, query, refetch }: SidebarAccountsListProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit: onSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues
  } = useForm<DefaultValues>({
    defaultValues: {
      offset: query.offset,
      limit: query.limit,
      activityStartDate: null,
      activityEndDate: null,
      isEmployee: query.isEmployee,
      status: query.status,
      period: "all"
    }
  });

  const prevFormValues = useRef<DefaultValues>(getValues());
  const [isPaginationButtonsDisabled, setIsPaginationButtonsDisabled] = useState(false);

  const handleSubmit = (data: any) => {
    const { period, isEmployee, status, phone, offset = 0, limit = 20 } = data;
    const formattedPhone = phone ? phone.replace(/\D/g, "") : "";
    let start;
    let end;
    switch (period) {
      case "yesterday":
        start = startOfYesterday();
        end = endOfYesterday();
        break;
      case "today":
        start = startOfToday();
        end = endOfToday();
        break;
      case "week":
        start = startOfWeek(new Date(), { weekStartsOn: 1 });
        end = endOfWeek(new Date(), { weekStartsOn: 1 });
        break;
      case "month":
        start = startOfMonth(new Date());
        end = endOfMonth(new Date());
        break;
      case "period":
        start = period.activityStartDate;
        end = period.activityEndDate;
        break;
    }

    prevFormValues.current = getValues();
    setIsPaginationButtonsDisabled(false);

    if (start && end) {
      return handleFilter({
        offset,
        limit,
        activityStartDate: formatISO(start),
        activityEndDate: formatISO(end),
        isEmployee,
        phone: formattedPhone,
        status
      });
    }
    return handleFilter({
      offset,
      limit,
      isEmployee,
      phone: formattedPhone,
      status
    });
  };

  const setOffset = (offset: number) => {
    setValue("offset", offset);
    const formValues = getValues();
    if (!formHasChanges(formValues, prevFormValues.current)) {
      handleSubmit(getValues());
    }
  };

  const setLimit = (limit: number) => {
    setValue("limit", limit);
    const formValues = getValues();
    if (!formHasChanges(formValues, prevFormValues.current)) {
      handleSubmit(formValues);
    }
  };

  const limit = watch("limit") || 20;

  const handleClear = () => {
    reset();
    setLimit(limit);
    handleFilter({ limit });
  };

  const handleSearchClick = () =>
    formHasChanges(getValues(), prevFormValues.current) ? setValue("offset", 0) : refetch();

  useEffect(() => {
    setIsPaginationButtonsDisabled(formHasChanges(getValues(), prevFormValues.current));
  }, [
    watch("period"),
    watch("activityStartDate"),
    watch("activityEndDate"),
    watch("status"),
    watch("isEmployee")
  ]);

  return (
    <form
      onSubmit={onSubmit(handleSubmit)}
      style={{ width: "100%" }}
      data-testid='AccountSidebarForm'
    >
      <FormControl margin='none' fullWidth>
        <FormLabel>{t("pages.Accounts.list.phone")}:</FormLabel>
        <Input
          control={control}
          name='phone'
          variant='standard'
          size='small'
          rules={{
            validate(value: string) {
              if (value && value.replace(/\D/g, "").length < 3) {
                return t("pages.Accounts.list.input_rules.minimum_3_digits");
              }
            }
          }}
        />
      </FormControl>
      <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
        <FormLabel>{t("pages.Accounts.list.is_employee")}:</FormLabel>
        <InputRadio
          control={control}
          name='isEmployee'
          options={[
            { value: "false", label: t("common.no_matter") },
            { value: "true", label: t("common.yes") }
          ]}
          defaultValue='false'
        />
      </FormControl>
      <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
        <FormLabel>{t("pages.Accounts.list.last_activity")}:</FormLabel>
        <InputRadio
          control={control}
          name='period'
          options={[
            { value: "all", label: t("pages.Accounts.list.all_period") },
            { value: "yesterday", label: t("pages.Accounts.list.yesterday") },
            { value: "today", label: t("pages.Accounts.list.today") },
            { value: "week", label: t("pages.Accounts.list.this_week") },
            { value: "month", label: t("pages.Accounts.list.this_month") },
            { value: "period", label: t("pages.Accounts.list.specify_period") }
          ]}
          defaultValue='all'
        />
      </FormControl>
      {watch("period") === "period" && (
        <>
          <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
            <DataPicker
              control={control}
              name='activityStartDate'
              label={`${t("pages.Accounts.list.period_start")}:`}
              inputFormat='dd.MM.yyyy'
              maxDate={watch("activityEndDate")}
            />
          </FormControl>
          <FormControl margin='none' sx={{ marginTop: 1 }} fullWidth>
            <DataPicker
              control={control}
              name='activityEndDate'
              label={`${t("pages.Accounts.list.period_end")}:`}
              inputFormat='dd.MM.yyyy'
              minDate={watch("activityStartDate")}
            />
          </FormControl>
        </>
      )}
      <FormControl margin='none' sx={{ marginTop: 3 }} fullWidth>
        <Select
          control={control}
          name='status'
          size='small'
          label={`${t("pages.Accounts.list.status")}:`}
          disabled={false}
          defaultValue={USER_STATUS_UNSPECIFIED}
        >
          <MenuItem value={USER_STATUS_UNSPECIFIED}>
            {t("pages.Accounts.list.not_specified")}
          </MenuItem>
          <MenuItem value={USER_STATUS_ACTIVE}>{t("pages.Accounts.list.active")}</MenuItem>
          <MenuItem value={USER_STATUS_BLOCKED}>{t("pages.Accounts.list.blocked")}</MenuItem>
        </Select>
      </FormControl>
      <Stack spacing={2} direction='row' sx={{ marginTop: 2 }}>
        <Button type='button' variant='outlined' onClick={handleClear}>
          {t("common.clear")}
        </Button>
        <Button type='submit' variant='contained' onClick={handleSearchClick}>
          {t("common.search")}
        </Button>
      </Stack>
      <TablePagination
        count={count}
        limit={limit}
        disabled={isPaginationButtonsDisabled}
        offset={watch("offset") || 0}
        setLimit={setLimit}
        setOffset={setOffset}
      />
    </form>
  );
};

export default SidebarAccountsList;
