import TablePaginationMui from "@mui/material/TablePagination";
import TablePaginationActions from "components/TablePaginationActions";
import { ChangeEvent, memo } from "react";

export type TablePaginationProps = {
  count: number;
  offset: number;
  limit: number;
  setOffset: (offset: number) => void;
  setLimit: (limit: number) => void;
};

const TablePagination = ({
  count,
  offset,
  limit,
  setOffset,
  setLimit,
  ...props
}: TablePaginationProps) => {
  const handlePageInputChange = (page: number) => setOffset(page - 1);
  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(+e?.target.value);
    setOffset(0);
  };

  return (
    <TablePaginationMui
      sx={{
        "& .MuiToolbar-root": {
          flexWrap: "wrap",
          justifyContent: "center",
          paddingLeft: 0,
          "& .MuiTablePagination-spacer": { display: "none" },
          "& > .MuiInputBase-root": { marginRight: "5px" },
          "& .MuiTablePagination-actions": { marginLeft: 0 },
          "& > .MuiBox-root": {
            marginLeft: 0,
            width: "100%",
            justifyContent: "center"
          }
        }
      }}
      rowsPerPageOptions={[5, 10, 20]}
      component='div'
      count={count}
      rowsPerPage={limit}
      page={offset}
      showFirstButton
      showLastButton
      labelRowsPerPage={null}
      onPageChange={(_, page) => setOffset(page)}
      onRowsPerPageChange={handleRowsPerPageChange}
      {...props}
      ActionsComponent={(props) => (
        <TablePaginationActions
          onInputChange={handlePageInputChange}
          offset={offset}
          limit={limit}
          {...props}
        />
      )}
    />
  );
};

// Если инпут пагинации в фокусе и пользователь наводит курсор мыши
// на объект на карте, то происходит перерисовка и инпут теряет фокус.
// Поэтому нужно предотвартить ререндер компонента, если никакие пропсы
// не изменились
export default memo(TablePagination);
