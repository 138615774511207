import { styled } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers";
import { HolidayType } from "api/generated";
import { FC } from "react";

const { HOLIDAY_TYPE_HOLIDAY, HOLIDAY_TYPE_DAYOFF } = HolidayType;

export const StyledLinkButton: FC<ButtonProps & { to: string }> = styled(Button)(({ theme }) => ({
  flex: "0 0 210px",
  marginLeft: "auto",
  color: theme.palette.primary.contrastText
}));

export const StyledDatePicker = styled(StaticDatePicker)({
  "& .MuiPickersCalendarHeader-root": {
    display: "none"
  }
});

export const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  [`&.${HOLIDAY_TYPE_DAYOFF}`]: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.primary.contrastText
  },
  [`&.${HOLIDAY_TYPE_HOLIDAY}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText
  },
  ["&.not-clickable"]: {
    cursor: "unset"
  }
}));
