import { useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { RequestParams } from "api/generated";

const initialRoles = { roles: [] };

export const GET_ROLES_QUERY_KEY = "get_roles";

export const useGetRolesQuery = (params?: RequestParams) => {
  const { data = initialRoles, ...rest } = useQuery([GET_ROLES_QUERY_KEY, params], () =>
    requests.adminGatewayRoleList(params).then(({ data }) => data)
  );

  return {
    data,
    ...rest
  };
};
